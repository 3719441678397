import React from 'react';

const FeedbackMessage = ({ message }) => {
  if (!message) return null;

  return (
    <div
      style={{
        color: 'white',
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: '15px',
        marginBottom: '15px'
      }}
    >
      <p>{message}</p>
    </div>
  );
};

export default FeedbackMessage;
