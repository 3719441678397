import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  // Handle the change of page
  const handlePageChange = page => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const activeButtonStyle = {
    backgroundColor: '#2bbbae',
    color: 'white'
  };

  const currentPageStyle = {
    backgroundColor: '#f15a29',
    color: 'white'
  };

  const inactiveButtonStyle = {
    backgroundColor: '#323346', // grey color
    color: 'white'
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Pagination>
        {/* First button */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first style={currentPage === 1 ? inactiveButtonStyle : activeButtonStyle} onClick={() => handlePageChange(1)}>
            First
          </PaginationLink>
        </PaginationItem>

        {/* Previous button */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink previous style={currentPage === 1 ? inactiveButtonStyle : activeButtonStyle} onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </PaginationLink>
        </PaginationItem>

        {/* Current Page */}
        <PaginationItem>
          <PaginationLink style={currentPageStyle}>
            {currentPage} / {totalPages}
          </PaginationLink>
        </PaginationItem>

        {/* Next button */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink next style={currentPage === totalPages ? inactiveButtonStyle : activeButtonStyle} onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </PaginationLink>
        </PaginationItem>

        {/* Last button */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last style={currentPage === totalPages ? inactiveButtonStyle : activeButtonStyle} onClick={() => handlePageChange(totalPages)}>
            Last
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
