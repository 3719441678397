import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import './Marker.css';
import config from '../../config';
import { useGlobalContext } from '../../context';
import Polyline from './Polylines';
import Polygon from './Polygons';
import defaultDriverProfile from '../../assets/img/default_driver_profile.png';
const Marker = (props: any) => {
  const { lat, lng, color, name, id } = props;
  return <div className="marker" style={{ backgroundColor: color, cursor: 'pointer' }} title={name} />;
};

const DispatchMap = props => {
  const {
    coordinates,
    routeClick,
    routeClickTwice,
    enrouteClickDriver,
    readyRoutes,
    deliveringRoutes,
    availableDrivers,
    enrouteDrivers,
    selectedMergingRoutes,
    showMergingRoutesMap,
    displayPolygon
  } = useGlobalContext();
  const [defaultCenter, setDefaultCenter] = useState({ lat: 30.23119, lng: -93.21163 });
  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState('');
  const [maps, setMaps] = useState('');
  const [mapsLoaded, setMapsLoaded] = useState(false);

  const [mapActive, setMapActive] = useState(false);

  const [hoveredDriver, setHoveredDriver] = useState(null);

  const handleDriverMarkerHover = driver => {
    setHoveredDriver(driver);
  };

  const handleDriverMarkerLeave = () => {
    setHoveredDriver(null);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      setMapActive(true);
    }, 50);

    return () => {
      window.clearTimeout(t);
    };
  }, []);

  const drawPolylines = (map, maps, routes) => {
    return (
      <div style={{ display: 'none' }}>
        <Polyline map={map} maps={maps} routes={routes} />
      </div>
    );
  };

  const drawPolygons = (map, maps, routes) => {
    return (
      <div style={{ display: 'none' }}>
        <Polygon map={map} maps={maps} routes={routes} />
      </div>
    );
  };

  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    setMapsLoaded(true);
  };

  const DriverMarker = ({ name, phone, employment_type, vehicle_info, permit, photo, onHover, onLeave, isHovered, color }) => (
    <div onMouseOver={onHover} onMouseOut={onLeave} style={{ position: 'relative', cursor: 'pointer', padding: '15px' }}>
      <div style={{ position: 'absolute', width: '15px', height: '15px', borderRadius: '50%', backgroundColor: color }} />
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '-50px',
            left: '-60px',
            padding: '10px',
            background: 'white',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
            width: 'auto'
          }}
        >
          <p style={{ color: 'black', fontSize: 15, fontWeight: 'bold', margin: '0 0 5px 0', whiteSpace: 'nowrap' }}>{name}</p>
          <p style={{ color: 'black', fontSize: 13, margin: '0 0 5px 0' }}>{phone}</p>
          <p style={{ color: 'black', fontSize: 13, margin: '0 0 5px 0' }}>{employment_type}</p>
          <p style={{ color: 'black', fontSize: 13, margin: '0 0 5px 0' }}>{vehicle_info}</p>
          {permit && <p style={{ color: 'black', fontSize: 13, margin: '0 0 5px 0' }}>Permit: {permit ? permit : 'N/A'}</p>}
          {photo && <img src={photo} alt="Driver Profile" style={{ width: '150px', height: 'auto' }} />}
        </div>
      )}
    </div>
  );

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {mapActive && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.config.GOOGLE_MAP.accessKey, libraries: ['places'] }}
          defaultCenter={defaultCenter}
          center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
          defaultZoom={zoom}
          layerTypes={props.trafficLayer ? ['TrafficLayer'] : []}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {/* available drivers */}
          {availableDrivers.length > 0
            ? availableDrivers.map(driver => {
                let permits = [];
                if (driver.can_deliver_alcohol) {
                  permits.push('Alcohol');
                }
                driver.restricted_area_credentials.map(credential => {
                  permits.push(credential);
                });
                let permit = permits.map(permit => permit).join(', ');
                let vehicleUsed = driver.Vehicles[0];
                let vehicle_info = vehicleUsed.year + ' ' + vehicleUsed.make + ' ' + vehicleUsed.model + ' (' + vehicleUsed.color + ')';
                return (
                  <DriverMarker
                    key={driver.driver_id}
                    lat={driver.LastDriverActions.location_blob.lat}
                    lng={driver.LastDriverActions.location_blob.lng}
                    name={driver.User.firstname + ' ' + driver.User.lastname}
                    phone={driver.User.phone}
                    employment_type={driver.employment_type}
                    vehicle_info={vehicle_info}
                    permit={permit}
                    photo={driver.profile_photo_url || defaultDriverProfile}
                    onHover={() => handleDriverMarkerHover(driver)}
                    onLeave={handleDriverMarkerLeave}
                    isHovered={hoveredDriver === driver}
                    color="pink"
                  />
                );
              })
            : null}
          {/* enroute drivers */}
          {enrouteDrivers.length > 0
            ? enrouteDrivers.map(driver => {
                let permits = [];
                if (driver.can_deliver_alcohol) {
                  permits.push('Alcohol');
                }
                driver.restricted_area_credentials.map(credential => {
                  permits.push(credential);
                });
                let permit = permits.map(permit => permit).join(', ');
                let vehicleUsed = driver.Vehicles[0];
                let vehicle_info = vehicleUsed.year + ' ' + vehicleUsed.make + ' ' + vehicleUsed.model + ' (' + vehicleUsed.color + ')';
                return (
                  <DriverMarker
                    key={driver.driver_id}
                    lat={driver.LastDriverActions.location_blob.lat}
                    lng={driver.LastDriverActions.location_blob.lng}
                    name={driver.User.firstname + ' ' + driver.User.lastname}
                    phone={driver.User.phone}
                    employment_type={driver.employment_type}
                    vehicle_info={vehicle_info}
                    permit={permit}
                    photo={driver.profile_photo_url || defaultDriverProfile}
                    onHover={() => handleDriverMarkerHover(driver)}
                    onLeave={handleDriverMarkerLeave}
                    isHovered={hoveredDriver === driver}
                    color="orange"
                  />
                );
              })
            : null}
          {/* single route click */}
          {routeClick != ''
            ? routeClick.path.map(location => {
                return location.actions.hasOwnProperty('pick_up') && !location.actions['pick_up'].item[0].includes('(drop)') ? (
                  <Marker
                    lat={location.address.lat}
                    lng={location.address.lng}
                    name={
                      'Route_ID: ' +
                      routeClick.route_friendly_id +
                      '\nShip_ID:' +
                      location.ship_id +
                      '\nPick Up: ' +
                      location.address.name +
                      '\nAddress: ' +
                      location.address.address_1
                    }
                    color={location.is_waypoint_completed ? 'green' : 'blue'}
                  />
                ) : (
                  <Marker
                    lat={location.address.lat}
                    lng={location.address.lng}
                    name={
                      'Route_ID: ' +
                      routeClick.route_friendly_id +
                      '\nShip_ID:' +
                      location.ship_id +
                      '\nDrop Off: ' +
                      location.address.name +
                      '\nAddress: ' +
                      location.address.address_1
                    }
                    color={location.is_waypoint_completed ? 'green' : 'red'}
                  />
                );
              })
            : null}
          {/* TQL tracking for single route click */}
          {routeClick !== '' &&
          (routeClick.status === 'route_assigned' || routeClick.status === 'route_claimed' || routeClick.status === 'route_completed') &&
          routeClick.RouteDrivers[0].tracking_blob &&
          routeClick.RouteDrivers[0].tracking_blob.hasOwnProperty('status') &&
          (routeClick.RouteDrivers[0].tracking_blob.status === 'IN TRANSIT' || routeClick.RouteDrivers[0].tracking_blob.status === 'DELIVERED') &&
          routeClick.RouteDrivers[0].tracking_blob.hasOwnProperty('trackingDetails') &&
          routeClick.RouteDrivers[0].tracking_blob.trackingDetails.length > 0
            ? routeClick.RouteDrivers[0].tracking_blob.trackingDetails.map(track => {
                return <Marker lat={track.latitude} lng={track.longitude} name={'Time: ' + track.time + '\nLocation:' + track.location} color={'gray'} />;
              })
            : null}
          {routeClick !== '' && (routeClick.status === 'route_assigned' || routeClick.status === 'route_claimed') && enrouteClickDriver !== '' && (
            <DriverMarker
              key={enrouteClickDriver.LastDriverActions.driver_id}
              lat={enrouteClickDriver.LastDriverActions.location_blob.lat}
              lng={enrouteClickDriver.LastDriverActions.location_blob.lng}
              name={enrouteClickDriver.User.first_name + ' ' + enrouteClickDriver.User.last_name}
              phone={enrouteClickDriver.User.phone}
              employment_type={enrouteClickDriver.User.employment_type}
              vehicle_info={enrouteClickDriver.User.vehicle_info}
              permit={enrouteClickDriver.User.permit}
              photo={enrouteClickDriver.User.profile_photo_url || defaultDriverProfile}
              onHover={() => handleDriverMarkerHover(enrouteClickDriver)}
              onLeave={handleDriverMarkerLeave}
              isHovered={hoveredDriver === enrouteClickDriver}
              color="orange"
            />
          )}
          {routeClick !== '' && mapsLoaded ? drawPolylines(map, maps, [routeClick]) : routeClick === '' && mapsLoaded ? drawPolylines(null, maps, [routeClickTwice]) : null}
          {routeClick !== '' && mapsLoaded && displayPolygon
            ? drawPolygons(map, maps, [routeClick])
            : routeClick !== '' && mapsLoaded && !displayPolygon
            ? drawPolygons(null, maps, [routeClick])
            : routeClick === '' && mapsLoaded
            ? drawPolygons(null, maps, [routeClickTwice])
            : null}
          {/* ready routes */}
          {readyRoutes.length > 0
            ? readyRoutes.map(route => {
                return route.path.map(pa => {
                  return pa.actions.hasOwnProperty('pick_up') && !pa.actions['pick_up'].item[0].includes('(drop)') ? (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nPick Up: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'blue'}
                    />
                  ) : (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nDrop Off: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'red'}
                    />
                  );
                });
              })
            : null}
          {readyRoutes.length > 0 && mapsLoaded ? drawPolylines(map, maps, readyRoutes) : null}
          {readyRoutes.length > 0 && mapsLoaded && displayPolygon ? drawPolygons(map, maps, readyRoutes) : null}
          {/* delivering routes */}
          {deliveringRoutes.length > 0
            ? deliveringRoutes.map(route => {
                return route.path.map(pa => {
                  return pa.actions.hasOwnProperty('pick_up') && !pa.actions['pick_up'].item[0].includes('(drop)') ? (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nPick Up: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'blue'}
                    />
                  ) : (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nDrop Off: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'red'}
                    />
                  );
                });
              })
            : null}
          {deliveringRoutes.length > 0 && mapsLoaded ? drawPolylines(map, maps, deliveringRoutes) : null}
          {deliveringRoutes.length > 0 && mapsLoaded && displayPolygon ? drawPolygons(map, maps, deliveringRoutes) : null}
          {/* merging routes */}
          {selectedMergingRoutes.length > 0 && showMergingRoutesMap
            ? selectedMergingRoutes.map(route => {
                return route.path.map(pa => {
                  return pa.actions.hasOwnProperty('pick_up') && !pa.actions['pick_up'].item[0].includes('(drop)') ? (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nPick Up: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'blue'}
                    />
                  ) : (
                    <Marker
                      lat={pa.address.lat}
                      lng={pa.address.lng}
                      name={'Route_ID: ' + route.route_friendly_id + '\nShip_ID:' + pa.ship_id + '\nDrop Off: ' + pa.address.name + '\nAddress: ' + pa.address.address_1}
                      color={pa.is_waypoint_completed ? 'green' : 'red'}
                    />
                  );
                });
              })
            : null}
          {selectedMergingRoutes.length > 0 && mapsLoaded && showMergingRoutesMap ? drawPolylines(map, maps, selectedMergingRoutes) : null}
          {selectedMergingRoutes.length > 0 && mapsLoaded && showMergingRoutesMap && displayPolygon ? drawPolygons(map, maps, selectedMergingRoutes) : null}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default DispatchMap;
