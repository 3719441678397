import React, { useState } from 'react';
import * as shipment_urls from '../../urls/api-shipments/merchant';
import config from '../../config';
import { Modal, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf'; // Only if using react-pdf
import axios from 'axios';
const clientEnv = process.env.REACT_APP_CLIENT_ENV || (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage' ? 'production' : 'development');

// Set the worker path to the PDF.js worker file
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FileViewer = ({ shipmentId, showGenerateBolButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [fileUrl, setFileUrl] = useState(null);
  const [viewFileModal, setViewFileModal] = useState(false);
  const [files, setFiles] = useState([]); // Array of files
  const [selectedFile, setSelectedFile] = useState(null); // Currently selected file
  const [fileType, setFileType] = useState(null);
  const [pdfRendered, setPdfRendered] = useState(false); // Track PDF rendering
  const [fetchError, setFetchError] = useState(null);
  const [noFileAvailable, setNoFileAvailable] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false); // Track loading state for `generateBol`

  const fetchFileUrl = async () => {
    setFetchError(null); // Reset error state
    setNoFileAvailable(false); // Reset no-file state

    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        url: shipment_urls.shipmentHost + '/shipments/' + shipmentId + '/documents'
      });

      if (response.data.status === 'successful' && response.data.payload.length > 0) {
        setFiles(response.data.payload);
      } else {
        setNoFileAvailable(true); // No file found
      }
    } catch (error) {
      console.log('get-bol-error: ', error);
      setFetchError('An error occurred while fetching the files.'); // Set fetch error message
    }
  };

  const generateBol = async () => {
    setLoadingGenerate(true); // Show loading state
    try {
      await axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'multipart/form-data'
        },
        url: config.config.api_files.host + `/shipments/${shipmentId}/bol/generate?client_env=${clientEnv}`
      });

      // Auto-refresh the file list after success
      await fetchFileUrl();
    } catch (error) {
      console.error('Error generating BOL:', error);
    } finally {
      setLoadingGenerate(false); // Reset loading state
    }
  };

  const handleOpenModal = async () => {
    await fetchFileUrl();
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedFile(null);
    setFileType(null);
    setPdfRendered(false); // Reset rendered state
    setFetchError(null);
    setNoFileAvailable(false);
  };

  const handleOpenFileModal = file => {
    setSelectedFile(file.signed_url);
    setFileType(file.file_name.endsWith('.pdf') ? 'pdf' : 'image');
    setPdfRendered(false);
    setViewFileModal(true);
  };

  const handleCloseFileModal = () => {
    setViewFileModal(false);
    setSelectedFile(null);
    setFileType(null);
    setPdfRendered(false);
  };

  //   const handleFileClick = file => {
  //     setSelectedFile(file.signed_url);
  //     setFileType(file.file_name.endsWith('.pdf') ? 'pdf' : 'image');
  //     setPdfRendered(false);
  //   };

  const handleSaveFile = () => {
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile;
      link.download = selectedFile.substring(selectedFile.lastIndexOf('/') + 1);
      link.click();
    }
  };

  const handlePrint = () => {
    if (!selectedFile) {
      console.error('No file selected.');
      return;
    }

    const printWindow = window.open('', '', 'height=800,width=600');

    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write(
      '<style>body { margin: 0; display: flex; justify-content: center; align-items: center; } #printContent { display: flex; justify-content: center; align-items: center; }</style></head><body>'
    );
    printWindow.document.write('<div id="printContent"></div>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    const printContent = printWindow.document.getElementById('printContent');

    if (fileType === 'pdf') {
      // Handle PDF printing
      if (!pdfRendered) {
        console.error('PDF is not fully loaded yet.');
        return;
      }

      pdfjs.getDocument(selectedFile).promise.then(pdf => {
        pdf.getPage(1).then(page => {
          const printDPI = 200; // High DPI for print quality
          const scale = printDPI / 72; // Standard PDF DPI is 72

          // Get viewport based on this higher scale
          const viewport = page.getViewport({ scale });

          // Adjust canvas to match the scaled viewport dimensions
          const canvas = printWindow.document.createElement('canvas');
          const context = canvas.getContext('2d');

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          printContent.appendChild(canvas);

          // Render the PDF page onto the canvas
          page.render({ canvasContext: context, viewport }).promise.then(() => {
            // Adjust the canvas style to scale within the print window dimensions
            canvas.style.maxWidth = '100%';
            canvas.style.maxHeight = '100%';

            // Wait briefly to ensure rendering completes
            setTimeout(() => {
              printWindow.print(); // Open the print dialog
              closePrintWindow(printWindow); // Close after printing
            }, 1000); // Delay for rendering and print dialog to open
          });
        });
      });
    } else if (fileType === 'image') {
      // Handle image printing
      const image = printWindow.document.createElement('img');
      image.src = selectedFile;
      image.style.maxWidth = '100%';
      image.style.maxHeight = '100%';
      printContent.appendChild(image);

      // Wait for the image to load before printing
      image.onload = () => {
        setTimeout(() => {
          printWindow.print(); // Open the print dialog
          closePrintWindow(printWindow); // Close after printing
        }, 500); // Delay to allow image to load
      };

      // If the image failed to load, log an error
      image.onerror = () => {
        console.error('Image failed to load.');
      };
    }
  };

  // Function to close the print window after a brief delay
  const closePrintWindow = printWindow => {
    // Close the print window after a short delay to give the print dialog time to load
    setTimeout(() => {
      printWindow.close(); // Close the window
    }, 700); // Adjust the timeout based on your needs (3 seconds is a safe delay)
  };

  const handlePdfLoadSuccess = () => {
    setPdfRendered(true); // Set to true once the PDF is successfully rendered
  };

  return (
    <div>
      <Button
        href="#"
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          textDecoration: 'underline',
          color: '#87CEEB',
          cursor: 'pointer',
          fontSize: 'inherit',
          fontFamily: 'inherit',
          textAlign: 'left'
        }}
        onMouseOver={e => (e.target.style.color = '#1E90FF')}
        onMouseOut={e => (e.target.style.color = '#87CEEB')}
        onClick={e => {
          e.preventDefault();
          handleOpenModal();
        }}
      >
        View Documents
      </Button>

      {/* Modal to list files */}
      <div>
        {/* Inline style block */}
        <style>
          {`
        .file-link {
          text-decoration: underline;
          color: #007BFF; /* Initial blue color */
          font-size: inherit;
          font-family: inherit;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
        }

        .file-link:hover {
          color: #C71585; /* Dark pink on hover */
          background: none !important; /* Ensure no background color on hover */
        }
      `}
        </style>

        <Modal isOpen={isOpen} toggle={handleCloseModal} size="lg" style={{ minWidth: '400px', top: '20vh' }}>
          <ModalBody>
            {fetchError ? (
              <Alert color="danger" style={{ width: '100%', textAlign: 'center' }}>
                {fetchError}
              </Alert>
            ) : noFileAvailable ? (
              <Alert color="info" style={{ width: '100%', textAlign: 'center' }}>
                No files are available for this shipment currently, you can upload or check it later
              </Alert>
            ) : (
              <div>
                {files.length > 0 ? (
                  <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
                    {files.map((file, index) => (
                      <li key={index} style={{ marginBottom: '10px' }}>
                        <Button
                          className="file-link" // Apply the CSS class
                          onClick={() => handleOpenFileModal(file)}
                        >
                          {file.file_name}
                        </Button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Alert color="info" style={{ width: '100%', textAlign: 'center' }}>
                    Loading files...
                  </Alert>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {showGenerateBolButton && (
              <Button color="primary" onClick={generateBol} disabled={loadingGenerate}>
                {loadingGenerate ? 'Generating...' : 'Generate BOL'}
              </Button>
            )}
            <Button color="danger" onClick={handleCloseModal} className="ml-auto">
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {/* Modal to display the selected file */}
      <Modal
        isOpen={viewFileModal}
        toggle={handleCloseFileModal}
        size="lg"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <ModalBody>
          {selectedFile ? (
            fileType === 'pdf' ? (
              <div
                style={{
                  width: '100%',
                  maxHeight: '100%',
                  overflow: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Document file={selectedFile} onLoadSuccess={handlePdfLoadSuccess}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            ) : (
              <img
                src={selectedFile}
                alt="file content"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
            )
          ) : (
            <Alert color="info" style={{ width: '100%', textAlign: 'center' }}>
              Loading file...
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={handlePrint} disabled={!selectedFile}>
            Print
          </Button> */}
          <Button color="primary" onClick={handleSaveFile} disabled={!selectedFile}>
            Save
          </Button>
          <Button color="danger" onClick={handleCloseFileModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FileViewer;
