import React from 'react';
import { Button } from 'reactstrap';
import { CompleteOrder } from '../HelperFunctions/ShipmentFunctions';

const CompleteOrderButton = ({ shipmentId, orderId, notify }) => {
  const handleClick = async () => {
    if (!shipmentId || !orderId) {
      console.error('Shipment ID and Order ID are required');
      return;
    }

    try {
      const status = await CompleteOrder(shipmentId, orderId);
      console.log(status);
      if (status === 200) {
        notify('tc', 'Order Successfully Completed');
      }
    } catch (error) {
      console.log(error.message || 'An error occurred while completing the order');
    }
  };

  return (
    <Button size="sm" className="btn btn-success" onClick={handleClick} style={{ padding: '5px 10px' }}>
      Complete
    </Button>
  );
};

export default CompleteOrderButton;
