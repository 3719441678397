/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import Dimensions from '../Inventory/AddProduct/Dimensions';

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Collapse,
  Container,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';

import './DropshipForm.css';

//! Mapbox AutoComplete
// import MapboxAutocomplete from '../../assets/react-mapbox-autocomplete/ReactMapboxAutocomplete';
// import '../../assets/react-mapbox-autocomplete/index.css';

//! Google Map AutoComplete
import GoogleMapAutoComplete from '../Helpers/GoogleMapAutoComplete';
import '../Helpers/googleMapAutoComplete.css';

import Car, {
  SUV,
  Truck,
  BoxTruck,
  Trailer,
  Wheeler18DryVan,
  CarHauler,
  EnclosedTrailer,
  Wheeler18FlatBed,
  GooseneckTrailer,
  DumpTrailer,
  TowTruck,
  MonsterTruck,
  LiftTruck,
  Float53FeetAsGooseneckTrailer,
  SprinterVan,
  Wheeler18Reefer
} from '../../assets/svgs/CarTypes';
import { FaUserAlt, FaUserFriends, FaUsers, FaCommentSlash } from 'react-icons/fa';
import Gophrs4, { Gophrs5 } from '../../assets/svgs/Gophrs';
import ForkLift, { HandLoadUnload, Dolly, Hazmat, HotBag, Cooler, TWIC, Bike, PalletJack, Liftgate, Umbrella, Medical } from '../../assets/svgs/ToolsAndEquipments';

import * as shipment_urls from '../../urls/api-shipments/merchant';
import * as inventory_urls from '../../urls/api-inventory/admin';
import states from '../../us_states';
import { formatTimestamp, formatDatestamp } from '../../helpers/formatter';
import ReactDatetime from 'react-datetime';
// import 'react-datetime/css/react-datetime.css';
import Loading from '../Global/Loading';
import { useHistory } from 'react-router-dom';
import PickupCustomerAutoComplete from './PickupCustomerAutoComplete';
import DropOffCustomerAutoComplete from './DropOffCustomerAutoComplete';
import WaypointCustomerAutoComplete from './WaypointCustomerAutoComplete';
import WaypointCustomerOnlyDropAutoComplete from './WaypointCustomerOnlyDropAutoComplete';
import LineItems from './LineItems';
import LineItemsMultipleDrop from './LineItemsMultipleDrop';
import FeedbackMessage from '../SharedComponents/FeedbackMessage';

export default function DropshipForm(props) {
  var moment = require('moment');
  var now = moment();
  var tomorrow = moment().add(1, 'day');
  var limit = moment().add(31, 'day');
  var weekdayTimeConstraint = {
    hours: {
      min: 7,
      max: 19,
      step: 1
    },
    minutes: {
      min: 0,
      max: 59,
      step: 30
    }
  };
  var saturdayTimeConstraint = {
    // hours: {
    //   min: 11,
    //   max: 15,
    //   step: 1
    // },
    //! temporary update for Saturday
    hours: {
      min: 7,
      max: 19,
      step: 1
    },
    minutes: {
      min: 0,
      max: 59,
      step: 30
    }
  };

  const [address, setAddress] = useState('');
  const [containsAlcohol, setContainsAlcohol] = useState(false);
  const [sellsAlcohol, setSellsAlcohol] = useState(false);
  const [allowWaypoint, setAllowWaypoint] = useState(false);
  const [allowMultipleDrops, setAllowMultipleDrops] = useState(false);
  const [controlLat, setControlLat] = useState('30.21294');
  const [controlLong, setControlLong] = useState('-93.2187');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerAddress2, setCustomerAddress2] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  // const [customerEmail, setCustomerEmail] = useState('');
  const [customerFirstName, setCustomerFirstName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [customerLat, setCustomerLat] = useState('');
  const [customerLong, setCustomerLong] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerState, setCustomerState] = useState('');
  const [customerZip, setCustomerZip] = useState('');
  const [description, setDescription] = useState('');
  const [merchantOrderID, setMerchantOrderID] = useState(null);
  const [distance, setDistance] = useState('');
  const [dropAddress1, setDropAddress1] = useState(null);
  const [dropAddress2, setDropAddress2] = useState(null);
  const [dropCity, setDropCity] = useState(null);
  const [dropCountry, setDropCountry] = useState(null);
  // const [dropEmail, setDropEmail] = useState('');
  const [dropFirstname, setDropFirstname] = useState('');
  const [dropFullAddress, setDropFullAddress] = useState('');
  const [dropLastname, setDropLastname] = useState('');
  const [dropLat, setDropLat] = useState(null);
  const [dropLng, setDropLng] = useState(null);
  const [dropPhone, setDropPhone] = useState('');
  const [dropState, setDropState] = useState(null);
  const [dropZip, setDropZip] = useState(null);
  const [dropoff, setDropoff] = useState('');
  const [dropoffBusinessName, setDropoffBusinessName] = useState('');
  const [dropoffInfo, setDropoffInfo] = useState({});
  const [errorMessageDistance, setErrorMessageDistance] = useState({ errorMessage: '', errorCode: 0 });
  const [errorMessageSubmit, setErrorMessageSubmit] = useState({ errorMessage: '', errorCode: 0 });
  const [errors, setErrors] = useState({
    pickupFirstname: false,
    pickupLastname: false,
    // pickupEmail: false,
    pickupPhone: false,
    pickupAddress: false,
    dropoffFirstname: false,
    dropoffLastname: false,
    // dropoffEmail: false,
    dropoffPhone: false,
    description: false,
    weight: false,
    gophrs: false,
    size: false,
    price: false
  });
  const [waypointErrors, setWaypointErrors] = useState({
    waypointFirstname: false,
    waypointLastname: false,
    // waypointEmail: false,
    waypointPhone: false,
    waypointAddress: false,
    pickOrDrop: false
  });

  const [isDropoffStore, setIsDropoffStore] = useState(false);
  const [isExpedited, setIsExpedited] = useState(false);
  const [isSuperExpedited, setIsSuperExpedited] = useState(false);
  const [isPickupStore, setIsPickupStore] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numPeople, setNumPeople] = useState(null);
  const [orderStatus, setOrderStatus] = useState('awaiting_driver');
  const [pickAddress1, setPickAddress1] = useState(null);
  const [pickAddress2, setPickAddress2] = useState(null);
  const [pickCity, setPickCity] = useState(null);
  const [pickCountry, setPickCountry] = useState(null);
  // const [pickEmail, setPickEmail] = useState('');
  const [pickFirstname, setPickFirstname] = useState('');
  const [pickFullAddress, setPickFullAddress] = useState('');
  const [pickLastname, setPickLastname] = useState('');
  const [pickLat, setPickLat] = useState(null);
  const [pickLng, setPickLng] = useState(null);
  const [pickPhone, setPickPhone] = useState('');
  const [pickState, setPickState] = useState(null);
  const [pickZip, setPickZip] = useState(null);
  const [pickup, setPickup] = useState('');
  const [pickBusinessName, setPickBusinessName] = useState('');
  const [pickupInfo, setPickupInfo] = useState({});
  const [price, setPrice] = useState(null);
  const [expeditedPrice, setExpeditedPrice] = useState('');
  const [superExpeditedPrice, setSuperExpeditedPrice] = useState('');
  const [priceRangeLow, setPriceRangeLow] = useState(null);
  const [priceRangeHigh, setPriceRangeHigh] = useState(null);
  const [standardPriceRangeLow, setStandardPriceRangeLow] = useState(0);
  const [standardPriceRangeHigh, setStandardPriceRangeHigh] = useState(0);
  const [expeditedPriceRangeLow, setExpeditedPriceRangeLow] = useState(0);
  const [expeditedPriceRangeHigh, setExpeditedPriceRangeHigh] = useState(0);
  const [superExpeditedPriceRangeLow, setSuperExpeditedPriceRangeLow] = useState(0);
  const [superExpeditedPriceRangeHigh, setSuperExpeditedPriceRangeHigh] = useState(0);
  const [route, setRoute] = useState({});
  const [scheduledFor, setScheduledFor] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(tomorrow);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [shipmentStatus, setShipmentStatus] = useState('shipment_ready_for_driver');
  // const [showAlert, setShowAlert] = useState(false);
  const [showForm, setShowForm] = useState(props.showForm);
  const [showForm1, setShowForm1] = useState(true);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [standardPrice, setStandardPrice] = useState('');
  const [store, setStore] = useState(null);
  const [merchantName, setMerchantName] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [storeAddress1, setStoreAddress1] = useState('');
  const [storeAddress2, setStoreAddress2] = useState('');
  const [storeCity, setStoreCity] = useState('');
  const [storeCountry, setStoreCountry] = useState('');
  // const [storeEmail, setStoreEmail] = useState(null);
  const [storeFirstName, setStoreFirstName] = useState(null);
  const [storeId, setStoreId] = useState('');
  const [storeLastName, setStoreLastName] = useState(null);
  const [storeLat, setStoreLat] = useState(null);
  const [storeLng, setStoreLng] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [storePhone, setStorePhone] = useState(null);
  const [storeState, setStoreState] = useState('');
  const [storeZip, setStoreZip] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vendorLat, setVendorLat] = useState('');
  const [vendorLong, setVendorLong] = useState('');
  const [weight, setWeight] = useState('');
  const [storeMarketId, setStoreMarketId] = useState('b641bc13-8f56-4f4b-b1ea-c1f833b982bd');

  //! autofill customer
  const [pickupCustomerSuggestions, setPickupCustomerSuggestions] = useState([]);
  const [dropCustomerSuggestions, setDropCustomerSuggestions] = useState([]);
  const [waypointCustomerSuggestions, setWaypointCustomerSuggestions] = useState([]);
  const [isPickupAutoFilled, setIsPickupAutoFilled] = useState(false);
  const [isDropOffAutoFilled, setIsDropOffAutoFilled] = useState(false);
  const [isWaypointAutoFilled, setIsWaypointAutoFilled] = useState(false);

  const [isProceeded, setIsProceeded] = useState(false);

  //! third waypoint
  const [hasThirdWaypoint, setHasThirdWayPoint] = useState(false);
  const [isWaypointPickup, setIsWaypointPickup] = useState(false);
  const [isWaypointDropoff, setIsWaypointDropoff] = useState(false);
  const [pickupItems, setPickupItems] = useState([{ quantity: 1, detail: '' }]);
  const [dropoffItems, setDropoffItems] = useState([{ quantity: 1, detail: '' }]);
  const [waypointPickupItems, setWaypointPickupItems] = useState([{ quantity: 1, detail: '' }]);
  const [waypointDropoffItems, setWaypointDropoffItems] = useState([{ quantity: 1, detail: '' }]);
  const [defaultItems, setDefaultItems] = useState([{ quantity: 1, detail: '' }]);
  const [isWaypointStore, setIsWaypointStore] = useState(false);
  const [waypointAddress1, setWaypointAddress1] = useState(null);
  const [waypointAddress2, setWaypointAddress2] = useState(null);
  const [waypointCity, setWaypointCity] = useState(null);
  const [waypointCountry, setWaypointCountry] = useState(null);
  // const [waypointEmail, setWaypointEmail] = useState('');
  const [waypointFirstname, setWaypointFirstname] = useState('');
  const [waypointFullAddress, setWaypointFullAddress] = useState('');
  const [waypointLastname, setWaypointLastname] = useState('');
  const [waypointLat, setWaypointLat] = useState(null);
  const [waypointLng, setWaypointLng] = useState(null);
  const [waypointPhone, setWaypointPhone] = useState('');
  const [waypointState, setWaypointState] = useState(null);
  const [waypointZip, setWaypointZip] = useState(null);
  const [waypointBusinessName, setWaypointBusinessName] = useState('');
  const [waypointInfo, setWaypointInfo] = useState({});
  const [waypointPickupInstruction, setWaypointPickupInstruction] = useState('');
  const [waypointDropoffInstruction, setWaypointDropoffInstruction] = useState('');
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [pickupItemsError, setPickupItemsError] = useState([false]);
  const [dropoffItemsError, setDropoffItemsError] = useState([false]);
  const [waypointPickupItemsError, setWaypointPickupItemsError] = useState([false]);
  const [waypointDropoffItemsError, setWaypointDropoffItemsError] = useState([false]);
  const [defaultItemsError, setDefaultItemsError] = useState([false]);
  const [twoStopsError, setTwoStopsError] = useState(false);
  const [threeStopsError, setThreeStopsError] = useState(false);
  const [infiniteStopsError, setInfiniteStopsError] = useState(false);
  const [itemsMatchError, setItemsMatchError] = useState(false);

  //! Infinite Dropoff waypoints
  const [dropWaypointsArray, setDropWaypointsArray] = useState([
    {
      waypointFirstname: '',
      waypointLastname: '',
      waypointPhone: '',
      waypointAddress1: null,
      waypointAddress2: null,
      waypointFullAddress: '',
      waypointLat: '',
      waypointLng: '',
      waypointCity: '',
      waypointState: '',
      waypointCountry: '',
      waypointZip: '',
      waypointDropoffInstruction: '',
      waypointBusinessName: '',
      waypointCustomerSuggestions: [],
      isWaypointAutoFilled: false,
      dropItems: [{ quantity: 1, detail: '' }],
      dropItemsError: [false]
    }
  ]);
  const [infiniteDropWaypointsErrors, setInfiniteDropWaypointsErrors] = useState([
    {
      waypointFirstname: false,
      waypointLastname: false,
      waypointPhone: false,
      waypointAddress: false
    }
  ]);

  const [infiniteDropsInfo, setInfiniteDropsInfo] = useState([]);

  //! Itemized infinite drops price
  const [perItemPrice, setPerItemPrice] = useState(null);
  const [perStopPrice, setPerStopPrice] = useState(null);
  const [extraPerItemPrice, setExtraPerItemPrice] = useState(null);
  const [freeItemCount, setFreeItemCount] = useState(null);

  //! Equipments and Tools
  const [isForkLift, setIsForkLift] = useState(false);
  const [isDolly, setIsDolly] = useState(false);
  const [isHandLoadUnload, setIsHandLoadUnload] = useState(false);
  const [isHazmat, setIsHazmat] = useState(false);
  const [isHotBag, setIsHotBag] = useState(false);
  const [isCooler, setIsCooler] = useState(false);
  const [isTWICCard, setIsTWICCard] = useState(false);
  const [isLiftgate, setIsLiftgate] = useState(false);
  const [isPalletJack, setIsPalletJack] = useState(false);
  const [isWeatherProtection, setIsWeatherProtection] = useState(false);
  const [isMedical, setIsMedical] = useState(false);
  const [isHandLoadUnloadDisabled, setIsHandLoadUnloadDisabled] = useState(false);
  const [isLiftgateDisabled, setIsLiftgateDisabled] = useState(false);

  const [isForkLiftHover, setIsForkLiftHover] = useState(false);
  const [isDollyHover, setIsDollyHover] = useState(false);
  const [isHandLoadUnloadHover, setIsHandLoadUnloadHover] = useState(false);
  const [isHazmatHover, setIsHazmatHover] = useState(false);
  const [isHotBagHover, setIsHotBagHover] = useState(false);
  const [isCoolerHover, setIsCoolerHover] = useState(false);
  const [isTWICCardHover, setIsTWICCardHover] = useState(false);
  const [isLiftgateHover, setIsLiftgateHover] = useState(false);
  const [isPalletJackHover, setIsPalletJackHover] = useState(false);
  const [isUmbrellaHover, setIsUmbrellaHover] = useState(false);
  const [isMedicalHover, setIsMedicalHover] = useState(false);

  const [isTruckHover, setIsTruckHover] = useState(false);
  const [isMonsterTruckHover, setIsMonsterTruckHover] = useState(false);
  const [isLiftTruckHover, setIsLiftTruckHover] = useState(false);
  const [is18WheelerDryVanHover, setIs18WheelerDryVanHover] = useState(false);
  const [is18WheelerFlatBedHover, setIs18WheelerFlatBedHover] = useState(false);
  const [is18WheelerReeferHover, setIs18WheelerReeferHover] = useState(false);
  const [isEnclosedTrailerHover, setIsEnclosedTrailerHover] = useState(false);
  const [isDumpTrailerHover, setIsDumpTrailerHover] = useState(false);
  const [isGooseneckTrailerHover, setIsGooseneckTrailerHover] = useState(false);
  const [is53feetFloatHover, setIs53feetFloatHover] = useState(false);
  const [isCarHaulerHover, setIsCarHaulerHover] = useState(false);
  const [isTrailerHover, setIsTrailerHover] = useState(false);
  const [isSprinterVanHover, setIsSprinterVanHover] = useState(false);

  const [is18WellerOpen, setIs18WellerOpen] = useState(false);
  const [isTruckOpen, setIsTruckOpen] = useState(false);
  const [isTrailerOpen, setIsTrailerOpen] = useState(false);

  //! Order submit button control
  const [isOrderSubmitDisabled, setIsOrderSubmitDisabled] = useState(false);

  //! TQL related
  const [temperature, setTemperature] = useState(20);
  const [loadValue, setLoadValue] = useState('36000');
  const [commodity, setCommodity] = useState('items');
  const [hasTqlRateValue, setHasTqlRateValue] = useState(false);
  const [tqlRateBlob, setTqlRateBlob] = useState({});

  const [isOverSize, setIsOverSize] = useState(false);

  //! Order submission indication
  const [submissionMessage, setSubmissionMessage] = useState('');

  const toggle18Wheeler = () => setIs18WellerOpen(prevState => !prevState);
  const toggleTruck = () => setIsTruckOpen(prevState => !prevState);
  const toggleTrailer = () => setIsTrailerOpen(prevState => !prevState);

  const refVehicle = useRef(null);

  const MAPBOX_ENDPOINT = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
  const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29waHItZW5naW5lZXJpbmciLCJhIjoiY2syYzd6NjdrMmo3azNtcGU1cXZzMTh1MSJ9.cHMxwcG_MoQk-l4ZO3bdEA';
  const HASURA_ENDPOINT = inventory_urls.hasuraHost;
  const ACCESS_TOKEN = 'Bearer ' + window.localStorage.getItem('access_token');
  const jwtDecode = require('jwt-decode');
  const DECODED_ACCESS = jwtDecode(ACCESS_TOKEN);
  const SUB = DECODED_ACCESS.sub;
  const history = useHistory();
  const QUERY = {
    query:
      '{Stores (where: {StoreOwnerships: {merchant_id: {_eq: "' +
      SUB +
      '"}}}) {state phone latitude longitude name address_1 address_2 city country zip store_id market_id sells_alcohol direct_type itemized_price StoreOwnerships {firstname lastname }}}'
  };

  useEffect(() => {
    if (isLiftgate) {
      setIsHandLoadUnloadDisabled(true);
    } else {
      setIsHandLoadUnloadDisabled(false);
    }
    setIsHandLoadUnload(false);
  }, [isLiftgate]);

  useEffect(() => {
    if (vehicleType) {
      if (!(vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'box_truck')) {
        setIsLiftgateDisabled(true);
      } else {
        setIsLiftgateDisabled(false);
      }
      setIsLiftgate(false);
    }
  }, [vehicleType]);

  useEffect(() => {
    setIsTruckHover(false);
    setIsMonsterTruckHover(false);
    setIsLiftTruckHover(false);
    setIsEnclosedTrailerHover(false);
    setIsDumpTrailerHover(false);
    setIsGooseneckTrailerHover(false);
    setIs53feetFloatHover(false);
    setIsCarHaulerHover(false);
    setIsTrailerHover(false);
    setIs18WheelerDryVanHover(false);
    setIs18WheelerFlatBedHover(false);
  }, [weight, vehicleType]);

  const MonsterTruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => (weight > 4000 ? null : setVehicleType('heavy_duty_truck'))}
          onMouseEnter={() => setIsMonsterTruckHover(true)}
          onMouseLeave={() => setIsMonsterTruckHover(false)}
        >
          <MonsterTruck
            alt="Monster Truck"
            title="Monster Truck"
            name="Monster Truck"
            size={1.5}
            fill={weight > 4000 ? handleFillGrayOut('heavy_duty_truck') : handleFill('heavy_duty_truck')}
          ></MonsterTruck>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isMonsterTruckHover} target={refVehicle} toggle={() => setIsMonsterTruckHover(!isMonsterTruckHover)}>
          1-Ton Truck
        </Tooltip>
      </Label>
    );
  };

  const LiftTruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFillGrayOut('box_truck')}></LiftTruck>
          </div>
        ) : (
          <div onClick={() => setVehicleType('box_truck')} onMouseEnter={() => setIsLiftTruckHover(true)} onMouseLeave={() => setIsLiftTruckHover(false)}>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFill('box_truck')}></LiftTruck>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isLiftTruckHover} target={refVehicle} toggle={() => setIsLiftTruckHover(!isLiftTruckHover)}>
          Box Truck
        </Tooltip>
      </Label>
    );
  };

  const TruckDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 2000 ? (
          <div>
            <Truck name="Truck" fill={handleFillGrayOut('Truck')}></Truck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('Truck');
              // setIsTruckOpen(!isTruckOpen);
            }}
            onMouseEnter={() => setIsTruckHover(true)}
            onMouseLeave={() => setIsTruckHover(false)}
          >
            <Truck name="Truck" fill={handleFill('Truck')}></Truck>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isTruckHover} target={refVehicle} toggle={() => setIsTruckHover(!isTruckHover)}>
          Half-Ton Truck
        </Tooltip>
      </Label>
    );
  };

  const SprinterVanDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <SprinterVan name="Sprinter Van" fill={handleFillGrayOut('sprinter_van')}></SprinterVan>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('sprinter_van');
              // setIsTruckOpen(!isTruckOpen);
            }}
            onMouseEnter={() => setIsSprinterVanHover(true)}
            onMouseLeave={() => setIsSprinterVanHover(false)}
          >
            <SprinterVan name="Sprinter Van" fill={handleFill('sprinter_van')}></SprinterVan>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isSprinterVanHover} target={refVehicle} toggle={() => setIsSprinterVanHover(!isSprinterVanHover)}>
          Sprinter Van
        </Tooltip>
      </Label>
    );
  };

  const EnclosedTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFillGrayOut('enclosed_trailer')}></EnclosedTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('enclosed_trailer')} onMouseEnter={() => setIsEnclosedTrailerHover(true)} onMouseLeave={() => setIsEnclosedTrailerHover(false)}>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFill('enclosed_trailer')}></EnclosedTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isEnclosedTrailerHover} target={refVehicle} toggle={() => setIsEnclosedTrailerHover(!isEnclosedTrailerHover)}>
          Enclosed Trailer
        </Tooltip>
      </Label>
    );
  };

  const DumpTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFillGrayOut('dump_trailer')}></DumpTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('dump_trailer')} onMouseEnter={() => setIsDumpTrailerHover(true)} onMouseLeave={() => setIsDumpTrailerHover(false)}>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFill('dump_trailer')}></DumpTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isDumpTrailerHover} target={refVehicle} toggle={() => setIsDumpTrailerHover(!isDumpTrailerHover)}>
          Dump Trailer
        </Tooltip>
      </Label>
    );
  };

  const Float53FeetAsGooseneckTrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 15000 ? (
          <div>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFillGrayOut('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        ) : (
          <div onClick={() => setVehicleType('gooseneck_trailer')} onMouseEnter={() => setIsGooseneckTrailerHover(true)} onMouseLeave={() => setIsGooseneckTrailerHover(false)}>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFill('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isGooseneckTrailerHover} target={refVehicle} toggle={() => setIsGooseneckTrailerHover(!isGooseneckTrailerHover)}>
          Gooseneck Trailer
        </Tooltip>
      </Label>
    );
  };

  const CarHaulerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFillGrayOut('car_hauler')}></CarHauler>
          </div>
        ) : (
          <div onClick={() => setVehicleType('car_hauler')} onMouseEnter={() => setIsCarHaulerHover(true)} onMouseLeave={() => setIsCarHaulerHover(false)}>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFill('car_hauler')}></CarHauler>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isCarHaulerHover} target={refVehicle} toggle={() => setIsCarHaulerHover(!isCarHaulerHover)}>
          Car Hauler
        </Tooltip>
      </Label>
    );
  };

  const TrailerDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFillGrayOut('trailer')}></Trailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('trailer');
              // setIsTrailerOpen(!isTrailerOpen);
            }}
            onMouseEnter={() => setIsTrailerHover(true)}
            onMouseLeave={() => setIsTrailerHover(false)}
          >
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFill('trailer')}></Trailer>
          </div>
        )}
        <Tooltip placement="bottom" ref={refVehicle} isOpen={isTrailerHover} target={refVehicle} toggle={() => setIsTrailerHover(!isTrailerHover)}>
          Utility Trailer
        </Tooltip>
      </Label>
    );
  };

  const Wheeler18DryVanDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div onClick={() => setVehicleType('18_wheeler_dry_van')} onMouseEnter={() => setIs18WheelerDryVanHover(true)} onMouseLeave={() => setIs18WheelerDryVanHover(false)}>
          <Wheeler18DryVan alt="18 wheeler dry van" title="18 wheeler dry van" name="18 wheeler dry van" size={1.5} fill={handleFill('18_wheeler_dry_van')}></Wheeler18DryVan>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={is18WheelerDryVanHover} target={refVehicle} toggle={() => setIs18WheelerDryVanHover(!is18WheelerDryVanHover)}>
          18 Wheeler Dry Van
        </Tooltip>
      </Label>
    );
  };

  const Wheeler18FlatBedDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_flat_bed');
            // setIs18WellerOpen(!is18WellerOpen);
          }}
          onMouseEnter={() => setIs18WheelerFlatBedHover(true)}
          onMouseLeave={() => setIs18WheelerFlatBedHover(false)}
        >
          <Wheeler18FlatBed alt="18 wheeler flat bed" title="18 wheeler flat bed" name="18 wheeler flat bed" size={1.5} fill={handleFill('18_wheeler_flat_bed')}></Wheeler18FlatBed>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={is18WheelerFlatBedHover} target={refVehicle} toggle={() => setIs18WheelerFlatBedHover(!is18WheelerFlatBedHover)}>
          18 Wheeler Flat Bed
        </Tooltip>
      </Label>
    );
  };

  const Wheeler18ReeferDropToggle = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_reefer');
            // setIs18WellerOpen(!is18WellerOpen);
          }}
          onMouseEnter={() => setIs18WheelerReeferHover(true)}
          onMouseLeave={() => setIs18WheelerReeferHover(false)}
        >
          <Wheeler18Reefer alt="18 wheeler reefer" title="18 wheeler reefer" name="18 wheeler reefer" size={1.5} fill={handleFill('18_wheeler_reefer')}></Wheeler18Reefer>
        </div>
        <Tooltip placement="bottom" ref={refVehicle} isOpen={is18WheelerReeferHover} target={refVehicle} toggle={() => setIs18WheelerReeferHover(!is18WheelerFlatBedHover)}>
          18 Wheeler Reefer
        </Tooltip>
      </Label>
    );
  };

  const TruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 2000 ? (
          <div>
            <Truck name="Truck" fill={handleFillGrayOut('Truck')}></Truck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('Truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsTruckHover(true)}
            onMouseLeave={() => setIsTruckHover(false)}
          >
            <Truck name="Truck" fill={handleFill('Truck')}></Truck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isTruckHover && 'Half-Ton Truck'}
      </Label>
    );
  };

  const MonsterTruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 4000 ? (
          <div>
            <MonsterTruck alt="Monster Truck" title="Monster Truck" name="Monster Truck" size={1.5} fill={handleFillGrayOut('heavy_duty_truck')}></MonsterTruck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('heavy_duty_truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsMonsterTruckHover(true)}
            onMouseLeave={() => setIsMonsterTruckHover(false)}
          >
            <MonsterTruck alt="Monster Truck" title="Monster Truck" name="Monster Truck" size={1.5} fill={handleFill('heavy_duty_truck')}></MonsterTruck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isMonsterTruckHover && '1-Ton Truck'}
      </Label>
    );
  };

  const LiftTruckDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFillGrayOut('box_truck')}></LiftTruck>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('box_truck');
              toggleTruck();
            }}
            onMouseEnter={() => setIsLiftTruckHover(true)}
            onMouseLeave={() => setIsLiftTruckHover(false)}
          >
            <LiftTruck alt="Lift Truck" title="Lift Truck" name="Lift Truck" size={1.5} fill={handleFill('box_truck')}></LiftTruck>
          </div>
        )}
        <span style={{ marginLeft: '10px' }} />
        {isLiftTruckHover && 'Box Truck'}
      </Label>
    );
  };

  const TrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFillGrayOut('trailer')}></Trailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsTrailerHover(true)}
            onMouseLeave={() => setIsTrailerHover(false)}
          >
            <Trailer alt="Truck and Trailer" title="Truck and Trailer" name="Truck and Trailer" size={1.5} fill={handleFill('trailer')}></Trailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '5px' }} />
        {isTrailerHover && 'Utility Trailer'}
      </Label>
    );
  };

  const EnclosedTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFillGrayOut('enclosed_trailer')}></EnclosedTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('enclosed_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsEnclosedTrailerHover(true)}
            onMouseLeave={() => setIsEnclosedTrailerHover(false)}
          >
            <EnclosedTrailer alt="Enclosed Trailer" title="Enclosed Trailer" name="Enclosed Trailer" size={1.5} fill={handleFill('enclosed_trailer')}></EnclosedTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isEnclosedTrailerHover && 'Enclosed Trailer'}
      </Label>
    );
  };

  const DumpTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFillGrayOut('dump_trailer')}></DumpTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('dump_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsDumpTrailerHover(true)}
            onMouseLeave={() => setIsDumpTrailerHover(false)}
          >
            <DumpTrailer alt="Dump Trailer" title="Dump Trailer" name="Dump Trailer" size={1.5} fill={handleFill('dump_trailer')}></DumpTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isDumpTrailerHover && 'Dump Trailer'}
      </Label>
    );
  };

  const SprinterVanDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 5000 ? (
          <div>
            <SprinterVan alt="Sprinter Van" title="Sprinter Van" name="Sprinter Van" size={1.5} fill={handleFillGrayOut('sprinter_van')}></SprinterVan>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('sprinter_van');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsSprinterVanHover(true)}
            onMouseLeave={() => setIsSprinterVanHover(false)}
          >
            <SprinterVan alt="Sprinter Van" title="Sprinter Van" name="Sprinter Van" size={1.5} fill={handleFill('sprinter_van')}></SprinterVan>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isSprinterVanHover && 'Sprinter Van'}
      </Label>
    );
  };

  const Float53FeetAsGooseneckTrailerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 15000 ? (
          <div>
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFillGrayOut('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('gooseneck_trailer');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsGooseneckTrailerHover(true)}
            onMouseLeave={() => setIsGooseneckTrailerHover(false)}
          >
            <Float53FeetAsGooseneckTrailer
              alt="Gooseneck Trailer"
              title="Gooseneck Trailer"
              name="Gooseneck Trailer"
              size={1.5}
              fill={handleFill('gooseneck_trailer')}
            ></Float53FeetAsGooseneckTrailer>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isGooseneckTrailerHover && 'Gooseneck Trailer'}
      </Label>
    );
  };

  const CarHaulerDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        {weight > 10000 ? (
          <div>
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFillGrayOut('car_hauler')}></CarHauler>
          </div>
        ) : (
          <div
            onClick={() => {
              setVehicleType('car_hauler');
              toggleTrailer();
            }}
            onMouseEnter={() => setIsCarHaulerHover(true)}
            onMouseLeave={() => setIsCarHaulerHover(false)}
          >
            <CarHauler alt="Car Hauler" title="Car Hauler" name="Car Hauler" size={1.5} fill={handleFill('car_hauler')}></CarHauler>
          </div>
        )}
        <span style={{ marginLeft: '5px', marginTop: '10px' }} />
        {isCarHaulerHover && 'Car Hauler'}
      </Label>
    );
  };

  const Wheeler18FlatBedDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_flat_bed');
            toggle18Wheeler();
            // setIs18WellerOpen(!is18WellerOpen);
          }}
          onMouseEnter={() => setIs18WheelerFlatBedHover(true)}
          onMouseLeave={() => setIs18WheelerFlatBedHover(false)}
        >
          <Wheeler18FlatBed alt="18 wheeler flat bed" title="18 wheeler flat bed" name="18 wheeler flat bed" size={1.5} fill={handleFill('18_wheeler_flat_bed')}></Wheeler18FlatBed>
        </div>
        <span style={{ marginTop: '15px' }} />
        {is18WheelerFlatBedHover && '18 Wheeler Flat Bed'}
      </Label>
    );
  };

  const Wheeler18DryVanDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_dry_van');
            toggle18Wheeler();
          }}
          onMouseEnter={() => setIs18WheelerDryVanHover(true)}
          onMouseLeave={() => setIs18WheelerDryVanHover(false)}
        >
          <Wheeler18DryVan alt="18 wheeler dry van" title="18 wheeler dry van" name="18 wheeler dry van" size={1.5} fill={handleFill('18_wheeler_dry_van')}></Wheeler18DryVan>
        </div>
        <span style={{ marginTop: '15px' }} />
        {is18WheelerDryVanHover && '18 Wheeler Dry Van'}
      </Label>
    );
  };

  const Wheeler18ReeferDropItem = () => {
    return (
      <Label className="form-check-label-vehicle">
        <div
          onClick={() => {
            setVehicleType('18_wheeler_reefer');
            toggle18Wheeler();
          }}
          onMouseEnter={() => setIs18WheelerReeferHover(true)}
          onMouseLeave={() => setIs18WheelerReeferHover(false)}
        >
          <Wheeler18Reefer alt="18 wheeler reefer" title="18 wheeler reefer" name="18 wheeler reefer" size={1.5} fill={handleFill('18_wheeler_reefer')}></Wheeler18Reefer>
        </div>
        <span style={{ marginTop: '15px' }} />
        {is18WheelerReeferHover && '18 Wheeler Reefer'}
      </Label>
    );
  };

  useEffect(() => {
    if (standardPrice) {
      if (isExpedited) {
        setPrice(expeditedPrice);
      } else if (isSuperExpedited) {
        setPrice(superExpeditedPrice);
      } else {
        setPrice(standardPrice);
      }
    }
  }, [isRoundTrip, standardPrice, expeditedPrice, superExpeditedPrice]);

  useEffect(() => {
    if (standardPriceRangeLow && standardPriceRangeHigh) {
      if (isExpedited) {
        setPriceRangeLow(expeditedPriceRangeLow);
        setPriceRangeHigh(expeditedPriceRangeHigh);
      } else if (isSuperExpedited) {
        setPriceRangeLow(superExpeditedPriceRangeLow);
        setPriceRangeHigh(superExpeditedPriceRangeHigh);
      } else {
        setPriceRangeLow(standardPriceRangeLow);
        setPriceRangeHigh(standardPriceRangeHigh);
      }
    }
  }, [
    standardPriceRangeLow,
    standardPriceRangeHigh,
    isExpedited,
    isSuperExpedited,
    expeditedPriceRangeLow,
    expeditedPriceRangeHigh,
    superExpeditedPriceRangeLow,
    superExpeditedPriceRangeHigh
  ]);

  const toggleThirdWaypoint = () => {
    setHasThirdWayPoint(!hasThirdWaypoint);
  };

  // Fully resets the component
  const reset = () => {
    let radWeight = document.getElementsByName('exampleRadio1');
    let radNumPeople = document.getElementsByName('exampleRadio2');
    let radIsExpedited = document.getElementsByName('exampleRadio3');
    let radScheduleTime = document.getElementsByName('exampleRadio4');
    let scheduleCheck = document.getElementById('schedule-check');

    props.handleForm(false, false);

    setPickupInfo({});
    setDropoffInfo({});
    setAddress('');
    setContainsAlcohol(false);
    setDescription('');
    setMerchantOrderID('');
    setDropoff('');
    setDropAddress1(null);
    setDropAddress2(null);
    setDropCity(null);
    setDropCountry(null);
    // setDropEmail('');
    setDropFirstname('');
    setDropFullAddress('');
    setDropLastname('');
    setDropLat(null);
    setDropLng(null);
    setDropPhone('');
    setDropState(null);
    setDropZip(null);
    setErrors({
      pickupFirstname: false,
      pickupLastname: false,
      pickupPhone: false,
      pickupAddress: false,
      dropoffFirstname: false,
      dropoffLastname: false,
      dropoffPhone: false,
      description: false,
      weight: false,
      gophrs: false,
      size: false,
      price: false
    });
    setWaypointErrors({
      waypointFirstname: false,
      waypointLastname: false,
      waypointPhone: false,
      waypointAddress: false,
      pickOrDrop: false
    });
    setExpeditedPrice(null);
    setIsExpedited(false);
    setIsSaved(false);
    setIsScheduled(false);
    setNumPeople(null);
    setOrderStatus('awaiting_driver');
    setPickFullAddress('');
    setPickAddress1(null);
    setPickAddress2(null);
    setPickCity(null);
    setPickCountry(null);
    setPickFirstname('');
    setPickLastname('');
    setPickLat(null);
    setPickLng(null);
    setPickPhone('');
    setPickState(null);
    setPickZip(null);
    setPickup('');

    // waypoint reset
    setWaypointPickupInstruction('');
    setWaypointDropoffInstruction('');
    setWaypointAddress1(null);
    setWaypointAddress2(null);
    setWaypointCity(null);
    setWaypointCountry(null);
    setWaypointFirstname('');
    setWaypointLastname('');
    setWaypointFullAddress('');
    setWaypointLat(null);
    setWaypointLng(null);
    setWaypointPhone('');
    setWaypointState(null);
    setWaypointZip(null);

    setScheduledFor(null);
    setShipmentStatus('shipment_ready_for_driver');
    // setShowForm(props.showForm);
    // setShowForm1(true);
    // setShowForm2(props.showForm2);
    setShowForm3(false);
    setStandardPrice(null);
    // setStoreAddress1("");
    // setStoreAddress2("");
    // setStoreCity("");
    // setStoreCountry("");
    // setStoreId("");
    // setStoreName("");
    // setStoreZip("");
    setVehicleType('');
    setWeight('');

    setIsPickupStore(true);
    setIsDropoffStore(false);
    props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
    props.handleErrorSubmit({ errorMessage: '', errorCode: 0 });

    // Clear the radio bubbles/checkboxes
    radWeight.forEach(element => (element.checked = false));
    radNumPeople.forEach(element => (element.checked = false));
    radIsExpedited.forEach(element => (element.checked = false));
    radScheduleTime.forEach(element => (element.checked = false));
    scheduleCheck.checked = false;

    // Clear the address input
    document.getElementById('mapbox-autocomplete').value = '';
    // ! need a way to clear mapbox
    // if (hasThirdWaypoint) {
    //   document.getElementById('mapbox-autocomplete2').value = '';
    //   document.getElementById('mapbox-autocomplete3').value = '';
    // }
    document.getElementsByClassName('react-mapbox-ac-input')[0].value = '';

    //reset line items
    setDefaultItems([{ quantity: 1, detail: '' }]);
    setPickupItems([[{ quantity: 1, detail: '' }]]);
    setDropoffItems([[{ quantity: 1, detail: '' }]]);
    setWaypointPickupItems([[{ quantity: 1, detail: '' }]]);
    setWaypointDropoffItems([[{ quantity: 1, detail: '' }]]);

    //reset infinite drops
    setDropWaypointsArray({
      waypointFirstname: '',
      waypointLastname: '',
      waypointPhone: '',
      waypointAddress1: null,
      waypointAddress2: null,
      waypointFullAddress: '',
      waypointLat: '',
      waypointLng: '',
      waypointCity: '',
      waypointState: '',
      waypointCountry: '',
      waypointZip: '',
      waypointDropoffInstruction: '',
      waypointBusinessName: '',
      waypointCustomerSuggestions: [],
      isWaypointAutoFilled: false,
      dropItems: [{ quantity: 1, detail: '' }],
      dropItemsError: [false]
    });
    setInfiniteDropWaypointsErrors({
      waypointFirstname: false,
      waypointLastname: false,
      waypointPhone: false,
      waypointAddress: false
    });
  };

  const handleFill = name => {
    if (name === vehicleType) {
      return '#f7941d';
    } else {
      return '#5e72e4';
    }
  };

  const handleFillGrayOut = name => {
    if (name === vehicleType) {
      setVehicleType('');
    }
    return '#333333';
  };

  const handleScheduleClick = () => {
    if (isScheduled) {
      setScheduledFor(
        tomorrow
          .hour(12)
          .minute(0)
          .second(0)
      );
    }
  };

  const handleWeight = event => {
    setWeight(event.target.value);
  };

  const handleScheduleTime = event => {
    setScheduledTime(event.target.value);
  };

  const verifyDate = (current, selected) => {
    // return current.day() !== 0 && current.isAfter(now) && limit.isAfter(current);
    return current.isAfter(now) && limit.isAfter(current); //! remove sunday
  };

  useEffect(() => {
    if (isScheduled && scheduledTime && scheduledDate) {
      setScheduledFor(
        moment(
          moment(scheduledDate)
            .startOf('day')
            .add(scheduledTime, 'hours')
        )
      );
      setIsProceeded(true);
    } else if (isScheduled && (!scheduledTime || !scheduledDate)) {
      setIsProceeded(false);
    } else if (!isScheduled) {
      setIsProceeded(true);
      setScheduledFor(null);
    }
  }, [isScheduled, scheduledDate, scheduledTime]);

  useEffect(() => {
    if (!loading) {
      setVendorLat(store.latitude.toString());
      setVendorLong(store.longitude.toString());
      setStoreAddress1(store.address_1);
      setStoreAddress2(store.address_2);
      setStoreCity(store.city);
      setStoreCountry(store.country);
      setStoreName(store.name);
      setStoreId(store.store_id);
      setStoreZip(store.zip.toString());
      setStoreFirstName(store.StoreOwnerships[0].firstname);
      setStoreLastName(store.StoreOwnerships[0].lastname);
      // setStoreEmail(store.StoreOwnerships[0].email);
      setStorePhone(store.phone);
      setStoreState(store.state);
      setStoreAddress(store.address_1 + ', ' + store.city + ', ' + store.state + ' ' + store.zip + ', ' + store.country);
      setStoreLat(store.latitude.toString());
      setStoreLng(store.longitude.toString());
      setSellsAlcohol(store.sells_alcohol);
      store.direct_type.map(type => {
        if (type === 'multidrop') {
          setAllowMultipleDrops(true);
          if (store.itemized_price) {
            setPerItemPrice(store.itemized_price.price_per_item);
            setPerStopPrice(store.itemized_price.charge_per_stop);
            setExtraPerItemPrice(store.itemized_price.extra_charge_per_item);
            setFreeItemCount(store.itemized_price.free_items_count_threshold);
          }
        } else if (type === 'waypoint') {
          setAllowWaypoint(true);
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    let storeInfo = {
      name: storeFirstName + ' ' + storeLastName,
      first_name: storeFirstName,
      last_name: storeLastName,
      phone: storePhone,
      address: storeAddress,
      address_1: storeAddress1,
      address_2: storeAddress2,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      business_name: storeName,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng)
    };
    let dropInfo = {
      name: dropFirstname + ' ' + dropLastname,
      first_name: dropFirstname,
      last_name: dropLastname,
      phone: dropPhone,
      address: dropFullAddress,
      address_1: dropAddress1,
      address_2: dropAddress2,
      city: dropCity,
      zip: dropZip,
      state: dropState,
      country: dropCountry,
      business_name: dropoffBusinessName,
      lat: parseFloat(dropLat),
      lng: parseFloat(dropLng)
    };
    let pickInfo = {
      name: pickFirstname + ' ' + pickLastname,
      first_name: pickFirstname,
      last_name: pickLastname,
      phone: pickPhone,
      address: pickFullAddress,
      address_1: pickAddress1,
      address_2: pickAddress2,
      city: pickCity,
      zip: pickZip,
      state: pickState,
      country: pickCountry,
      business_name: pickBusinessName,
      lat: parseFloat(pickLat),
      lng: parseFloat(pickLng)
    };
    if (hasThirdWaypoint) {
      if (!isRoundTrip) {
        if (isPickupStore) {
          setPickupInfo(storeInfo);
        } else {
          setPickupInfo(pickInfo);
        }
        if (isDropoffStore) {
          setDropoffInfo(storeInfo);
        } else {
          setDropoffInfo(dropInfo);
        }
      } else {
        if (isPickupStore) {
          setPickupInfo(storeInfo);
          setDropoffInfo(storeInfo);
        } else {
          setPickupInfo(pickInfo);
          setDropoffInfo(pickInfo);
        }
      }
    } else {
      if (isPickupStore) {
        setPickupInfo(storeInfo);
        setDropoffInfo(dropInfo);
      } else if (isDropoffStore) {
        setDropoffInfo(storeInfo);
        setPickupInfo(pickInfo);
      } else {
        setPickupInfo(pickInfo);
        setDropoffInfo(dropInfo);
      }
    }
  }, [
    pickLat,
    pickLng,
    dropLat,
    dropLng,
    storeLat,
    storeLng,
    isPickupStore,
    isDropoffStore,
    pickFirstname,
    pickLastname,
    pickPhone,
    pickBusinessName,
    dropPhone,
    dropFirstname,
    dropLastname,
    dropoffBusinessName,
    isRoundTrip,
    hasThirdWaypoint
  ]);

  useEffect(() => {
    if (hasThirdWaypoint) {
      if (isWaypointStore) {
        setWaypointInfo({
          name: storeFirstName + ' ' + storeLastName,
          first_name: storeFirstName,
          last_name: storeLastName,
          phone: storePhone,
          address: storeAddress,
          address_1: storeAddress1,
          address_2: storeAddress2,
          city: storeCity,
          zip: storeZip,
          state: storeState,
          country: storeCountry,
          business_name: storeName,
          lat: parseFloat(storeLat),
          lng: parseFloat(storeLng)
        });
      } else {
        setWaypointInfo({
          name: waypointFirstname + ' ' + waypointLastname,
          first_name: waypointFirstname,
          last_name: waypointLastname,
          phone: waypointPhone,
          address: waypointFullAddress,
          address_1: waypointAddress1,
          address_2: waypointAddress2,
          city: waypointCity,
          zip: waypointZip,
          state: waypointState,
          country: waypointCountry,
          business_name: waypointBusinessName,
          lat: parseFloat(waypointLat),
          lng: parseFloat(waypointLng)
        });
      }
    }
  }, [hasThirdWaypoint, isWaypointStore, waypointLat, waypointLng, storeLat, storeLng, waypointFirstname, waypointLastname, waypointPhone, waypointBusinessName]);

  useEffect(() => {
    if (allowMultipleDrops) {
      setInfiniteDropsInfo(
        dropWaypointsArray.map(dr => {
          return {
            name: dr.waypointFirstname + ' ' + dr.waypointLastname,
            first_name: dr.waypointFirstname,
            last_name: dr.waypointLastname,
            phone: dr.waypointPhone,
            address: dr.waypointFullAddress,
            address_1: dr.waypointAddress1,
            address_2: dr.waypointAddress2,
            city: dr.waypointCity,
            zip: dr.waypointZip,
            state: dr.waypointState,
            country: 'US',
            business_name: dr.waypointBusinessName ? dr.waypointBusinessName : '',
            lat: parseFloat(dr.waypointLat),
            lng: parseFloat(dr.waypointLng)
          };
        })
      );
    }
  }, [allowMultipleDrops, dropWaypointsArray]);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousNumPeople = usePrevious(numPeople);
  const previousVehicleType = usePrevious(vehicleType);
  const previousWeight = usePrevious(weight);
  const previousDrop = usePrevious(dropoffInfo);
  const previousPick = usePrevious(pickupInfo);
  const previousWaypoint = usePrevious(waypointInfo);
  const previousAlc = usePrevious(containsAlcohol);
  const previousRoundTrip = usePrevious(isRoundTrip);
  const previousDropWaypointsArray = usePrevious(dropWaypointsArray);
  const previousPickupItems = usePrevious(pickupItems);

  const previousForkLift = usePrevious(isForkLift);
  const previousDolly = usePrevious(isDolly);
  const previousHazmat = usePrevious(isHazmat);
  const previousTWICCard = usePrevious(isTWICCard);
  const previousLiftgate = usePrevious(isLiftgate);
  const previousPalletJack = usePrevious(isPalletJack);
  const previousHandLoad = usePrevious(isHandLoadUnload);
  const previousWeatherProtection = usePrevious(isWeatherProtection);
  const previousMedical = usePrevious(isMedical);
  const previousOverSize = usePrevious(isOverSize);

  useEffect(() => {
    // Fetch relevant store information from Hasura
    axios({
      method: 'post',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'content-type': 'application/json' },
      url: HASURA_ENDPOINT,
      data: QUERY
    })
      .then(response => {
        setStore(response.data.data.Stores[0]);
        setStoreAddress(
          response.data.data.Stores[0].address_1 +
            ', ' +
            response.data.data.Stores[0].city +
            ', ' +
            response.data.data.Stores[0].state +
            ' ' +
            response.data.data.Stores[0].zip +
            ', ' +
            response.data.data.Stores[0].country
        );
        setStoreMarketId(response.data.data.Stores[0].market_id);
        setMerchantName(response.data.data.Stores[0].StoreOwnerships[0].lastname + ' ' + response.data.data.Stores[0].StoreOwnerships[0].firstname);
        setLoading(false);
      })
      .catch(function(error) {});
  }, []);

  useEffect(() => {
    if (isSaved) {
      setShipmentStatus('shipment_saved');
      setOrderStatus('order_saved');
    } else {
      setShipmentStatus('shipment_ready_for_driver');
      setOrderStatus('awaiting_driver');
    }

    setShowForm(props.showForm);
    setErrorMessageSubmit(props.errorSubmit);
    setErrorMessageDistance(props.errorDistance);

    //! This method used to compare equality of array of objects
    var isEqual = function(value, other) {
      // Get the value type
      var type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) return false;

      // If items are not an object or array, return false
      if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

      // Compare the length of the length of the two items
      var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
      var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) return false;

      // Compare two items
      var compare = function(item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
          if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) return false;

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === '[object Function]') {
            if (item1.toString() !== item2.toString()) return false;
          } else {
            if (item1 !== item2) return false;
          }
        }
      };

      // Compare properties
      if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) return false;
        }
      } else {
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (compare(value[key], other[key]) === false) return false;
          }
        }
      }

      // If nothing failed, return true
      return true;
    };

    if (allowMultipleDrops) {
      if (
        previousPick != pickupInfo ||
        previousNumPeople !== numPeople ||
        previousVehicleType !== vehicleType ||
        previousWeight !== weight ||
        !isEqual(previousDropWaypointsArray, dropWaypointsArray) ||
        (perItemPrice && dropWaypointsArray.length > 1 && !isEqual(previousPickupItems, pickupItems)) ||
        previousDolly !== isDolly ||
        previousHazmat !== isHazmat ||
        previousTWICCard !== isTWICCard ||
        previousLiftgate !== isLiftgate ||
        previousPalletJack !== isPalletJack ||
        previousHandLoad !== isHandLoadUnload ||
        previousWeatherProtection !== isWeatherProtection ||
        previousMedical !== isMedical ||
        previousOverSize !== isOverSize
      ) {
        let start = pickupInfo.lng + ',' + pickupInfo.lat;
        let coordinates = '';
        let orderPoints = [];
        let orderPickPoint = { lat: pickupInfo.lat, lng: pickupInfo.lng };
        let orderDropPoints = [];
        let dropsArrayFilled = true;
        let itemsCountPerStop = [];

        let tqlMultiDropStops = [];
        tqlMultiDropStops.push({
          stopType: 'PICK',
          stopOrder: 1,
          postalCode: pickupInfo.zip,
          countryCode: 'USA'
        });
        dropWaypointsArray.map((ar, index) => {
          if (ar.waypointLng && ar.waypointLat) {
            coordinates += ';' + ar.waypointLng + ',' + ar.waypointLat;
            orderPoints.push([ar.waypointLat, ar.waypointLng]);
            orderDropPoints.push({ lat: ar.waypointLat, lng: ar.waypointLng });
            let count = 0;
            ar.dropItems.map(it => (count += it.quantity));
            itemsCountPerStop.push(count);
            tqlMultiDropStops.push({
              stopType: 'DROP',
              stopOrder: index + 2,
              postalCode: ar.waypointZip,
              countryCode: 'USA'
            });
          } else {
            dropsArrayFilled = false;
          }
        });

        // console.log('orderDropPoints ', orderDropPoints);

        // find the farthest location from store in a route having more than 1 drop
        function haversineDistance(coords1, coords2) {
          function toRad(x) {
            return (x * Math.PI) / 180;
          }

          var lon1 = coords1.lng;
          var lat1 = coords1.lat;

          var lon2 = coords2.lng;
          var lat2 = coords2.lat;

          var R = 6371; // km

          var x1 = lat2 - lat1;
          var dLat = toRad(x1);
          var x2 = lon2 - lon1;
          var dLon = toRad(x2);
          var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          var d = R * c;

          return d;
        }

        const farthestLocation = (startCoord, dropCoords) => {
          let distance = 0;
          let index = 0;
          dropCoords.map((drop, i) => {
            let calculateDistance = haversineDistance(startCoord, drop);
            if (calculateDistance > distance) {
              distance = calculateDistance;
              index = i;
            }
          });
          // console.log('address ', dropWaypointsArray[index].waypointAddress1);
          return dropCoords[index];
        };

        //! have not find the way to optimize the routes and it depends on the order you put the waypoint
        let requestEndpointInfiniteDrops;
        if (perItemPrice && dropWaypointsArray.length > 1) {
          let targetCoors = farthestLocation(orderPickPoint, orderDropPoints);
          let destination = ';' + targetCoors.lng + ',' + targetCoors.lat;
          requestEndpointInfiniteDrops = MAPBOX_ENDPOINT + start + destination + '?alternatives=true&geometries=geojson&access_token=' + MAPBOX_TOKEN;
        } else {
          requestEndpointInfiniteDrops = MAPBOX_ENDPOINT + start + coordinates + '?alternatives=true&geometries=geojson&access_token=' + MAPBOX_TOKEN;
        }

        // console.log('requestEndpointInfiniteDrops ', requestEndpointInfiniteDrops);

        if (
          pickupInfo.lat &&
          pickupInfo.lng &&
          numPeople !== '' &&
          numPeople !== null &&
          vehicleType !== '' &&
          vehicleType !== null &&
          weight !== null &&
          weight !== '' &&
          dropsArrayFilled
        ) {
          axios
            .get(requestEndpointInfiniteDrops)
            .then(response => {
              var route = response.data.routes[0];
              for (let i = 1; i < response.data.routes.length; i++) {
                if (response.data.routes[i].duration < route.duration) {
                  route = response.data.routes[i];
                }
              }
              if (route) {
                setDistance(route.distance / 1609.344);
                setRoute(route);
                let realMiles = route.distance / 1609.344;
                let roundMiles = Math.round(realMiles);
                let itemNum = 0;
                pickupItems.map(it => {
                  itemNum += it.quantity;
                });
                let standardData = {
                  distance: realMiles,
                  weight: weight,
                  is_expedited: false,
                  is_super_expedited: false,
                  quoted_time: route.duration / 60 / 60,
                  num_of_gophrs: numPeople,
                  vehicle_type: vehicleType
                    .toLowerCase()
                    .split(' ')
                    .join('_'),
                  contains_alcohol: containsAlcohol,
                  market_id: storeMarketId,
                  order_points: [[pickupInfo.lat, pickupInfo.lng], ...orderPoints],
                  num_of_drops: orderPoints.length,
                  is_roundtrip: isRoundTrip,
                  is_itemized: perItemPrice && dropWaypointsArray.length > 1,
                  per_item_price: perItemPrice,
                  per_stop_price: perStopPrice,
                  extra_price_per_item: extraPerItemPrice,
                  free_items_count_per_stop: freeItemCount,
                  items_count_per_stop_array: itemsCountPerStop,
                  item_count: itemNum,
                  is_fork_lift: isForkLift,
                  is_dolly: isDolly,
                  is_hazmat: isHazmat,
                  is_pallet_jack: isPalletJack,
                  is_twic_card: isTWICCard,
                  is_liftgate: isLiftgate,
                  is_hand_loading: isHandLoadUnload,
                  is_weather_protection: isWeatherProtection,
                  is_medical: isMedical,
                  temperature,
                  scheduled_for: scheduledFor || tomorrow, // add one day to get the quote, sometimes can not get the quote today, update later
                  load_value: loadValue,
                  stops: tqlMultiDropStops,
                  commodity,
                  do_tql_cal: true,
                  is_over_size: isOverSize,
                  drop_off_state: dropWaypointsArray[dropWaypointsArray.length - 1].state
                };
                console.log('standardData ', standardData);
                let expeditedData = {
                  ...standardData,
                  is_expedited: true
                };
                let superExpeditedData = {
                  ...standardData,
                  is_super_expedited: true
                };
                if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') {
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuoteWithRate,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                    data: { ...standardData, distance: roundMiles }
                  })
                    .then(response => {
                      // console.log('000 multi ', response);
                      props.handleErrorDistance({ errorMessage: '', errorCode: 0 });

                      if (response.data.payload.hasOwnProperty('shipping_fee_range')) {
                        setHasTqlRateValue(false);
                        setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setStandardPrice(response.data.payload.shipping_fee);
                        setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_expedited.min);
                        setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_expedited.max);
                        setExpeditedPrice(response.data.payload.shipping_fee);
                        setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_super_expedited.min);
                        setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_super_expedited.max);
                        setSuperExpeditedPrice(response.data.payload.shipping_fee);
                      } else {
                        setHasTqlRateValue(true);
                        setStandardPriceRangeLow(0);
                        setStandardPriceRangeHigh(0);
                        setStandardPrice(response.data.payload.shipping_fee);
                        setExpeditedPriceRangeLow(0);
                        setExpeditedPriceRangeHigh(0);
                        setExpeditedPrice(response.data.payload.shipping_fee_expedited);
                        setSuperExpeditedPriceRangeLow(0);
                        setSuperExpeditedPriceRangeHigh(0);
                        setSuperExpeditedPrice(response.data.payload.shipping_fee_super_expedited);
                        setTqlRateBlob(response.data);
                      }
                      props.handleForm(true, true);
                    })
                    .catch(error => {
                      console.log('standard quote error: ', error.response);
                      props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                      props.notify('tc', 'distance');
                    });
                } else {
                  setHasTqlRateValue(false);
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuoteWithRate,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                    data: standardData
                  })
                    .then(response => {
                      props.handleErrorDistance({ errorMessage: '', errorCode: 0 });

                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setStandardPrice(response.data.payload.shipping_fee);
                      } else {
                        setStandardPrice(response.data.payload.shipping_fee);
                      }
                    })
                    .catch(error => {
                      console.log('standard quote error: ', error.response);
                      props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                      props.notify('tc', 'distance');
                    });
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuoteWithRate,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                    data: expeditedData
                  })
                    .then(response => {
                      props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setExpeditedPrice(response.data.payload.shipping_fee);
                      } else {
                        setExpeditedPrice(response.data.payload.shipping_fee);
                      }
                      props.handleForm(true, true);
                    })
                    .catch(error => {
                      console.log('expedite quote error: ', error.response);
                      props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                    });
                  axios({
                    method: 'post',
                    url: shipment_urls.getShipmentQuoteWithRate,
                    withCredentials: true,
                    headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                    data: superExpeditedData
                  })
                    .then(response => {
                      props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                        setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                        setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                        setSuperExpeditedPrice(response.data.payload.shipping_fee);
                      } else {
                        setSuperExpeditedPrice(response.data.payload.shipping_fee);
                      }
                      // setPrice(response.data.payload);
                      props.handleForm(true, true);
                    })
                    .catch(error => {
                      console.log('super expedite quote error: ', error.response);
                      props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 4 });
                    });
                }
              }
            })
            .catch(error => {
              console.log('calculate distance error: ', error.response);
              props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 1 });
              props.notify('tc', 'distance');
            });
        }
      }
    } else {
      if (
        previousDrop != dropoffInfo ||
        previousAlc != containsAlcohol ||
        previousPick != pickupInfo ||
        previousNumPeople !== numPeople ||
        previousVehicleType !== vehicleType ||
        previousWeight !== weight ||
        (hasThirdWaypoint && previousWaypoint !== waypointInfo) ||
        previousRoundTrip !== isRoundTrip ||
        previousDolly !== isDolly ||
        previousHazmat !== isHazmat ||
        previousTWICCard !== isTWICCard ||
        previousLiftgate !== isLiftgate ||
        previousPalletJack !== isPalletJack ||
        previousHandLoad !== isHandLoadUnload ||
        previousWeatherProtection !== isWeatherProtection ||
        previousMedical !== isMedical ||
        previousOverSize !== isOverSize
      ) {
        // Check if form entries are valid
        let end = dropoffInfo.lng + ',' + dropoffInfo.lat;
        let start = pickupInfo.lng + ',' + pickupInfo.lat;
        let waypoint = waypointInfo.lng + ',' + waypointInfo.lat;
        //! This distance check does not make sense somehow, did not cover all cases even for 2 stops
        let requestEndpointDistanceCheck = MAPBOX_ENDPOINT + controlLong + ',' + controlLat + ';' + end + '?geometries=geojson&access_token=' + MAPBOX_TOKEN;
        let requestEndpointRegular = MAPBOX_ENDPOINT + start + ';' + end + '?alternatives=true&geometries=geojson&access_token=' + MAPBOX_TOKEN;
        let requestEndpointWithWaypoint = MAPBOX_ENDPOINT + start + ';' + waypoint + ';' + end + '?alternatives=true&geometries=geojson&access_token=' + MAPBOX_TOKEN;

        if (hasThirdWaypoint) {
          if (
            dropoffInfo.lat &&
            dropoffInfo.lng &&
            pickupInfo.lat &&
            pickupInfo.lng &&
            numPeople !== '' &&
            numPeople !== null &&
            vehicleType !== '' &&
            vehicleType !== null &&
            weight !== null &&
            weight !== '' &&
            waypointInfo.lat &&
            waypointInfo.lng
          ) {
            // Get distance from MapBox

            axios
              .get(requestEndpointWithWaypoint)
              .then(response => {
                var route = response.data.routes[0];
                for (let i = 1; i < response.data.routes.length; i++) {
                  if (response.data.routes[i].duration < route.duration) {
                    route = response.data.routes[i];
                  }
                }
                if (route) {
                  setDistance(route.distance / 1609.344);
                  setRoute(route);
                  let realMiles = route.distance / 1609.344;
                  let roundMiles = Math.round(realMiles);
                  let standardData = {
                    distance: realMiles,
                    weight: weight,
                    is_expedited: false,
                    is_super_expedited: false,
                    quoted_time: route.duration / 60 / 60,
                    num_of_gophrs: numPeople,
                    vehicle_type: vehicleType
                      .toLowerCase()
                      .split(' ')
                      .join('_'),
                    contains_alcohol: containsAlcohol,
                    market_id: storeMarketId,
                    order_points: [[pickupInfo.lat, pickupInfo.lng], [waypointInfo.lat, waypointInfo.lng], [dropoffInfo.lat, dropoffInfo.lng]],
                    num_of_drops: isWaypointDropoff ? 2 : 1,
                    is_roundtrip: isRoundTrip,
                    is_fork_lift: isForkLift,
                    is_dolly: isDolly,
                    is_hazmat: isHazmat,
                    is_pallet_jack: isPalletJack,
                    is_twic_card: isTWICCard,
                    is_liftgate: isLiftgate,
                    is_hand_loading: isHandLoadUnload,
                    is_weather_protection: isWeatherProtection,
                    is_medical: isMedical,
                    temperature,
                    scheduled_for: scheduledFor || tomorrow, // add one day to get the quote, sometimes can not get the quote today, update later
                    load_value: loadValue,
                    stops: [
                      {
                        stopType: 'PICK',
                        stopOrder: 1,
                        postalCode: pickupInfo.zip,
                        countryCode: 'USA'
                      },
                      {
                        stopType: 'PICK',
                        stopOrder: 2,
                        postalCode: waypointInfo.zip,
                        countryCode: 'USA'
                      },
                      {
                        stopType: 'DROP',
                        stopOrder: 3,
                        postalCode: dropoffInfo.zip,
                        countryCode: 'USA'
                      }
                    ], // Does not know if tql support pick and drop at same point, so just mark it as pick for the middle waypoint
                    commodity,
                    do_tql_cal: true,
                    is_over_size: isOverSize,
                    drop_off_state: dropoffInfo.state
                  };
                  // console.log('standardData ', standardData);
                  let expeditedData = {
                    ...standardData,
                    is_expedited: true
                  };
                  // console.log('expeditedData ', expeditedData);
                  let superExpeditedData = {
                    ...standardData,
                    is_super_expedited: true
                  };
                  // console.log('superExpeditedData ', superExpeditedData);

                  axios.get(requestEndpointDistanceCheck).then(response => {
                    let quickDistance = route.distance / 1609.344;
                    if (quickDistance > 10000) {
                      props.handleErrorDistance({
                        errorMessage:
                          "We're sorry, but your delivery distance of " +
                          parseInt(quickDistance) +
                          ' miles is too far.  Please, contact a Gophr representative for more information.',
                        errorCode: 1
                      });
                    } else {
                      // Use distance to get both price quotes
                      props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                      if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') {
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: {
                            'content-type': 'application/json',
                            Authorization: ACCESS_TOKEN
                          },
                          data: { ...standardData, distance: roundMiles }
                        })
                          .then(response => {
                            props.handleErrorDistance({
                              errorMessage: '',
                              errorCode: 0
                            });
                            // console.log('000 routed', response);
                            if (response.data.payload.hasOwnProperty('shipping_fee_range')) {
                              setHasTqlRateValue(false);
                              setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setStandardPrice(response.data.payload.shipping_fee);
                              setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_expedited.min);
                              setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_expedited.max);
                              setExpeditedPrice(response.data.payload.shipping_fee);
                              setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_super_expedited.min);
                              setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_super_expedited.max);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setHasTqlRateValue(true);
                              setStandardPriceRangeLow(0);
                              setStandardPriceRangeHigh(0);
                              setStandardPrice(response.data.payload.shipping_fee);
                              setExpeditedPriceRangeLow(0);
                              setExpeditedPriceRangeHigh(0);
                              setExpeditedPrice(response.data.payload.shipping_fee_expedited);
                              setSuperExpeditedPriceRangeLow(0);
                              setSuperExpeditedPriceRangeHigh(0);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee_super_expedited);
                              setTqlRateBlob(response.data);
                            }
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({
                              errorMessage: 'Something went wrong. Please refresh the page, and try again.',
                              errorCode: 2
                            });
                            props.notify('tc', 'distance');
                          });
                      } else {
                        setHasTqlRateValue(false);
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                          data: standardData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });

                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setStandardPrice(response.data.payload.shipping_fee);
                            } else {
                              setStandardPrice(response.data.payload.shipping_fee);
                            }
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                            props.notify('tc', 'distance');
                          });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                          data: expeditedData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setExpeditedPrice(response.data.payload.shipping_fee);
                            }
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                          });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                          data: superExpeditedData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            }
                            // setPrice(response.data.payload);
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                          });
                      }
                    }
                  });
                }
              })
              .catch(error => {
                console.log('distance error: ', error.response);
                props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                props.notify('tc', 'distance');
              });
          }
        } else {
          if (
            dropoffInfo.lat &&
            dropoffInfo.lng &&
            pickupInfo.lat &&
            pickupInfo.lng &&
            numPeople !== '' &&
            numPeople !== null &&
            vehicleType !== '' &&
            vehicleType !== null &&
            weight !== null &&
            weight !== ''
          ) {
            // Get distance from MapBox

            axios
              .get(requestEndpointRegular)
              .then(response => {
                var route = response.data.routes[0];
                for (let i = 1; i < response.data.routes.length; i++) {
                  if (response.data.routes[i].duration < route.duration) {
                    route = response.data.routes[i];
                  }
                }
                if (route) {
                  setDistance(route.distance / 1609.344);
                  setRoute(route);
                  let realMiles = route.distance / 1609.344;
                  let roundMiles = Math.round(realMiles);
                  let standardData = {
                    distance: realMiles,
                    weight: weight,
                    is_expedited: false,
                    is_super_expedited: false,
                    quoted_time: route.duration / 60 / 60,
                    num_of_gophrs: numPeople,
                    vehicle_type: vehicleType
                      .toLowerCase()
                      .split(' ')
                      .join('_'),
                    contains_alcohol: containsAlcohol,
                    market_id: storeMarketId,
                    order_points: [[pickupInfo.lat, pickupInfo.lng], [dropoffInfo.lat, dropoffInfo.lng]],
                    num_of_drops: 1,
                    is_roundtrip: isRoundTrip,
                    is_fork_lift: isForkLift,
                    is_dolly: isDolly,
                    is_hazmat: isHazmat,
                    is_pallet_jack: isPalletJack,
                    is_twic_card: isTWICCard,
                    is_liftgate: isLiftgate,
                    is_hand_loading: isHandLoadUnload,
                    is_weather_protection: isWeatherProtection,
                    is_medical: isMedical,
                    temperature,
                    scheduled_for: scheduledFor || tomorrow, // add one day to get the quote, sometimes can not get the quote today, update later
                    load_value: loadValue,
                    stops: [
                      {
                        stopType: 'PICK',
                        stopOrder: 1,
                        postalCode: pickupInfo.zip,
                        countryCode: 'USA'
                      },
                      {
                        stopType: 'DROP',
                        stopOrder: 2,
                        postalCode: dropoffInfo.zip,
                        countryCode: 'USA'
                      }
                    ],
                    commodity: defaultItems.detail || commodity,
                    do_tql_cal: true,
                    is_over_size: isOverSize,
                    drop_off_state: dropoffInfo.state
                  };
                  // console.log('standardData ', standardData);
                  let expeditedData = {
                    ...standardData,
                    is_expedited: true
                  };
                  let superExpeditedData = {
                    ...standardData,
                    is_super_expedited: true
                  };
                  if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') {
                    axios.get(requestEndpointDistanceCheck).then(response => {
                      let quickDistance = route.distance / 1609.344;
                      if (quickDistance > 10000) {
                        props.handleErrorDistance({
                          errorMessage:
                            "We're sorry, but your delivery distance of " +
                            parseInt(quickDistance) +
                            ' miles is too far.  Please, contact a Gophr representative for more information.',
                          errorCode: 1
                        });
                      } else {
                        // Use distance to get both price quotes
                        props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                          data: { ...standardData, distance: roundMiles }
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            // console.log('000 ', response);
                            if (response.data.payload.hasOwnProperty('shipping_fee_range')) {
                              setHasTqlRateValue(false);
                              setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setStandardPrice(response.data.payload.shipping_fee);
                              setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_expedited.min);
                              setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_expedited.max);
                              setExpeditedPrice(response.data.payload.shipping_fee);
                              setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range_super_expedited.min);
                              setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range_super_expedited.max);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setHasTqlRateValue(true);
                              setStandardPriceRangeLow(0);
                              setStandardPriceRangeHigh(0);
                              setStandardPrice(response.data.payload.shipping_fee);
                              setExpeditedPriceRangeLow(0);
                              setExpeditedPriceRangeHigh(0);
                              setExpeditedPrice(response.data.payload.shipping_fee_expedited);
                              setSuperExpeditedPriceRangeLow(0);
                              setSuperExpeditedPriceRangeHigh(0);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee_super_expedited);
                              setTqlRateBlob(response.data);
                            }
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                            props.notify('tc', 'distance');
                          });
                      }
                    });
                  } else {
                    setHasTqlRateValue(false);
                    axios.get(requestEndpointDistanceCheck).then(response => {
                      let quickDistance = route.distance / 1609.344;
                      if (quickDistance > 10000) {
                        props.handleErrorDistance({
                          errorMessage:
                            "We're sorry, but your delivery distance of " +
                            parseInt(quickDistance) +
                            ' miles is too far.  Please, contact a Gophr representative for more information.',
                          errorCode: 1
                        });
                      } else {
                        // Use distance to get both price quotes
                        props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: ACCESS_TOKEN },
                          data: standardData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setStandardPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setStandardPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setStandardPrice(response.data.payload.shipping_fee);
                            } else {
                              setStandardPrice(response.data.payload.shipping_fee);
                            }
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 2 });
                            props.notify('tc', 'distance');
                          });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                          data: expeditedData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setExpeditedPrice(response.data.payload.shipping_fee);
                            }
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                          });
                        axios({
                          method: 'post',
                          url: shipment_urls.getShipmentQuoteWithRate,
                          withCredentials: true,
                          headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
                          data: superExpeditedData
                        })
                          .then(response => {
                            props.handleErrorDistance({ errorMessage: '', errorCode: 0 });
                            if (vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === 'car_hauler') {
                              setSuperExpeditedPriceRangeLow(response.data.payload.shipping_fee_range.min);
                              setSuperExpeditedPriceRangeHigh(response.data.payload.shipping_fee_range.max);
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            } else {
                              setSuperExpeditedPrice(response.data.payload.shipping_fee);
                            }
                            // setPrice(response.data.payload);
                            props.handleForm(true, true);
                          })
                          .catch(error => {
                            console.log('error: ', error.response);
                            props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                          });
                      }
                    });
                  }
                }
              })
              .catch(error => {
                console.log('distance error: ', error.response);
                props.handleErrorDistance({ errorMessage: 'Something went wrong. Please refresh the page, and try again.', errorCode: 3 });
                props.notify('tc', 'distance');
              });
          }
        }
      }
    }
  });

  // function that returns true if value is a valid email, false otherwise
  const validateEmail = email => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(String(email.toLowerCase().trim()));
  };

  // function that returns true if value is a valid phone number, false otherwise
  const validatePhone = phone => {
    var phoneRex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{10}))/;

    if (phone.replace(/[^0-9]/g, '').length > 9 && phone.replace(/[^0-9]/g, '').length < 14) {
      return phoneRex.test(String(phone));
    }
  };

  const handleSubmit = () => {
    // Yaboi has had a few and this is how he's error checking...
    // big yikes

    const checkItemsError = (items, itemsError, setItemsError) => {
      let ItemsCheck = false;
      let itemsErrorArray = [...itemsError];
      items.forEach((pi, index) => {
        if (pi.detail === '' || pi.detail === null) {
          itemsErrorArray[index] = true;
          ItemsCheck = true;
        } else {
          itemsErrorArray[index] = false;
        }
      });
      setItemsError(itemsErrorArray);
      return ItemsCheck;
    };

    const checkInfiniteDropItemsError = infiniteDropArray => {
      let dropoffItemCheck = false;
      infiniteDropArray.forEach((dr, i) => {
        let itemsErrorArray = dr.dropItemsError;
        dr.dropItems.forEach((it, j) => {
          if (it.detail === '' || it.detail === null) {
            itemsErrorArray[j] = true;
            dropoffItemCheck = true;
          } else {
            itemsErrorArray[j] = false;
          }
        });
        setDropWaypointsArray(infiniteDropArray.map((ar, k) => (i === k ? { ...infiniteDropArray[i], dropItemsError: itemsErrorArray } : ar)));
      });
      return dropoffItemCheck;
    };

    const genericMatchCheck = (totalPickupItems, totalDropoffItems) => {
      let hasError = false;
      let pickCount = 0;
      let dropCount = 0;

      totalPickupItems.map(item => {
        pickCount += item.quantity;
      });
      totalDropoffItems.map(item => {
        dropCount += item.quantity;
      });

      if (pickCount !== dropCount) {
        hasError = true;
        setItemsMatchError(hasError);
        return hasError;
      }

      let totalPickupItemsCopy = [...totalPickupItems].map(item => item.detail);
      let totalDropoffItemsCopy = [...totalDropoffItems].map(item => item.detail);
      const uniqueArray = array => [...new Set(array.map(item => JSON.stringify(item)))].map(itemBack => JSON.parse(itemBack));

      let totalPickupItemsSet = uniqueArray(totalPickupItemsCopy);
      let totalDropoffItemsSet = uniqueArray(totalDropoffItemsCopy);

      if (!(totalPickupItemsSet.every(item => totalDropoffItemsSet.includes(item)) && totalDropoffItemsSet.every(item => totalPickupItemsSet.includes(item)))) {
        hasError = true;
        setItemsMatchError(hasError);
        return hasError;
      }

      totalPickupItemsSet.forEach(item => {
        let countPick = 0;
        let countDrop = 0;
        totalPickupItems.forEach(pick => {
          if (item === pick.detail) {
            countPick += pick.quantity;
          }
        });
        totalDropoffItems.forEach(drop => {
          if (item === drop.detail) {
            countDrop += drop.quantity;
          }
        });
        if (countPick !== countDrop) {
          hasError = true;
          setItemsMatchError(hasError);
          return hasError;
        }
      });

      setItemsMatchError(hasError);
      return hasError;
    };

    const checkPickAndDropMatchError = () => {
      let totalPickupItems = [...pickupItems, ...waypointPickupItems].filter(item => {
        return item.detail !== '';
      });
      let totalDropoffItems = [...dropoffItems, ...waypointDropoffItems].filter(item => {
        return item.detail !== '';
      });
      return genericMatchCheck(totalPickupItems, totalDropoffItems);
    };

    const checkInfiniteDropMatchError = () => {
      let totalPickupItems = pickupItems.filter(item => {
        return item.detail !== '';
      });
      let totalDropoffItems = [];
      dropWaypointsArray.map(dr => {
        dr.dropItems.map(item => {
          totalDropoffItems.push(item);
        });
      });
      totalDropoffItems = totalDropoffItems.filter(item => {
        return item.detail !== '';
      });
      return genericMatchCheck(totalPickupItems, totalDropoffItems);
    };

    let updatedErrors = errors;
    let updateWaypointErrors = waypointErrors;
    let updateInfiniteDropsErrors = infiniteDropWaypointsErrors;

    if (allowMultipleDrops) {
      if (!isPickupStore) {
        if (pickFirstname) {
          updatedErrors.pickupFirstname = false;
        } else {
          updatedErrors.pickupFirstname = true;
        }
        if (pickLastname) {
          updatedErrors.pickupLastname = false;
        } else {
          updatedErrors.pickupLastname = true;
        }

        if (validatePhone(pickPhone)) {
          updatedErrors.pickupPhone = false;
        } else {
          updatedErrors.pickupPhone = true;
        }
        if (pickLat && pickLng) {
          updatedErrors.pickupAddress = false;
        } else {
          updatedErrors.pickupAddress = true;
        }
      } else {
        updatedErrors.pickupFirstname = false;
        updatedErrors.pickupLastname = false;
        updatedErrors.pickupPhone = false;
        updatedErrors.pickupAddress = false;
      }

      infiniteDropWaypointsErrors.map((dr, i) => {
        let drop = dropWaypointsArray[i];
        if (drop.waypointFirstname) {
          updateInfiniteDropsErrors[i].waypointFirstname = false;
        } else {
          updateInfiniteDropsErrors[i].waypointFirstname = true;
        }
        if (drop.waypointLastname) {
          updateInfiniteDropsErrors[i].waypointLastname = false;
        } else {
          updateInfiniteDropsErrors[i].waypointLastname = true;
        }

        if (validatePhone(drop.waypointPhone)) {
          updateInfiniteDropsErrors[i].waypointPhone = false;
        } else {
          updateInfiniteDropsErrors[i].waypointPhone = true;
        }
        if (drop.waypointLat && drop.waypointLng) {
          updateInfiniteDropsErrors[i].waypointAddress = false;
        } else {
          updateInfiniteDropsErrors[i].waypointAddress = true;
        }
      });
    } else {
      if (hasThirdWaypoint) {
        if (!isPickupStore) {
          if (pickFirstname) {
            updatedErrors.pickupFirstname = false;
          } else {
            updatedErrors.pickupFirstname = true;
          }
          if (pickLastname) {
            updatedErrors.pickupLastname = false;
          } else {
            updatedErrors.pickupLastname = true;
          }

          if (validatePhone(pickPhone)) {
            updatedErrors.pickupPhone = false;
          } else {
            updatedErrors.pickupPhone = true;
          }
          if (pickLat && pickLng) {
            updatedErrors.pickupAddress = false;
          } else {
            updatedErrors.pickupAddress = true;
          }
        } else {
          updatedErrors.pickupFirstname = false;
          updatedErrors.pickupLastname = false;
          updatedErrors.pickupPhone = false;
          updatedErrors.pickupAddress = false;
        }

        if (!isWaypointStore) {
          if (waypointFirstname) {
            updateWaypointErrors.waypointFirstname = false;
          } else {
            updateWaypointErrors.waypointFirstname = true;
          }
          if (waypointLastname) {
            updateWaypointErrors.waypointLastname = false;
          } else {
            updateWaypointErrors.waypointLastname = true;
          }
          // if (validateEmail(pickEmail)) {
          //   updateWaypointErrors.waypointEmail = false;
          // } else {
          //   updateWaypointErrors.waypointEmail = true;
          // }
          if (validatePhone(waypointPhone)) {
            updateWaypointErrors.waypointPhone = false;
          } else {
            updateWaypointErrors.waypointPhone = true;
          }
          if (waypointLat && waypointLng) {
            updateWaypointErrors.waypointAddress = false;
          } else {
            updateWaypointErrors.waypointAddress = true;
          }
        } else {
          updateWaypointErrors.waypointFirstname = false;
          updateWaypointErrors.waypointLastname = false;
          updateWaypointErrors.waypointPhone = false;
          updateWaypointErrors.waypointAddress = false;
        }
        if (!isWaypointPickup && !isWaypointDropoff) {
          updateWaypointErrors.pickOrDrop = true;
        } else {
          updateWaypointErrors.pickOrDrop = false;
        }
        if (!isDropoffStore && !isRoundTrip) {
          if (dropFirstname) {
            updatedErrors.dropoffFirstname = false;
          } else {
            updatedErrors.dropoffFirstname = true;
          }
          if (dropLastname) {
            updatedErrors.dropoffLastname = false;
          } else {
            updatedErrors.dropoffLastname = true;
          }

          if (validatePhone(dropPhone)) {
            updatedErrors.dropoffPhone = false;
          } else {
            updatedErrors.dropoffPhone = true;
          }
          if (dropLat && dropLng) {
            updatedErrors.dropoffAddress = false;
          } else {
            updatedErrors.dropoffAddress = true;
          }
        } else {
          updatedErrors.dropoffFirstname = false;
          updatedErrors.dropoffLastname = false;
          updatedErrors.dropoffPhone = false;
          updatedErrors.dropoffAddress = false;
        }
      } else {
        if (!isPickupStore) {
          if (pickFirstname) {
            updatedErrors.pickupFirstname = false;
          } else {
            updatedErrors.pickupFirstname = true;
          }
          if (pickLastname) {
            updatedErrors.pickupLastname = false;
          } else {
            updatedErrors.pickupLastname = true;
          }
          // if (validateEmail(pickEmail)) {
          //   updatedErrors.pickupEmail = false;
          // } else {
          //   updatedErrors.pickupEmail = true;
          // }
          if (validatePhone(pickPhone)) {
            updatedErrors.pickupPhone = false;
          } else {
            updatedErrors.pickupPhone = true;
          }
          if (pickLat && pickLng) {
            updatedErrors.pickupAddress = false;
          } else {
            updatedErrors.pickupAddress = true;
          }
        } else {
          updatedErrors.pickupFirstname = false;
          updatedErrors.pickupLastname = false;
          // updatedErrors.pickupEmail = false;
          updatedErrors.pickupPhone = false;
          updatedErrors.pickupAddress = false;
        }
        if (!isDropoffStore) {
          if (dropFirstname) {
            updatedErrors.dropoffFirstname = false;
          } else {
            updatedErrors.dropoffFirstname = true;
          }
          if (dropLastname) {
            updatedErrors.dropoffLastname = false;
          } else {
            updatedErrors.dropoffLastname = true;
          }
          // if (validateEmail(dropEmail)) {
          //   updatedErrors.dropoffEmail = false;
          // } else {
          //   updatedErrors.dropoffEmail = true;
          // }
          if (validatePhone(dropPhone)) {
            updatedErrors.dropoffPhone = false;
          } else {
            updatedErrors.dropoffPhone = true;
          }
          if (dropLat && dropLng) {
            updatedErrors.dropoffAddress = false;
          } else {
            updatedErrors.dropoffAddress = true;
          }
        } else {
          updatedErrors.dropoffFirstname = false;
          updatedErrors.dropoffLastname = false;
          // updatedErrors.dropoffEmail = false;
          updatedErrors.dropoffPhone = false;
          updatedErrors.dropoffAddress = false;
        }
      }
    }

    // if (description === null || description === '') {
    //   updatedErrors.description = true;
    // } else {
    //   updatedErrors.description = false;
    // }
    if (weight === null || weight === '') {
      updatedErrors.weight = true;
    } else {
      updatedErrors.weight = false;
    }
    if (numPeople === null || numPeople === '') {
      updatedErrors.gophrs = true;
    } else {
      updatedErrors.gophrs = false;
    }
    if (vehicleType === null || vehicleType === '') {
      updatedErrors.size = true;
    } else {
      updatedErrors.size = false;
    }
    if (price || priceRangeLow) {
      updatedErrors.price = false;
    } else {
      updatedErrors.price = true;
    }

    if (allowMultipleDrops) {
      setErrors(updatedErrors);
      setInfiniteDropWaypointsErrors(updateInfiniteDropsErrors);
      let regularCheck = Object.values(errors).includes(true);
      let infiniteDropsCheck = false;
      infiniteDropWaypointsErrors.map(er => {
        if (Object.values(er).includes(true)) {
          infiniteDropsCheck = true;
        }
      });
      let pickupItemsCheck = checkItemsError(pickupItems, pickupItemsError, setPickupItemsError);
      let dropoffItemsCheck = checkInfiniteDropItemsError(dropWaypointsArray);
      let pickAndDropMatchError = checkInfiniteDropMatchError();
      if (!(regularCheck || infiniteDropsCheck || pickupItemsCheck || dropoffItemsCheck || pickAndDropMatchError)) {
        // ! open confirm form
        props.handleForm(false, true);
        setShowForm3(true);
        setInfiniteStopsError(false);
      } else {
        setInfiniteStopsError(true);
      }
    } else {
      if (hasThirdWaypoint) {
        setErrors(updatedErrors);
        setWaypointErrors(updateWaypointErrors);
        let regularCheck = Object.values(errors).includes(true);
        let waypointCheck = Object.values(waypointErrors).includes(true);
        let pickupItemsCheck = checkItemsError(pickupItems, pickupItemsError, setPickupItemsError);
        let dropoffItemsCheck = checkItemsError(dropoffItems, dropoffItemsError, setDropoffItemsError);
        let waypointPickupItemsCheck = checkItemsError(waypointPickupItems, waypointPickupItemsError, setWaypointPickupItemsError);
        let waypointDropoffItemsCheck = checkItemsError(waypointDropoffItems, waypointDropoffItemsError, setWaypointDropoffItemsError);
        let pickAndDropMatchError = checkPickAndDropMatchError();

        if (!(regularCheck || waypointCheck || pickupItemsCheck || dropoffItemsCheck || pickAndDropMatchError)) {
          if (isWaypointPickup && isWaypointDropoff) {
            if (!waypointPickupItemsCheck && !waypointDropoffItemsCheck) {
              props.handleForm(false, true);
              setShowForm3(true);
              setThreeStopsError(false);
            } else {
              setThreeStopsError(true);
            }
          } else if (isWaypointPickup) {
            if (!waypointPickupItemsCheck) {
              props.handleForm(false, true);
              setShowForm3(true);
              setThreeStopsError(false);
            } else {
              setThreeStopsError(true);
            }
          } else if (isWaypointDropoff) {
            if (!waypointDropoffItemsCheck) {
              props.handleForm(false, true);
              setShowForm3(true);
              setThreeStopsError(false);
            } else {
              setThreeStopsError(true);
            }
          }
        } else {
          setThreeStopsError(true);
        }
      } else {
        setErrors(updatedErrors);
        let regularCheck = Object.values(errors).includes(true);
        let defaultItemsCheck = checkItemsError(defaultItems, defaultItemsError, setDefaultItemsError);
        if (!regularCheck && !defaultItemsCheck) {
          setTwoStopsError(false);
          // setShowForm1(false);
          // ! open confirm form
          props.handleForm(false, true);
          setShowForm3(true);
        } else {
          setTwoStopsError(true);
        }
      }
    }
  };

  const handleBack = () => {
    setShowForm3(false);
    // setShowForm1(true);
    props.handleForm(true, true);
  };

  const handleConfirm = (shipment, order, isScheduled) => {
    setIsOrderSubmitDisabled(true);
    setSubmissionMessage('Your order is submitting, please wait...');
    let url;
    let scheduledForData = null;
    let numOfDrops = 0;
    if (dropoff === null || dropoff === '') {
      setDropoff('N/A');
    }
    if (scheduledFor) {
      scheduledForData = scheduledFor.toISOString();
    }
    if (isScheduled) {
      if (allowMultipleDrops) {
        url = shipment_urls.createMultidropScheduledShipmentWithRate;
      } else {
        if (hasThirdWaypoint) {
          url = shipment_urls.createRoutedScheduledShipmentWithRate;
        } else {
          url = shipment_urls.createScheduledShipmentWithRate;
        }
      }
    } else {
      if (shipment === 'shipment_saved') {
        if (allowMultipleDrops) {
          url = shipment_urls.createMultidropSavedShipment;
        } else {
          if (hasThirdWaypoint) {
            url = shipment_urls.createRoutedSavedShipment;
          } else {
            url = shipment_urls.createSavedShipment;
          }
        }
      } else {
        if (allowMultipleDrops) {
          url = shipment_urls.createMultidropShipmentWithRate;
        } else {
          if (hasThirdWaypoint) {
            url = shipment_urls.createRoutedShipmentWithRate;
          } else {
            url = shipment_urls.createShipmentWithRate;
          }
        }
      }
    }
    let pickOrder = {
      store_id: storeId,
      // Pickup location
      location_blob: {
        name: pickupInfo.name,
        first_name: pickupInfo.first_name,
        last_name: pickupInfo.last_name,
        phone: pickupInfo.phone,
        address: pickupInfo.address,
        address_1: pickupInfo.address_1,
        address_2: pickupInfo.address_2,
        city: pickupInfo.city,
        zip: pickupInfo.zip,
        state: pickupInfo.state,
        country: 'US',
        business_name: pickupInfo.business_name ? pickupInfo.business_name : '',
        lat: pickupInfo.lat,
        lng: pickupInfo.lng
      },
      weight: weight,
      // description: description,
      description: JSON.stringify(hasThirdWaypoint ? pickupItems : defaultItems),
      // line_items: hasThirdWaypoint ? pickupItems : defaultItems,
      pick_up_instructions: pickup,
      actions: {
        pick_up: allowMultipleDrops || hasThirdWaypoint ? pickupItems : defaultItems
      }
    };
    let dropOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: dropoffInfo.name,
        first_name: dropoffInfo.first_name,
        last_name: dropoffInfo.last_name,
        phone: dropoffInfo.phone,
        address: dropoffInfo.address,
        address_1: dropoffInfo.address_1,
        address_2: dropoffInfo.address_2,
        city: dropoffInfo.city,
        zip: dropoffInfo.zip,
        state: dropoffInfo.state,
        country: 'US',
        business_name: dropoffInfo.business_name ? dropoffInfo.business_name : '',
        lat: dropoffInfo.lat,
        lng: dropoffInfo.lng
      },
      weight: weight,
      // description: description,
      description: JSON.stringify(hasThirdWaypoint ? dropoffItems : defaultItems),
      // line_items: hasThirdWaypoint ? dropoffItems : defaultItems,
      drop_off_instructions: dropoff,
      actions: {
        drop_off: hasThirdWaypoint ? dropoffItems : defaultItems
      }
    };
    let waypointPickOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: waypointInfo.name,
        first_name: waypointInfo.first_name,
        last_name: waypointInfo.last_name,
        phone: waypointInfo.phone,
        address: waypointInfo.address,
        address_1: waypointInfo.address_1,
        address_2: waypointInfo.address_2,
        city: waypointInfo.city,
        zip: waypointInfo.zip,
        state: waypointInfo.state,
        country: 'US',
        business_name: waypointInfo.business_name ? waypointInfo.business_name : '',
        lat: waypointInfo.lat,
        lng: waypointInfo.lng
      },
      weight: weight,
      // description: description,
      description: JSON.stringify(waypointPickupItems),
      // line_items: waypointPickupItems,
      pick_up_instructions: waypointPickupInstruction,
      actions: {
        pick_up: waypointPickupItems
      }
    };
    let waypointDropOrder = {
      store_id: storeId,
      // Dropoff location
      location_blob: {
        name: waypointInfo.name,
        first_name: waypointInfo.first_name,
        last_name: waypointInfo.last_name,
        // email: waypointInfo.email,
        phone: waypointInfo.phone,
        address: waypointInfo.address,
        address_1: waypointInfo.address_1,
        address_2: waypointInfo.address_2,
        city: waypointInfo.city,
        zip: waypointInfo.zip,
        state: waypointInfo.state,
        country: 'US',
        business_name: waypointInfo.business_name ? waypointInfo.business_name : '',
        lat: waypointInfo.lat,
        lng: waypointInfo.lng
      },
      weight: weight,
      // description: description,
      description: JSON.stringify(waypointDropoffItems),
      // line_items: waypointDropoffItems,
      drop_off_instructions: waypointDropoffInstruction,
      actions: {
        drop_off: waypointDropoffItems
      }
    };

    let infiniteDropOrders = infiniteDropsInfo.map((drop, index) => {
      return {
        store_id: storeId,
        location_blob: {
          ...drop
        },
        weight: weight,
        description: JSON.stringify(dropWaypointsArray[index].dropItems),
        drop_off_instructions: dropWaypointsArray[index].waypointDropoffInstruction,
        actions: {
          drop_off: dropWaypointsArray[index].dropItems
        }
      };
    });
    let regularOrdersArray = [pickOrder, dropOrder];
    let waypointPickOrdersArray = [pickOrder, waypointPickOrder, dropOrder];
    let waypointDropOrdersArray = [pickOrder, waypointDropOrder, dropOrder];
    let waypointPickAndDropOrdersArray = [pickOrder, waypointDropOrder, waypointPickOrder, dropOrder];
    let infiniteDropsArray = [pickOrder, ...infiniteDropOrders];
    let ordersData;

    let itemsCountPerStop = [];

    if (allowMultipleDrops) {
      ordersData = infiniteDropsArray;
      dropWaypointsArray.map(ar => {
        let count = 0;
        ar.dropItems.map(it => (count += it.quantity));
        itemsCountPerStop.push(count);
      });
      numOfDrops = dropWaypointsArray.length;
    } else {
      if (hasThirdWaypoint) {
        if (isWaypointPickup && isWaypointDropoff) {
          ordersData = waypointPickAndDropOrdersArray;
          numOfDrops = 2;
        } else if (isWaypointPickup && !isWaypointDropoff) {
          ordersData = waypointPickOrdersArray;
          numOfDrops = 1;
        } else if (!isWaypointPickup && isWaypointDropoff) {
          ordersData = waypointDropOrdersArray;
          numOfDrops = 2;
        }
      } else {
        ordersData = regularOrdersArray;
        numOfDrops = 1;
      }
    }
    let itemNum = 0;
    pickupItems.map(it => {
      itemNum += it.quantity;
    });
    let data = {
      status: shipment,
      market_id: storeMarketId,
      scheduled_for: scheduledForData,
      weight: weight,
      vehicle_type: vehicleType
        .toLowerCase()
        .split(' ')
        .join('_'),
      is_expedited: isExpedited.toString(),
      is_super_expedited: isSuperExpedited,
      num_of_drops: numOfDrops,
      is_roundtrip: isRoundTrip,
      contains_alcohol: containsAlcohol,
      distance: distance.toString(),
      quote: price.toString(),
      quoted_time: route.duration / 60 / 60,
      is_itemized: perItemPrice && dropWaypointsArray.length > 1,
      per_item_price: perItemPrice,
      per_stop_price: perStopPrice,
      extra_price_per_item: extraPerItemPrice,
      free_items_count_per_stop: freeItemCount,
      items_count_per_stop_array: itemsCountPerStop,
      item_count: itemNum,
      num_of_gophrs: numPeople,
      is_fork_lift: isForkLift,
      is_dolly: isDolly,
      is_hazmat: isHazmat,
      is_pallet_jack: isPalletJack,
      is_twic_card: isTWICCard,
      is_liftgate: isLiftgate,
      is_hand_loading: isHandLoadUnload,
      is_weather_protection: isWeatherProtection,
      is_medical: isMedical,
      customer_blob: {
        first_name: storeFirstName,
        last_name: storeLastName,
        store_name: storeName,
        phone: storePhone.trim(),
        address: storeAddress,
        address_1: storeAddress1,
        address_2: null,
        city: storeCity,
        state: storeState,
        zip: storeZip,
        country: storeCountry,
        lat: parseFloat(storeLat),
        lng: parseFloat(storeLng)
      },
      address_1: storeAddress1,
      address_2: null,
      city: storeCity,
      zip: storeZip,
      state: storeState,
      country: storeCountry,
      lat: parseFloat(storeLat),
      lng: parseFloat(storeLng),
      pick_up_instructions: pickup,
      drop_off_instructions: dropoff,
      // description: description,
      description: allowMultipleDrops ? 'A Multiple Drops Shipment' : hasThirdWaypoint ? 'A Routed Direct Shipment' : JSON.stringify(defaultItems),
      merchant_order_id: merchantOrderID == '' || merchantOrderID == null ? null : merchantOrderID,
      billed_route: route,
      actions: {},
      orders: ordersData,
      placed_by: merchantName,
      is_onBehalf: false,
      has_tql_rate_value: hasTqlRateValue,
      tql_rate_blob: hasTqlRateValue ? tqlRateBlob : {},
      is_over_size: isOverSize,
      drop_off_state: ordersData[ordersData.length - 1].location_blob.state
    };

    console.log('data ', data);
    axios({
      method: 'post',
      url: url,
      withCredentials: true,
      headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + window.localStorage.getItem('access_token') },
      data: data
    })
      .then(response => {
        if (response.status === 200 && response.data.status === 'successful') {
          // Display a success message to the user
          setSubmissionMessage('Submission successful! Redirecting...');

          window.location.reload();
        } else {
          // Handle unexpected success response
          setSubmissionMessage('Unexpected response received. Please contact support.');
          // setIsOrderSubmitDisabled(false);
        }

        // if (shipment === 'shipment_ready_for_driver' && order === 'awaiting_driver') {
        //   // props.notifySuccess('tc', 'Shipment Created Successfully');
        //   // setShowForm2(false);
        //   // props.handleForm(false, false);
        //   // reset();
        //   window.location.reload();
        // } else {
        //   // props.notifySuccess('tc', 'Shipment Saved Successfully');
        //   // setShowForm2(false);
        //   // props.handleForm(false, false);
        //   // reset();
        //   window.location.reload();
        // }
      })
      .catch(error => {
        console.log('error on creation: ', error.response);
        props.handleErrorSubmit({ errorMessage: 'Something went wrong with your submission.  Please wait a few seconds, and try again.', errorCode: 10 });
        props.notify('tc', 'submit');
        setIsOrderSubmitDisabled(false); // Re-enable the button if there's an error
        setSubmissionMessage('Submission failed. Please try again.');
      });
  };

  const handleSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    for (var i = 0; i < states.length; i++) {
      if (states[i].label === stateZip[1]) {
        state = states[i].val;
        break;
      }
    }
    setAddress(result);
    setCustomerAddress(split[0]);
    setCustomerCity(split[1]);
    setCustomerState(state);
    setCustomerZip(stateZip[2]);
    setCustomerLat(lat);
    setCustomerLong(lng);
  };

  const handlePickupSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];

    setPickFullAddress(result);
    setPickAddress1(split[0]);
    setPickCity(split[1]);
    setPickState(state);
    setPickZip(stateZip[2]);
    setPickLat(lat);
    setPickLng(lng);
  };

  const handleDropoffSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];

    setDropFullAddress(result);
    setDropAddress1(split[0]);
    setDropCity(split[1]);
    setDropState(state);
    setDropZip(stateZip[2]);
    setDropLat(lat);
    setDropLng(lng);
  };

  const handleWaypointSelect = (result, lat, lng) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    //  for (var i = 0; i < states.length; i++) {
    //    if (states[i].label === stateZip[1]) {
    //      state = states[i].val;
    //      break;
    //    }
    //  }
    //! Google returns abbreviated name of the state
    state = stateZip[1];

    setWaypointFullAddress(result);
    setWaypointAddress1(split[0]);
    setWaypointCity(split[1]);
    setWaypointState(state);
    setWaypointZip(stateZip[2]);
    setWaypointLat(lat);
    setWaypointLng(lng);
  };

  const handleDropWaypointSelect = (result, lat, lng, index) => {
    var split = result.split(',');
    var stateZip = split[2].split(' ');
    var state = null;
    //! Mapbox returns full name of the state
    // for (var i = 0; i < states.length; i++) {
    //   if (states[i].label === stateZip[1]) {
    //     state = states[i].val;
    //     break;
    //   }
    // }
    //! Google returns abbreviated name of the state
    state = stateZip[1];

    setDropWaypointsArray(
      dropWaypointsArray.map((ar, i) =>
        i === index
          ? {
              ...dropWaypointsArray[index],
              waypointFullAddress: result,
              waypointAddress1: split[0],
              waypointCity: split[1],
              waypointState: state,
              waypointZip: stateZip[2],
              waypointLat: lat,
              waypointLng: lng
            }
          : ar
      )
    );
  };

  const priceChoice = (standardPrice, expeditedPrice, superExpeditedPrice) => {
    return (
      <Collapse id="collapse2" isOpen={props.showForm2}>
        <Card style={{ backgroundColor: '#344674', color: '#fff' }} className="card-form">
          <CardBody>
            Shipment Route Quote
            <p>
              Deliver To <p style={{ color: '#f7941d' }}>{address}</p>
            </p>
            <Col>
              {now.hour() < 13 ? (
                <>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="standard-price"
                        type="radio"
                        name="exampleRadio3"
                        value={standardPrice}
                        onClick={() => {
                          setIsScheduled(false);
                          setIsExpedited(false);
                          setIsSuperExpedited(false);
                          setPrice(standardPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                          setIsProceeded(true);
                        }}
                      />
                      <span className="form-check-sign" /> Standard Price Today:{' '}
                      {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                      vehicleType === 'car_hauler' ? (
                        <div style={{ color: '#f7941d' }}>
                          Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
                        </div>
                      ) : (
                        <div style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</div>
                      )}
                      (Orders placed <span style={{ color: '#f7941d' }}>before 5 PM</span> will be delivered the <span style={{ color: '#f7941d' }}>same day</span>)
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="expedited-price"
                        type="radio"
                        name="exampleRadio3"
                        value={expeditedPrice}
                        onClick={() => {
                          setIsScheduled(false);
                          setIsExpedited(true);
                          setIsSuperExpedited(false);
                          setPrice(expeditedPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                          setIsProceeded(true);
                        }}
                      />
                      <span className="form-check-sign" /> Expedited Price Today:{' '}
                      {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                      vehicleType === 'car_hauler' ? (
                        <>
                          <div style={{ color: '#f7941d' }}>
                            Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
                          </div>
                          (<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched <span style={{ color: '#f7941d' }}>upon approval</span>)
                        </>
                      ) : (
                        <>
                          <div style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>(<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched{' '}
                          <span style={{ color: '#f7941d' }}>immediately</span>)
                        </>
                      )}
                    </Label>
                  </FormGroup>
                </>
              ) : now.hour() >= 13 && now.hour() < 17 ? (
                <>
                  {vehicleType === 'sprinter_van' ||
                  vehicleType === 'box_truck' ||
                  vehicleType === 'gooseneck_trailer' ||
                  vehicleType === 'car_hauler' ||
                  vehicleType === '18_wheeler_dry_van' ||
                  vehicleType === '18_wheeler_flat_bed' ||
                  vehicleType === '18_wheeler_reefer' ? (
                    <FormGroup check className="form-check-radio" id="shipment-price">
                      <Label check>
                        <Input
                          required
                          id="standard-price"
                          type="radio"
                          name="exampleRadio3"
                          value={standardPrice}
                          onClick={() => {
                            setIsExpedited(false);
                            setIsScheduled(false);
                            setIsSuperExpedited(false);
                            setPrice(standardPrice);
                            priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                          }}
                          disabled
                        />

                        <div style={{ color: 'gray' }}>
                          <span className="form-check-sign" /> Standard Price Today:{' '}
                          {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                          vehicleType === 'car_hauler' ? (
                            <div style={{ color: '#gray' }}>
                              Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                            </div>
                          ) : (
                            <div style={{ color: 'gray' }}>${parseFloat(standardPrice).toFixed(2)}</div>
                          )}
                          (<span style={{ color: 'gray' }}>If you would like the same day delivery for order placed after 1 PM, </span>{' '}
                          <span style={{ color: '#f7941d' }}>please choose expedited</span>)
                        </div>
                      </Label>
                    </FormGroup>
                  ) : (
                    <FormGroup check className="form-check-radio" id="shipment-price">
                      <Label check>
                        <Input
                          required
                          id="standard-price"
                          type="radio"
                          name="exampleRadio3"
                          value={standardPrice}
                          onClick={() => {
                            setIsScheduled(false);
                            setIsExpedited(false);
                            setIsSuperExpedited(false);
                            setPrice(standardPrice);
                            priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                            setIsProceeded(true);
                          }}
                        />
                        <span className="form-check-sign" /> Standard Price Today:{' '}
                        {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                        vehicleType === 'car_hauler' ? (
                          <div style={{ color: '#f7941d' }}>
                            Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
                          </div>
                        ) : (
                          <div style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</div>
                        )}
                        (Orders placed <span style={{ color: '#f7941d' }}>before 5 PM</span> will be delivered the <span style={{ color: '#f7941d' }}>same day</span>)
                      </Label>
                    </FormGroup>
                  )}

                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="expedited-price"
                        type="radio"
                        name="exampleRadio3"
                        value={expeditedPrice}
                        onClick={() => {
                          setIsExpedited(true);
                          setIsScheduled(false);
                          setIsSuperExpedited(false);
                          setPrice(expeditedPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                          setIsProceeded(true);
                        }}
                      />
                      <span className="form-check-sign" /> Expedited Price Today:{' '}
                      {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                      vehicleType === 'car_hauler' ? (
                        <>
                          <div style={{ color: '#f7941d' }}>
                            Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
                          </div>
                          (<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched <span style={{ color: '#f7941d' }}>upon approval</span>)
                        </>
                      ) : (
                        <>
                          <div style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>(<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched{' '}
                          <span style={{ color: '#f7941d' }}>immediately</span>)
                        </>
                      )}
                    </Label>
                  </FormGroup>
                </>
              ) : now.hour() >= 17 && now.hour() < 20 ? (
                <>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="standard-price"
                        type="radio"
                        name="exampleRadio3"
                        value={standardPrice}
                        onClick={() => {
                          setIsExpedited(false);
                          setIsScheduled(false);
                          setIsSuperExpedited(false);
                          setPrice(standardPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                        }}
                        disabled
                      />

                      <div style={{ color: 'gray' }}>
                        <span className="form-check-sign" /> Standard Price Today:{' '}
                        {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                        vehicleType === 'car_hauler' ? (
                          <div style={{ color: '#gray' }}>
                            Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                          </div>
                        ) : (
                          <div style={{ color: 'gray' }}>${parseFloat(standardPrice).toFixed(2)}</div>
                        )}
                        (<span style={{ color: 'gray' }}>If you would like the same day delivery for order placed after 5 PM, </span>{' '}
                        <span style={{ color: '#f7941d' }}>please choose expedited</span>)
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="expedited-price"
                        type="radio"
                        name="exampleRadio3"
                        value={expeditedPrice}
                        onClick={() => {
                          setIsExpedited(true);
                          setIsScheduled(false);
                          setIsSuperExpedited(false);
                          setPrice(expeditedPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                          setIsProceeded(true);
                        }}
                      />
                      <span className="form-check-sign" /> Expedited Price Today:{' '}
                      {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                      vehicleType === 'car_hauler' ? (
                        <>
                          <div style={{ color: '#f7941d' }}>
                            Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}, we will get you accurate quote back asap
                          </div>
                          (<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched <span style={{ color: '#f7941d' }}>upon approval</span>)
                        </>
                      ) : (
                        <>
                          <div style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>(<span style={{ color: '#f7941d' }}>Driver will be </span> dispatched{' '}
                          <span style={{ color: '#f7941d' }}>immediately</span>)
                        </>
                      )}
                    </Label>
                  </FormGroup>
                </>
              ) : now.hour() >= 20 ? (
                <>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="standard-price"
                        type="radio"
                        name="exampleRadio3"
                        value={standardPrice}
                        onClick={() => {
                          setIsExpedited(false);
                          setIsScheduled(false);
                          setIsSuperExpedited(false);
                          setPrice(standardPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                        }}
                        disabled
                      />

                      <div style={{ color: 'gray' }}>
                        <span className="form-check-sign" /> Standard Price Today:{' '}
                        {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                        vehicleType === 'car_hauler' ? (
                          <div style={{ color: '#gray' }}>
                            Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                          </div>
                        ) : (
                          <div style={{ color: 'gray' }}>${parseFloat(standardPrice).toFixed(2)}</div>
                        )}{' '}
                        (<span style={{ color: 'gray' }}>Please schedule for tomorrow, or if you have emergency </span>
                        <span style={{ color: '#f7941d' }}>please call (833) 467-4748</span>)
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio" id="shipment-price">
                    <Label check>
                      <Input
                        required
                        id="expedited-price"
                        type="radio"
                        name="exampleRadio3"
                        value={expeditedPrice}
                        onClick={() => {
                          setIsExpedited(true);
                          setIsScheduled(false);
                          setIsSuperExpedited(false);
                          setPrice(expeditedPrice);
                          priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                        }}
                        disabled
                      />

                      <div style={{ color: 'gray' }}>
                        <span className="form-check-sign" /> Expedited Price Today:{' '}
                        {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                        vehicleType === 'car_hauler' ? (
                          <div style={{ color: 'gray' }}>
                            Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
                          </div>
                        ) : (
                          <div style={{ color: 'gray' }}>${parseFloat(expeditedPrice).toFixed(2)}</div>
                        )}
                        (<span style={{ color: 'gray' }}>Please schedule for tomorrow, or if you have emergency </span>
                        <span style={{ color: '#f7941d' }}>please call (833) 467-4748</span>)
                      </div>
                    </Label>
                  </FormGroup>
                </>
              ) : null}
              <FormGroup check className="form-check-radio" id="shipment-price">
                <Label check>
                  <Input
                    required
                    id="schedule-price"
                    type="radio"
                    name="exampleRadio3"
                    // value={standardPrice}
                    onClick={() => {
                      setIsScheduled(true);
                      setIsExpedited(false);
                      setIsSuperExpedited(false);
                      // priceChoice(standardPrice, expeditedPrice, superExpeditedPrice);
                    }}
                  />
                  <span className="form-check-sign"> Schedule For Different Date</span>
                  <Collapse isOpen={true} hidden={!isScheduled} style={{ marginTop: '6px' }}>
                    <Label className="form-check-label"></Label>
                    <Row className="justify-content-md-center">
                      <Col md="auto">
                        <FormGroup>
                          <ReactDatetime
                            input={false}
                            inputProps={{
                              className: 'form-control',
                              placeholder: formatTimestamp(tomorrow),
                              readOnly: true,
                              cursor: 'text'
                            }}
                            onChange={e => (e.toISOString ? setScheduledDate(e) : null)}
                            dateFormat="dddd, MMMM D, YYYY"
                            timeFormat={false}
                            isValidDate={(currentDate, selectedDate) => verifyDate(currentDate, selectedDate)}
                            // timeConstraints={scheduledFor ? (scheduledFor.day() === 6 ? saturdayTimeConstraint : weekdayTimeConstraint) : weekdayTimeConstraint}
                            defaultValue={tomorrow}
                            // closeOnSelect={true}
                            closeOnTab={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="auto" style={{ marginTop: 50 }}>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={0}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(superExpeditedPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(true);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              12 AM - 2 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(superExpeditedPriceRangeLow).toFixed(2)} -- {parseFloat(superExpeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(superExpeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={2}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(superExpeditedPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(true);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              2 AM - 4 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(superExpeditedPriceRangeLow).toFixed(2)} -- {parseFloat(superExpeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(superExpeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={4}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(expeditedPrice);
                                setIsExpedited(true);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              4 AM - 6 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={6}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(expeditedPrice);
                                setIsExpedited(true);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              6 AM - 8 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={8}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(standardPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              8 AM - 10 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={10}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(standardPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              10 AM - 12 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="auto" style={{ marginTop: 50 }}>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={12}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(standardPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              12 PM - 2 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={14}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(standardPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              2 PM - 4 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(standardPriceRangeLow).toFixed(2)} -- {parseFloat(standardPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(standardPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={16}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(expeditedPrice);
                                setIsExpedited(true);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              4 PM - 6 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={18}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(expeditedPrice);
                                setIsExpedited(true);
                                setIsSuperExpedited(false);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              6 PM - 8 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(expeditedPriceRangeLow).toFixed(2)} -- {parseFloat(expeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(expeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={20}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(superExpeditedPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(true);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              8 PM - 10 PM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(superExpeditedPriceRangeLow).toFixed(2)} -- {parseFloat(superExpeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(superExpeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" id="schedule-time">
                          <Label check>
                            <Input
                              required
                              type="radio"
                              name="exampleRadio4"
                              id="exampleRadios4"
                              value={22}
                              onClick={e => {
                                handleScheduleTime(e);
                                setPrice(superExpeditedPrice);
                                setIsExpedited(false);
                                setIsSuperExpedited(true);
                              }}
                            />
                            <p id="radio" className="form-check-sign">
                              10 PM - 12 AM :{'  '}
                              {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                              vehicleType === 'car_hauler' ? (
                                <span style={{ color: '#f7941d' }}>
                                  Estimate ${parseFloat(superExpeditedPriceRangeLow).toFixed(2)} -- {parseFloat(superExpeditedPriceRangeHigh).toFixed(2)}
                                </span>
                              ) : (
                                <span style={{ color: '#f7941d' }}>${parseFloat(superExpeditedPrice).toFixed(2)}</span>
                              )}
                            </p>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div hidden={errorMessageSubmit.errorCode !== 12}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageSubmit.errorMessage}</p>
                    </div>{' '}
                    <div>
                      {JSON.stringify(scheduledFor) && scheduledFor != null ? (
                        <div>
                          {' '}
                          <p style={{ marginBottom: 10 }}>
                            You select the scheduled <span style={{ color: '#f7941d' }}>pickup</span> time window:{' '}
                            <span style={{ color: '#f7941d' }}>
                              {scheduledFor
                                ? JSON.stringify(
                                    moment(scheduledFor).format('dddd, MMMM-DD-YYYY, hh:mm A') +
                                      '--' +
                                      moment(scheduledFor)
                                        .add(2, 'hours')
                                        .format('hh:mm A')
                                  )
                                : ''}
                            </span>
                          </p>
                          <p>
                            If you need <span style={{ color: '#f7941d' }}>pickup</span> at a specific time, please add it to{' '}
                            <span style={{ color: '#f7941d' }}>Pickup Instructions</span>
                          </p>
                          <p>
                            If you need <span style={{ color: 'green' }}>dropoff</span> at a specific time, please add it to{' '}
                            <span style={{ color: 'green' }}>Dropoff Instructions</span>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>
                            Please select date and time window for delivery <span style={{ color: '#f7941d', fontWeight: 'bold' }}>to be picked up</span>
                          </p>
                          <p>
                            If you need <span style={{ color: '#f7941d' }}>pickup</span> at a specific time, please add it to{' '}
                            <span style={{ color: '#f7941d' }}>Pickup Instructions</span>
                          </p>
                          <p>
                            If you need <span style={{ color: 'green' }}>dropoff</span> at a specific time, please add it to{' '}
                            <span style={{ color: 'green' }}>Dropoff Instructions</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </Label>
              </FormGroup>
              <div hidden={!errors.price}>
                <p style={{ color: 'red', marginTop: '4px' }}>Please select a shipping preference</p>
              </div>
              {vehicleType ? null : <p style={{ color: 'red', marginTop: '4px' }}>Please select a vehicle for delivery</p>}
              {isProceeded ? (
                <Button
                  className="btn submit-btn"
                  color="danger"
                  onClick={() => {
                    handleSubmit();
                    history.push('/admin/dashboard/#form-top');
                    window.scrollTo(0, 0);
                  }}
                >
                  Proceed
                </Button>
              ) : (
                <Button className="btn submit-btn" style={{ color: 'gray' }} onClick={() => {}}>
                  Proceed
                </Button>
              )}

              {/* <div hidden={ errorMessageSubmit.errorCode !== 10}><p style={{color: "red", marginTop: "4px"}}>{errorMessageSubmit.errorMessage}</p></div> */}
            </Col>
          </CardBody>
        </Card>
      </Collapse>
    );
  };
  if (loading) return <Loading hidden={!showForm} />;
  return (
    <>
      <Collapse id="collapse" isOpen={props.showForm}>
        {/* <Collapse isOpen={showForm1}> */}
        <form className="dropship-container" autoComplete="off" style={{ padding: `15px` }}>
          <input autoComplete="off" hidden="true"></input>
          <CardTitle id="form-top" tag="h2" style={{ padding: 20 }}>
            Create a Delivery
          </CardTitle>
          {/* deal with waypoint error and different items */}
          <div hidden={allowMultipleDrops ? !infiniteStopsError : hasThirdWaypoint ? !threeStopsError : !twoStopsError}>
            <p style={{ color: 'red', marginTop: '4px', paddingBottom: 20 }}>Please fix the problems on this form</p>
          </div>
          <Row>
            {allowWaypoint &&
              (hasThirdWaypoint ? (
                <Button
                  className="btn btn-success"
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    toggleThirdWaypoint();
                    if (isPickupStore) {
                      if (isDropoffStore) {
                        setIsDropoffStore(false);
                        // setDropFirstname('');
                        // setDropFullAddress('');
                        // setDropLastname('');
                        // setDropPhone('');
                      }
                    }
                  }}
                >
                  <div style={{ color: 'red' }}>Back to 2 stops</div>
                </Button>
              ) : (
                <Button className="btn btn-success" style={{ marginLeft: '20px' }} onClick={toggleThirdWaypoint}>
                  Add additional stop/round trip
                </Button>
              ))}
          </Row>
          {/* //! infinite drop waypoints */}
          {allowMultipleDrops ? (
            <>
              <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0 }}>
                Pickup Information
              </CardTitle>
              <Row style={{ paddingTop: 0 }}>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={isPickupStore}
                      value="mp"
                      name="store-address-radios2"
                      id="my-store-address"
                      onClick={() => {
                        setIsPickupStore(true);
                        setIsWaypointStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> My Store Address
                  </Label>
                </FormGroup>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={!isPickupStore}
                      name="store-address-radios2"
                      value="dp"
                      id="different-address"
                      onClick={() => {
                        setIsPickupStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> Different Address
                  </Label>
                </FormGroup>
              </Row>
              <Row form={true}>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeFirstName">First Name</Label>
                  <FormGroup className={errors.pickupFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <PickupCustomerAutoComplete
                      isPickupStore={isPickupStore}
                      storeFirstName={storeFirstName}
                      pickFirstname={pickFirstname}
                      setPickFirstname={setPickFirstname}
                      pickupCustomerSuggestions={pickupCustomerSuggestions}
                      setPickupCustomerSuggestions={setPickupCustomerSuggestions}
                      storeId={storeId}
                      setPickLastname={setPickLastname}
                      setPickPhone={setPickPhone}
                      pickFullAddress={pickFullAddress}
                      setPickFullAddress={setPickFullAddress}
                      setPickup={setPickup}
                      isPickupAutoFilled={isPickupAutoFilled}
                      setIsPickupAutoFilled={setIsPickupAutoFilled}
                      setPickAddress1={setPickAddress1}
                      setPickAddress2={setPickAddress2}
                      setPickCity={setPickCity}
                      setPickState={setPickState}
                      setPickZip={setPickZip}
                      setPickCountry={setPickCountry}
                      setPickLat={setPickLat}
                      setPickLng={setPickLng}
                      setPickBusinessName={setPickBusinessName}
                    />
                    <div hidden={!errors.pickupFirstname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeLastName">Last Name</Label>
                  <FormGroup className={errors.pickupLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      readOnly={isPickupStore}
                      type="text"
                      name="storeLastName"
                      id="store-last-name"
                      placeholder="Doe"
                      onChange={e => (isPickupStore ? null : setPickLastname(e.target.value))}
                      value={isPickupStore ? storeLastName : pickLastname}
                    />
                    <div hidden={!errors.pickupLastname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storePhone">Phone Number</Label>
                  <FormGroup className={errors.pickupPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      readOnly={isPickupStore}
                      required
                      type="text"
                      name="storePhone"
                      id="store-phone"
                      placeholder="555-555-5555"
                      onChange={e => (isPickupStore ? null : setPickPhone(e.target.value))}
                      value={isPickupStore ? storePhone : pickPhone}
                    />
                    <div hidden={!errors.pickupPhone}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Business Name (optional)</Label>
                    <Input
                      readOnly={isPickupStore}
                      type="text"
                      name="pickBusinessName"
                      id="pick-business-name"
                      placeholder="Store Name"
                      onChange={e => (isPickupStore ? null : setPickBusinessName(e.target.value))}
                      value={isPickupStore ? storeName : pickBusinessName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Pickup Address</Label>
                    {isPickupAutoFilled ? (
                      <Input value={isPickupStore ? storeAddress : pickFullAddress} onChange={e => (isPickupStore ? null : setPickFullAddress(e.target.value))} />
                    ) : (
                      // <MapboxAutocomplete
                      //   id="mapbox-autocomplete"
                      //   inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                      //   publicKey={MAPBOX_TOKEN}
                      //   onSuggestionSelect={handlePickupSelect}
                      //   country="us"
                      //   resetSearch={false}
                      //   readOnly={isPickupStore}
                      //   defaultValue={isPickupStore ? storeAddress : null}
                      //   value={isPickupStore ? storeAddress : pickFullAddress}
                      //   query={pickFullAddress}
                      //   placeholder="Street Address"
                      //   proximityLat={vendorLat}
                      //   proximityLng={vendorLong}
                      // />
                      <GoogleMapAutoComplete
                        inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                        onSuggestionSelect={handlePickupSelect}
                        country="us"
                        resetSearch={false}
                        readOnly={isPickupStore}
                        defaultValue={isPickupStore ? storeAddress : null}
                        value={isPickupStore ? storeAddress : pickFullAddress}
                        query={pickFullAddress}
                        placeholder="Street Address"
                        proximityLat={vendorLat}
                        proximityLng={vendorLong}
                      />
                    )}

                    <div hidden={errorMessageDistance.errorCode !== 1}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                    </div>
                    <div hidden={!errors.pickupAddress}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Pickup Instructions (optional)</Label>
                    <Input
                      type="text"
                      name="dropoff"
                      id="dropoff-instructions"
                      placeholder="Park in back, attendant will assist."
                      onChange={e => {
                        if (e.target.value.length < 510) {
                          return setPickup(e.target.value);
                        } else {
                          return;
                        }
                      }}
                      value={pickup}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <LineItems
                title="Pick up Item(s)"
                items={pickupItems}
                setItems={setPickupItems}
                itemsErrorArray={pickupItemsError}
                setItemsErrorArray={setPickupItemsError}
                pickList1={pickupItems}
                pickList2={waypointPickupItems}
              />

              {dropWaypointsArray.map((waypoint, index) => {
                return (
                  <>
                    <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
                    <Row>
                      <Col style={{ textAlign: 'left' }}>
                        <Button
                          className="btn btn-info"
                          size="sm"
                          onClick={() => {
                            let updateArray = [];
                            let errorsUpdate = [];
                            for (let i = 0; i < index; i++) {
                              updateArray.push(dropWaypointsArray[i]);
                              errorsUpdate.push(infiniteDropWaypointsErrors[i]);
                            }
                            updateArray.push({
                              waypointFirstname: '',
                              waypointLastname: '',
                              waypointPhone: '',
                              waypointAddress1: null,
                              waypointAddress2: null,
                              waypointFullAddress: '',
                              waypointLat: '',
                              waypointLng: '',
                              waypointCity: '',
                              waypointState: '',
                              waypointCountry: '',
                              waypointZip: '',
                              waypointInfo: {},
                              waypointDropoffInstruction: '',
                              waypointBusinessName: '',
                              waypointCustomerSuggestions: [],
                              isWaypointAutoFilled: false,
                              dropItems: [{ quantity: 1, detail: '' }],
                              dropItemsError: [false]
                            });
                            errorsUpdate.push({
                              waypointFirstname: false,
                              waypointLastname: false,
                              waypointPhone: false,
                              waypointAddress: false
                            });
                            for (let i = index; i < dropWaypointsArray.length; i++) {
                              updateArray.push(dropWaypointsArray[i]);
                              errorsUpdate.push(infiniteDropWaypointsErrors[i]);
                            }
                            setDropWaypointsArray(updateArray);
                            setInfiniteDropWaypointsErrors(errorsUpdate);
                          }}
                        >
                          Add Drop Above{' '}
                          <span style={{ display: 'inline' }} role="img">
                            🔺
                          </span>
                        </Button>
                      </Col>

                      {dropWaypointsArray.length > 1 && (
                        <Col style={{ textAlign: 'right' }}>
                          <Button
                            className="btn btn-warning"
                            size="sm"
                            onClick={() => {
                              setDropWaypointsArray(
                                dropWaypointsArray.filter((ar, i) => {
                                  return i !== index;
                                })
                              );
                              setInfiniteDropWaypointsErrors(
                                infiniteDropWaypointsErrors.filter((ie, i) => {
                                  return i !== index;
                                })
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
                      Dropoff ({index + 1}) Information
                    </CardTitle>
                    <Row form={true}>
                      <Col style={{ textAlign: 'left' }}>
                        <Label for="infiniteDropCustomerFirstName">First Name</Label>
                        <FormGroup className={infiniteDropWaypointsErrors[index].waypointFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                          <WaypointCustomerOnlyDropAutoComplete
                            dropWaypointsArray={dropWaypointsArray}
                            setDropWaypointsArray={setDropWaypointsArray}
                            index={index}
                            storeId={storeId}
                          />
                          <div hidden={!infiniteDropWaypointsErrors[index].waypointFirstname}>
                            <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col style={{ textAlign: 'left' }}>
                        <Label for="customerLastName">Last Name</Label>
                        <FormGroup className={infiniteDropWaypointsErrors[index].waypointLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                          <Input
                            required
                            type="text"
                            name="customerLastName"
                            id="customer-last-name"
                            placeholder="Doe"
                            onChange={e => {
                              setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointLastname: e.target.value } : ar)));
                            }}
                            value={dropWaypointsArray[index].waypointLastname}
                          />
                          <div hidden={!infiniteDropWaypointsErrors[index].waypointLastname}>
                            <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col style={{ textAlign: 'left' }}>
                        <Label for="customerPhone">Phone Number</Label>
                        <FormGroup className={infiniteDropWaypointsErrors[index].waypointPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                          <Input
                            required
                            type="text"
                            name="customerPhone"
                            id="customer-phone"
                            placeholder="555-555-5555"
                            onChange={e =>
                              setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointPhone: e.target.value } : ar)))
                            }
                            value={dropWaypointsArray[index].waypointPhone}
                          />
                          <div hidden={!infiniteDropWaypointsErrors[index].waypointPhone}>
                            <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="order-multidrop">Business Name (optional)</Label>
                          <Input
                            type="text"
                            name="multidropBusinessName"
                            id="multidrop-business-name"
                            placeholder="Store Name"
                            value={dropWaypointsArray[index].waypointBusinessName}
                            onChange={e => {
                              setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointBusinessName: e.target.value } : ar)));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Delivery Address</Label>
                          {waypoint.isWaypointAutoFilled ? (
                            <Input
                              value={dropWaypointsArray[index].waypointFullAddress}
                              onChange={e =>
                                setDropWaypointsArray(dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointFullAddress: e.target.value } : ar)))
                              }
                            />
                          ) : (
                            // <MapboxAutocomplete
                            //   id="mapbox-autocomplete2"
                            //   inputClass="form-control mb-2"
                            //   publicKey={MAPBOX_TOKEN}
                            //   index={index}
                            //   onSuggestionSelect={handleDropWaypointSelect}
                            //   country="us"
                            //   resetSearch={false}
                            //   // readOnly={isWaypointStore}
                            //   defaultValue={null}
                            //   value={dropWaypointsArray[index].waypointFullAddress}
                            //   query={dropWaypointsArray[index].waypointFullAddress}
                            //   placeholder="Street Address"
                            //   proximityLat={vendorLat}
                            //   proximityLng={vendorLong}
                            // />
                            <GoogleMapAutoComplete
                              inputClass="form-control mb-2"
                              index={index}
                              onSuggestionSelect={handleDropWaypointSelect}
                              country="us"
                              resetSearch={false}
                              // readOnly={isWaypointStore}
                              defaultValue={null}
                              value={dropWaypointsArray[index].waypointFullAddress}
                              query={dropWaypointsArray[index].waypointFullAddress}
                              placeholder="Street Address"
                              proximityLat={vendorLat}
                              proximityLng={vendorLong}
                            />
                          )}
                          <div hidden={errorMessageDistance.errorCode !== 1}>
                            <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                          </div>
                          <div hidden={!infiniteDropWaypointsErrors[index].waypointAddress}>
                            <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                          <Input
                            type="text"
                            name="waypointDropoff"
                            id="waypoint-dropoff-instructions"
                            placeholder="Gate Code, Apt. #, etc."
                            value={dropWaypointsArray[index].waypointDropoffInstruction}
                            onChange={e => {
                              if (e.target.value.length < 510) {
                                return setDropWaypointsArray(
                                  dropWaypointsArray.map((ar, i) => (i === index ? { ...dropWaypointsArray[index], waypointDropoffInstruction: e.target.value } : ar))
                                );
                              } else {
                                return;
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <LineItemsMultipleDrop
                      title="Drop off Item(s)"
                      dropWaypointsArray={dropWaypointsArray}
                      setDropWaypointsArray={setDropWaypointsArray}
                      index={index}
                      pickList={pickupItems}
                    />
                    <Row>
                      <Col style={{ textAlign: 'left' }}>
                        <Button
                          className="btn btn-info"
                          size="sm"
                          onClick={() => {
                            let updateArray = [];
                            let errorsUpdate = [];
                            for (let i = 0; i < index + 1; i++) {
                              updateArray.push(dropWaypointsArray[i]);
                              errorsUpdate.push(infiniteDropWaypointsErrors[i]);
                            }

                            updateArray.push({
                              waypointFirstname: '',
                              waypointLastname: '',
                              waypointPhone: '',
                              waypointAddress1: null,
                              waypointAddress2: null,
                              waypointFullAddress: '',
                              waypointLat: '',
                              waypointLng: '',
                              waypointCity: '',
                              waypointState: '',
                              waypointCountry: '',
                              waypointZip: '',
                              waypointInfo: {},
                              waypointDropoffInstruction: '',
                              waypointCustomerSuggestions: [],
                              isWaypointAutoFilled: false,
                              dropItems: [{ quantity: 1, detail: '' }],
                              dropItemsError: [false]
                            });
                            errorsUpdate.push({
                              waypointFirstname: false,
                              waypointLastname: false,
                              waypointPhone: false,
                              waypointAddress: false
                            });
                            for (let i = index + 1; i < dropWaypointsArray.length; i++) {
                              updateArray.push(dropWaypointsArray[i]);
                              errorsUpdate.push(infiniteDropWaypointsErrors[i]);
                            }
                            setDropWaypointsArray(updateArray);
                            setInfiniteDropWaypointsErrors(errorsUpdate);
                          }}
                        >
                          Add Drop Below{' '}
                          <span style={{ display: 'inline' }} role="img">
                            🔻
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              })}
              {itemsMatchError && (
                <p style={{ color: 'red', marginTop: '4px', marginBottom: '5px' }}>Please check to make sure quantity or detail of pick up item(s) matching drop off item(s) </p>
              )}
            </>
          ) : !hasThirdWaypoint ? (
            <div>
              <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0 }}>
                Pickup Information
              </CardTitle>
              <Row style={{ paddingTop: 0 }}>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={isPickupStore}
                      value="mp"
                      name="store-address-radios"
                      id="my-store-address"
                      onClick={() => {
                        setIsPickupStore(true);
                        setIsDropoffStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> My Store Address{'  '}
                  </Label>
                </FormGroup>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={!isPickupStore}
                      name="store-address-radios"
                      value="dp"
                      id="different-address"
                      onClick={() => {
                        setIsPickupStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> Different Address{'  '}
                  </Label>
                </FormGroup>
              </Row>
              <Row form={true}>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeFirstName">First Name</Label>
                  <FormGroup className={errors.pickupFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    {/* <Input
                  required
                  type="text"
                  readOnly={isPickupStore}
                  name="storeFirstName"
                  id="customer-first-name"
                  placeholder="John"
                  value={isPickupStore ? storeFirstName : pickFirstname}
                  onChange={e => (isPickupStore ? null : setPickFirstname(e.target.value))}
                /> */}
                    <PickupCustomerAutoComplete
                      isPickupStore={isPickupStore}
                      storeFirstName={storeFirstName}
                      pickFirstname={pickFirstname}
                      setPickFirstname={setPickFirstname}
                      pickupCustomerSuggestions={pickupCustomerSuggestions}
                      setPickupCustomerSuggestions={setPickupCustomerSuggestions}
                      storeId={storeId}
                      setPickLastname={setPickLastname}
                      setPickPhone={setPickPhone}
                      pickFullAddress={pickFullAddress}
                      setPickFullAddress={setPickFullAddress}
                      setPickup={setPickup}
                      isPickupAutoFilled={isPickupAutoFilled}
                      setIsPickupAutoFilled={setIsPickupAutoFilled}
                      setPickAddress1={setPickAddress1}
                      setPickAddress2={setPickAddress2}
                      setPickCity={setPickCity}
                      setPickState={setPickState}
                      setPickZip={setPickZip}
                      setPickCountry={setPickCountry}
                      setPickLat={setPickLat}
                      setPickLng={setPickLng}
                      setPickBusinessName={setPickBusinessName}
                    />
                    <div hidden={!errors.pickupFirstname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeLastName">Last Name</Label>
                  <FormGroup className={errors.pickupLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      readOnly={isPickupStore}
                      type="text"
                      name="storeLastName"
                      id="store-last-name"
                      placeholder="Doe"
                      onChange={e => (isPickupStore ? null : setPickLastname(e.target.value))}
                      value={isPickupStore ? storeLastName : pickLastname}
                    />
                    <div hidden={!errors.pickupLastname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storePhone">Phone Number</Label>
                  <FormGroup className={errors.pickupPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      readOnly={isPickupStore}
                      required
                      type="text"
                      name="storePhone"
                      id="store-phone"
                      placeholder="555-555-5555"
                      onChange={e => (isPickupStore ? null : setPickPhone(e.target.value))}
                      value={isPickupStore ? storePhone : pickPhone}
                    />
                    <div hidden={!errors.pickupPhone}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Business Name (optional)</Label>
                    <Input
                      readOnly={isPickupStore}
                      type="text"
                      name="pickBusinessName"
                      id="pick-business-name"
                      placeholder="Store Name"
                      onChange={e => (isPickupStore ? null : setPickBusinessName(e.target.value))}
                      value={isPickupStore ? storeName : pickBusinessName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row form={true}>
                        <Col style={{textAlign: 'left'}}>
                            <Label required for="storeEmail">Email</Label>
                            <FormGroup className={errors.pickupEmail ? "has-danger" : "thisIsAPlaceholderClass"}>
                                <Input readOnly={isPickupStore} type="text" name="storeEmail" id="store-email" placeholder="johndoe@gophr.app"
                                    onChange={e => isPickupStore ? null : setPickEmail(e.target.value)} value={isPickupStore? storeEmail : pickEmail} 
                                    />
                                <div hidden={!errors.pickupEmail}><p style={{color: "red", marginTop:"4px"}}>Please enter a valid email address</p></div>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'left' }}>
                            <Label for='storePhone'>Phone Number</Label>
                            <FormGroup className={errors.pickupPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                                <Input
                                    readOnly={isPickupStore}
                                    required
                                    type='text'
                                    name='storePhone'
                                    id='store-phone'
                                    placeholder='555-555-5555'
                                    onChange={e => (isPickupStore ? null : setPickPhone(e.target.value))}
                                    value={isPickupStore ? storePhone : pickPhone}
                                />
                                <div hidden={!errors.pickupPhone}>
                                    <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row> */}
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Pickup Address</Label>
                    {isPickupAutoFilled ? (
                      <Input value={isPickupStore ? storeAddress : pickFullAddress} onChange={e => (isPickupStore ? null : setPickFullAddress(e.target.value))} />
                    ) : (
                      // <MapboxAutocomplete
                      //   id="mapbox-autocomplete"
                      //   inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                      //   publicKey={MAPBOX_TOKEN}
                      //   onSuggestionSelect={handlePickupSelect}
                      //   country="us"
                      //   resetSearch={false}
                      //   readOnly={isPickupStore}
                      //   defaultValue={isPickupStore ? storeAddress : null}
                      //   value={isPickupStore ? storeAddress : pickFullAddress}
                      //   query={pickFullAddress}
                      //   placeholder="Street Address"
                      //   proximityLat={vendorLat}
                      //   proximityLng={vendorLong}
                      // />
                      <GoogleMapAutoComplete
                        inputClass={errors.pickupAddress ? 'form-control mb-2 has-danger' : 'form-control mb-2'}
                        onSuggestionSelect={handlePickupSelect}
                        country="us" // Adjust this based on your requirements for Google Places Autocomplete
                        resetSearch={false}
                        readOnly={isPickupStore}
                        defaultValue={isPickupStore ? storeAddress : null}
                        value={isPickupStore ? storeAddress : pickFullAddress}
                        query={pickFullAddress}
                        placeholder="Street Address"
                        proximityLat={vendorLat}
                        proximityLng={vendorLong}
                      />
                    )}

                    <div hidden={errorMessageDistance.errorCode !== 1}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                    </div>
                    <div hidden={!errors.pickupAddress}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Pickup Instructions (optional)</Label>
                    <Input
                      type="text"
                      name="dropoff"
                      id="pickup-instructions"
                      placeholder="Park in back, attendant will assist."
                      onChange={e => {
                        if (e.target.value.length < 510) {
                          return setPickup(e.target.value);
                        } else {
                          return;
                        }
                      }}
                      value={pickup}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
              <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
                Dropoff Information
              </CardTitle>
              <Row style={{ paddingTop: 0 }}>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={isDropoffStore}
                      value="md"
                      name="store-address-radios1"
                      id="my-store-address"
                      onClick={() => {
                        setIsDropoffStore(true);
                        setIsPickupStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> My Store Address{'  '}
                  </Label>
                </FormGroup>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={!isDropoffStore}
                      name="store-address-radios1"
                      value="dd"
                      id="different-address"
                      onClick={() => {
                        setIsDropoffStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> Different Address{'  '}
                  </Label>
                </FormGroup>
              </Row>
              <Row form={true}>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerFirstName">First Name</Label>
                  <FormGroup className={errors.dropoffFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    {/* <Input
                  required
                  type="text"
                  name="customerFirstName"
                  id="customer-first-name"
                  placeholder="John"
                  onChange={e => (isDropoffStore ? null : setDropFirstname(e.target.value))}
                  value={isDropoffStore ? storeFirstName : dropFirstname}
                /> */}
                    <DropOffCustomerAutoComplete
                      isDropoffStore={isDropoffStore}
                      storeFirstName={storeFirstName}
                      dropFirstname={dropFirstname}
                      setDropFirstname={setDropFirstname}
                      dropCustomerSuggestions={dropCustomerSuggestions}
                      setDropCustomerSuggestions={setDropCustomerSuggestions}
                      storeId={storeId}
                      setDropLastname={setDropLastname}
                      setDropPhone={setDropPhone}
                      dropFullAddress={dropFullAddress}
                      setDropFullAddress={setDropFullAddress}
                      setDropoff={setDropoff}
                      isDropOffAutoFilled={isDropOffAutoFilled}
                      setIsDropOffAutoFilled={setIsDropOffAutoFilled}
                      setDropAddress1={setDropAddress1}
                      setDropAddress2={setDropAddress2}
                      setDropCity={setDropCity}
                      setDropState={setDropState}
                      setDropZip={setDropZip}
                      setDropCountry={setDropCountry}
                      setDropLat={setDropLat}
                      setDropLng={setDropLng}
                      isRoundTrip={isRoundTrip}
                      pickFirstname={pickFirstname}
                      isPickupStore={isPickupStore}
                      hasThirdWaypoint={hasThirdWaypoint}
                      setDropoffBusinessName={setDropoffBusinessName}
                    />
                    <div hidden={!errors.dropoffFirstname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerLastName">Last Name</Label>
                  <FormGroup className={errors.dropoffLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      type="text"
                      name="customerLastName"
                      id="customer-last-name"
                      placeholder="Doe"
                      onChange={e => (isDropoffStore ? null : setDropLastname(e.target.value))}
                      value={isDropoffStore ? storeLastName : dropLastname}
                    />
                    <div hidden={!errors.dropoffLastname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerPhone">Phone Number</Label>
                  <FormGroup className={errors.dropoffPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      type="text"
                      name="customerPhone"
                      id="customer-phone"
                      placeholder="555-555-5555"
                      onChange={e => (isDropoffStore ? null : setDropPhone(e.target.value))}
                      value={isDropoffStore ? storePhone : dropPhone}
                    />
                    <div hidden={!errors.dropoffPhone}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-dropoff">Business Name (optional)</Label>
                    <Input
                      type="text"
                      name="dropoffBusinessName"
                      id="dropoff-business-name"
                      placeholder="Store Name"
                      onChange={e => (isDropoffStore ? null : setDropoffBusinessName(e.target.value))}
                      value={isDropoffStore ? storeName : dropoffBusinessName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row form={true}>
                        <Col style={{textAlign: 'left'}}>
                            <Label required for="customerEmail">Email</Label>
                            <FormGroup className={errors.dropoffEmail ? "has-danger" : "thisIsAPlaceholderClass"}>
                                <Input type="text" name="customerEmail" id="customer-email" placeholder="johndoe@gophr.app"
                                    onChange={e => isDropoffStore ? null : setDropEmail(e.target.value)} value={isDropoffStore ? storeEmail : dropEmail} 
                                    />
                                <div hidden={!errors.dropoffEmail}><p style={{color: "red", marginTop: "4px"}}>Please enter a valid email address</p></div>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'left' }}>
                            <Label for='customerPhone'>Phone Number</Label>
                            <FormGroup className={errors.dropoffPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                                <Input required type='text' name='customerPhone' id='customer-phone' placeholder='555-555-5555' onChange={e => (isDropoffStore ? null : setDropPhone(e.target.value))} value={isDropoffStore ? storePhone : dropPhone} />
                                <div hidden={!errors.dropoffPhone}>
                                    <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row> */}
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Delivery Address</Label>
                    {isDropOffAutoFilled ? (
                      <Input value={isDropoffStore ? storeAddress : dropFullAddress} onChange={e => (isDropoffStore ? null : setDropFullAddress(e.target.value))} />
                    ) : (
                      // <MapboxAutocomplete
                      //   id="mapbox-autocomplete"
                      //   inputClass="form-control mb-2"
                      //   publicKey={MAPBOX_TOKEN}
                      //   onSuggestionSelect={handleDropoffSelect}
                      //   country="us"
                      //   resetSearch={false}
                      //   readOnly={isDropoffStore}
                      //   defaultValue={isDropoffStore ? storeAddress : null}
                      //   value={isDropoffStore ? storeAddress : dropFullAddress}
                      //   query={dropFullAddress}
                      //   placeholder="Street Address"
                      //   proximityLat={vendorLat}
                      //   proximityLng={vendorLong}
                      // />
                      <GoogleMapAutoComplete
                        inputClass="form-control mb-2"
                        onSuggestionSelect={handleDropoffSelect}
                        country="us"
                        resetSearch={false}
                        readOnly={isDropoffStore}
                        defaultValue={isDropoffStore ? storeAddress : null}
                        value={isDropoffStore ? storeAddress : dropFullAddress}
                        query={dropFullAddress}
                        placeholder="Street Address"
                        proximityLat={vendorLat}
                        proximityLng={vendorLong}
                      />
                    )}
                    <div hidden={errorMessageDistance.errorCode !== 1}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                    </div>
                    <div hidden={!errors.dropoffAddress}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                    <Input
                      type="text"
                      name="dropoff"
                      id="dropoff-instructions"
                      placeholder="Gate Code, Apt. #, etc."
                      value={dropoff}
                      onChange={e => {
                        if (e.target.value.length < 510) {
                          return setDropoff(e.target.value);
                        } else {
                          return;
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left', paddingBottom: 0 }}>
                Pickup Information
              </CardTitle>
              <Row style={{ paddingTop: 0 }}>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={isPickupStore}
                      value="mp"
                      name="store-address-radios2"
                      id="my-store-address"
                      onClick={() => {
                        setIsPickupStore(true);
                        setIsWaypointStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> My Store Address
                  </Label>
                </FormGroup>
                <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                  <Label check>
                    <Input
                      required
                      type="radio"
                      checked={!isPickupStore}
                      name="store-address-radios2"
                      value="dp"
                      id="different-address"
                      onClick={() => {
                        setIsPickupStore(false);
                      }}
                    />
                    <span id="radio" className="form-check-sign" /> Different Address
                  </Label>
                </FormGroup>
              </Row>
              <Row form={true}>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeFirstName">First Name</Label>
                  <FormGroup className={errors.pickupFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <PickupCustomerAutoComplete
                      isPickupStore={isPickupStore}
                      storeFirstName={storeFirstName}
                      pickFirstname={pickFirstname}
                      setPickFirstname={setPickFirstname}
                      pickupCustomerSuggestions={pickupCustomerSuggestions}
                      setPickupCustomerSuggestions={setPickupCustomerSuggestions}
                      storeId={storeId}
                      setPickLastname={setPickLastname}
                      setPickPhone={setPickPhone}
                      pickFullAddress={pickFullAddress}
                      setPickFullAddress={setPickFullAddress}
                      setPickup={setPickup}
                      isPickupAutoFilled={isPickupAutoFilled}
                      setIsPickupAutoFilled={setIsPickupAutoFilled}
                      setPickAddress1={setPickAddress1}
                      setPickAddress2={setPickAddress2}
                      setPickCity={setPickCity}
                      setPickState={setPickState}
                      setPickZip={setPickZip}
                      setPickCountry={setPickCountry}
                      setPickLat={setPickLat}
                      setPickLng={setPickLng}
                      setPickBusinessName={setPickBusinessName}
                    />
                    <div hidden={!errors.pickupFirstname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storeLastName">Last Name</Label>
                  <FormGroup className={errors.pickupLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      readOnly={isPickupStore}
                      type="text"
                      name="storeLastName"
                      id="store-last-name"
                      placeholder="Doe"
                      onChange={e => (isPickupStore ? null : setPickLastname(e.target.value))}
                      value={isPickupStore ? storeLastName : pickLastname}
                    />
                    <div hidden={!errors.pickupLastname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="storePhone">Phone Number</Label>
                  <FormGroup className={errors.pickupPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      readOnly={isPickupStore}
                      required
                      type="text"
                      name="storePhone"
                      id="store-phone"
                      placeholder="555-555-5555"
                      onChange={e => (isPickupStore ? null : setPickPhone(e.target.value))}
                      value={isPickupStore ? storePhone : pickPhone}
                    />
                    <div hidden={!errors.pickupPhone}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Business Name (optional)</Label>
                    <Input
                      readOnly={isPickupStore}
                      type="text"
                      name="pickBusinessName"
                      id="pick-business-name"
                      placeholder="Store Name"
                      onChange={e => (isPickupStore ? null : setPickBusinessName(e.target.value))}
                      value={isPickupStore ? storeName : pickBusinessName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Pickup Address</Label>
                    {isPickupAutoFilled ? (
                      <Input value={isPickupStore ? storeAddress : pickFullAddress} onChange={e => (isPickupStore ? null : setPickFullAddress(e.target.value))} />
                    ) : (
                      // <MapboxAutocomplete
                      //   id="mapbox-autocomplete"
                      //   inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                      //   publicKey={MAPBOX_TOKEN}
                      //   onSuggestionSelect={handlePickupSelect}
                      //   country="us"
                      //   resetSearch={false}
                      //   readOnly={isPickupStore}
                      //   defaultValue={isPickupStore ? storeAddress : null}
                      //   value={isPickupStore ? storeAddress : pickFullAddress}
                      //   query={pickFullAddress}
                      //   placeholder="Street Address"
                      //   proximityLat={vendorLat}
                      //   proximityLng={vendorLong}
                      // />
                      <GoogleMapAutoComplete
                        inputClass={errors.pickupAddress ? 'form-control mb-2' + ' has-danger' : 'form-control mb-2 has-danger'}
                        onSuggestionSelect={handlePickupSelect}
                        country="us"
                        resetSearch={false}
                        readOnly={isPickupStore}
                        defaultValue={isPickupStore ? storeAddress : null}
                        value={isPickupStore ? storeAddress : pickFullAddress}
                        query={pickFullAddress}
                        placeholder="Street Address"
                        proximityLat={vendorLat}
                        proximityLng={vendorLong}
                      />
                    )}

                    <div hidden={errorMessageDistance.errorCode !== 1}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                    </div>
                    <div hidden={!errors.pickupAddress}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-pickup">Pickup Instructions (optional)</Label>
                    <Input
                      type="text"
                      name="dropoff"
                      id="dropoff-instructions"
                      placeholder="Park in back, attendant will assist."
                      value={dropoff}
                      onChange={e => {
                        if (e.target.value.length < 510) {
                          return setPickup(e.target.value);
                        } else {
                          return;
                        }
                      }}
                      value={pickup}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <LineItems
                title="Pick up Item(s)"
                items={pickupItems}
                setItems={setPickupItems}
                itemsErrorArray={pickupItemsError}
                setItemsErrorArray={setPickupItemsError}
                pickList1={pickupItems}
                pickList2={waypointPickupItems}
              />
              <>
                <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
                <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
                  Pickup/Dropoff Information
                </CardTitle>
                <Row style={{ paddingTop: 0 }}>
                  <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                    <Label check>
                      <Input
                        required
                        type="radio"
                        checked={isWaypointStore}
                        value="wmd"
                        name="store-address-radios3"
                        id="my-store-address"
                        onClick={() => {
                          setIsWaypointStore(true);
                          setIsPickupStore(false);
                        }}
                      />
                      <span id="radio" className="form-check-sign" /> My Store Address
                    </Label>
                  </FormGroup>

                  <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                    <Label check>
                      <Input
                        required
                        type="radio"
                        checked={!isWaypointStore}
                        name="store-address-radios3"
                        value="wdd"
                        id="different-address"
                        onClick={() => {
                          setIsWaypointStore(false);
                        }}
                      />
                      <span id="radio" className="form-check-sign" /> Different Address
                    </Label>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup check className="mt-3" style={{ marginLeft: '15px', marginBottom: '30px' }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="pickup-dropoff-check"
                        checked={isWaypointPickup}
                        value={isWaypointPickup}
                        onClick={e => {
                          setIsWaypointPickup(!isWaypointPickup);
                        }}
                      />
                      <span className="form-check-sign" />
                      Pick Up
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mt-3" style={{ marginLeft: '30px' }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="pickup-dropoff-check"
                        checked={isWaypointDropoff}
                        value={isWaypointDropoff}
                        onClick={e => {
                          setIsWaypointDropoff(!isWaypointDropoff);
                        }}
                      />
                      <span className="form-check-sign" />
                      Drop Off
                    </Label>
                  </FormGroup>
                  <div hidden={!waypointErrors.pickOrDrop}>
                    <p style={{ color: 'red', marginTop: '15px', marginLeft: '20px' }}>Choose at least one field</p>
                  </div>
                </Row>
                <Row form={true}>
                  <Col style={{ textAlign: 'left' }}>
                    <Label for="customerFirstName">First Name</Label>
                    <FormGroup className={waypointErrors.waypointFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                      <WaypointCustomerAutoComplete
                        isWaypointStore={isWaypointStore}
                        storeFirstName={storeFirstName}
                        waypointFirstname={waypointFirstname}
                        setWaypointFirstname={setWaypointFirstname}
                        waypointCustomerSuggestions={waypointCustomerSuggestions}
                        setWaypointCustomerSuggestions={setWaypointCustomerSuggestions}
                        storeId={storeId}
                        setWaypointLastname={setWaypointLastname}
                        setWaypointPhone={setWaypointPhone}
                        waypointFullAddress={waypointFullAddress}
                        setWaypointFullAddress={setWaypointFullAddress}
                        setWaypointPickupInstruction={setWaypointPickupInstruction}
                        setWaypointDropoffInstruction={setWaypointDropoffInstruction}
                        isWaypointAutoFilled={isWaypointAutoFilled}
                        setIsWaypointAutoFilled={setIsWaypointAutoFilled}
                        setWaypointAddress1={setWaypointAddress1}
                        setWaypointAddress2={setWaypointAddress2}
                        setWaypointCity={setWaypointCity}
                        setWaypointState={setWaypointState}
                        setWaypointZip={setWaypointZip}
                        setWaypointCountry={setWaypointCountry}
                        setWaypointLat={setWaypointLat}
                        setWaypointLng={setWaypointLng}
                        setWaypointBusinessName={setWaypointBusinessName}
                      />
                      <div hidden={!waypointErrors.waypointFirstname}>
                        <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col style={{ textAlign: 'left' }}>
                    <Label for="customerLastName">Last Name</Label>
                    <FormGroup className={waypointErrors.waypointLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                      <Input
                        required
                        type="text"
                        name="customerLastName"
                        id="customer-last-name"
                        placeholder="Doe"
                        onChange={e => (isWaypointStore ? null : setWaypointLastname(e.target.value))}
                        value={isWaypointStore ? storeLastName : waypointLastname}
                      />
                      <div hidden={!waypointErrors.waypointLastname}>
                        <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col style={{ textAlign: 'left' }}>
                    <Label for="customerPhone">Phone Number</Label>
                    <FormGroup className={waypointErrors.waypointPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                      <Input
                        required
                        type="text"
                        name="customerPhone"
                        id="customer-phone"
                        placeholder="555-555-5555"
                        onChange={e => (isWaypointStore ? null : setWaypointPhone(e.target.value))}
                        value={isWaypointStore ? storePhone : waypointPhone}
                      />
                      <div hidden={!waypointErrors.waypointPhone}>
                        <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="order-waypoint">Business Name (optional)</Label>
                      <Input
                        type="text"
                        name="waypointBusinessName"
                        id="waypoint-business-name"
                        placeholder="Store Name"
                        onChange={e => (isWaypointStore ? null : setWaypointBusinessName(e.target.value))}
                        value={isWaypointStore ? storeName : waypointBusinessName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Delivery Address</Label>
                      {isWaypointAutoFilled ? (
                        <Input value={isWaypointStore ? storeAddress : waypointFullAddress} onChange={e => (isWaypointStore ? null : setWaypointFullAddress(e.target.value))} />
                      ) : (
                        // <MapboxAutocomplete
                        //   id="mapbox-autocomplete2"
                        //   inputClass="form-control mb-2"
                        //   publicKey={MAPBOX_TOKEN}
                        //   onSuggestionSelect={handleWaypointSelect}
                        //   country="us"
                        //   resetSearch={false}
                        //   readOnly={isWaypointStore}
                        //   defaultValue={isWaypointStore ? storeAddress : null}
                        //   value={isWaypointStore ? storeAddress : waypointFullAddress}
                        //   query={waypointFullAddress}
                        //   placeholder="Street Address"
                        //   proximityLat={vendorLat}
                        //   proximityLng={vendorLong}
                        // />
                        <GoogleMapAutoComplete
                          inputClass="form-control mb-2"
                          onSuggestionSelect={handleWaypointSelect}
                          country="us"
                          resetSearch={false}
                          readOnly={isWaypointStore}
                          defaultValue={isWaypointStore ? storeAddress : null}
                          value={isWaypointStore ? storeAddress : waypointFullAddress}
                          query={waypointFullAddress}
                          placeholder="Street Address"
                          proximityLat={vendorLat}
                          proximityLng={vendorLong}
                        />
                      )}
                      <div hidden={errorMessageDistance.errorCode !== 1}>
                        <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                      </div>
                      <div hidden={!errors.waypointAddress}>
                        <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                      </div>
                    </FormGroup>
                  </Col>

                  {isWaypointPickup && !isWaypointDropoff ? (
                    <Col>
                      <FormGroup>
                        <Label for="order-pickup">Pickup Instructions (optional)</Label>
                        <Input
                          type="text"
                          name="waypointPickup"
                          id="waypoint-pickup-instructions"
                          placeholder="Park in back, attendant will assist."
                          value={waypointPickupInstruction}
                          onChange={e => {
                            if (e.target.value.length < 510) {
                              return setWaypointPickupInstruction(e.target.value);
                            } else {
                              return;
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  ) : !isWaypointPickup && isWaypointDropoff ? (
                    <Col>
                      <FormGroup>
                        <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                        <Input
                          type="text"
                          name="waypointDropoff"
                          id="waypoint-dropoff-instructions"
                          placeholder="Gate Code, Apt. #, etc."
                          value={waypointDropoffInstruction}
                          onChange={e => {
                            if (e.target.value.length < 510) {
                              return setWaypointDropoffInstruction(e.target.value);
                            } else {
                              return;
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  ) : null}
                </Row>
                {isWaypointPickup && isWaypointDropoff ? (
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="order-pickup">Pickup Instructions (optional)</Label>
                        <Input
                          type="text"
                          name="waypointPickup"
                          id="waypoint-pickup-instructions"
                          placeholder="Park in back, attendant will assist."
                          value={waypointPickupInstruction}
                          onChange={e => {
                            if (e.target.value.length < 510) {
                              return setWaypointPickupInstruction(e.target.value);
                            } else {
                              return;
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                        <Input
                          type="text"
                          name="waypointDropoff"
                          id="waypoint-dropoff-instructions"
                          placeholder="Gate Code, Apt. #, etc."
                          value={waypointDropoffInstruction}
                          onChange={e => {
                            if (e.target.value.length < 510) {
                              return setWaypointDropoffInstruction(e.target.value);
                            } else {
                              return;
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {isWaypointPickup && isWaypointDropoff ? (
                  <>
                    <LineItems
                      title="Pick up Item(s)"
                      items={waypointPickupItems}
                      setItems={setWaypointPickupItems}
                      itemsErrorArray={waypointPickupItemsError}
                      setItemsErrorArray={setWaypointPickupItemsError}
                      pickList1={pickupItems}
                      pickList2={waypointPickupItems}
                    />
                    <LineItems
                      title="Drop off Item(s)"
                      items={waypointDropoffItems}
                      setItems={setWaypointDropoffItems}
                      itemsErrorArray={waypointDropoffItemsError}
                      setItemsErrorArray={setWaypointDropoffItemsError}
                      pickList1={pickupItems}
                      pickList2={waypointPickupItems}
                    />
                  </>
                ) : isWaypointPickup && !isWaypointDropoff ? (
                  <LineItems
                    title="Pick up Item(s)"
                    items={waypointPickupItems}
                    setItems={setWaypointPickupItems}
                    itemsErrorArray={waypointPickupItemsError}
                    setItemsErrorArray={setWaypointPickupItemsError}
                    pickList1={pickupItems}
                    pickList2={waypointPickupItems}
                  />
                ) : !isWaypointPickup && isWaypointDropoff ? (
                  <LineItems
                    title="Drop off Item(s)"
                    items={waypointDropoffItems}
                    setItems={setWaypointDropoffItems}
                    itemsErrorArray={waypointDropoffItemsError}
                    setItemsErrorArray={setWaypointDropoffItemsError}
                    pickList1={pickupItems}
                    pickList2={waypointPickupItems}
                  />
                ) : null}
              </>
              <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
              <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
                Dropoff Information
              </CardTitle>
              <Row>
                <FormGroup check className="mt-3" style={{ marginLeft: '15px' }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="round-trip-check"
                      value={isRoundTrip}
                      checked={isRoundTrip}
                      onClick={e => {
                        setIsRoundTrip(!isRoundTrip);
                        // handleRoundTrip();
                      }}
                    />
                    <span className="form-check-sign" />
                    Round Trip
                  </Label>
                </FormGroup>
              </Row>
              <Row style={{ paddingTop: 0 }}>
                {isRoundTrip ? (
                  <>
                    <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                      <Label check style={{ color: '#4c5057' }}>
                        <Input
                          required
                          type="radio"
                          checked={isPickupStore}
                          value="md"
                          name="store-address-radios4"
                          id="my-store-address"
                          // onClick={() => {
                          //   setIsDropoffStore(true);
                          // }}
                          disabled
                        />
                        <span id="radio" className="form-check-sign" /> My Store Address
                      </Label>
                    </FormGroup>
                    <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                      <Label check style={{ color: '#4c5057' }}>
                        <Input
                          required
                          type="radio"
                          checked={!isPickupStore}
                          name="store-address-radios4"
                          value="dd"
                          id="different-address"
                          // onClick={() => {
                          //   setIsDropoffStore(false);
                          // }}
                          disabled
                        />
                        <span id="radio" className="form-check-sign" /> Different Address
                      </Label>
                    </FormGroup>{' '}
                  </>
                ) : (
                  <>
                    <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="store-address">
                      <Label check>
                        <Input
                          required
                          type="radio"
                          checked={isDropoffStore}
                          value="md"
                          name="store-address-radios4"
                          id="my-store-address"
                          onClick={() => {
                            setIsDropoffStore(true);
                          }}
                        />
                        <span id="radio" className="form-check-sign" /> My Store Address
                      </Label>
                    </FormGroup>
                    <FormGroup style={{ padding: 15 }} check className="form-check-radio" id="shipment-weight">
                      <Label check>
                        <Input
                          required
                          type="radio"
                          checked={!isDropoffStore}
                          name="store-address-radios4"
                          value="dd"
                          id="different-address"
                          onClick={() => {
                            setIsDropoffStore(false);
                          }}
                        />
                        <span id="radio" className="form-check-sign" /> Different Address
                      </Label>
                    </FormGroup>
                  </>
                )}
              </Row>
              <Row form={true}>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerFirstName">First Name</Label>
                  <FormGroup className={errors.dropoffFirstname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <DropOffCustomerAutoComplete
                      isDropoffStore={isDropoffStore}
                      storeFirstName={storeFirstName}
                      dropFirstname={dropFirstname}
                      setDropFirstname={setDropFirstname}
                      dropCustomerSuggestions={dropCustomerSuggestions}
                      setDropCustomerSuggestions={setDropCustomerSuggestions}
                      storeId={storeId}
                      setDropLastname={setDropLastname}
                      setDropPhone={setDropPhone}
                      dropFullAddress={dropFullAddress}
                      setDropFullAddress={setDropFullAddress}
                      setDropoff={setDropoff}
                      isDropOffAutoFilled={isDropOffAutoFilled}
                      setIsDropOffAutoFilled={setIsDropOffAutoFilled}
                      setDropAddress1={setDropAddress1}
                      setDropAddress2={setDropAddress2}
                      setDropCity={setDropCity}
                      setDropState={setDropState}
                      setDropZip={setDropZip}
                      setDropCountry={setDropCountry}
                      setDropLat={setDropLat}
                      setDropLng={setDropLng}
                      isRoundTrip={isRoundTrip}
                      pickFirstname={pickFirstname}
                      isPickupStore={isPickupStore}
                      hasThirdWaypoint={hasThirdWaypoint}
                      setDropoffBusinessName={setDropoffBusinessName}
                    />
                    <div hidden={!errors.dropoffFirstname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerLastName">Last Name</Label>
                  <FormGroup className={errors.dropoffLastname ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      type="text"
                      name="customerLastName"
                      id="customer-last-name"
                      placeholder="Doe"
                      // onChange={e => (isDropoffStore ? null : setDropLastname(e.target.value))}
                      // value={isRoundTrip && isPickupStore ? storeLastName : isRoundTrip && !isPickupStore ? pickLastname : isDropoffStore ? storeLastName : dropLastname}
                      onChange={e => (isRoundTrip || isDropoffStore ? null : setDropLastname(e.target.value))}
                      value={isRoundTrip && isPickupStore ? storeLastName : isRoundTrip && !isPickupStore ? pickLastname : isDropoffStore ? storeLastName : dropLastname}
                    />
                    <div hidden={!errors.dropoffLastname}>
                      <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ textAlign: 'left' }}>
                  <Label for="customerPhone">Phone Number</Label>
                  <FormGroup className={errors.dropoffPhone ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                    <Input
                      required
                      type="text"
                      name="customerPhone"
                      id="customer-phone"
                      placeholder="555-555-5555"
                      onChange={e => (isDropoffStore ? null : setDropPhone(e.target.value))}
                      value={isRoundTrip && isPickupStore ? storePhone : isRoundTrip && !isPickupStore ? pickPhone : isDropoffStore ? storePhone : dropPhone}
                    />
                    <div hidden={!errors.dropoffPhone}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please enter a valid phone number (10-13 digits)</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-dropoff">Business Name (optional)</Label>
                    <Input
                      type="text"
                      name="dropoffBusinessName"
                      id="dropoff-business-name"
                      placeholder="Store Name"
                      onChange={e => (isDropoffStore ? null : setDropoffBusinessName(e.target.value))}
                      value={isRoundTrip && isPickupStore ? storeName : isRoundTrip && !isPickupStore ? pickBusinessName : isDropoffStore ? storeName : dropoffBusinessName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Delivery Address</Label>
                    {isRoundTrip ? (
                      <Input value={isPickupStore ? storeAddress : pickFullAddress} />
                    ) : isDropOffAutoFilled ? (
                      <Input value={isDropoffStore ? storeAddress : dropFullAddress} onChange={e => (isDropoffStore ? null : setDropFullAddress(e.target.value))} />
                    ) : (
                      // <MapboxAutocomplete
                      //   id="mapbox-autocomplete3"
                      //   inputClass="form-control mb-2"
                      //   publicKey={MAPBOX_TOKEN}
                      //   onSuggestionSelect={handleDropoffSelect}
                      //   country="us"
                      //   resetSearch={false}
                      //   readOnly={isDropoffStore}
                      //   defaultValue={isDropoffStore ? storeAddress : null}
                      //   value={isDropoffStore ? storeAddress : dropFullAddress}
                      //   query={dropFullAddress}
                      //   placeholder="Street Address"
                      //   proximityLat={vendorLat}
                      //   proximityLng={vendorLong}
                      // />
                      <GoogleMapAutoComplete
                        inputClass="form-control mb-2"
                        onSuggestionSelect={handleDropoffSelect}
                        country="us"
                        resetSearch={false}
                        readOnly={isDropoffStore}
                        defaultValue={isDropoffStore ? storeAddress : null}
                        value={isDropoffStore ? storeAddress : dropFullAddress}
                        query={dropFullAddress}
                        placeholder="Street Address"
                        proximityLat={vendorLat}
                        proximityLng={vendorLong}
                      />
                    )}
                    <div hidden={errorMessageDistance.errorCode !== 1}>
                      <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageDistance.errorMessage}</p>
                    </div>
                    <div hidden={!errors.dropoffAddress}>
                      <p style={{ color: 'red', marginTop: '4px' }}>Please choose an address from the dropdown menu</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="order-dropoff">Dropoff Instructions (optional)</Label>
                    <Input
                      type="text"
                      name="dropoff"
                      id="dropoff-instructions"
                      placeholder="Gate Code, Apt. #, etc."
                      value={dropoff}
                      onChange={e => {
                        if (e.target.value.length < 510) {
                          return setDropoff(e.target.value);
                        } else {
                          return;
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <LineItems
                title="Drop off Item(s)"
                items={dropoffItems}
                setItems={setDropoffItems}
                itemsErrorArray={dropoffItemsError}
                setItemsErrorArray={setDropoffItemsError}
                pickList1={pickupItems}
                pickList2={waypointPickupItems}
              />
              {itemsMatchError && (
                <p style={{ color: 'red', marginTop: '4px', marginBottom: '5px' }}>Please check to make sure quantity or detail of pick up item(s) matching drop off item(s) </p>
              )}
            </div>
          )}
          <hr style={{ borderColor: '#344675', borderStyle: 'solid' }}></hr>
          <CardTitle tag="h4" style={{ padding: 5, paddingTop: 10, textAlign: 'left' }}>
            Shipment Information
          </CardTitle>

          {/* <Row>
            <Col className="col-8">
              <Label for="order-description">Shipment Details</Label> 
              <FormGroup className={errors.description ? 'has-danger' : 'thisIsAPlaceholderClass'}>
                <Input
                  required
                  style={errors.description ? { borderColor: 'red' } : null}
                  type="textarea"
                  name="description"
                  id="order-description"
                  placeholder="Description of Goods (i.e. type of product(s); color, quantity, etc.)"
                  onChange={e => {
                    if (e.target.value.length < 255) {
                      return setDescription(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  value={description}
                />
                <div hidden={!errors.description}>
                  <p style={{ color: 'red', marginTop: '4px' }}>This is a required field</p>
                </div>{' '}
              </FormGroup>
            </Col>
            <Col className="col-4">
              <FormGroup>
                <Label for="merchant-order-id">Store Order ID (optional)</Label>
                <Input
                  type="textarea"
                  name="merchant_store_id"
                  id="merchant-store-id"
                  placeholder="Internal identifier for purchase order/ticket number(i.e. SYC05698)"
                  onChange={e => {
                    if (e.target.value.length < 510) {
                      return setMerchantOrderID(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  value={merchantOrderID}
                />
              </FormGroup>
            </Col>
          </Row> */}

          {!hasThirdWaypoint && !allowMultipleDrops ? (
            <LineItems
              title="Description"
              items={defaultItems}
              setItems={setDefaultItems}
              itemsErrorArray={defaultItemsError}
              setItemsErrorArray={setDefaultItemsError}
              pickList1={pickupItems}
              pickList2={waypointPickupItems}
            />
          ) : null}
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label for="merchant-order-id">Store Order ID (optional)</Label>
                <Input
                  type="text"
                  name="merchant_store_id"
                  id="merchant-store-id"
                  placeholder="Internal identifier for purchase order/ticket number(i.e. SYC05698)"
                  onChange={e => {
                    if (e.target.value.length < 510) {
                      return setMerchantOrderID(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  value={merchantOrderID}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="justify-content-md-center" style={{ textAlign: 'left', marginBottom: 30, marginLeft: 30 }}>
            <Col md="auto">
              <Label inline for="shipment-weight" style={{ textAlign: 'left' }}>
                Total Shipment Weight
              </Label>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios1" value={50} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> Less than 50 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios2" value={100} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 50 to 100 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={200} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 100 to 200 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={300} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 200 to 300 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={400} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 300 to 400 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 400 to 500 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={1000} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 500 to 1000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={1500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 1000 to 2000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={2500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 2000 to 3000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={3500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 3000 to 4000 lbs{'  '}
                </Label>
              </FormGroup>
            </Col>
            <Col md="auto" style={{ marginTop: 23, marginRight: 250 }}>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={4500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 4000 to 5000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={6250} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 5000 to 7500 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={8750} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 7500 to 10000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={11250} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 10000 to 12500 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={13750} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 12500 to 15000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={16250} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 15000 to 17500 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={18750} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 17500 to 20000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={32500} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> 20000 to 45000 lbs{'  '}
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" id="shipment-weight">
                <Label check>
                  <Input required type="radio" name="exampleRadio1" id="exampleRadios3" value={45000} onClick={e => handleWeight(e)} />
                  <span id="radio" className="form-check-sign" /> over 45000 lbs{'  '}
                </Label>
                <div hidden={!errors.weight}>
                  <p style={{ color: 'red', marginTop: '4px' }}>Please give us your best estimate of the total delivery weight</p>
                </div>
              </FormGroup>
              <FormGroup check style={{ marginTop: '10px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    id="tool-check"
                    onClick={e => {
                      setIsOverSize(!isOverSize);
                    }}
                  />
                  <span className="form-check-sign" />
                  oversize
                </Label>
              </FormGroup>
            </Col>
            <Col md="auto">
              <Label inline for="equipment_tools" style={{ textAlign: 'left' }}>
                Equipments and Tools Needed
              </Label>
              <div style={{ marginTop: '20px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsHandLoadUnloadHover(true)} onMouseLeave={() => setIsHandLoadUnloadHover(false)}>
                      <HandLoadUnload name="HandLoadUnload" fill={isHandLoadUnloadDisabled ? '#333333' : '#5e72e4'}></HandLoadUnload>
                    </div>
                    {!isHandLoadUnloadDisabled && (
                      <Input
                        type="checkbox"
                        id="tool-check"
                        onClick={e => {
                          setIsHandLoadUnload(!isHandLoadUnload);
                        }}
                        disabled={isHandLoadUnloadDisabled}
                      />
                    )}
                    <span className="form-check-sign" style={{ marginLeft: '12px' }} />
                    {isHandLoadUnloadHover && 'Hand load'}
                  </Label>
                </FormGroup>
              </div>
              <div style={{ marginTop: '20px' }}>
                <FormGroup check className="mt-3">
                  <Label check>
                    <div style={{ marginTop: '-15px', marginLeft: '10px' }} onMouseEnter={() => setIsLiftgateHover(true)} onMouseLeave={() => setIsLiftgateHover(false)}>
                      <Liftgate name="Liftgate" fill={isLiftgateDisabled ? '#333333' : '#5e72e4'}></Liftgate>
                    </div>
                    {!isLiftgateDisabled && (
                      <Input
                        type="checkbox"
                        id="tool-check"
                        onClick={e => {
                          setIsLiftgate(!isLiftgate);
                        }}
                        disabled={isLiftgateDisabled}
                      />
                    )}

                    <span className="form-check-sign" style={{ marginLeft: '15px', marginBottom: '2px' }} />
                    {isLiftgateHover && 'Liftgate'}
                  </Label>
                </FormGroup>
              </div>

              <div style={{ marginTop: '20px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-15px', marginLeft: '10px' }} onMouseEnter={() => setIsPalletJackHover(true)} onMouseLeave={() => setIsPalletJackHover(false)}>
                      <PalletJack name="PalletJack"></PalletJack>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsPalletJack(!isPalletJack);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '12px' }} />
                    {isPalletJackHover && 'Pallet Jack'}
                  </Label>
                </FormGroup>
              </div>

              <div style={{ marginTop: '20px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-15px' }} onMouseEnter={() => setIsDollyHover(true)} onMouseLeave={() => setIsDollyHover(false)}>
                      <Dolly name="Dolly"></Dolly>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsDolly(!isDolly);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '20px' }} />
                    {isDollyHover && 'Dolly'}
                  </Label>
                </FormGroup>
              </div>
            </Col>
            <Col md="auto" style={{ marginLeft: -25, width: '12%' }}>
              <div style={{ marginTop: '43px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-14px' }} onMouseEnter={() => setIsHazmatHover(true)} onMouseLeave={() => setIsHazmatHover(false)}>
                      <Hazmat name="Hazmat" fill={handleFill('Hazmat')}></Hazmat>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsHazmat(!isHazmat);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '12px' }} />
                    {isHazmatHover && 'Hazmat'}
                  </Label>
                </FormGroup>
              </div>
              <div style={{ marginTop: '18px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }} onMouseEnter={() => setIsTWICCardHover(true)} onMouseLeave={() => setIsTWICCardHover(false)}>
                  <Label check>
                    <div style={{ marginTop: '-12px' }}>
                      <TWIC name="TWIC" fill={handleFill('TWIC')}></TWIC>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsTWICCard(!isTWICCard);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '5px' }} />
                    {isTWICCardHover && 'TWIC Card'}
                  </Label>
                </FormGroup>
              </div>
              <div style={{ marginTop: '13px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsUmbrellaHover(true)} onMouseLeave={() => setIsUmbrellaHover(false)}>
                      <Umbrella name="Umbrella"></Umbrella>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsWeatherProtection(!isWeatherProtection);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '2px' }} />
                    {isUmbrellaHover && 'Weather Protection'}
                  </Label>
                </FormGroup>
              </div>

              <div style={{ marginTop: '17px' }}>
                <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
                  <Label check>
                    <div style={{ marginTop: '-12px' }} onMouseEnter={() => setIsMedicalHover(true)} onMouseLeave={() => setIsMedicalHover(false)}>
                      <Medical name="TWIC" fill={handleFill('TWIC')}></Medical>
                    </div>
                    <Input
                      type="checkbox"
                      id="tool-check"
                      onClick={e => {
                        setIsMedical(!isMedical);
                      }}
                    />
                    <span className="form-check-sign" style={{ marginLeft: '12px' }} />
                    {isMedicalHover && 'Medical'}
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-md-center" style={{ textAlign: 'left', marginBottom: 30, marginLeft: 30 }}>
            <Col md="auto" style={{ marginTop: 30, marginRight: 100 }}>
              <Label inline style={{ marginLeft: '20px', marginBottom: '20px' }}>
                How many Gophrs will be needed to load/unload the shipment?
              </Label>
              <Row style={{ textAlign: 'center' }}>
                <FaUserAlt
                  id="num1"
                  size="39"
                  style={{ cursor: 'pointer', marginLeft: '35', marginTop: '8' }}
                  color={numPeople === 1 ? '#f7941d' : '#5e72e4'}
                  onClick={() => setNumPeople(1)}
                />
                <FaUserFriends
                  id="num2"
                  size="55"
                  style={{ cursor: 'pointer', marginLeft: '30', marginRight: '30' }}
                  color={numPeople === 2 ? '#f7941d' : '#5e72e4'}
                  onClick={() => setNumPeople(2)}
                />

                <FaUsers
                  style={{ cursor: 'pointer', marginLeft: '5', marginRight: '30' }}
                  id="num3"
                  size="55"
                  color={numPeople === 3 ? '#f7941d' : '#5e72e4'}
                  onClick={() => setNumPeople(3)}
                />
                <div style={{ cursor: 'pointer', marginTop: '7px', marginRight: '25px' }} onClick={() => setNumPeople(4)}>
                  <Gophrs4 id="num4" size="55" fill={numPeople === 4 ? '#f7941d' : '#5e72e4'}></Gophrs4>
                </div>
                <div style={{ cursor: 'pointer', marginTop: '7px' }} onClick={() => setNumPeople(5)}>
                  <Gophrs5 id="num5" size="55" fill={numPeople === 5 ? '#f7941d' : '#5e72e4'}></Gophrs5>
                </div>
              </Row>
            </Col>
            <Col md="auto" style={{ marginTop: 30 }}>
              <Label inline for="shipment-size" style={{ marginLeft: '10px' }}>
                Shipment Size (click 🔽 for more options)
              </Label>
              <Row style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', backgroundColor: '#27293e' }}>
                  <FormGroup check className="form-check-radio" id="shipment-size" style={{ textAlign: 'left', display: 'inline-block' }}>
                    <Label className="form-check-label">
                      {weight > 500 ? (
                        <div>
                          <Car name="Car" fill={handleFillGrayOut('Car')}></Car>
                        </div>
                      ) : (
                        <div onClick={() => setVehicleType('Car')}>
                          <Car name="Car" fill={handleFill('Car')}></Car>
                        </div>
                      )}
                    </Label>
                    <Label className="form-check-label">
                      {weight > 1000 ? (
                        <div>
                          <SUV name="SUV" fill={handleFillGrayOut('SUV')}></SUV>
                        </div>
                      ) : (
                        <div onClick={() => setVehicleType('SUV')}>
                          <SUV name="SUV" fill={handleFill('SUV')}></SUV>
                        </div>
                      )}
                    </Label>
                    {/* <Label className="form-check-label">
                      {weight > 5000 ? (
                        <div>
                          <SprinterVan name="Sprinter Van" fill={handleFillGrayOut('sprinter_van')}></SprinterVan>
                        </div>
                      ) : (
                        <div onClick={() => setVehicleType('sprinter_van')}>
                          <SprinterVan name="Sprinter Van" fill={handleFill('sprinter_van')}></SprinterVan>
                        </div>
                      )}
                    </Label> */}

                    <Label className="form-check-label" style={{ paddingRight: '10px', marginRight: '10px' }}>
                      <UncontrolledDropdown className="dropdown-vehicle" isOpen={isTruckOpen} toggle={toggleTruck}>
                        <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                          {vehicleType === 'heavy_duty_truck' ? (
                            <MonsterTruckDropToggle />
                          ) : vehicleType === 'box_truck' ? (
                            <LiftTruckDropToggle />
                          ) : vehicleType === 'sprinter_van' ? (
                            <SprinterVanDropToggle />
                          ) : weight < 2000 ? (
                            <TruckDropToggle />
                          ) : weight < 4000 ? (
                            <MonsterTruckDropToggle />
                          ) : weight < 5000 ? (
                            <SprinterVanDropToggle />
                          ) : weight < 10000 ? (
                            <LiftTruckDropToggle />
                          ) : (
                            <TruckDropToggle />
                          )}
                          🔽
                        </DropdownToggle>
                        <DropdownMenu>
                          {(weight < 2000 || weight > 10000) && (
                            <>
                              {(vehicleType === 'heavy_duty_truck' || vehicleType === 'sprinter_van' || vehicleType === 'box_truck') && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <TruckDropItem />
                                </DropdownItem>
                              )}
                              {vehicleType !== 'heavy_duty_truck' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <MonsterTruckDropItem />
                                </DropdownItem>
                              )}
                              {vehicleType !== 'sprinter_van' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <SprinterVanDropItem />
                                </DropdownItem>
                              )}
                              {vehicleType !== 'box_truck' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <LiftTruckDropItem />
                                </DropdownItem>
                              )}
                            </>
                          )}
                          {weight >= 2000 && weight < 4000 && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <TruckDropItem />
                              </DropdownItem>

                              {vehicleType == '' ? null : vehicleType === 'sprinter_van' || vehicleType === 'box_truck' ? (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <MonsterTruckDropItem />
                                </DropdownItem>
                              ) : null}
                              {vehicleType !== 'sprinter_van' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <SprinterVanDropItem />
                                </DropdownItem>
                              )}
                              {vehicleType !== 'box_truck' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <LiftTruckDropItem />
                                </DropdownItem>
                              )}
                            </>
                          )}
                          {weight >= 4000 && weight < 5000 && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <TruckDropItem />
                              </DropdownItem>

                              <DropdownItem className="dropdown-item-vehicle" text>
                                <MonsterTruckDropItem />
                              </DropdownItem>

                              {vehicleType == '' ? null : vehicleType === 'box_truck' ? (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <SprinterVanDropItem />
                                </DropdownItem>
                              ) : null}
                              {vehicleType !== 'box_truck' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <LiftTruckDropItem />
                                </DropdownItem>
                              )}
                            </>
                          )}
                          {weight >= 5000 && weight < 10000 && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <TruckDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <MonsterTruckDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <SprinterVanDropItem />
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Label>

                    <Label className="form-check-label-vehicle-dropdown">
                      <UncontrolledDropdown className="dropdown-vehicle" isOpen={isTrailerOpen} toggle={toggleTrailer}>
                        <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                          {/* {vehicleType === 'enclosed_trailer' ? (
                            <EnclosedTrailerDropToggle />
                          ) : vehicleType === 'dump_trailer' ? (
                            <DumpTrailerDropToggle />
                          ) : vehicleType === 'gooseneck_trailer' ? (
                            <Float53FeetAsGooseneckTrailerDropToggle />
                          ) : vehicleType === 'car_hauler' ? (
                            <CarHaulerDropToggle />
                          ) : weight < 5000 ? (
                            <TrailerDropToggle />
                          ) : weight < 10000 ? (
                            <DumpTrailerDropToggle />
                          ) : weight < 15000 ? (
                            <Float53FeetAsGooseneckTrailerDropToggle />
                          ) : (
                            <TrailerDropToggle />
                          )} */}
                          {vehicleType === 'gooseneck_trailer' ? (
                            <Float53FeetAsGooseneckTrailerDropToggle />
                          ) : vehicleType === 'car_hauler' ? (
                            <CarHaulerDropToggle />
                          ) : weight < 5000 ? (
                            <TrailerDropToggle />
                          ) : weight < 10000 ? (
                            <CarHaulerDropToggle />
                          ) : weight < 15000 ? (
                            <Float53FeetAsGooseneckTrailerDropToggle />
                          ) : (
                            <TrailerDropToggle />
                          )}
                          🔽
                        </DropdownToggle>
                        <DropdownMenu>
                          {(weight < 5000 || weight > 18000) && (
                            <>
                              {/* {(vehicleType === 'enclosed_trailer' || vehicleType === 'dump_trailer' || vehicleType === 'gooseneck_trailer' || vehicleType === 'car_hauler') && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <TrailerDropItem />
                                </DropdownItem>
                              )} */}
                              {(vehicleType === 'gooseneck_trailer' || vehicleType === 'car_hauler') && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <TrailerDropItem />
                                </DropdownItem>
                              )}
                              {/* {vehicleType !== 'enclosed_trailer' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <EnclosedTrailerDropItem />
                                </DropdownItem>
                              )} */}
                              {/* {vehicleType !== 'dump_trailer' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <DumpTrailerDropItem />
                                </DropdownItem>
                              )} */}

                              {vehicleType !== 'car_hauler' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <CarHaulerDropItem />
                                </DropdownItem>
                              )}
                              {vehicleType !== 'gooseneck_trailer' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <Float53FeetAsGooseneckTrailerDropItem />
                                </DropdownItem>
                              )}
                            </>
                          )}
                          {weight > 5000 && weight < 10000 && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <TrailerDropItem />
                              </DropdownItem>
                              {/* <DropdownItem className="dropdown-item-vehicle" text>
                                <EnclosedTrailerDropItem />
                              </DropdownItem> */}

                              {/* {vehicleType == '' ? null : vehicleType === 'car_hauler' || vehicleType === 'gooseneck_trailer' ? (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <DumpTrailerDropItem />
                                </DropdownItem>
                              ) : null} */}

                              {/* older comment out */}
                              {/* {vehicleType !== 'dump_trailer' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <DumpTrailerDropItem />
                                </DropdownItem>
                              )} */}
                              {/* {vehicleType !== 'car_hauler' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <CarHaulerDropItem />
                                </DropdownItem>
                              )} */}

                              {vehicleType !== 'gooseneck_trailer' && (
                                <DropdownItem className="dropdown-item-vehicle" text>
                                  <Float53FeetAsGooseneckTrailerDropItem />
                                </DropdownItem>
                              )}
                            </>
                          )}
                          {weight > 10000 && weight < 18000 && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <TrailerDropItem />
                              </DropdownItem>
                              {/* <DropdownItem className="dropdown-item-vehicle" text>
                                <EnclosedTrailerDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <DumpTrailerDropItem />
                              </DropdownItem> */}
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <CarHaulerDropItem />
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Label>
                    <Label className="form-check-label-vehicle-dropdown">
                      <UncontrolledDropdown className="dropdown-vehicle" isOpen={is18WellerOpen} toggle={toggle18Wheeler}>
                        <DropdownToggle className="dropdown-toggle-vehicle" caret tag="button" type="button">
                          {vehicleType === '18_wheeler_dry_van' ? (
                            <Wheeler18DryVanDropToggle />
                          ) : vehicleType === '18_wheeler_dry_van' ? (
                            <Wheeler18FlatBedDropToggle />
                          ) : vehicleType === '18_wheeler_reefer' ? (
                            <Wheeler18ReeferDropToggle />
                          ) : (
                            <Wheeler18FlatBedDropToggle />
                          )}
                          🔽
                        </DropdownToggle>
                        <DropdownMenu>
                          {vehicleType === '18_wheeler_dry_van' && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18FlatBedDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18ReeferDropItem />
                              </DropdownItem>
                            </>
                          )}
                          {(vehicleType === '18_wheeler_flat_bed' ||
                            (vehicleType !== '18_wheeler_dry_van' && vehicleType !== '18_wheeler_flat_bed' && vehicleType !== '18_wheeler_reefer')) && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18DryVanDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18ReeferDropItem />
                              </DropdownItem>
                            </>
                          )}
                          {vehicleType === '18_wheeler_reefer' && (
                            <>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18FlatBedDropItem />
                              </DropdownItem>
                              <DropdownItem className="dropdown-item-vehicle" text>
                                <Wheeler18DryVanDropItem />
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Label>
                  </FormGroup>
                </div>
              </Row>
            </Col>
          </Row>

          <div hidden={errorMessageSubmit.errorCode !== 8}>
            <p style={{ color: 'red', marginTop: '4px' }}>{errorMessageSubmit.errorMessage}</p>
          </div>

          {sellsAlcohol ? (
            <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
              <Label check>
                <Input
                  type="checkbox"
                  id="schedule-check"
                  onClick={e => {
                    setContainsAlcohol(!containsAlcohol);
                  }}
                />
                <span className="form-check-sign" />
                This shipment contains an alcoholic beverage
              </Label>
            </FormGroup>
          ) : null}
          {/* <FormGroup check className="mt-3" style={{ marginTop: '10px' }}>
            <Label check>
              <Input
                type="checkbox"
                id="schedule-check"
                onClick={e => {
                  setIsScheduled(!isScheduled);
                  if (e.target.checked) {
                    setScheduledFor(
                      tomorrow
                        .hour(19)
                        .minute(0)
                        .second(0)
                    );
                  } else {
                    setScheduledFor(null);
                  }
                }}
              />
              <span className="form-check-sign" />
              Schedule Pickup for a Later Date and Time (Up to <strong>30 Days</strong> in the Future)
            </Label>
          </FormGroup>
          <Collapse isOpen={true} hidden={!isScheduled} style={{ marginTop: '6px' }}>
            <Label className="form-check-label">Select Date and Time of Delivery(click CALENDER for date and CLOCK for time)</Label>
            <FormGroup>
              <ReactDatetime
                input={false}
                inputProps={{
                  className: 'form-control',
                  placeholder: formatTimestamp(tomorrow),
                  readOnly: true,
                  cursor: 'text'
                }}
                onChange={e => (e.toISOString ? setScheduledFor(e) : null)}
                dateFormat="dddd, MMMM D, YYYY"
                timeFormat={true}
                isValidDate={(currentDate, selectedDate) => verifyDate(currentDate, selectedDate)}
                timeConstraints={scheduledFor ? (scheduledFor.day() === 6 ? saturdayTimeConstraint : weekdayTimeConstraint) : weekdayTimeConstraint}
                defaultValue={tomorrow
                  .hour(19)
                  .minute(0)
                  .second(0)}
                // closeOnSelect={true}
                closeOnTab={true}
              />
            </FormGroup>
            {/* <div hidden={ errorMessageSubmit.errorCode !== 12}><p style={{color: "red", marginTop: "4px"}}>{errorMessageSubmit.errorMessage}</p></div> */}
          {/* <div>
              {JSON.stringify(scheduledFor) ? (
                <p style={{ marginBottom: 10 }}>
                  You choose the schedule time: <span style={{ color: 'red' }}>{JSON.stringify(moment(scheduledFor).format('dddd, MMMM-DD-YYYY, hh:mm A'))}</span>, you can change
                  by selecting a new date and time
                </p>
              ) : null}
            </div>
          </Collapse> */}

          {priceChoice(standardPrice, expeditedPrice, superExpeditedPrice)}
          <Row style={{ paddingLeft: `15px`, marginTop: '25px' }}>
            <FormGroup>
              <Button className="btn cancel-btn" color="default" onClick={reset}>
                Cancel
              </Button>
            </FormGroup>
          </Row>
        </form>
      </Collapse>

      <Collapse isOpen={showForm3} style={{ backgroundColor: '#27293d' }} className="container">
        <div style={{ marginBottom: 15, marginTop: 25, fontSize: 15, color: '#f7941d' }}>
          Review your order
          <hr style={{ backgroundColor: '#344675' }} />
        </div>

        <div>
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Pickup Details</div>
          <div style={{ color: '#cdcaca' }}>{pickupInfo.name ? pickupInfo.name : null}</div>
          <div style={{ color: '#cdcaca' }}>{pickupInfo.phone ? pickupInfo.phone : null}</div>
          <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{pickupInfo.address}</div>
          <div hidden={pickup !== '' && pickup !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
            Pickup Instructions
          </div>
          <div hidden={pickup !== '' ? false : true} style={{ color: '#cdcaca' }}>
            {pickup}
          </div>

          <hr style={{ backgroundColor: '#344675' }} />
        </div>

        {hasThirdWaypoint && (
          <div>
            <div style={{ color: '#fff', fontStyle: 'bold' }}>Pickup/Dropoff Details</div>
            <div style={{ color: '#cdcaca' }}>{waypointInfo.name ? waypointInfo.name : null}</div>
            <div style={{ color: '#cdcaca' }}>{waypointInfo.phone ? waypointInfo.phone : null}</div>
            <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{waypointInfo.address}</div>
            <div hidden={waypointPickupInstruction !== '' && waypointPickupInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
              Pickup Instructions
            </div>
            <div hidden={waypointPickupInstruction !== '' ? false : true} style={{ color: '#cdcaca' }}>
              {waypointPickupInstruction}
            </div>

            <div hidden={waypointDropoffInstruction !== '' && waypointDropoffInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
              Dropoff Instructions
            </div>
            <div hidden={waypointDropoffInstruction !== '' ? false : true} style={{ color: '#cdcaca' }}>
              {waypointDropoffInstruction}
            </div>

            <hr style={{ backgroundColor: '#344675' }} />
          </div>
        )}

        {allowMultipleDrops &&
          dropWaypointsArray.map((dr, index) => {
            return (
              <div>
                <div style={{ color: '#fff', fontStyle: 'bold' }}>Dropoff ({index + 1}) Details</div>
                <div style={{ color: '#cdcaca' }}>{dr.waypointFirstname ? dr.waypointFirstname + ' ' + dr.waypointLastname : null}</div>
                <div style={{ color: '#cdcaca' }}>{dr.waypointPhone ? dr.waypointPhone : null}</div>
                <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{dr.waypointFullAddress ? dr.waypointFullAddress : null}</div>
                <div hidden={dr.waypointDropoffInstruction !== '' && dr.waypointDropoffInstruction !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
                  Dropoff Instructions
                </div>
                <div hidden={dr.waypointDropoffInstruction !== 'N/A' ? false : true} style={{ color: '#cdcaca' }}>
                  {dr.waypointDropoffInstruction}
                </div>
                <hr style={{ backgroundColor: '#344675' }} />
              </div>
            );
          })}

        {!allowMultipleDrops && (
          <div>
            <div style={{ color: '#fff', fontStyle: 'bold' }}>Dropoff Details</div>
            <div style={{ color: '#cdcaca' }}>{dropoffInfo.name ? dropoffInfo.name : null}</div>
            <div style={{ color: '#cdcaca' }}>{dropoffInfo.phone ? dropoffInfo.phone : null}</div>
            <div style={{ color: '#cdcaca', marginTop: '10px', marginBottom: '10px' }}>{dropoffInfo.address ? dropoffInfo.address : null}</div>
            <div hidden={dropoff !== '' && dropoff !== null ? false : true} style={{ color: '#fff', fontStyle: 'bold' }}>
              Dropoff Instructions
            </div>
            <div hidden={dropoff !== 'N/A' ? false : true} style={{ color: '#cdcaca' }}>
              {dropoff}
            </div>

            <hr style={{ backgroundColor: '#344675' }} />
          </div>
        )}

        <div>
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Shipment Description</div>
          <div style={{ color: '#cdcaca', marginBottom: '5px' }}>
            {hasThirdWaypoint &&
              pickupItems.length > 0 &&
              pickupItems.map(item => {
                return (
                  <p style={{ marginLeft: '8px' }}>
                    {item.quantity} {item.detail}
                  </p>
                );
              })}
            {hasThirdWaypoint &&
              isWaypointPickup &&
              waypointPickupItems.length > 0 &&
              waypointPickupItems.map(item => {
                return (
                  <p style={{ marginLeft: '8px' }}>
                    {item.quantity} {item.detail}
                  </p>
                );
              })}
            {!hasThirdWaypoint &&
              !allowMultipleDrops &&
              defaultItems.length > 0 &&
              defaultItems.map(item => {
                return (
                  <p style={{ marginLeft: '8px' }}>
                    {item.quantity} {item.detail}
                  </p>
                );
              })}
            {allowMultipleDrops &&
              pickupItems.length > 0 &&
              pickupItems.map(item => {
                return (
                  <p style={{ marginLeft: '8px' }}>
                    {item.quantity} {item.detail}
                  </p>
                );
              })}
          </div>

          <div hidden={!merchantOrderID} style={{ color: '#fff', fontStyle: 'bold' }}>
            Store Order ID
          </div>
          <div hidden={!merchantOrderID} style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>
            {merchantOrderID}
          </div>
          <div style={{ color: '#fff', fontStyle: 'bold' }}>Appropriate Vehicle Type</div>
          <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>{vehicleType}</div>

          <div style={{ color: '#fff', fontStyle: 'bold' }}>Number of Gophrs Needed</div>
          <div style={{ color: '#cdcaca', marginLeft: '8px', marginBottom: '5px' }}>{numPeople}</div>
          {scheduledFor ? (
            <div>
              <div style={{ color: '#fff', fontStyle: 'bold' }}>Scheduled For</div>
              <div style={{ color: '#cdcaca' }}>{formatTimestamp(scheduledFor)}</div>
            </div>
          ) : null}

          <hr style={{ backgroundColor: '#344675' }} />
        </div>

        <Row style={{ textAlign: 'right' }}>
          <Col>
            <div style={{ color: '#fff', fontStyle: 'bold' }}>Shipping Type:</div>
            <div style={{ color: '#fff', fontStyle: 'bold' }}>Total Shipping Cost:</div>
          </Col>
          <Col>
            <div style={{ color: '#fff' }}>{isExpedited ? 'Expedited' : 'Standard'}</div>
            {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
            vehicleType === 'car_hauler' ? (
              priceRangeLow && (
                <div style={{ color: '#f7941d', fontStyle: 'bold' }}>
                  {' '}
                  Estimate ${parseFloat(priceRangeLow).toFixed(2)} -- {parseFloat(priceRangeHigh).toFixed(2)}
                </div>
              )
            ) : (
              <div style={{ color: '#f7941d', fontStyle: 'bold' }}>{price ? '$' + price.toFixed(2) : null}</div>
            )}
          </Col>
        </Row>
        {/* <Row>
                    <FormGroup check className="mt-3">
                      <Label check>
                        <Input type="checkbox" id="schedule-check" onClick={e => {
                            setIsScheduled(!isScheduled);
                            }}/>
                            <span className="form-check-sign" />
                            Schedule Delivery for a Future Date
                      </Label>
                    </FormGroup>
                </Row> */}
        {/* <Collapse isOpen={isSaved}>
                    <Label className="form-check-label">
                        Date of Delivery
                    </Label>
                    <FormGroup>
                        <ReactDatetime
                        inputProps={{
                            className: "form-control",
                            placeholder: formatTimestamp(now),
                            // style: { width: '100px' }
                        }}
                        onChange={e => setScheduledFor(e._d)}
                        dateFormat="dddd, MMMM D, YYYY"
                        timeFormat={false}
                        // isValidDate={(currentDate, selectedDate) => {
                        //     
                        //     
                        //     // if (selectedDate) {
                        //     //     return (selectedDate.isAfter(currentDate));
                        //     // }
                        //     // return (selectedDate.isAfter(currentDate));

                        // }}
                        // closeOnSelect={true}
                        closeOnTab={false}
                        />
                    </FormGroup>
                </Collapse> */}
        {isScheduled ? (
          <Row style={{ marginTop: 20 }}>
            <Col style={{ textAlign: 'left' }}>
              <div>
                <Button className="btn" color="default" style={{ paddingLeft: 57, paddingRight: 57 }} onClick={() => handleBack()}>
                  Go Back
                </Button>
              </div>
              <div>
                <Button className="btn cancel-btn" color="default" onClick={reset}>
                  Cancel Order
                </Button>
              </div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <div>
                <Button hidden={true}>Placeholder</Button>
              </div>
              <div>
                <Button
                  className="btn submit-btn"
                  color="danger"
                  onClick={e => {
                    handleConfirm('shipment_scheduled', 'order_scheduled', isScheduled);
                  }}
                  disabled={isOrderSubmitDisabled}
                >
                  {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                  vehicleType === 'car_hauler'
                    ? 'Get Quote and Schedule'
                    : 'Schedule This Order'}
                </Button>
                <FeedbackMessage message={submissionMessage} />
              </div>
              {/* <div>
                            <Button className="btn cancel-btn" color="default"
                            onClick={e => {
                                setIsSaved(true);
                                setOrderStatus("order_saved");
                                setShipmentStatus("shipment_saved");
                                handleConfirm("shipment_saved", "order_saved");
                            }}
                            style={{ paddingLeft: 52, paddingRight: 52}}
                            >
                                Save for Later
                            </Button>
                        </div> */}
            </Col>
          </Row>
        ) : (
          // : isScheduled && !scheduledFor ?
          // <Row style={{marginTop: 20}}>
          //     <Col style={{textAlign: 'left'}}>
          //         <div>
          //             <Button className="btn" color="default" style={{paddingLeft: 57, paddingRight: 57}} onClick={() => handleBack()}>
          //                 Go Back
          //             </Button>
          //         </div>
          //         <div>
          //             <Button className="btn cancel-btn" color="default"
          //                 onClick={reset}
          //             >
          //                 Cancel Order
          //             </Button>
          //         </div>
          //     </Col>
          //     <Col style={{textAlign: 'right'}}>
          //         <div>
          //             <Button hidden={true}>Placeholder</Button>
          //         </div>
          /* <div>
                            <Button className="btn submit-btn" color="danger"
                                onClick={e => {
                                    handleConfirm("shipment_saved", "order_saved")
                                }}
                            >
                                Save for Later
                            </Button>
                        </div> */
          /* <div>
                            <Button className="btn cancel-btn" color="default"
                            onClick={e => {
                                setIsSaved(true);
                                setOrderStatus("order_saved");
                                setShipmentStatus("shipment_saved");
                                handleConfirm("shipment_saved", "order_saved");
                            }}
                            style={{ paddingLeft: 52, paddingRight: 52}}
                            >
                                Save for Later
                            </Button>
                        </div> */
          /* </Col>
                </Row> */
          <Row style={{ marginTop: 20 }}>
            <Col style={{ textAlign: 'left' }}>
              <div>
                <Button className="btn" color="default" style={{ paddingLeft: 57, paddingRight: 57 }} onClick={() => handleBack()}>
                  Go Back
                </Button>
              </div>
              <div>
                <Button className="btn cancel-btn" color="default" onClick={reset}>
                  Cancel Order
                </Button>
              </div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <div>
                <Button
                  className="btn submit-btn"
                  color="danger"
                  onClick={e => {
                    handleConfirm('shipment_ready_for_driver', 'awaiting_driver', isScheduled);
                  }}
                  disabled={isOrderSubmitDisabled}
                >
                  {((vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer') && !hasTqlRateValue) ||
                  vehicleType === 'car_hauler'
                    ? 'Get Quote'
                    : 'Ready for Pick Up'}
                </Button>
                <FeedbackMessage message={submissionMessage} />
              </div>
              {vehicleType === '18_wheeler_dry_van' || vehicleType === '18_wheeler_flat_bed' || vehicleType === '18_wheeler_reefer' || vehicleType === 'car_hauler' ? null : (
                <div>
                  <Button
                    className="btn cancel-btn"
                    color="default"
                    onClick={e => {
                      setIsSaved(true);
                      setOrderStatus('order_saved');
                      setShipmentStatus('shipment_saved');
                      handleConfirm('shipment_saved', 'order_saved', isScheduled);
                    }}
                    disabled={isOrderSubmitDisabled}
                    style={{ paddingLeft: 52, paddingRight: 52 }}
                  >
                    Save for Later
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        )}

        {/* </Collapse> */}
      </Collapse>
      {/* <div id='warning-alert' hidden={!showAlert}>
              <ReactBSAlert
                style={{ display: "block", marginTop: "-200px"}}
                title="Saving Order for Later"
                onConfirm={() => {
                }}
                onCancel={() => {
                }}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
              >
                <div>Would you like to be reminded of this order at a future date? (optional)</div>
                    
              </ReactBSAlert>
            </div> */}
    </>
  );
}
