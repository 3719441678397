import axios from 'axios';
import * as shipment_urls from '../../../urls/api-shipments/admin';

export function CompleteOrder(shipmentId, orderId) {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'content-type': 'application/json'
    },
    url: shipment_urls.shipmentHost + '/shipments/' + shipmentId + '/orders/' + orderId + '/complete'
  })
    .then(response => response.status)
    .catch(error => {
      if (error.response) {
        console.log('error-response: ', error.response);
      } else {
        console.log('error: ', error);
      }
    });
}
