import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
const clientEnv = process.env.REACT_APP_CLIENT_ENV || (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage' ? 'production' : 'development');

const FileUpload = ({ shipmentId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type.startsWith('image/'))) {
      setSelectedFile(file);
    } else {
      setUploadStatus('Only PDF or image files are allowed');
    }
  };

  const handleUpload = async () => {
    console.log('Uploading file in environment:', clientEnv);
    if (!selectedFile) {
      setUploadStatus('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('bill_of_lading_file', selectedFile);

    try {
      setUploadStatus('Uploading...');
      const response = await axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'multipart/form-data'
        },
        url: config.config.api_files.host + '/shipments/' + shipmentId + `/bol/upload?client_env=${clientEnv}`,
        data: formData
      });
      console.log(response);
      setUploadStatus('Upload successful');
    } catch (error) {
      setUploadStatus('Upload failed');
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div style={{ borderRadius: '8px' }}>
      <label
        style={{
          display: 'block',
          marginBottom: '10px',
          marginTop: '10px',
          color: 'yellow'
        }}
      >
        Upload(PDF/Image)
      </label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="file"
          onChange={handleFileChange}
          accept="application/pdf,image/*"
          style={{
            display: 'inline-block',
            padding: '5px',
            color: '#fff',
            borderRadius: '4px',
            border: '1px solid #666',
            background: '#555',
            width: '95%' // Adjust width as needed
          }}
        />
      </div>
      <button
        onClick={handleUpload}
        style={{
          display: 'inline-block',
          padding: '2px 5px',
          background: '#1E90FF',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          marginTop: '10px'
        }}
      >
        Upload
      </button>
      {uploadStatus && (
        <p style={{ color: '#32CD32', marginTop: '10px' }}>{uploadStatus}</p> // Add space under the button
      )}
    </div>
  );
};

export default FileUpload;
