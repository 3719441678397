import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';

// Set the worker path to the PDF.js worker file
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentationViewer = () => {
  const [isOpen, setIsOpen] = useState(false); // State for modal visibility
  const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
  const [pdfRendered, setPdfRendered] = useState(false); // Track PDF rendering

  // Hardcoded list of PDF URLs
  const documents = [
    { fileName: 'Cert of Liability', fileUrl: 'https://storage.googleapis.com/gophr-documents/certificate-of-liability-insurance.pdf' },
    { fileName: 'W9', fileUrl: 'https://storage.googleapis.com/gophr-documents/gophr-w9.pdf' }
  ];

  const handleOpenModal = fileUrl => {
    setSelectedFile(fileUrl);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedFile(null);
    setPdfRendered(false);
  };

  const handlePdfLoadSuccess = () => {
    setPdfRendered(true); // Set to true once the PDF is successfully rendered
  };

  const handleSaveFile = () => {
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile;
      link.download = selectedFile.substring(selectedFile.lastIndexOf('/') + 1);
      link.click();
    }
  };

  return (
    <div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
      <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: '20px' }}>
        {documents.map((doc, index) => (
          <li key={index} style={{ marginBottom: '15px', marginLeft: '30px' }}>
            <button
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                textDecoration: 'underline',
                color: 'white', // Adjusted for better contrast in dark mode
                cursor: 'pointer',
                fontSize: '1.2em', // Larger font size
                fontFamily: 'inherit'
              }}
              onMouseOver={e => (e.target.style.color = '#be93d4')} // Bright green on hover for dark mode
              onMouseOut={e => (e.target.style.color = 'white')} // Original color on mouse out
              onClick={() => handleOpenModal(doc.fileUrl)}
            >
              {doc.fileName}
            </button>
          </li>
        ))}
      </ul>

      {/* Modal to display the selected PDF */}
      <Modal
        isOpen={isOpen}
        toggle={handleCloseModal}
        size="lg"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: '#121212', // Dark mode background
          color: '#FFFFFF' // Light text for contrast
        }}
      >
        <ModalBody>
          {selectedFile ? (
            <div
              style={{
                width: '100%',
                maxHeight: '100%',
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Document file={selectedFile} onLoadSuccess={handlePdfLoadSuccess}>
                <Page pageNumber={1} />
              </Document>
            </div>
          ) : (
            <p>Loading file...</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveFile} disabled={!pdfRendered}>
            Save
          </Button>
          <Button color="danger" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentationViewer;
