import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Switch, Route, Link } from 'react-router-dom';

import Loading from '../Global/Loading';
import { Styles } from './Styles/MerchantListStyles';
import { IndividualsHeader } from './Styles/MerchantListStyles';
import MerchantListHeader from './MerchantListHeader';
import SortSearch from '../Global/SortSearch';
import LandingContainer from '../Inventory/LandingContainer';
import MerchantCard from './MerchantCard';

import * as inventory_urls from '../../urls/api-inventory/admin';
// import { Breadcrumb } from 'reactstrap';
// import Breadcrumb from '../../BreadcrumbNav';

import MerchantsMarketsSelection from './MerchantsMarketsSelection';
import getRoles from '../../Auth/getRoles';
import { useGlobalContext } from '../../context';

export default function MerchantList(props) {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]);
  const [role, setRole] = useState(500);
  const { marketIdMerchants } = useGlobalContext();

  function getStores() {
    axios({
      method: `post`,
      url: inventory_urls.hasuraHost,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      data: {
        query:
          `{Stores(where: {market_id: {_eq: "` +
          marketIdMerchants +
          `"}},order_by: [{is_enabled: desc}, {is_individual_user: asc}, {, name: asc}]) { store_id city state name phone logo address_1 is_enabled is_individual_user}}`
      }
    })
      .then(response => {
        setLoading(false);
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setStores(response.data.data.Stores);
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error);
      });
  }

  const getUserRole = async () => {
    let roles = await getRoles();
    if (roles !== 'error') {
      setRole(roles[0]);
    }
  };
  useEffect(() => {
    getStores();
    getUserRole();
  }, []);

  //   useEffect(() => {}, [marketIdMerchants]);

  useEffect(() => {
    getStores();
  }, [marketIdMerchants]);

  if (loading) return <Loading />;
  return (
    <>
      <Switch>
        <Route path="/admin/inventory/store/:id" component={LandingContainer} />
        <Route
          exact
          path="/admin/inventory"
          render={() => (
            <div className="merchant-page-container" style={{ margin: `80px 0 10px 115px` }}>
              <MerchantListHeader className="merchant-list-header" role={role} />
              {/* <Breadcrumb /> */}
              <Styles className="merchant-listing-container">
                {/**
                 * * Figure out the best way to implement the
                 * * 'Recent Merchants' list
                 */}
                {/* <SortSearch /> */}
                {/* <div className="recently-added-container">
                                    <h5>Recently Added</h5>
                                    <div className="recently-added">
                                        {stores.map(store => {
                                            return (
                                                <Link to={"/admin/inventory/store/" + store.store_id}>
                                                    <MerchantCard store={store}/>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </div> */}

                <div className="all-merchants-container">
                  {/* {role < 200 && <h5>All Merchants</h5>} */}
                  {role < 200 && <MerchantsMarketsSelection />}
                  {/* <div className="all-merchants">
                    {stores.map(store => {
                      return (
                        <Link to={'/admin/inventory/store/' + store.store_id} key={store.store_id}>
                          <MerchantCard store={store} />
                        </Link>
                      );
                    })}
                  </div> */}
                  {/* List for Stores */}
                  {role < 200 && <IndividualsHeader>Stores</IndividualsHeader>}
                  <div className="all-merchants">
                    {stores
                      .filter(store => !store.is_individual_user)
                      .map(store => (
                        <Link to={'/admin/inventory/store/' + store.store_id} key={store.store_id}>
                          <MerchantCard store={store} />
                        </Link>
                      ))}
                  </div>

                  {/* List for Individuals */}
                  {role < 200 && <IndividualsHeader>Individuals</IndividualsHeader>}
                  <div className="all-merchants">
                    {stores
                      .filter(store => store.is_individual_user)
                      .map(store => (
                        <Link to={'/admin/inventory/store/' + store.store_id} key={store.store_id}>
                          <MerchantCard store={store} />
                        </Link>
                      ))}
                  </div>
                </div>
              </Styles>
            </div>
          )}
        />
      </Switch>
    </>
  );
}
