import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import Admin from '../src/components/Global/Admin';

const App = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/logout" component={Logout} />
    <Route path="/admin/dashboard" component={Admin} />
    <Route path="/admin/users" component={Admin} />
    <Route path="/admin/inventory" component={Admin} />
    <Route path="/admin/delivery_creation" component={Admin} />
    <Route path="/admin/documentation" component={Admin} />
  </Switch>
);

export default App;
