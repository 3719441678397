import styled from 'styled-components';

export const Styles = styled.div`
  padding-top: 15px;
  width: 98%;
  height: auto;
  background-color: #27293d;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  .sorting-search {
    display: flex;
    flex-direction: flex-end;
  }
  .recently-added-container,
  .all-merchants-container {
    margin-bottom: 20px;
    margin-left: 10px;
    h5 {
      color: #f7941d;
      font-size: 1rem;
    }
  }
  .recently-added,
  .all-merchants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const IndividualsHeader = styled.h4`
  margin-top: 30px;
  font-size: 1rem; /* Slightly larger text */
  background-color: #ffa500; /* Light gray background */
  color: #333; /* Dark text for contrast */
  padding: 5px 20px; /* Add padding for a box effect */
  border-radius: 5px; /* Optional: Rounded corners */
  display: inline-block; /* Keeps the header compact */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
`;
