import { gql } from 'apollo-boost';

export const SUBSCRIBE_TO_ORDERS = gql`
  subscription {
    Shipments(order_by: { is_expedited: desc, updated_at: asc }) {
      is_expedited
      num_of_gophrs
      shipment_friendly_id
      customer_blob
      distance
      shipment_total
      contains_alcohol
      shipping_fee
      sum_of_orders_total
      weight
      created_at
      shipment_id
      updated_at
      description
      drop_off_instructions
      pick_up_instructions
      scheduled_for
      shipment_type
      status
      vehicle_type
      is_roundtrip
      merchant_order_id
      shipping_fee_blob
      placed_by
      completed_at
      Orders(order_by: { order_friendly_id: asc }) {
        order_id
        shipment_id
        location_blob
        order_friendly_id
        actions
        status
        subtotal
        sum_tax
        total
        created_at
        updated_at
        pick_up_instructions
        description
        weight
        drop_off_instructions
        estimated_arrival_time
        completed_at
        Items {
          item_id
          product_blob
          quantity
          product_price
          status
          product_id
          ItemOptions {
            option_name
            option_price
            variant_name
            variant_blob
            option_blob
          }
          Order {
            order_id
            contains_alcohol
            Shipment {
              shipment_id
            }
          }
        }
      }
      Route {
        RouteDrivers(where: { status: { _eq: "driver" }, deleted_at: { _is_null: true } }) {
          driver_blob
          driver_id
          vehicle_blob
          tracking_blob
        }
      }
      ShipmentHandshakes {
        url
      }
    }
  }
`;

export const SUBSCRIBE_TO_SHIPMENTS = gql`
  subscription($market_id: [String!]) {
    Shipments(
      order_by: { is_expedited: desc, updated_at: asc }
      where: {
        market_id: { _in: $market_id }
        deleted_at: { _is_null: true }
        status: { _in: ["shipment_created", "shipment_scheduled", "shipment_saved", "shipment_pending", "shipment_pending_schedule", "shipment_pending_payment"] }
      }
    ) {
      created_at
      customer_blob
      description
      contains_alcohol
      distance
      drop_off_instructions
      gratuity
      is_expedited
      num_of_gophrs
      pick_up_instructions
      scheduled_for
      shipment_friendly_id
      shipment_id
      shipment_total
      shipment_type
      shipping_fee
      status
      sum_of_orders_total
      updated_at
      vehicle_type
      weight
      completed_at
      merchant_order_id
      shipping_fee_blob
      placed_by
      Orders(order_by: { order_friendly_id: asc }) {
        order_id
        actions
        location_blob
        order_friendly_id
        status
        subtotal
        sum_tax
        total
        created_at
        updated_at
        pick_up_instructions
        description
        weight
        drop_off_instructions
        estimated_arrival_time
        completed_at
        Items {
          item_id
          product_blob
          quantity
          product_price
          status
          product_id
          ItemOptions {
            option_name
            option_price
            variant_name
            variant_blob
            option_blob
          }
        }
      }
      Route {
        route_id
        route_friendly_id
        path
        bounds
        RouteDrivers {
          distance
          driver_auth0_id
          driver_blob
          vehicle_blob
          status
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_ROUTES = gql`
  subscription($sevenDaysAgo: timestamptz!, $market_id: [String!]) {
    Routes(
      order_by: { is_expedited: desc, updated_at: asc }
      where: {
        _and: [
          { market_id: { _in: $market_id } }
          { deleted_at: { _is_null: true } }
          {
            _or: [
              { status: { _in: ["route_ready", "route_claimed", "route_assigned", "route_enroute", "route_temp_claimed"] } }
              { _and: [{ status: { _eq: "route_completed" } }, { completed_at: { _gt: $sevenDaysAgo } }] }
            ]
          }
        ]
      }
    ) {
      bounds
      path
      contains_alcohol
      created_at
      updated_at
      completed_at
      market_id
      num_of_gophrs
      distance
      route_id
      route_friendly_id
      status
      waypoints_number
      item_count
      restrictions
      is_expedited
      RouteDrivers(where: { deleted_at: { _is_null: true } }, order_by: { created_at: asc }) {
        driver_blob
        distance
        driver_auth0_id
        vehicle_blob
        status
        driver_id
        employment_type
        tracking_blob
      }
      Shipments {
        shipment_id
        route_id
        shipment_friendly_id
        shipment_type
        customer_blob
        description
        drop_off_instructions
        status
        distance
        shipping_fee
        shipping_fee_blob
        weight
        is_expedited
        vehicle_type
        num_of_gophrs
        created_at
        updated_at
        pick_up_instructions
        scheduled_for
        restrictions
        contains_alcohol
        item_count
        merchant_order_id
        shipping_fee_blob
        placed_by
        Orders(order_by: { order_friendly_id: asc }) {
          shipment_id
          order_id
          location_blob
          contains_alcohol
          order_friendly_id
          status
          subtotal
          sum_tax
          total
          created_at
          updated_at
          pick_up_instructions
          description
          weight
          drop_off_instructions
          item_count
          actions
          estimated_arrival_time
          completed_at
          Items {
            item_id
            product_blob
            quantity
            product_price
            status
            product_id
            ItemOptions {
              option_name
              option_price
              variant_name
              variant_blob
              option_blob
            }
          }
        }
      }
    }
  }
`;
