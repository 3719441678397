import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactTable from 'react-table';
import {
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  ButtonGroup,
  Badge,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import moment from 'moment';
import { useSubscription } from '@apollo/react-hooks';
import { ResizableBox } from 'react-resizable';
import io from 'socket.io-client';

import {
  FaHourglassHalf,
  FaPeopleCarry,
  FaHome,
  FaRegCheckCircle,
  FaClock,
  FaBookmark,
  FaExclamationTriangle,
  FaCaretDown,
  FaCaretRight,
  FaExclamationCircle
} from 'react-icons/fa';

import { SUBSCRIBE_TO_SHIPMENTS, SUBSCRIBE_TO_ROUTES } from '../../graphql/ShipmentQueries';
// import { SUBSCRIBE_TO_DRIVERS } from '../../graphql/GetDriversQueries';
// import { usersClient } from '../../Auth/apolloClientUser';
import * as shipment_urls from '../../urls/api-shipments/admin';
import * as user_urls from '../../urls/api-users/admin';
import { formatTimestamp } from '../../helpers/formatter';
// import Multiplayer from '../Multiplayer';

import Loading from '../../components/Global/Loading';
// import { GreenStatusCircle } from '../../assets/img/Dispatch/GreenStatusCircle';
// import { YellowStatusCircle } from '../../assets/img/Dispatch/YellowStatusCircle';
// import { RedStatusCircle } from '../../assets/img/Dispatch/RedStatusCircle';
// import { OrangeStatusCircle } from '../../assets/img/Dispatch/OrangeStatusCircle';
// import Car, { SUV, Truck, BoxTruck, Trailer } from '../../assets/svgs/CarTypes';
import './DispatchOrderTable.css';
import './react-resizable.css';
import './Marker.css';

import DispatchMap from './DispatchMap';
import MapFilters from './MapFilters';
// import { gql } from 'apollo-boost';
import DispatchMarketsSelection from './DispatchMarketsSelection';
import { useGlobalContext } from '../../context';

import ReorderWaypoints from './ReorderWaypoints';

import useSound from 'use-sound';
// import Sound from '../../assets/example.mp3';
import Sound from '../../assets/CashRegister.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import DriverSMSRefreshButton from './HelperComponents/DriverSMSRefreshButton';
import MessageLogsContainer from './HelperComponents/MessageLogsContainer';
import { getCustomerNameFromRoute } from './HelperFunctions/CustomerNames';
import { RouteTotalDriverPays } from './HelperFunctions/RouteTotalDriverPays';
import ETAUpdate from './HelperComponents/ETAUpdate';
import FileUpload from '../SharedComponents/FileUpload';
import FileViewer from '../SharedComponents/FileViewer';
import CompleteOrderButton from './HelperComponents/CompleteOrderButton';

const DispatchOrderTable = props => {
  const ACCESS_TOKEN = 'Bearer ' + window.localStorage.getItem('access_token');
  const jwtDecode = require('jwt-decode');
  const DECODED_ACCESS = jwtDecode(ACCESS_TOKEN);
  const SUB = DECODED_ACCESS.sub;

  const now = moment();
  const tomorrow = moment().add(1, 'day');

  const sevenDaysBefore = moment().subtract(14, 'day')['_d']; //! try 14 days instead of 7 days
  const sevenDaysBeforeNow = moment(sevenDaysBefore).format('YYYY-MM-DD');
  // State variables
  const {
    marketId,
    marketIdArray,
    setMarketIdArray,
    isSingleMarket,
    // singleMarketId,
    // setSingleMarketId,
    // regionMarketId,
    // setRegionMarketId,
    // superRegionMarketId,
    // setSuperRegionMarketId,
    regionId,
    coordinates,
    trafficLayer,
    routesReadyCheck,
    routesDeliveringCheck,
    routesExpeditedCheck,
    routesAlcoholCheck,
    driversAvailableCheck,
    driversEnrouteCheck,
    driversAlcoholCheck,
    driversCarCheck,
    driversSUVCheck,
    driversTruckCheck,
    driversTrailerCheck,
    setRouteClick,
    enrouteClickDriver,
    setEnrouteClickDriver,
    setRouteClickTwice,
    setReadyRoutes,
    setDeliveringRoutes,
    setAvailableDrivers,
    setEnrouteDrivers,
    setSelectedMergingRoutes,
    setShowMergingRoutesMap
  } = useGlobalContext();
  const { loading: apolloading, error, data } = useSubscription(SUBSCRIBE_TO_SHIPMENTS, {
    variables: { market_id: marketIdArray }
  });
  const { loading: apolloadingRoute, error: errorRoute, data: dataRoute } = useSubscription(SUBSCRIBE_TO_ROUTES, {
    variables: { market_id: marketIdArray, sevenDaysAgo: sevenDaysBeforeNow }
  });

  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [routesData, setRoutesData] = useState([]);

  // const { loading: apolloadingDriver, error: errorDriver, data: dataDriver } = useQuery(SUBSCRIBE_TO_DRIVERS, {
  //   context: { clientName: 'userClient' },
  //   variables: { market_id: marketId }
  // });
  const [filter, setFilter] = useState([]);
  const [filterRoutes, setFilterRoutes] = useState([]);
  // const [filterDrivers, setFilterDrivers] = useState(true);
  const [shipmentsToDisplay, setShipmentsToDisplay] = useState([]);
  const [routesToDisplay, setRoutesToDisplay] = useState([]);
  const [driversToDisplay, setDriversToDisplay] = useState([]);
  const [driversToSMS, setDriversToSMS] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalReassign, setModalReassign] = useState(false);
  const [modalAssignPassengerOnly, setModalAssignPassengerOnly] = useState(false);
  const [expandedItems, setExpandedItems] = useState(props.expandedItems);
  const [selected, setSelected] = useState('ready');
  const prevSelectedRef = useRef(selected); // Track the previous 'selected' value
  const [loading, setLoading] = useState(false);
  const [amountReady, setAmountReady] = useState(0);
  const [amountPending, setAmountPending] = useState(0);
  const [amountApproval, setAmountApproval] = useState(0);
  const [amountDelivering, setAmountDelivering] = useState(0);
  const [amountScheduled, setAmountScheduled] = useState(0);
  const [amountMerging, setAmountMerging] = useState(0);

  const [drivers, setDrivers] = useState([]);
  const [driverSelected, setDriverSelected] = useState(false);
  const [order, setOrder] = useState(null);
  const [primaryDriver, setPrimaryDriver] = useState('');
  const [passenger1, setPassenger1] = useState('');
  const [passenger2, setPassenger2] = useState('');
  const [passenger1Selected, setPassenger1Selected] = useState(false);
  const [passenger2Selected, setPassenger2Selected] = useState(false);
  const [driverReassign, setDriverReassign] = useState('');
  const [driverReassignSelected, setDriverReassignSelected] = useState(false);
  const [passenger1OnlyAssigned, setPassenger1OnlyAssigned] = useState('');
  const [passenger2OnlyAssigned, setPassenger2OnlyAssigned] = useState('');
  const [passenger1OnlyAssignedSelected, setPassenger1OnlyAssignedSelected] = useState(false);
  const [passenger2OnlyAssignedSelected, setPassenger2OnlyAssignedSelected] = useState(false);
  const [driversForAssign, setDriversForAssign] = useState([]);
  const [driverName, setDriverName] = useState('');
  const [filterDriversForAssign, setFilterDriversForAssign] = useState([]);
  const [isFilterDrivers, setIsFilterDrivers] = useState(false);

  const [shipment, setShipment] = useState('');
  const [route, setRoute] = useState({ RouteDrivers: [] });

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertCancelOrder, setShowAlertCancelOrder] = useState(false);
  const [showAlertCancelShipment, setShowAlertCancelShipment] = useState(false);
  const [showAlertRemoveShipment, setShowAlertRemoveShipment] = useState(false);
  const [showAlertUnassignRoute, setShowAlertUnassignRoute] = useState(false);
  const [showAlertBreakRoute, setShowAlertBreakRoute] = useState(false);
  const [showAlertMergeRoute, setShowAlertMergeRoute] = useState(false);
  const [showUpdateShippingFee, setShowUpdateShippingFee] = useState(false);
  const [showChargeSetupFee, setShowChargeSetupFee] = useState(false);
  const [shippingFee, setShippingFee] = useState('');
  const [setupDuration, setSetupDuration] = useState('');
  const [showDriverPay, setShowDriverPay] = useState(false);
  const [driverPay, setDriverPay] = useState({
    distance_earning: 0,
    expedited_earning: 0,
    overweight_earning: 0,
    setup_earning: 0,
    tools_earning: 0,
    gratuity: 0,
    bonus: 0,
    min_pay: 0,
    total_earning: 0
  });
  const [driverBonus, setDriverBonus] = useState('');
  const [bonusReason, setBonusReason] = useState('');
  const [shippingFeeReason, setShippingFeeReason] = useState('');
  const [showUpdateDriverPayBeforeClaim, setShowUpdateDriverPayBeforeClaim] = useState(false);
  const [updateDriverPayBeforeClaimReason, setUpdateDriverPayBeforeClaimReason] = useState('');
  const [driverPayBeforeClaimTotalAmount, setDriverPayBeforeClaimTotalAmount] = useState('');

  const [showOrder, setShowOrder] = useState(false);
  const [almostReady, setAlmostReady] = useState([]);
  const [ready, setReady] = useState([]);
  const [availableDriversCopy, setAvailableDriversCopy] = useState('');
  const [enrouteDriversCopy, setEnrouteDriversCopy] = useState('');

  // Merging routes
  const [mergeRoutesList, setMergeRoutesList] = useState([]);
  const [mergingCheckState, setMergingCheckedState] = useState([]);
  const [mergingCheckStateAllChecked, setMergingCheckStateAllChecked] = useState(false);
  const [isRouteBuilderAccessible, setIsRouteBuilderAccessible] = useState(false);
  const [mergeRouteWaypointsSelected, setMergeRouteWaypointsSelected] = useState(0);
  const [mergingRoutes, setMergingRoutes] = useState([]);
  const mergeRouteWaypointsWarning = 24;

  // Reorder
  const [showReorderWaypoints, setShowReorderWaypoints] = useState(false);
  const [routeWaypoints, setRouteWaypoints] = useState([]);
  const [reorderError, setReorderError] = useState('');
  const [isReorderError, setIsReorderError] = useState(false);

  // Gas Price
  const [gasPrice, setGasPrice] = useState(0.0);
  const [dieselPrice, setDieselPrice] = useState(0.0);
  const [showGasPrice, setShowGasPrice] = useState(false);
  const [showGasPriceRegion, setShowGasPriceRegion] = useState(false);
  const [gasPriceError, setGasPriceError] = useState(false);
  const [dieselPriceError, setDieselPriceError] = useState(false);
  const [gasPriceUpdate, setGasPriceUpdate] = useState(null);
  const [dieselPriceUpdate, setDieselPriceUpdate] = useState(null);
  const [priceUpdateTime, setPriceUpdateTime] = useState('');
  const [marketName, setMarketName] = useState('');
  const [region, setRegion] = useState('');

  // Market Note
  const [showMarketNote, setShowMarketNote] = useState(false);
  const [showMarketNoteRegion, setShowMarketNoteRegion] = useState(false);
  const [marketNote, setMarketNote] = useState('');
  const [marketNoteUpdate, setMarketNoteUpdate] = useState('');
  const [regionNote, setRegionNote] = useState('');

  const [play] = useSound(Sound);

  const [marketAndRegionOptions, setMarketAndRegionOptions] = useState([]);

  // Twilio Driver Message
  const [showDriverMessageForm, setShowDriverMessageForm] = useState(false);
  const [driverMessage, setDriverMessage] = useState('');
  const [driverMessageRetrieved, setDriverMessageRetrieved] = useState([]);
  const [messageRecipientInfo, setMessageRecipientInfo] = useState({});
  const [totalUnreadDriverMessages, setTotalUnreadDriverMessages] = useState(0);
  const [driverNameSMS, setDriverNameSMS] = useState('');
  const [driversToSMSFiltered, setDriversToSMSFiltered] = useState([]);

  // Update Driver Info and PO# in RouteDriver for displaying
  const [showDriverInfoAndPO, setShowDriverInfoAndPO] = useState(false);
  const [routeDriverFirstName, setRouteDriverFirstName] = useState('');
  const [routeDriverLastName, setRouteDriverLastName] = useState('');
  const [routeDriverPhone, setRouteDriverPhone] = useState('');
  const [routeDriverPONumber, setRouteDriverPONumber] = useState('');
  const [routeDriverRouteId, setRouteDriverRouteId] = useState('');

  //Update order info
  const [showUpdateOrderInfo, setShowUpdateOrderInfo] = useState(false);
  const [updateOrderInfo, setUpdateOrderInfo] = useState({ first_name: '', last_name: '', phone: '', business_name: '', instruction: '' });
  const [orderType, setOrderType] = useState('Pickup');
  const [orderId, setOrderId] = useState('');

  //! Display or hide the map
  const [showDispatchMap, setShowDispatchMap] = useState(true);

  // Reset currentRecordId and currentRecord when 'selected' changes
  useEffect(() => {
    if (selected !== prevSelectedRef.current) {
      // Reset the currentRecordId and currentRecord when tab is changed
      setCurrentRecordId(null);
      setCurrentRecord(null);
      // Call the resetExpand function to collapse rows when tab is changed
      resetExpand();
    }

    // Update the ref value whenever 'selected' changes
    prevSelectedRef.current = selected;
  }, [selected]);

  // useEffect for handling 'saved' or 'pending' tabs (Shipments data)
  useEffect(() => {
    if (currentRecordId && currentRecord && data) {
      // Handling 'saved' or 'pending' tabs
      if (selected === 'saved' || selected === 'pending') {
        const updatedRecord = data.Shipments.find(record => record.shipment_id === currentRecordId);

        // If the record exists and the status has changed, update state
        if (updatedRecord && JSON.stringify(updatedRecord) !== JSON.stringify(currentRecord)) {
          setShipmentsData(data); // Update shipments data
          resetExpand();
        }
      }
    } else if (!currentRecordId && data) {
      setShipmentsData(data); // Set shipments data if no current record
    }
  }, [data, selected, currentRecordId, currentRecord]);

  // useEffect for handling 'ready', 'progressing', or 'completed' tabs (Routes data)
  useEffect(() => {
    if (currentRecordId && currentRecord && dataRoute) {
      // Handling 'ready', 'progressing', or 'completed' tabs
      if (selected === 'ready' || selected === 'progressing' || selected === 'completed') {
        const updatedRecord = dataRoute.Routes.find(record => record.route_id === currentRecordId);

        // Compare all fields of updatedRecord and currentRecord
        if (updatedRecord && JSON.stringify(updatedRecord) !== JSON.stringify(currentRecord)) {
          setRoutesData(dataRoute); // Update routes data
          resetExpand(); // Reset expanded rows
        }
      }
    } else if (!currentRecordId && dataRoute) {
      setRoutesData(dataRoute); // Set routes data if no current record
    }
  }, [dataRoute, selected, currentRecordId, currentRecord]);

  // Web socket for new incoming SMS notification
  useEffect(() => {
    // Connect to the WebSocket server
    const socket = io(user_urls.usersHost);

    // Listen for the 'newMessage' event
    socket.on('newMessage', data => {
      // Handle the incoming message and update state based on the current state

      // Use a callback to update the state based on the previous state
      setMarketIdArray(prevMarketIdArray => {
        // Ensure that prevMarketIdArray is the current value of marketIdArray
        if (prevMarketIdArray.includes(data.market_id)) {
          let messageCount = 1;
          let updates;
          setDriversToSMS(prevDrivers => {
            updates = prevDrivers.map(driver => {
              messageCount += driver.unread_message_count;
              return driver.phone === data.driver_phone ? { ...driver, unread_message_count: driver.unread_message_count + 1 } : driver;
            });
            return updates;
          });
          setDriversToSMSFiltered(updates);
          setTotalUnreadDriverMessages(messageCount);
        }
        // Return the updated marketIdArray
        return prevMarketIdArray;
      });
    });

    // Clean up the WebSocket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [marketIdArray, setMarketIdArray]);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousAmountReady = usePrevious(amountReady);
  const previousAmountPending = usePrevious(amountPending);

  const fetchEnrouteAndDriver = async route => {
    let driverId = route.RouteDrivers[0].driver_id;
    let driver = await axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.hasuraHost,
      data: {
        query: ` query LastDriverActions ($driverID: uuid!) {LastDriverActions (where: {driver_id: {_eq: $driverID}}) {
                  driver_id
                  location_blob
                    Driver {
                    profile_photo_url
                    restricted_area_credentials
                    can_deliver_alcohol
                    employment_type
                  }
                  }
                }`,
        variables: { driverID: driverId }
      }
    });
    let user = {};
    let info = route.RouteDrivers[0];
    user.first_name = info.driver_blob.first_name;
    user.last_name = info.driver_blob.last_name;
    user.phone = info.driver_blob.phone;
    let vehicle = info.vehicle_blob;
    user.vehicle_info = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model + ' (' + vehicle.color + ')';
    let driver_info = driver.data.data.LastDriverActions[0];
    user.profile_photo_url = driver_info.Driver.profile_photo_url;
    let permits = [];
    if (driver_info.Driver.can_deliver_alcohol) {
      permits.push('Alcohol');
    }
    driver_info.Driver.restricted_area_credentials.map(credential => {
      permits.push(credential);
    });
    user.permit = permits.map(permit => permit).join(', ');
    user.employment_type = driver_info.Driver.employment_type;
    setEnrouteClickDriver({ LastDriverActions: driver.data.data.LastDriverActions[0], User: user });
  };

  const updateTQLTracking = async route => {
    let shipId = route.Shipments[0].shipment_id;
    let po_number;
    if (route.RouteDrivers[0].tracking_blob && route.RouteDrivers[0].tracking_blob.hasOwnProperty('po_number')) {
      po_number = route.RouteDrivers[0].tracking_blob.po_number;
    } else {
      return;
    }
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/shipments/direct/' + shipId + '/tql_ftl_tracking',
      data: { po_number }
    })
      .then(response => {
        console.log('response: ', response);
        return response;
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const sendMessage = (driverMessage, messageRecipient) => {
    let data = {
      message: driverMessage,
      recipient: messageRecipient
    };
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.usersHost + '/driver/send-message',
      data: data
    })
      .then(response => {
        if (response.data.status === 'successful') {
          toggleDriverMessageForm();
          props.notify('tc', 'Successfully sent the message');
          setDriverMessage('');
          setMessageRecipientInfo({});
        } else {
          console.log('send driver message error:', response.data.message);
        }
      })
      .catch(error => {
        console.log('catch send driver message error:', error);
      });
  };

  // console.log('setDriverMessageRetrieved ', driverMessageRetrieved);
  const retrieveMessage = messageRecipient => {
    axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.usersHost + `/driver/fetch-messages?phoneNumber=${messageRecipient}`
    })
      .then(response => {
        if (response.data.status === 'successful') {
          setDriverMessageRetrieved(response.data.payload);
        } else {
          console.log('retrieve driver message error:', response.data.message);
        }
      })
      .catch(error => {
        console.log('catch retrieve driver message error:', error);
      });
  };

  const fetchGasPrice = () => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
        Markets (where: {market_id: {_eq: "` +
          marketIdArray[0] +
          `"}}) {
          fuel_prices 
          market_name
          market_id
          updated_at
        }
      }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setGasPrice(response.data.data.Markets[0].fuel_prices.gas_price);
          setDieselPrice(response.data.data.Markets[0].fuel_prices.diesel_price);
          setPriceUpdateTime(response.data.data.Markets[0].updated_at);
          setMarketName(response.data.data.Markets[0].market_name);
        } else if (response.data.errors) {
          console.log('fetch gas price error: ', response.data.errors);
        }
      })
      .catch(error => {
        console.log('catch fetch gas price error: ', error);
        // throw error;
      });
  };

  useEffect(() => {
    if (isSingleMarket) {
      fetchGasPrice();
    }
  }, [marketIdArray, isSingleMarket]);

  const fetchRegionAndMarketGasPrice = () => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
            Regions (where: {region_id: {_eq: "` +
          regionId +
          `"}}) {
            region_name
            Markets {
              fuel_prices 
              market_name
              market_id
              updated_at
            }
         }
      }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setRegion(response.data.data.Regions[0]);
        } else if (response.data.errors) {
          console.log('fetch regional gas price error: ', response.data.errors);
        }
      })
      .catch(error => {
        console.log('catch fetch gas price error: ', error);
        // throw error;
      });
  };

  const fetchMarketNote = () => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
        Markets (where: {market_id: {_eq: "` +
          marketIdArray[0] +
          `"}}) {
          market_name
          note
        }
      }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setMarketNote(response.data.data.Markets[0]);
        } else if (response.data.errors) {
          console.log('fetch regional market note error: ', response.data.errors);
        }
      })
      .catch(error => {
        console.log('catch fetch regional market note error: ', error);
        // throw error;
      });
  };

  const fetchRegionAndMarketNote = () => {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
            Regions (where: {region_id: {_eq: "` +
          regionId +
          `"}}) {
            region_name
            Markets {
              market_name
              note
            }
         }
      }`
      }
    })
      .then(response => {
        if (response.data.data) {
          setRegionNote(response.data.data.Regions[0]);
        } else if (response.data.errors) {
          console.log('fetch regional market note error: ', response.data.errors);
        }
      })
      .catch(error => {
        console.log('catch fetch regional market note error: ', error);
        // throw error;
      });
  };

  function fetchMarketAndRegions() {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        //   query: `{
        //   Markets(where: {deleted_at: {_is_null: true}}) {
        //     center
        //     market_name
        //     market_id
        //   }
        // }`
        query: `{
          SuperRegions(order_by: {super_region_name: asc}) {
            super_region_name
            super_region_id
            Regions (order_by: {region_name: asc}){
              region_name
              region_id
              Markets (order_by: {market_name: asc}){
                market_name
                center       
                market_id
              }
            }
          }
        }`
      }
    })
      .then(res => {
        //! need to think region and superRegion center could be set in db, right now just average from markets for region, and set center hard code for US
        let superRegions = res.data.data.SuperRegions;
        let finalOptions = [];
        superRegions.map(sr => {
          let superRegionOptions = [];
          let superRegionMarketIdArray = [];
          superRegionOptions.push({
            value: { id: sr.super_region_id, coordinates: { latitude: 39.8283, longitude: -98.5795 } },
            label: 'Country: ' + sr.super_region_name
          });
          finalOptions.push(...superRegionOptions);

          sr.Regions.map(region => {
            let regionOptions = [];
            let marketOptions = [];
            let regionLatitude = 0;
            let regionLongitude = 0;
            let regionMarketIdArray = [];
            region.Markets.map(market => {
              regionLatitude += market.center[0];
              regionLongitude += market.center[1];
              regionMarketIdArray.push(market.market_id);
              marketOptions.push({
                value: { id: market.market_id, coordinates: { latitude: market.center[0], longitude: market.center[1] } },
                label: `      - ` + market.market_name
              });
            });
            regionOptions.push({
              value: {
                id: region.region_id,
                idArray: regionMarketIdArray,
                coordinates: { latitude: regionLatitude / region.Markets.length, longitude: regionLongitude / region.Markets.length }
              },
              label: '    Region: ' + region.region_name
            });
            finalOptions.push(...regionOptions);
            finalOptions.push(...marketOptions);
            superRegionMarketIdArray.push(...regionMarketIdArray);
          });
          finalOptions = finalOptions.map(option => {
            if (option.value.id === sr.super_region_id) {
              return { ...option, value: { ...option.value, idArray: superRegionMarketIdArray } };
            } else {
              return option;
            }
          });
        });
        setMarketAndRegionOptions(finalOptions);
      })
      .catch(error => {
        throw error;
      });
  }
  useEffect(() => {
    fetchMarketAndRegions();
  }, []);

  const memoizedOptions = useMemo(() => marketAndRegionOptions, [marketAndRegionOptions]);

  const resetGasPricesInput = () => {
    setGasPriceUpdate(null);
    setDieselPriceUpdate(null);
  };

  const handleGasPriceUpdate = (gasPrice, dieselPrice) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/admins/markets/' + marketId + '/fuel_prices/update',
      data: { gas_price: gasPrice, diesel_price: dieselPrice }
    })
      .then(response => {
        props.notify('tc', 'Fuel Prices Successfully Updated');
        fetchGasPrice();
        toggleGasPrice();
        resetGasPricesInput();
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const handleGasPriceUpdateThroughRegion = (gasPrice, dieselPrice) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/admins/regions/' + regionId + '/fuel_prices/update',
      data: { gas_price: gasPrice, diesel_price: dieselPrice }
    })
      .then(response => {
        props.notify('tc', 'Fuel Prices Successfully Updated');
        // fetchGasPrice();
        toggleGasPriceRegion();
        resetGasPricesInput();
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const handleMarketNoteUpdate = note => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/admins/markets/' + marketId + '/note/update',
      data: { note }
    })
      .then(response => {
        props.notify('tc', 'Market Note Successfully Updated');
        toggleMarketNote();
        setMarketNoteUpdate('');
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const handleMarketNoteUpdateThroughRegion = note => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/admins/regions/' + regionId + '/note/update',
      data: { note }
    })
      .then(response => {
        props.notify('tc', 'Market Notes Successfully Updated');
        toggleMarketNoteRegion();
        setMarketNoteUpdate('');
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const handleOrderETAUpdate = (order, eta) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/admins/shipments/' + order.shipment_id + '/orders/' + order.order_id + '/eta-update',
      data: { estimated_arrival_time: eta }
    })
      .then(response => {
        props.notify('tc', 'Order ETA Successfully Updated');
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const DriverList = ({ driver }) => {
    return (
      <p>
        {' '}
        {driver.User.firstname} {driver.User.lastname} {'  -  '} {capitalize(driver.Vehicles[0].type.replace(/_/, ' '))}{' '}
        <span style={{ float: 'right', fontSize: 8 }}>{driver.is_online && driver.is_available ? '🟢 ' : driver.is_online && !driver.is_available ? '🟠 ' : '⚪️'}</span>
      </p>
    );
  };
  const toggle = () => {
    setModal(!modal);
    setPrimaryDriver('');
    setDriverSelected(false);
    setPassenger1('');
    setPassenger1Selected(false);
    setPassenger2('');
    setPassenger2Selected(false);
  };

  const toggleReassign = () => {
    setModalReassign(!modalReassign);
    setDriverReassign('');
    setDriverReassignSelected(false);
  };

  const toggleAssignPassengerOnly = () => {
    setModalAssignPassengerOnly(!modalAssignPassengerOnly);
    setPassenger1OnlyAssigned('');
    setPassenger1OnlyAssignedSelected(false);
    setPassenger2OnlyAssigned('');
    setPassenger2OnlyAssignedSelected(false);
  };

  const DeliveryDescription = ({ shipment }) => {
    return (
      <>
        <strong>Delivery Description</strong>
        {shipment.description.includes('{') &&
        shipment.description.includes('[') &&
        shipment.description.includes(':') &&
        shipment.description.includes('quantity') &&
        shipment.description.includes('detail') ? (
          JSON.parse(shipment.description).map(de => {
            return (
              <>
                <p style={{ wordSpacing: '2px' }}>
                  {de.quantity} {de.detail}
                </p>
                <div style={{ marginLeft: '5px' }}>
                  {/* Check if the properties exist before rendering */}
                  {de.weight && <p>Weight: {de.weight}</p>}
                  {de.height && <p>Height: {de.height}</p>}
                  {de.width && <p>Width: {de.width}</p>}
                  {de.length && <p>Length: {de.length}</p>}
                  {de.stackable !== undefined && <p>Stackable: {de.stackable ? 'Yes' : 'No'}</p>}
                </div>{' '}
              </>
            );
          })
        ) : (
          <p> {shipment.description}</p>
        )}
      </>
    );
  };

  const LoadDetails = ({ shipment }) => {
    let blob = shipment.shipping_fee_blob;
    return (
      <>
        <p>
          {shipment.vehicle_type === 'suv'
            ? 'SUV'
            : shipment.vehicle_type === 'car'
            ? 'Car'
            : shipment.vehicle_type === 'truck'
            ? 'Truck'
            : shipment.vehicle_type === 'trailer'
            ? 'Truck and Trailer'
            : shipment.vehicle_type
                .split('_')
                .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ')}
        </p>
        <p>{shipment.weight} lbs</p>
        <p>{shipment.num_of_gophrs} Gophrs Needed</p>
        <p>{shipment.distance.toFixed(2) + ' miles'}</p>
        {/* <p>{'$' + shipment.shipping_fee.toFixed(2)}</p> */}
        {shipment.placed_by ? (
          <p>
            Placed By: <strong>{'  ' + shipment.placed_by} </strong>{' '}
          </p>
        ) : null}
        {shipment.merchant_order_id ? (
          <p>
            Store Order ID: <strong>{'  ' + shipment.merchant_order_id} </strong>{' '}
          </p>
        ) : null}
        {shipment.scheduled_for ? (
          <p>
            Schedule: <strong>{'  ' + formatTimestamp(shipment.scheduled_for)}</strong>{' '}
          </p>
        ) : null}
        {blob.hasOwnProperty('calculated') && (
          <>
            <p>{blob.shipment.is_personal_protective_equipment && 'PPE required'}</p>
            <p>{blob.calculated.hasOwnProperty('hand_load_fee') && blob.calculated.hand_load_fee >= 0 && 'Hand Load: $' + blob.calculated.hand_load_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('dolly_fee') && 'Dolly: $' + blob.calculated.dolly_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('pallet_jack_fee') && 'Pallet Jack: $' + blob.calculated.pallet_jack_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('twic_card_fee') && 'TWIC Card: $' + blob.calculated.twic_card_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('liftgate_fee') && 'Liftgate: $' + blob.calculated.liftgate_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('hazmat_fee') && 'Hazmat: $' + blob.calculated.hazmat_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('weather_protection_fee') && 'Weather Protection: $' + blob.calculated.weather_protection_fee.toFixed(2)}</p>
            <p>{blob.calculated.hasOwnProperty('medical_fee') && 'Medical: $' + blob.calculated.medical_fee.toFixed(2)}</p>
          </>
        )}
        <p>Quote:{'  $' + blob.shipping_fee.toFixed(2)}</p>
        <p>
          Total Shipping:{' '}
          {shipment.shipping_fee == 0 && shipment.shipment_type != 'school_lunch'
            ? '  $' + shipment.shipping_fee_blob.calculated.shipping_fee_range.min.toFixed(2) + '--' + shipment.shipping_fee_blob.calculated.shipping_fee_range.max.toFixed(2)
            : '  $' + shipment.shipping_fee.toFixed(2)}
        </p>
      </>
    );
  };

  const DeliveryStatus = ({ shipment }) => {
    return (
      <p style={{ marginBottom: '15px' }}>
        {shipment.status === 'shipment_ready_for_driver'
          ? 'Ready for Driver'
          : shipment.status === 'shipment_assigned'
          ? 'Assigned to Driver'
          : shipment.status === 'shipment_enroute'
          ? 'En Route'
          : shipment.status === 'shipment_completed'
          ? 'Completed✅'
          : shipment.status === 'shipment_scheduled'
          ? 'Scheduled'
          : null}
      </p>
    );
  };

  const BasicInfo = ({ order }) => {
    return (
      <>
        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        {order.location_blob.business_name && <p> Business Name: {order.location_blob.business_name}</p>}
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
      </>
    );
  };

  const BasicInfoDirectPick = ({ shipment }) => {
    let order;
    shipment.Orders.map(od => {
      if (od.actions.hasOwnProperty('pick_up')) {
        order = od;
      }
    });
    return (
      <>
        <div>
          <strong>Pickup Information</strong>
          <Button
            size="sm"
            className="btn btn-info"
            style={{ padding: `5px 10px`, display: `inline-block`, marginLeft: '10px' }}
            onClick={() => {
              setOrderId(order.order_id);
              setOrderType('Pickup');
              setShowUpdateOrderInfo(true);
            }}
          >
            Update
          </Button>
        </div>

        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        <p>{order.location_blob.email}</p>
        {order.location_blob.business_name && <p> Business Name: {order.location_blob.business_name}</p>}
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
        {order.status === 'order_completed' && <p>{formatTimestamp(order.completed_at) + ' ✅'}</p>}
      </>
    );
  };

  const BasicInfoDirectDrop = ({ shipment }) => {
    let order;
    shipment.Orders.map(od => {
      if (od.actions.hasOwnProperty('drop_off')) {
        order = od;
      }
    });
    return (
      <>
        <div>
          <strong>Dropoff Information</strong>
          <Button
            size="sm"
            className="btn btn-info"
            style={{ padding: `5px 10px`, display: `inline-block`, marginLeft: '10px' }}
            onClick={() => {
              setOrderId(order.order_id);
              setOrderType('Dropoff');
              setShowUpdateOrderInfo(true);
            }}
          >
            Update
          </Button>
        </div>

        <p>{order.location_blob.name}</p>
        <p>{order.location_blob.phone}</p>
        <p>{order.location_blob.email}</p>
        {order.location_blob.business_name && <p> Business Name: {order.location_blob.business_name}</p>}
        <p>{order.location_blob.address_1}</p>
        <p>
          {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
        </p>
        {order.status === 'order_completed' && <p>{formatTimestamp(order.completed_at) + ' ✅'}</p>}
      </>
    );
  };

  const BasicInfoMultidropWaypoint = ({ orders, display }) => {
    let pickOrder;
    let finalDropOrder;
    let middleDropOrders = [];
    orders.map(order => {
      if (order.actions.hasOwnProperty('pick_up')) {
        if (order.actions['pick_up'][0].includes('drop')) {
          middleDropOrders.push(order);
        } else {
          pickOrder = order;
        }
      } else if (order.actions.hasOwnProperty('drop_off')) {
        finalDropOrder = order;
      }
    });
    return (
      <>
        <Col className="col-2.5">
          <strong>Pickup Info</strong>
          <BasicInfo order={pickOrder} />
          <RoutedDirectItemList order={pickOrder} />
          <PickOrDropInstructions order={pickOrder} />
          {(pickOrder.status === 'driver_assigned' || pickOrder.status === 'driver_claimed' || pickOrder.status === 'order_completed') && display && (
            <p>
              <strong>Status:</strong> {pickOrder.status === 'order_completed' ? formatTimestamp(pickOrder.completed_at) + '✅' : 'approaching'}
            </p>
          )}
          {(pickOrder.status === 'driver_assigned' || pickOrder.status === 'driver_claimed') && (
            <div>
              <ETAUpdate handleOrderETAUpdate={handleOrderETAUpdate} order={pickOrder} />
              <CompleteOrderButton shipmentId={pickOrder.shipment_id} orderId={pickOrder.order_id} notify={props.notify} />{' '}
            </div>
          )}
        </Col>
        {middleDropOrders.map(order => {
          return (
            <Col className="col-2.5">
              <strong>Dropoff Info</strong>
              <BasicInfo order={order} />
              <RoutedDirectItemListTwist order={order} /> <PickOrDropInstructionsTwist order={order} />
              {(order.status === 'driver_assigned' || order.status === 'driver_claimed' || order.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={pickOrder} currentOrder={order} />
              )}
              {(order.status === 'driver_assigned' || order.status === 'driver_claimed') && display && (
                <CompleteOrderButton shipmentId={order.shipment_id} orderId={order.order_id} notify={props.notify} />
              )}
            </Col>
          );
        })}
        <Col className="col-2.5">
          <strong>Dropoff Info</strong>
          <BasicInfo order={finalDropOrder} />
          <RoutedDirectItemList order={finalDropOrder} />
          <PickOrDropInstructions order={finalDropOrder} />
          {(finalDropOrder.status === 'driver_assigned' || finalDropOrder.status === 'driver_claimed' || finalDropOrder.status === 'order_completed') && display && (
            <WaypointStatus pickOrder={pickOrder} currentOrder={finalDropOrder} />
          )}
        </Col>
      </>
    );
  };

  const BasicInfoRoutedWaypoint = ({ orders, display }) => {
    let pickOrders = [];
    let firstPickOrder;
    let middlePickOrder;
    let middleDropOrder;
    let finalDropOrder;
    let hasMiddleDrop = false;

    orders.map(order => {
      if (order.actions.hasOwnProperty('drop_off')) {
        finalDropOrder = order;
      } else if (order.actions.hasOwnProperty('pick_up')) {
        if (order.actions['pick_up'][0].includes('drop')) {
          middleDropOrder = order;
          hasMiddleDrop = true;
        } else {
          pickOrders.push(order);
        }
      }
    });

    if (orders.length === 4) {
      pickOrders.map(po => {
        if (po.location_blob.address === middleDropOrder.location_blob.address) {
          middlePickOrder = po;
        } else {
          firstPickOrder = po;
        }
      });
    } else if (orders.length === 3) {
      if (hasMiddleDrop) {
        firstPickOrder = pickOrders[0];
      } else {
        firstPickOrder = pickOrders[0];
        middlePickOrder = pickOrders[1];
      }
    }

    return (
      <>
        <Col className="col-2.5">
          <strong>Pickup Info</strong>
          <BasicInfo order={firstPickOrder} />
          <RoutedDirectItemList order={firstPickOrder} />
          <PickOrDropInstructions order={firstPickOrder} />
          {(firstPickOrder.status === 'driver_assigned' || firstPickOrder.status === 'driver_claimed' || firstPickOrder.status === 'order_completed') && display && (
            <p>
              <strong>Status:</strong> {firstPickOrder.status === 'order_completed' ? formatTimestamp(firstPickOrder.completed_at) + '✅' : 'approaching'}
            </p>
          )}
          {(firstPickOrder.status === 'driver_assigned' || firstPickOrder.status === 'driver_claimed') && (
            <div>
              <ETAUpdate handleOrderETAUpdate={handleOrderETAUpdate} order={firstPickOrder} />
              <CompleteOrderButton shipmentId={firstPickOrder.shipment_id} orderId={firstPickOrder.order_id} notify={props.notify} />
            </div>
          )}
        </Col>
        <Col className="col-2.5">
          <strong>Pickup/Dropoff Info</strong>
          <BasicInfo order={middlePickOrder ? middlePickOrder : middleDropOrder} />
          {orders.length === 3 ? (
            <>
              {!hasMiddleDrop ? (
                <>
                  <RoutedDirectItemList order={middlePickOrder} />
                  <PickOrDropInstructionsTwist order={middlePickOrder} />
                  {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed' || middlePickOrder.status === 'order_completed') && display && (
                    <WaypointStatus pickOrder={firstPickOrder} currentOrder={middlePickOrder} />
                  )}
                  {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed') && display && (
                    <CompleteOrderButton shipmentId={middlePickOrder.shipment_id} orderId={middlePickOrder.order_id} notify={props.notify} />
                  )}
                </>
              ) : (
                <>
                  <RoutedDirectItemListTwist order={middleDropOrder} />
                  <PickOrDropInstructionsTwist order={middleDropOrder} />
                  {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed' || middleDropOrder.status === 'order_completed') && display && (
                    <WaypointStatus pickOrder={firstPickOrder} currentOrder={middleDropOrder} />
                  )}
                  {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed') && display && (
                    <CompleteOrderButton shipmentId={middleDropOrder.shipment_id} orderId={middleDropOrder.order_id} notify={props.notify} />
                  )}
                </>
              )}
            </>
          ) : (
            <div>
              <RoutedDirectItemList order={middlePickOrder} />
              <PickOrDropInstructions order={middlePickOrder} />
              {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed' || middlePickOrder.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={firstPickOrder} currentOrder={middlePickOrder} />
              )}
              {(middlePickOrder.status === 'driver_assigned' || middlePickOrder.status === 'driver_claimed') && display && (
                <CompleteOrderButton shipmentId={middlePickOrder.shipment_id} orderId={middlePickOrder.order_id} notify={props.notify} />
              )}
              <RoutedDirectItemListTwist order={middleDropOrder} />
              <PickOrDropInstructionsTwist order={middleDropOrder} />

              {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed' || middleDropOrder.status === 'order_completed') && display && (
                <WaypointStatus pickOrder={firstPickOrder} currentOrder={middleDropOrder} />
              )}
              {(middleDropOrder.status === 'driver_assigned' || middleDropOrder.status === 'driver_claimed') && display && (
                <CompleteOrderButton shipmentId={middleDropOrder.shipment_id} orderId={middleDropOrder.order_id} notify={props.notify} />
              )}
            </div>
          )}
        </Col>
        <Col className="col-2.5">
          <strong>Dropoff Info</strong>
          <BasicInfo order={finalDropOrder} />
          <RoutedDirectItemList order={finalDropOrder} />
          <PickOrDropInstructions order={finalDropOrder} />
          {(finalDropOrder.status === 'driver_assigned' || finalDropOrder.status === 'driver_claimed' || finalDropOrder.status === 'order_completed') && display && (
            <WaypointStatus pickOrder={firstPickOrder} currentOrder={finalDropOrder} />
          )}
        </Col>
      </>
    );
  };

  const RoutedDirectItemList = ({ order }) => {
    return order.actions.hasOwnProperty('pick_up')
      ? order.actions['pick_up'].map((item, index) => {
          return index === 0 ? <p>Pick: {item}</p> : <p style={{ textIndent: '30px' }}>{item}</p>;
        })
      : order.actions['drop_off'].map((item, index) => {
          return index === 0 ? <p>Drop: {item}</p> : <p style={{ textIndent: '35px' }}>{item}</p>;
        });
  };

  const RoutedDirectItemListTwist = ({ order }) => {
    return order.actions['pick_up'].map((item, index) => {
      if (item.includes('(drop)')) {
        return index === 0 ? <p>Drop: {item.replace('(drop)', '')}</p> : <p style={{ textIndent: '30px' }}>{item.replace('(drop)', '')}</p>;
      } else {
        return index === 0 ? <p>Pick: {item}</p> : <p style={{ textIndent: '30px' }}>{item}</p>;
      }
    });
  };

  const PickOrDropInstructions = ({ order }) => {
    if (order.actions.hasOwnProperty('pick_up')) {
      return order.pick_up_instructions ? (
        <>
          <p style={{ textIndent: '10px' }}> Instructions: </p>
          <p style={{ textIndent: '30px' }}>{order.pick_up_instructions}</p>
        </>
      ) : null;
    } else if (order.actions.hasOwnProperty('drop_off')) {
      return order.drop_off_instructions ? (
        <>
          <p style={{ textIndent: '10px' }}> Instructions: </p>
          <p style={{ textIndent: '30px' }}>{order.drop_off_instructions}</p>
        </>
      ) : null;
    }
  };

  const PickOrDropInstructionsTwist = ({ order }) => {
    let isDrop = false;
    order.actions['pick_up'].map(item => {
      if (item.includes('(drop)')) {
        isDrop = true;
      }
    });
    return isDrop ? (
      <>
        {order.drop_off_instructions ? (
          <>
            <p style={{ textIndent: '10px' }}> Instructions: </p>
            <p style={{ textIndent: '30px' }}>{order.drop_off_instructions}</p>
          </>
        ) : null}
      </>
    ) : (
      <>
        {order.pick_up_instructions ? (
          <>
            <p style={{ textIndent: '10px' }}> Instructions: </p>
            <p style={{ textIndent: '30px' }}>{order.pick_up_instructions}</p>
          </>
        ) : null}
      </>
    );
  };

  const WaypointStatus = ({ pickOrder, currentOrder }) => {
    return (
      <>
        <p>
          <strong>Status:</strong>
          {pickOrder.status === 'order_completed' && currentOrder.status === 'order_completed'
            ? formatTimestamp(currentOrder.completed_at) + '✅'
            : pickOrder.status === 'order_completed' && currentOrder.status !== 'order_completed'
            ? ' delivering'
            : ' waiting'}
        </p>
        {(currentOrder.status === 'driver_assigned' || currentOrder.status === 'driver_claimed') && <ETAUpdate handleOrderETAUpdate={handleOrderETAUpdate} order={currentOrder} />}
      </>
    );
  };

  const RegularPickAndDropInstructions = ({ shipment }) => {
    return (
      <>
        <Col style={{ textAlign: 'left' }}>
          <strong>Pickup Instructions</strong>
          <p>{shipment.pick_up_instructions}</p>
        </Col>

        <Col style={{ textAlign: 'left' }}>
          <strong>Dropoff Instructions</strong>
          <p>{shipment.drop_off_instructions}</p>
        </Col>
      </>
    );
  };

  const ShipmentType = ({ shipment, type }) => {
    return (
      <strong style={{ color: '#cdcaca' }}>
        Type: {type} -- Ship_ID : {shipment.shipment_friendly_id}
        {shipment.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>Expedited Delivery</p> : null}
      </strong>
    );
  };

  const DriverPay = ({ shipment, driver, driverStatus }) => {
    return (
      <>
        <Row>
          {driver && (
            <p style={{ margin: 10 }}>
              {driver.driver_blob.first_name} {driver.driver_blob.last_name} {driverStatus === 'driver' ? '🚚' : driverStatus === 'passenger' ? '🏋️‍♀️' : null}
            </p>
          )}
          <Button
            size="sm"
            className="btn btn-warning"
            style={{ padding: `5px 10px`, display: `flex`, flexDirection: `column` }}
            onClick={() => {
              fetchDriverPay(shipment.shipment_id, driver.driver_id, driverStatus);
              setShowDriverPay(true);
            }}
          >
            Pay
          </Button>
          {driverStatus === 'driver' && driver && (
            <Button
              size="sm"
              className="btn btn-info"
              style={{ padding: `5px 10px`, display: `flex`, flexDirection: `column` }}
              onClick={() => {
                setRouteDriverRouteId(shipment.route_id);
                setShowDriverInfoAndPO(true);
              }}
            >
              Info&PO#
            </Button>
          )}
        </Row>
        {driver && driverStatus === 'driver' && driver.tracking_blob && driver.tracking_blob.hasOwnProperty('po_number') && (
          <p style={{ margin: 5 }}> PO#: {driver.tracking_blob.po_number}</p>
        )}
        {driver && <p style={{ margin: 5 }}>{driver.driver_blob.phone}</p>}
        {driverStatus === 'driver' && driver && <p style={{ margin: 5 }}>{driver.employment_type}</p>}
        {driverStatus === 'driver' && driver && (
          <p style={{ margin: 5 }}>{driver.vehicle_blob.year + ' ' + driver.vehicle_blob.make + ' ' + driver.vehicle_blob.model + ' (' + driver.vehicle_blob.color + ')'}</p>
        )}
        {driverStatus === 'driver' && enrouteClickDriver && <p style={{ margin: 5 }}>Permit: {enrouteClickDriver.User.permit ? enrouteClickDriver.User.permit : 'N/A'}</p>}
      </>
    );
  };

  const DriverCounts = ({ count }) => {
    return (
      <strong>
        {count > 1 ? 'Drivers' : 'Driver'}({count})
      </strong>
    );
  };

  const UpdateShippingFee = ({ shipment }) => {
    return (
      <Col className="col-2" style={{ textAlign: 'left' }}>
        <Button
          className="btn btn-success"
          style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
          onClick={() => {
            setShipment(shipment);
            setShowUpdateShippingFee(true);
          }}
        >
          Shipping Fee
        </Button>
      </Col>
    );
  };

  const UpdateDriverPayBeforeClaim = ({ shipment }) => {
    return (
      <Col className="col-2" style={{ textAlign: 'left' }}>
        <Button
          size="sm"
          className="btn btn-warning"
          style={{ padding: `5px 10px`, display: `flex`, flexDirection: `column` }}
          onClick={() => {
            setShipment(shipment);
            setShowUpdateDriverPayBeforeClaim(true);
          }}
        >
          Driver Pay
        </Button>
      </Col>
    );
  };

  const SchoolLunchInfo = ({ order, shipment }) => {
    return (
      <>
        <div style={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '20px', marginRight: '20px', textAlign: 'left' }}>
          <Row>
            <Col className="col-1.5">
              <strong>Dining Period</strong>
              <p>{order.actions.pick_up[0].slice(15).slice(0, -3)}</p>
            </Col>

            <Col className="col-3">
              <strong>Quantity & Meal Type</strong>
              {order.actions.pick_up.map((meal, index) => {
                if (index > 0) {
                  return <p>{meal}</p>;
                }
              })}
            </Col>

            <Col className="col-1.5">
              <strong>Total Price</strong>
              <p>${order.total.toFixed(2)}</p>
            </Col>
            <Col className="col-3">
              <strong>Pickup Instructions</strong>
              <p>{shipment.pick_up_instructions}</p>
            </Col>
            <Col className="col-3">
              <strong>Dropoff Instructions</strong>
              <p>{shipment.drop_off_instructions}</p>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const QuoteShippingFeeButton = ({ shipment }) => {
    return (
      <>
        <Row>
          <ButtonGroup>
            {shipment.shipping_fee === 0 ? (
              <Button style={{ width: `100%`, textAlign: 'center' }} color="info" name="waitingApproval" className={'btn btn-simple'} disabled>
                Waiting Quote
              </Button>
            ) : (
              <Button style={{ width: `100%`, textAlign: 'center' }} color="success" name="quoteApproved" className={'btn btn-simple'} disabled>
                Quote Submitted
              </Button>
            )}
          </ButtonGroup>
        </Row>
        <Row>
          <Button
            className={shipment.shipping_fee > 0 ? 'btn btn-warning' : 'btn btn-info'}
            style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
            onClick={() => {
              setShipment(shipment);
              setShowUpdateShippingFee(true);
            }}
          >
            {shipment.shipping_fee > 0 ? 'Update Quote' : 'Input Quote'}
          </Button>
        </Row>
      </>
    );
  };

  // reorder function
  const handleReorder = (routeId, path) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/' + routeId + '/reorder-waypoints',
      data: { path: path }
    })
      .then(response => {
        props.notify('tc', 'Routes Successfully Reordered');
        toggleReorderWaypoints();
      })
      .catch(error => {
        if (error.response) {
          console.log('error-response: ', error.response);
          setIsReorderError(true);
          setReorderError(error.response.data.message);
        } else {
          console.log('error: ', error);
        }
      });
  };

  // route builder functions
  const handleMergingCheckbox = position => {
    const userAuth0Id = mergingRoutes[position].updated_by;
    if (userAuth0Id === SUB) {
      const updatedCheckedState = mergingCheckState.map((item, index) => (index === position ? !item : item));
      setMergingCheckedState(updatedCheckedState);

      const allChecked = updatedCheckedState.filter((_, index) => mergingRoutes[index].updated_by === SUB).every(item => item);
      setMergingCheckStateAllChecked(allChecked);
    }
  };

  const handleSelectAllMergingCheckbox = () => {
    const allChecked = !mergingCheckStateAllChecked;
    const updatedCheckedState = mergingCheckState.map((item, index) => {
      const userAuth0Id = mergingRoutes[index].updated_by;
      return userAuth0Id === SUB ? allChecked : item;
    });
    setMergingCheckedState(updatedCheckedState);
    setMergingCheckStateAllChecked(allChecked);
  };

  const currentUserHasMergingRecords = mergingRoutes.some(item => item.updated_by === SUB);

  useEffect(() => {
    let selectedIdArray = [];
    let selectedRoutesArray = [];
    let waypointsCount = 0;
    let selectedNumber = 0;
    mergingCheckState.map((state, index) => {
      if (state === true) {
        selectedIdArray.push(mergingRoutes[index].route_id);
        selectedRoutesArray.push(mergingRoutes[index]);
        waypointsCount += mergingRoutes[index].waypoints_number;
        selectedNumber++;
      }
    });
    setMergeRoutesList(selectedIdArray);
    setMergeRouteWaypointsSelected(waypointsCount);
    setSelectedMergingRoutes(selectedRoutesArray);
    if (selectedNumber > 0) {
      setShowMergingRoutesMap(true);
    } else {
      setShowMergingRoutesMap(false);
    }
  }, [mergingCheckState]);

  useEffect(() => {
    if (mergeRoutesList.length >= 2) {
      setIsRouteBuilderAccessible(true);
    } else {
      setIsRouteBuilderAccessible(false);
    }
  }, [mergeRoutesList]);

  useEffect(() => {
    if (mergeRouteWaypointsSelected >= mergeRouteWaypointsWarning) {
      setTimeout(() => {
        alert(
          `Warning: You have selected ${mergeRouteWaypointsSelected}, which has reached the route waypoints limit ${mergeRouteWaypointsWarning}, but you can continue add and merge`
        );
      }, 500);
    }
  }, [mergeRouteWaypointsSelected]);

  const buildRoutes = mergeRoutesList => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/route-builder/merge',
      data: { route_ids: mergeRoutesList }
    })
      .then(response => {
        props.notify('tc', 'Routes Successfully Merged');
        setTimeout(() => {
          alert(`The Route_ID of new generated route is ${response.data.payload.route_friendly_id}`);
        }, 1000);
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const tempClaimRoutes = routeId => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/temp_claim/' + routeId + '/dispatch'
    })
      .then(response => {})
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const CancelTempClaimRoutes = routeId => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/cancel_temp_claim/' + routeId + '/dispatch'
    })
      .then(response => {})
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('error: ', error);
        }
      });
  };

  const toggleDriverMessageForm = () => {
    setShowDriverMessageForm(!showDriverMessageForm);
  };

  const toggleGasPrice = () => {
    setShowGasPrice(!showGasPrice);
  };

  const toggleGasPriceRegion = () => {
    setShowGasPriceRegion(!showGasPriceRegion);
  };

  const toggleMarketNote = () => {
    setShowMarketNote(!showMarketNote);
  };

  const toggleMarketNoteRegion = () => {
    setShowMarketNoteRegion(!showMarketNoteRegion);
  };

  const toggleReorderWaypoints = () => {
    setShowReorderWaypoints(!showReorderWaypoints);
    if (showReorderWaypoints) {
      setIsReorderError(false);
    }
  };

  const toggleShippingFee = () => {
    setShowUpdateShippingFee(!showUpdateShippingFee);
  };

  const toggleSetupFee = () => {
    setShowChargeSetupFee(!showChargeSetupFee);
  };

  const toggleDriverPay = () => {
    setShowDriverPay(!showDriverPay);
  };

  const toggleDriverInfoAndPO = () => {
    setShowDriverInfoAndPO(!showDriverInfoAndPO);
  };
  const toggleShowUpdateOrderInfo = () => {
    setShowUpdateOrderInfo(!showUpdateOrderInfo);
  };

  const toggleUpdateDriverPayBeforeClaim = () => {
    setShowUpdateDriverPayBeforeClaim(!showUpdateDriverPayBeforeClaim);
  };

  const resetShippingFee = () => {
    setShippingFee('');
  };

  const resetSetupFee = () => {
    setSetupDuration('');
  };

  const resetDriverBonus = () => {
    setDriverBonus('');
  };

  const resetBonusReason = () => {
    setBonusReason('');
  };

  const resetShippingFeeReason = () => {
    setShippingFeeReason('');
  };

  const resetRouteDriverInfoAndPO = () => {
    setRouteDriverFirstName('');
    setRouteDriverLastName('');
    setRouteDriverPhone('');
    setRouteDriverPONumber('');
    setRouteDriverRouteId('');
  };

  // useEffects
  useEffect(() => {
    if (error) {
      if (error.message === 'cannot start as connection_init failed with : Could not verify JWT: JWTExpired') {
        props.history.push('/');
      }
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      if (error.message === 'cannot start as connection_init failed with : Could not verify JWT: JWTExpired') {
        props.history.push('/');
      }
    }
  }, [error]);

  useEffect(() => {
    if (shipmentsData && shipmentsData.Shipments) {
      var shownShipments = [];
      shipmentsData.Shipments.forEach(element => {
        if (filter.includes(element.status)) {
          shownShipments.push(element);
        }
      });
      // Sort shipments if `selected` is 'saved'
      if (selected === 'saved') {
        shownShipments.sort((a, b) => {
          return new Date(a.scheduled_for) - new Date(b.scheduled_for); // Descending order by completed_at
        });
      }
      setShipmentsToDisplay(shownShipments);
    }
  }, [filter]);

  useEffect(() => {
    if (routesData && routesData.Routes) {
      let shownRoutes = [];

      routesData.Routes.forEach(element => {
        if (filterRoutes.includes(element.status)) {
          shownRoutes.push(element);
        }
      });

      // Sort routes if `selected` is 'completed'
      if (selected === 'completed') {
        shownRoutes.sort((a, b) => {
          return new Date(b.completed_at) - new Date(a.completed_at); // Descending order by completed_at
        });
      }

      setRoutesToDisplay(shownRoutes);
    }
  }, [filterRoutes, routesData, selected]); // Make sure to include `selected` as a dependency

  // Dispatch Map Filters
  useEffect(() => {
    if (!routesReadyCheck) {
      setReadyRoutes([]);
    } else {
      if (routesData) {
        let allDeliveringRoutes = [];
        routesData.Routes.map(route => {
          if (route.status === 'route_ready' || route.status === 'route_temp_claimed' || route.status === 'route_temp_claimed_dispatch') {
            allDeliveringRoutes.push(route);
          }
        });
        setReadyRoutes(allDeliveringRoutes);
        if (allDeliveringRoutes.length > 0) {
          if (routesExpeditedCheck && !routesAlcoholCheck) {
            let expeditedReadyRoutes = [];
            allDeliveringRoutes.map(route => {
              if (route.is_expedited) {
                expeditedReadyRoutes.push(route);
              }
            });
            setReadyRoutes(expeditedReadyRoutes);
          }
          if (routesAlcoholCheck && !routesExpeditedCheck) {
            let alcoholReadyRoutes = [];
            allDeliveringRoutes.map(route => {
              if (route.contains_alcohol) {
                alcoholReadyRoutes.push(route);
              }
            });
            setReadyRoutes(alcoholReadyRoutes);
          }
          if (routesAlcoholCheck && routesExpeditedCheck) {
            let expeditedAlcoholReadyRoutes = [];
            allDeliveringRoutes.map(route => {
              if (route.contains_alcohol && route.is_expedited) {
                expeditedAlcoholReadyRoutes.push(route);
              }
            });
            setReadyRoutes(expeditedAlcoholReadyRoutes);
          }
        } else {
          setReadyRoutes([]);
        }
      } else {
        setReadyRoutes([]);
      }
    }
  }, [routesData, routesReadyCheck, routesExpeditedCheck, routesAlcoholCheck]);

  useEffect(() => {
    if (!routesDeliveringCheck) {
      setDeliveringRoutes([]);
    } else {
      if (routesData) {
        let allReadyRoutes = [];
        routesData.Routes.map(route => {
          if (route.status === 'route_claimed' || route.status === 'route_assigned') {
            allReadyRoutes.push(route);
          }
        });
        setDeliveringRoutes(allReadyRoutes);
        if (allReadyRoutes.length > 0) {
          if (routesExpeditedCheck && !routesAlcoholCheck) {
            let expeditedReadyRoutes = [];
            allReadyRoutes.map(route => {
              if (route.is_expedited) {
                expeditedReadyRoutes.push(route);
              }
            });
            setDeliveringRoutes(expeditedReadyRoutes);
          }
          if (routesAlcoholCheck && !routesExpeditedCheck) {
            let alcoholReadyRoutes = [];
            allReadyRoutes.map(route => {
              if (route.contains_alcohol) {
                alcoholReadyRoutes.push(route);
              }
            });
            setDeliveringRoutes(alcoholReadyRoutes);
          }
          if (routesAlcoholCheck && routesExpeditedCheck) {
            let expeditedAlcoholReadyRoutes = [];
            allReadyRoutes.map(route => {
              if (route.contains_alcohol && route.is_expedited) {
                expeditedAlcoholReadyRoutes.push(route);
              }
            });
            setDeliveringRoutes(expeditedAlcoholReadyRoutes);
          }
        } else {
          setDeliveringRoutes([]);
        }
      } else {
        setDeliveringRoutes([]);
      }
    }
  }, [routesData, routesDeliveringCheck, routesExpeditedCheck, routesAlcoholCheck]);

  useEffect(() => {
    if (!driversAvailableCheck) {
      setAvailableDrivers([]);
    } else {
      fetchAllDrivers();

      if (availableDriversCopy) {
        let alcoholVehicleDrivers = [];
        let alcoholDrivers = [];
        let vehicleDrivers = [];
        let vehicles = [];
        if (driversCarCheck) {
          vehicles.push('sedan');
        }
        if (driversSUVCheck) {
          vehicles.push('suv');
        }
        if (driversTruckCheck) {
          vehicles.push('pickup_truck');
        }
        if (driversTrailerCheck) {
          vehicles.push('truck');
        }

        if (vehicles.length > 0 && driversAlcoholCheck) {
          availableDriversCopy.map(driver => {
            if (driver.can_deliver_alcohol) {
              for (let vehicle of vehicles) {
                if (driver.Vehicles[0].type == vehicle) {
                  alcoholVehicleDrivers.push(driver);
                  break;
                }
              }
            }
          });
          setAvailableDrivers(alcoholVehicleDrivers);
        }
        if (vehicles.length > 0 && !driversAlcoholCheck) {
          availableDriversCopy.map(driver => {
            for (let vehicle of vehicles) {
              if (driver.Vehicles[0].type == vehicle) {
                vehicleDrivers.push(driver);
                break;
              }
            }
          });
          setAvailableDrivers(vehicleDrivers);
        }
        if (vehicles.length === 0 && driversAlcoholCheck) {
          availableDriversCopy.map(driver => {
            if (driver.can_deliver_alcohol) {
              alcoholDrivers.push(driver);
            }
          });
          setAvailableDrivers(alcoholDrivers);
        }
        if (vehicles.length === 0 && !driversAlcoholCheck) {
          setAvailableDrivers(availableDriversCopy);
        }
      } else {
        setAvailableDrivers([]);
      }
    }
  }, [driversAvailableCheck, driversAlcoholCheck, driversCarCheck, driversSUVCheck, driversTruckCheck, driversTrailerCheck]);

  useEffect(() => {
    if (!driversEnrouteCheck) {
      setEnrouteDrivers([]);
    } else {
      fetchAllDrivers();

      if (enrouteDriversCopy) {
        let alcoholVehicleDrivers = [];
        let alcoholDrivers = [];
        let vehicleDrivers = [];
        let vehicles = [];
        if (driversCarCheck) {
          vehicles.push('sedan');
        }
        if (driversSUVCheck) {
          vehicles.push('suv');
        }
        if (driversTruckCheck) {
          vehicles.push('pickup_truck');
        }
        if (driversTrailerCheck) {
          vehicles.push('truck');
        }

        if (vehicles.length > 0 && driversAlcoholCheck) {
          enrouteDriversCopy.map(driver => {
            if (driver.can_deliver_alcohol) {
              for (let vehicle of vehicles) {
                if (driver.Vehicles[0].type == vehicle) {
                  alcoholVehicleDrivers.push(driver);
                  break;
                }
              }
            }
          });
          setEnrouteDrivers(alcoholVehicleDrivers);
        }
        if (vehicles.length > 0 && !driversAlcoholCheck) {
          enrouteDriversCopy.map(driver => {
            for (let vehicle of vehicles) {
              if (driver.Vehicles[0].type == vehicle) {
                vehicleDrivers.push(driver);
                break;
              }
            }
          });
          setEnrouteDrivers(vehicleDrivers);
        }
        if (vehicles.length === 0 && driversAlcoholCheck) {
          enrouteDriversCopy.map(driver => {
            if (driver.can_deliver_alcohol) {
              alcoholDrivers.push(driver);
            }
          });
          setEnrouteDrivers(alcoholDrivers);
        }
        if (vehicles.length === 0 && !driversAlcoholCheck) {
          setEnrouteDrivers(enrouteDriversCopy);
        }
      } else {
        setEnrouteDrivers([]);
      }
    }
  }, [driversEnrouteCheck, driversAlcoholCheck, driversCarCheck, driversSUVCheck, driversTruckCheck, driversTrailerCheck]);

  // Functions
  const reset = () => {
    setPrimaryDriver('');
    setDriverSelected(false);
    setPassenger1('');
    setPassenger1Selected(false);
    setPassenger2('');
    setPassenger2Selected(false);
  };

  const resetReassign = () => {
    setDriverReassign('');
    setDriverReassignSelected(false);
  };

  const resetAssignPassengerOnly = () => {
    setPassenger1OnlyAssigned('');
    setPassenger1OnlyAssignedSelected(false);
    setPassenger2OnlyAssigned('');
    setPassenger2OnlyAssignedSelected(false);
  };

  const makeAlmostReady = order_friendly_id => {
    if (almostReady.includes(order_friendly_id)) {
      return <FaExclamationTriangle size="30" color="#fcb23a" />;
    } else {
      return <FaExclamationTriangle size="30" color="#fcb23a" />;
    }
  };

  const makeReady = order_friendly_id => {
    if (ready.includes(order_friendly_id)) {
      return <FaExclamationCircle size="30" color="#be1e2d" />;
    } else {
      return <FaExclamationCircle size="30" color="#be1e2d" />;
    }
  };

  const resetExpand = () => {
    expandedItems[selected] = {};
    props.expand(expandedItems);
  };

  async function handleRowClick(event, rowInfo, cell, element) {
    // Set currentRecordId using the appropriate ID field based on the selected tab
    if (selected === 'ready' || selected === 'progressing' || selected === 'completed') {
      setCurrentRecordId(event.original.route_id);
      setCurrentRecord(event.original);
    } else if (selected === 'saved' || selected === 'pending') {
      setCurrentRecordId(event.original.shipment_id);
      setCurrentRecord(event.original);
    }
    if (selected === 'ready') {
      if (
        element.target.toString().split(' ')[1] !== 'HTMLDivElement]' &&
        element.target.toString().split(' ')[1] !== 'SVGPathElement]' &&
        element.target.toString().split(' ')[1] !== 'SVGSVGElement]'
      ) {
        if (element.target.className.split(' ')[0] === 'btn' || element.target.className.split(' ')[0] === 'btn-simple') {
          return;
        }
      }
      if (expandedItems.ready[event.viewIndex]) {
        expandedItems.ready[event.viewIndex] = !expandedItems.ready[event.viewIndex];
        setRouteClick('');
        setRouteClickTwice(event.original);
      } else {
        expandedItems.ready[event.viewIndex] = true;
        setRouteClick(event.original);
        setRouteClickTwice(event.original);
      }
    } else if (selected === 'completed') {
      if (expandedItems.completed[event.viewIndex]) {
        expandedItems.completed[event.viewIndex] = !expandedItems.completed[event.viewIndex];
        setRouteClick('');
        setRouteClickTwice(event.original);
      } else {
        expandedItems.completed[event.viewIndex] = true;
        setRouteClick(event.original);
        setRouteClickTwice(event.original);
      }
    } else if (selected === 'saved') {
      if (expandedItems.saved[event.viewIndex]) {
        expandedItems.saved[event.viewIndex] = !expandedItems.saved[event.viewIndex];
        //! convert shipment to route click
        setRouteClick('');
        setRouteClickTwice(event.original.Route);
      } else {
        expandedItems.saved[event.viewIndex] = true;
        setRouteClick(event.original.Route);
        setRouteClickTwice(event.original.Route);
      }
    } else if (selected === 'progressing') {
      if (expandedItems.progressing[event.viewIndex]) {
        expandedItems.progressing[event.viewIndex] = !expandedItems.progressing[event.viewIndex];
        setRouteClick('');
        setRouteClickTwice(event.original);
      } else {
        expandedItems.progressing[event.viewIndex] = true;
        await updateTQLTracking(event.original);
        setRouteClick(event.original);
        fetchEnrouteAndDriver(event.original);
        setRouteClickTwice(event.original);
      }
    } else if (selected === 'pending') {
      if (expandedItems.pending[event.viewIndex]) {
        expandedItems.pending[event.viewIndex] = !expandedItems.pending[event.viewIndex];
      } else {
        expandedItems.pending[event.viewIndex] = true;
      }
    } else if (selected === 'driverList') {
      expandedItems.driving[event.viewIndex] = !expandedItems.driving[event.viewIndex];
    } else if (selected === 'merging') {
      expandedItems.merging[event.viewIndex] = !expandedItems.merging[event.viewIndex];
    }

    props.expand(expandedItems);
  }

  // function fetchAllDriversOld() {
  //   setLoading(true);
  //   axios({
  //     method: 'post',
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.getItem('access_token'),
  //       'content-type': 'application/json'
  //     },
  //     url: user_urls.hasuraHost,
  //     data: {
  //       query:
  //         `{Drivers(where: {default_market_id: {_eq: "` +
  //         marketId +
  //         `"}, is_online: {_eq: true}}) { driver_id is_available active_vehicle auth0_id updatedAt updated_by user_id carrying_capacity employment_type can_deliver_alcohol User { auth0_id username firstname lastname email phone } Vehicles{vehicle_id type make model color year plate_state} LastDriverActions { market_id timestamp location_blob
  //         }}}`
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.errors) {
  //         if (response.data.errors.length > 0) {
  //           if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
  //             this.props.history.push('/logout');
  //           }
  //         }
  //       }
  //       if (response.data.data) {
  //         setDrivers(response.data.data.Drivers);
  //         // console.log('driversold ', drivers);
  //         setLoading(false);
  //       } else {
  //         console.log('error: ', response);
  //       }
  //     })
  //     .catch(error => {});
  // }

  function fetchAllDriversForAssign() {
    const marketIdArrayString = marketIdArray.map(id => `"${id}"`).join(',');
    setLoading(true);
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.hasuraHost,

      data: {
        query:
          `{Drivers(order_by: [{ is_online: desc} , {is_available: desc}, {User: {firstname: asc}}, {User: {lastname: asc}}] where: {default_market_id: {_in:[` +
          marketIdArrayString +
          `]}}) { driver_id is_online is_available active_vehicle auth0_id updatedAt updated_by user_id carrying_capacity employment_type can_deliver_alcohol default_market_id restricted_area_credentials unread_message_count User { auth0_id username firstname lastname email phone } Vehicles{vehicle_id type make model color year plate_state} LastDriverActions { market_id timestamp location_blob
          }}}`
      }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setDriversForAssign(response.data.data.Drivers);
          // console.log('drivers-for-assign ', driversForAssign);
          setLoading(false);
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {});
  }

  function fetchAllDriversForSMS() {
    const marketIdArrayString = marketIdArray.map(id => `"${id}"`).join(',');
    setLoading(true);
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.hasuraHost,

      data: {
        query:
          `{Drivers(order_by: [{ unread_message_count: desc} ,{ is_online: desc} , {is_available: desc}, {User: {firstname: asc}}, {User: {lastname: asc}}] where: {default_market_id: {_in:[` +
          marketIdArrayString +
          `]}}) { driver_id is_online is_available active_vehicle auth0_id updatedAt updated_by user_id carrying_capacity employment_type can_deliver_alcohol default_market_id restricted_area_credentials unread_message_count phone User { auth0_id username firstname lastname email phone } Vehicles{vehicle_id type make model color year plate_state} LastDriverActions { market_id timestamp location_blob
          }}}`
      }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          let driverData = response.data.data.Drivers;
          axios({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            url: shipment_urls.hasuraHost,
            data: {
              query: `{
                Markets (where: {deleted_at : {_is_null: true}}) {
                  market_name
                  market_id

                }
              }`
            }
          })
            .then(response => {
              if (response.data.data) {
                let markets = response.data.data.Markets;
                let driverList = [];
                let messageCount = 0;
                driverData.map(driver => {
                  messageCount += driver.unread_message_count;
                  let market_name;
                  let permits = [];
                  for (let i = 0; i < markets.length; i++) {
                    if (driver.default_market_id === markets[i].market_id) {
                      market_name = markets[i].market_name;
                      break;
                    }
                  }
                  if (driver.can_deliver_alcohol) {
                    permits.push('Alcohol');
                  }
                  driver.restricted_area_credentials.map(credential => {
                    permits.push(credential);
                  });

                  driver = {
                    ...driver,
                    market_name,
                    permit: permits.map(permit => permit).join(', ')
                  };
                  driverList.push(driver);
                });
                setDriversToSMS(driverList);
                setDriversToSMSFiltered(driverList);
                setTotalUnreadDriverMessages(messageCount);
                setLoading(false);
              } else if (response.data.errors) {
                console.log('fetch markets error: ', response.data.errors);
              }
            })
            .catch(error => {
              console.log('catch fetch markets error: ', error);
              // throw error;
            });
          setLoading(false);
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {});
  }

  function markDriverMessageRead(driverId, currentUnread) {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.hasuraRest + '/driver/message/read',
      data: {
        driver_id: driverId
      }
    })
      .then(response => {
        if (response.status === 200) {
          setTotalUnreadDriverMessages(totalUnreadDriverMessages - currentUnread);
          let updates = driversToSMS.map(driver => {
            return driver.driver_id === driverId ? { ...driver, unread_message_count: 0 } : driver;
          });
          setDriversToSMS(updates);
          setDriversToSMSFiltered(updates);
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {
        console.log('set driver message as read error: ', error);
      });
  }

  function fetchMergingRoutes() {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          `{
            routes_temp_claimed_by_dispatch(args: {mkt_id: "` +
          marketId +
          `"}) {
              bounds
              path
              contains_alcohol
              created_at
              updated_at
              updated_by
              completed_at
              market_id
              num_of_gophrs
              distance
              route_id
              route_friendly_id
              status
              waypoints_number
              item_count
              restrictions
              is_expedited
              Shipments {
                shipment_id
                route_id
                shipment_friendly_id
                shipment_type
                customer_blob
                description
                drop_off_instructions
                status
                distance
                shipping_fee
                shipping_fee_blob
                weight
                is_expedited
                vehicle_type
                num_of_gophrs
                created_at
                updated_at
                pick_up_instructions
                scheduled_for
                restrictions
                contains_alcohol
                item_count
                merchant_order_id
                Orders(order_by: { order_friendly_id: asc }) {
                  location_blob
                  contains_alcohol
                  order_friendly_id
                  status
                  subtotal
                  sum_tax
                  total
                  created_at
                  updated_at
                  pick_up_instructions
                  description
                  weight
                  drop_off_instructions
                  item_count
                  actions
                  Items {
                    item_id
                    product_blob
                    quantity
                    product_price
                    status
                    product_id
                    ItemOptions {
                      option_name
                      option_price
                      variant_name
                      variant_blob
                      option_blob
                    }
                  }
                }
              }
              RouteDrivers(where: { deleted_at: { _is_null: true } }, order_by: { created_at: asc }) {
                distance
                driver_auth0_id
                driver_blob
                vehicle_blob
                status
                driver_id
              }
            }
          }`
      }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          setMergingRoutes(response.data.data.routes_temp_claimed_by_dispatch);
          setMergingCheckStateAllChecked(false);
          setMergingCheckedState(new Array(response.data.data.routes_temp_claimed_by_dispatch.length).fill(false));
          setAmountMerging(response.data.data.routes_temp_claimed_by_dispatch.length);
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {});
  }

  const filterDriver = e => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = driversForAssign.filter(driver => {
        return driver.User.firstname.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });

      setIsFilterDrivers(true);
      setFilterDriversForAssign(results);
    } else {
      setFilterDriversForAssign(driversForAssign);
      // If the text field is empty, show all users
    }

    setDriverName(keyword);
  };

  const filterDriverSMS = e => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = driversToSMS.filter(driver => {
        return driver.User.firstname.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setDriversToSMSFiltered(results);
    } else {
      setDriversToSMSFiltered(driversToSMS);
      // If the text field is empty, show all users
    }

    setDriverNameSMS(keyword);
  };

  function fetchAllDrivers() {
    let marketIdArrayString = marketIdArray.map(id => `"${id}"`).join(',');
    setLoading(true);
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: user_urls.hasuraHost,
      data: {
        query:
          ` {LastDriverActions(where: {market_id: {_in:[` +
          marketIdArrayString +
          `]}, Driver: {is_online: {_eq: true}}}) {
            location_blob
            timestamp
            market_id
            Driver {
              can_deliver_alcohol
              active_vehicle
              driver_id
              auth0_id
              employment_type
              carrying_capacity
              driver_id
              is_available
              restricted_area_credentials
              profile_photo_url
              User {
                firstname
                lastname
                username
                email
                phone
              }
              Vehicles {
                vehicle_id type make model color year plate_state
              }
            }
          }
        }`
      }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          let driverData = response.data.data.LastDriverActions;
          axios({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'content-type': 'application/json'
            },
            url: shipment_urls.hasuraHost,
            data: {
              query: `{
                Markets (where: {deleted_at : {_is_null: true}}) {
                  market_name
                  market_id

                }
              }`
            }
          })
            .then(response => {
              if (response.data.data) {
                let markets = response.data.data.Markets;
                let driverList = [];
                let availableDriverList = [];
                let unavailableDriverList = [];

                driverData.map(action => {
                  let market_name;
                  let permits = [];
                  for (let i = 0; i < markets.length; i++) {
                    if (action.market_id === markets[i].market_id) {
                      market_name = markets[i].market_name;
                      break;
                    }
                  }
                  if (action.Driver.can_deliver_alcohol) {
                    permits.push('Alcohol');
                  }
                  action.Driver.restricted_area_credentials.map(credential => {
                    permits.push(credential);
                  });

                  action.Driver = {
                    ...action.Driver,
                    LastDriverActions: { location_blob: action.location_blob, timestamp: action.timestamp },
                    market_name,
                    permit: permits.map(permit => permit).join(', ')
                  };
                  driverList.push(action.Driver);
                  if (action.Driver.is_available) {
                    availableDriverList.push(action.Driver);
                  } else {
                    unavailableDriverList.push(action.Driver);
                  }
                });
                setDrivers(driverList);
                setDriversToDisplay(driverList);
                setAvailableDriversCopy(availableDriverList);
                setEnrouteDriversCopy(unavailableDriverList);
                setLoading(false);
              } else if (response.data.errors) {
                console.log('fetch markets error: ', response.data.errors);
              }
            })
            .catch(error => {
              console.log('catch fetch markets error: ', error);
              // throw error;
            });
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {});
  }

  function fetchDriverPay(shipId, driverId, driverStatus) {
    axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.hasuraHost,
      data: {
        query:
          ` {DriverPays(where: {driver_id: {_eq: "` +
          driverId +
          `"}, shipment_id: {_eq: "` +
          shipId +
          `"}, driver_status: {_in:[ "` +
          driverStatus +
          `", "miscellaneous"]},deleted_at : {_is_null: true}}, order_by: { created_at: asc }) {
              bonus
              distance
              distance_earning
              expedited_earning
              gratuity
              overweight_earning
              min_pay
              setup_earning
              tools_earning
              total_earning
              driver_id
              shipment_id
              driver_status
              first_name
              last_name
            }
          }`
      }
    })
      .then(response => {
        if (response.data.errors) {
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].message === 'Malformed Authorization header' || response.data.errors[0].extensions.code === 'invalid-jwt') {
              this.props.history.push('/logout');
            }
          }
        }
        if (response.data.data) {
          if (response.data.data.DriverPays.length === 1) {
            setDriverPay(response.data.data.DriverPays[0]);
          } else if (response.data.data.DriverPays.length > 1) {
            let bonus = 0;
            let total = 0;
            response.data.data.DriverPays.map(pay => {
              bonus += pay.bonus;
              total += pay.total_earning;
            });
            setDriverPay({ ...response.data.data.DriverPays[0], bonus, total_earning: total });
          }
        } else {
          console.log('error: ', response);
        }
      })
      .catch(error => {});
  }

  useEffect(() => {
    fetchAllDrivers();
    fetchAllDriversForAssign();
    fetchAllDriversForSMS();
    fetchMergingRoutes();
  }, []);

  useEffect(() => {
    fetchAllDriversForSMS();
  }, [marketIdArray]);

  //! deprecated
  // function completeShipment(shipment) {
  //   var drivers = shipment.Drivers;
  //   axios({
  //     method: 'put',
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.getItem('access_token'),
  //       'content-type': 'application/json'
  //     },
  //     url: shipment_urls.completeDropShipment + shipment.shipment_id + '/complete'
  //   })
  //     .then(response => {
  //       // for(var i = 0; i < drivers.length; i++) {
  //       //   axios({
  //       //     method: 'put',
  //       //     headers: {
  //       //       'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
  //       //       'content-type': 'application/json'
  //       //     },
  //       //     url: user_urls.driverEndpoint + drivers[i].driver_auth0_id + "/set/available"
  //       //   }).then(response => {

  //       //   }).catch(error => {
  //       //     console.log("error: ", error.response);
  //       //   });
  //       // }
  //       props.notify('tc', 'Delivery Successfully Marked as Complete');
  //     })
  //     .catch(error => {
  //       if (error.response) {
  //         console.log('error: ', error.response);
  //       } else {
  //         console.log('timeout error: ', error);
  //       }
  //     });
  // }

  //! Ready a scheduled Shipment
  function readyScheduledShipment(shipment) {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentEndpoint + shipment.shipment_id + '/ready'
    })
      .then(response => {
        props.notify('tc', 'Shipment Successfully Updated to Ready');
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  }

  //! complete route
  function completeRoute(route) {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.completeDropRoute + route.route_id + '/complete'
    })
      .then(response => {
        props.notify('tc', 'Route Successfully Marked as Complete');
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  }

  const capitalize = s => {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  useEffect(() => {
    if (shipmentsData && shipmentsData.Shipments) {
      if (filter) {
        var shownShipments = [];
        shipmentsData.Shipments.forEach(element => {
          if (filter.includes(element.status)) {
            shownShipments.push(element);
          }
        });
        setShipmentsToDisplay(shownShipments);
      }
      if (selected === 'pending') {
        setFilter(['shipment_created', 'shipment_pending', 'shipment_pending_schedule', 'shipment_pending_payment']);
      } else if (selected === 'saved') {
        setFilter(['shipment_scheduled']);
      }
    }
  }, [shipmentsData]);

  useEffect(() => {
    if (data && data.Shipments) {
      let i = 0;
      let j = 0;
      let k = 0;
      data.Shipments.forEach(element => {
        if (
          element.status === 'shipment_created' ||
          element.status === 'shipment_pending' ||
          element.status === 'shipment_pending_schedule' ||
          element.status === 'shipment_pending_payment'
        ) {
          i++;
        }
        if (element.status === 'shipment_scheduled') {
          j++;
        }
        if ((element.status === 'shipment_pending' || element.status === 'shipment_pending_schedule') && element.shipping_fee > 0) {
          k++;
        }
      });
      setAmountPending(i);
      setAmountScheduled(j);
      setAmountApproval(k);
      if (i > previousAmountPending) {
        play();
      }
    }
  }, [data]);

  useEffect(() => {
    if (routesData && routesData.Routes) {
      if (filterRoutes) {
        var shownRoutes = [];
        routesData.Routes.forEach(element => {
          if (filterRoutes.includes(element.status)) {
            shownRoutes.push(element);
          }
        });
        setRoutesToDisplay(shownRoutes);
        // setMergingCheckedState(new Array(shownRoutes.length).fill(false));
      }
      if (selected === 'ready') {
        setFilterRoutes(['route_ready', 'route_temp_claimed']);
      } else if (selected === 'progressing') {
        setFilterRoutes(['route_claimed', 'route_assigned', 'route_enroute']);
      } else if (selected === 'completed') {
        setFilterRoutes(['route_completed']);
      }
    }
  }, [routesData]);

  useEffect(() => {
    if (dataRoute && dataRoute.Routes) {
      let i = 0;
      let j = 0;
      dataRoute.Routes.forEach(element => {
        if (element.status === 'route_ready' || element.status === 'route_temp_claimed') {
          i++;
        }
        if (element.status === 'route_claimed' || element.status === 'route_assigned') {
          j++;
        }
      });
      setAmountReady(i);
      setAmountDelivering(j);
      if (i > previousAmountReady) {
        play();
      }
    }
  }, [dataRoute]);

  const handleDriverSelect = driver => {
    setDriverSelected(true);
    setPrimaryDriver(driver);
  };

  const handlePassenger1Select = driver => {
    setPassenger1Selected(true);
    setPassenger1(driver);
  };

  const handlePassenger2Select = driver => {
    setPassenger2Selected(true);
    setPassenger2(driver);
  };

  const handleReassignDriverSelect = driver => {
    setDriverReassignSelected(true);
    setDriverReassign(driver);
  };

  const handlePassenger1OnlyAssignedSelect = driver => {
    setPassenger1OnlyAssignedSelected(true);
    setPassenger1OnlyAssigned(driver);
  };

  const handlePassenger2OnlyAssignedSelect = driver => {
    setPassenger2OnlyAssignedSelected(true);
    setPassenger2OnlyAssigned(driver);
  };

  const handleAssign = row => {
    setRoute(row);
    toggle();
    // fetchAllDrivers();
    fetchAllDriversForAssign();
  };

  const handleReassign = row => {
    setRoute(row);
    toggleReassign();
    // fetchAllDrivers();
    fetchAllDriversForAssign();
  };

  const handleAssignPassengerOnly = row => {
    setRoute(row);
    toggleAssignPassengerOnly();
    fetchAllDriversForAssign();
  };

  const getTotalItems = items => {
    let total = 0;
    items.map(item => {
      total = total + item.quantity;
    });
    return total;
  };

  const updateShippingFee = shipment => {
    if (shipment.shipping_fee > shippingFee) {
      alert(`The shipping fee you input is less than the original one $${shipment.shipping_fee}, if you think it's a mistake, please update again!`);
    }

    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/shipping_fee/update',
      data: { shipping_fee: shippingFee, reason: shippingFeeReason }
    })
      .then(response => {
        if (response.status === 200) {
          toggleShippingFee();
          props.notify('tc', 'Successfully update shipping fee');
          resetShippingFee();
          resetShippingFeeReason();
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const chargeSetupFee = shipment => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/setup/charge',
      data: { duration: setupDuration }
    })
      .then(response => {
        if (response.status === 200) {
          toggleSetupFee();
          props.notify('tc', 'Successfully charged setup fee');
          resetSetupFee();
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const updateBonus = driverPay => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + driverPay.shipment_id + '/driver/' + driverPay.driver_id + '/bonus',
      data: { bonus: driverBonus, reason: bonusReason }
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.payload.overpay) {
            alert(`Total driver(s) earnings is exceeding shipment total charges, if it's a mistake, please update again! `);
          }
          toggleDriverPay();
          props.notify('tc', 'Successfully update driver bonus');
          resetDriverBonus();
          resetBonusReason();
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const updateTotalDriverPayBeforeClaim = shipment => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/driver_pay/update',
      data: { amount: driverPayBeforeClaimTotalAmount, reason: updateDriverPayBeforeClaimReason }
    })
      .then(response => {
        if (response.status === 200) {
          toggleUpdateDriverPayBeforeClaim();
          props.notify('tc', 'Successfully set total driver earning');
          setDriverPayBeforeClaimTotalAmount('');
          setUpdateDriverPayBeforeClaimReason('');
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const updateRouteDriverInfoAndPO = routeId => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminRoute + routeId + '/driver_info-and-po/update',
      data: { first_name: routeDriverFirstName, last_name: routeDriverLastName, phone: routeDriverPhone, po_number: routeDriverPONumber }
    })
      .then(response => {
        if (response.status === 200) {
          toggleDriverInfoAndPO();
          props.notify('tc', 'Successfully update driver info and/or  po#');
          resetRouteDriverInfoAndPO();
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const updateOrderInfoForAdmin = orderId => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminOrder + orderId + '/update',
      data: { order_info: updateOrderInfo }
    })
      .then(response => {
        if (response.status === 200) {
          toggleShowUpdateOrderInfo();
          props.notify('tc', 'Successfully update order info');
          setUpdateOrderInfo({ first_name: '', last_name: '', phone: '', business_name: '', instruction: '' });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('error: ', error.response);
        } else {
          console.log('timeout error: ', error);
        }
      });
  };

  const readyOrderAdmin = (shipment, order) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/orders/' + order.order_id + '/ready'
    })
      .then(response => {
        props.notify('tc', 'Order Successfully be Readied');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const cancelOrderAdmin = (shipment, order) => {
    axios({
      method: 'delete',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/orders/' + order.order_id + '/cancel'
    })
      .then(response => {
        props.notify('tc', 'Order Successfully be Readied');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const cancelShipment = shipment => {
    axios({
      method: 'delete',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.adminShipment + shipment.shipment_id + '/cancel'
    })
      .then(response => {
        props.notify('tc', 'Shipment Successfully canceled');
      })
      .catch(error => {
        console.log('error: ', error.response);
      });
  };

  const removeShipmentFromRoute = (routeId, shipId) => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/' + routeId + '/remove/' + shipId
    })
      .then(response => {
        props.notify('tc', 'Shipment removed from Route Successfully');
      })
      .catch(error => {
        console.log('remove shipment from route error: ', error.response);
      });
  };

  const unassignRoute = route => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/unassign-only'
    })
      .then(response => {
        props.notify('tc', 'Route Successfully unassigned');
      })
      .catch(error => {
        console.log('unassign error: ', error.response);
      });
  };

  const breakRoute = route => {
    axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'application/json'
      },
      url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/unmerge-entire'
    })
      .then(response => {
        props.notify('tc', 'Route Successfully broke into individual routes');
      })
      .catch(error => {
        console.log('route break error: ', error.response);
      });
  };

  const reassignDriverToRoute = d0 => {
    if (driverReassign !== '' && driverReassign !== null) {
      let d0Vehicle;
      d0.Vehicles.forEach(vehicle => {
        if (vehicle.vehicle_id === d0.active_vehicle) {
          d0Vehicle = vehicle;
        }
      });
      let reassignDriver = {
        driver_id: d0.driver_id,
        status: 'driver',
        distance: route.distance,
        // gratuity: shipment.gratuity,
        employment_type: d0.employment_type,
        route_id: route.route_id,
        driver_auth0_id: d0.auth0_id,
        driver_blob: {
          first_name: d0.User.firstname,
          last_name: d0.User.lastname,
          user_name: d0.User.username,
          email: d0.User.email,
          phone: d0.User.phone,
          carry_capacity: parseInt(d0.carrying_capacity)
        },
        vehicle_id: d0.active_vehicle,
        vehicle_blob: {
          make: d0Vehicle.make,
          model: d0Vehicle.model,
          color: d0Vehicle.color,
          year: parseInt(d0Vehicle.year),
          plate_state: d0Vehicle.plate_state
        }
      };

      axios({
        method: 'put',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/drivers/reassign',
        data: reassignDriver
      })
        .then(response => {
          toggleReassign();
          resetReassign();
        })
        .catch(error => {
          console.log('error: ', error.response);
        });
    } else {
      props.notifyError('tc', null, 'You must reassign one new driver');
    }
  };

  const assignPassengerOnlyToRoute = (d1, d2) => {
    if (passenger1OnlyAssigned !== '') {
      let d1Vehicle;
      d1.Vehicles.map(vehicle => {
        if (vehicle.vehicle_id === d1.active_vehicle) {
          d1Vehicle = vehicle;
        }
      });
      let data1 = {
        driver_id: d1.driver_id,
        status: 'passenger',
        distance: route.distance,
        // gratuity: shipment.gratuity,
        employment_type: d1.employment_type,
        route_id: route.route_id,
        driver_auth0_id: d1.auth0_id,
        driver_blob: {
          first_name: d1.User.firstname,
          last_name: d1.User.lastname,
          user_name: d1.User.username,
          email: d1.User.email,
          phone: d1.User.phone,
          carry_capacity: parseInt(d1.carrying_capacity)
        },
        vehicle_id: d1.active_vehicle,
        vehicle_blob: {
          make: d1Vehicle.make,
          model: d1Vehicle.model,
          color: d1Vehicle.color,
          year: parseInt(d1Vehicle.year),
          plate_state: d1Vehicle.plate_state
        }
      };
      axios({
        method: 'put',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/assign/passenger',
        data: data1
      })
        .then(response => {
          if (passenger2OnlyAssigned === '') {
            toggleAssignPassengerOnly();
            resetAssignPassengerOnly();
          } else {
            let d2Vehicle;
            d2.Vehicles.forEach(vehicle => {
              if (vehicle.vehicle_id === d2.active_vehicle) {
                d2Vehicle = vehicle;
              }
            });
            let data2 = {
              driver_id: d2.driver_id,
              status: 'passenger',
              distance: shipment.distance,
              // gratuity: shipment.gratuity,
              employment_type: d2.employment_type,
              route_id: route.route_id,
              driver_auth0_id: d2.auth0_id,
              driver_blob: {
                first_name: d2.User.firstname,
                last_name: d2.User.lastname,
                user_name: d2.User.username,
                email: d2.User.email,
                phone: d2.User.phone,
                carry_capacity: parseInt(d2.carrying_capacity)
              },
              vehicle_id: d2.active_vehicle,
              vehicle_blob: {
                make: d2Vehicle.make,
                model: d2Vehicle.model,
                color: d2Vehicle.color,
                year: parseInt(d2Vehicle.year),
                plate_state: d2Vehicle.plate_state
              }
            };
            axios({
              method: 'put',
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                'content-type': 'application/json'
              },
              url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/assign/passenger',
              data: data2
            })
              .then(response => {
                toggleAssignPassengerOnly();
                resetAssignPassengerOnly();
              })
              .catch(error => {
                console.log('passenger2-only-error: ', error.response);
              });
          }
        })
        .catch(error => {
          console.log('passenger1-onlyerror: ', error.response);
        });
    } else {
      props.notifyError('tc', null, 'You must assign at least one passenger');
    }
  };

  const handleConfirm = (d0, d1, d2) => {
    if (primaryDriver !== '' && primaryDriver !== null) {
      let d0Vehicle;
      d0.Vehicles.forEach(vehicle => {
        if (vehicle.vehicle_id === d0.active_vehicle) {
          d0Vehicle = vehicle;
        }
      });
      let data0 = {
        driver_id: d0.driver_id,
        status: 'driver',
        distance: route.distance,
        // gratuity: shipment.gratuity,
        employment_type: d0.employment_type,
        route_id: route.route_id,
        driver_auth0_id: d0.auth0_id,
        driver_blob: {
          first_name: d0.User.firstname,
          last_name: d0.User.lastname,
          user_name: d0.User.username,
          email: d0.User.email,
          phone: d0.User.phone,
          carry_capacity: parseInt(d0.carrying_capacity)
        },
        vehicle_id: d0.active_vehicle,
        vehicle_blob: {
          make: d0Vehicle.make,
          model: d0Vehicle.model,
          color: d0Vehicle.color,
          year: parseInt(d0Vehicle.year),
          plate_state: d0Vehicle.plate_state
        }
      };
      axios({
        method: 'put',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'content-type': 'application/json'
        },
        url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/assign/driver',
        data: data0
      })
        .then(response => {
          if (passenger1 === '' || passenger1 === null) {
            toggle();
            reset();
          } else {
            let d1Vehicle;
            d1.Vehicles.forEach(vehicle => {
              if (vehicle.vehicle_id === d1.active_vehicle) {
                d1Vehicle = vehicle;
              }
            });

            let data1 = {
              driver_id: d1.driver_id,
              status: 'passenger',
              distance: route.distance,
              // gratuity: shipment.gratuity,
              employment_type: d1.employment_type,
              route_id: route.route_id,
              driver_auth0_id: d1.auth0_id,
              driver_blob: {
                first_name: d1.User.firstname,
                last_name: d1.User.lastname,
                user_name: d1.User.username,
                email: d1.User.email,
                phone: d1.User.phone,
                carry_capacity: parseInt(d1.carrying_capacity)
              },
              vehicle_id: d1.active_vehicle,
              vehicle_blob: {
                make: d1Vehicle.make,
                model: d1Vehicle.model,
                color: d1Vehicle.color,
                year: parseInt(d1Vehicle.year),
                plate_state: d1Vehicle.plate_state
              }
            };
            axios({
              method: 'put',
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                'content-type': 'application/json'
              },
              url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/assign/passenger',
              data: data1
            })
              .then(response => {
                if (passenger2 === '' || passenger2 === null) {
                  toggle();
                  reset();
                } else {
                  let d2Vehicle;
                  d2.Vehicles.forEach(vehicle => {
                    if (vehicle.vehicle_id === d2.active_vehicle) {
                      d2Vehicle = vehicle;
                    }
                  });
                  let data2 = {
                    driver_id: d2.driver_id,
                    status: 'passenger',
                    distance: shipment.distance,
                    // gratuity: shipment.gratuity,
                    employment_type: d2.employment_type,
                    route_id: route.route_id,
                    driver_auth0_id: d2.auth0_id,
                    driver_blob: {
                      first_name: d2.User.firstname,
                      last_name: d2.User.lastname,
                      user_name: d2.User.username,
                      email: d2.User.email,
                      phone: d2.User.phone,
                      carry_capacity: parseInt(d2.carrying_capacity)
                    },
                    vehicle_id: d2.active_vehicle,
                    vehicle_blob: {
                      make: d2Vehicle.make,
                      model: d2Vehicle.model,
                      color: d2Vehicle.color,
                      year: parseInt(d2Vehicle.year),
                      plate_state: d2Vehicle.plate_state
                    }
                  };
                  axios({
                    method: 'put',
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                      'content-type': 'application/json'
                    },
                    url: shipment_urls.shipmentHost + '/routes/' + route.route_id + '/assign/passenger',
                    data: data2
                  })
                    .then(response => {
                      toggle();
                      reset();
                    })
                    .catch(error => {
                      console.log('error: ', error.response);
                    });
                }
              })
              .catch(error => {
                console.log('error: ', error.response);
              });
          }
        })
        .catch(error => {
          console.log('error: ', error.response);
        });
    } else {
      props.notifyError('tc', null, 'You must assign at least one driver');
    }
  };

  const getTrProps = (rowInfo, column, cell) => {
    if (rowInfo) {
      if (column) {
        if (!apolloading) {
          if ((column.original.status === 'shipment_pending' || column.original.status === 'shipment_pending_schedule') && column.original.shipping_fee > 0) {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px',
                border: '2px solid green',
                textAlign: 'left'
              }
            };
          } else if ((column.original.status === 'shipment_pending' || column.original.status === 'shipment_pending_schedule') && column.original.shipping_fee === 0) {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px',
                border: '2px solid red',
                textAlign: 'left'
              }
            };
          }
          if (column.original.is_expedited) {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                padding: '0px',
                border: '2px solid #f79400',
                textAlign: 'left'
              }
            };
          } else {
            return {
              onClick: e => {
                handleRowClick(column, rowInfo, cell, e);
              },
              style: {
                cursor: 'pointer',
                height: `70px`,
                margin: `5px`,
                borderRadius: `5px`,
                background: `#27293d`,
                textAlign: 'left',
                padding: '0px'
              }
            };
          }
        } else {
          return {};
        }
      }
      return {};
    }
  };

  const getTheadThProps = () => {
    return {
      style: {
        fontSize: `10px`,
        fontWeight: `600`,
        textAlign: `left`,
        color: `#cdcaca`
      }
    };
  };

  const pendingColumns = [
    {
      expander: true,
      Expander: ({ isExpanded, ...rest }) => (
        <div style={{ textAlign: 'left' }}>
          {!isExpanded ? (
            <span>
              <FaCaretRight color={'#BFC0C5'} size="20" />
            </span>
          ) : (
            <span>
              <FaCaretDown color={'#BFC0C5'} size="20" />
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'Ship_ID',
      accessor: 'shipment_friendly_id',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      // width: 65,
      width: 200,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div style={{ textAlign: 'left' }}>
          {row.original.status === 'shipment_pending_payment' ? (
            'Pending Invoice Payment'
          ) : (row.original.shipment_type === 'direct' || row.original.shipment_type === 'routed_direct' || row.original.shipment_type === 'multidrop_direct') &&
            row.original.shipping_fee > 0 ? (
            'Waiting Merchant Acceptance'
          ) : (row.original.shipment_type === 'direct' || row.original.shipment_type === 'routed_direct' || row.original.shipment_type === 'multidrop_direct') &&
            row.original.shipping_fee === 0 ? (
            'Quote Pending'
          ) : (
            <span style={{ textAlign: 'left' }}>Pending Merchant Approval</span>
          )}
        </div>
      )
    },
    {
      Header: 'Customer',
      accessor: 'customer_blob',
      width: 150,
      // width: 'auto',
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            // width: 1,
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {props.value.store_name ? props.value.store_name : props.value.first_name + ' ' + props.value.last_name}
        </div>
      )
    },
    {
      Header: 'Customer Ph.',
      accessor: 'customer_blob',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {props.value.phone}
        </div>
      )
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    },
    {
      Header: 'Actions',
      style: {
        paddingTop: `0px`,
        paddingBottom: `0px`
      },
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => {
        if (!apolloading) {
          return (
            <div style={{ textAlign: 'left' }}>
              {props.original.shipment_type === 'direct' ||
              props.original.shipment_type === 'connect' ||
              props.original.shipment_type === 'routed_direct' ||
              props.original.shipment_type === 'multidrop_direct' ? (
                <ButtonGroup style={{ textAlign: 'left' }}>
                  <Button
                    className="btn btn-danger"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      setShipment(props.original);
                      setShowAlertCancelShipment(true);
                    }}
                  >
                    Cancel
                  </Button>
                  {props.original.shipping_fee > 0 && (
                    <Button
                      className="btn btn-default"
                      style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                      onClick={e => {
                        e.stopPropagation();
                        readyScheduledShipment(props.original);
                      }}
                    >
                      Ready
                    </Button>
                  )}
                </ButtonGroup>
              ) : null}
            </div>
          );
        } else {
          return <Loading />;
        }
      }
    }
  ];

  const mergingRoutesColumns = [
    {
      Header: () => (
        <div style={{ width: '60px' }}>
          <input type="checkbox" checked={mergingCheckStateAllChecked} onChange={handleSelectAllMergingCheckbox} disabled={!currentUserHasMergingRecords} />
          {'     Select All'}
        </div>
      ),
      accessor: 'updated_by',
      width: 100,
      textAlign: 'left',
      Cell: row => (
        <div>
          {row.value === SUB && (
            <input
              key={row.index}
              type="checkbox"
              checked={mergingCheckState[row.index]}
              // onChange={e => {
              //   e.stopPropagation();
              //   handleMergingCheckbox(row.index);
              // }}
              onClick={e => {
                e.stopPropagation();
                handleMergingCheckbox(row.index);
              }}
            />
          )}
        </div>
      )
    },
    {
      Header: 'Route-Ship_ID',
      accessor: 'route_friendly_id',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.value}--
          {props.original.Shipments.map((ship, index) => {
            return index !== 0 ? `/${ship.shipment_friendly_id}` : ship.shipment_friendly_id;
          })}
        </div>
      )
    },
    {
      Header: 'Pay($)',
      // accessor: 'status',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{RouteTotalDriverPays(props.original.Shipments)}</div>
    },
    {
      Header: 'Customer',
      // accessor: 'status',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{getCustomerNameFromRoute(props.original.Shipments)}</div>
    },
    //! Comment out Alcohol
    // {
    //   Header: 'Lit',
    //   accessor: 'contains_alcohol',
    //   // width: 'auto',
    //   width: 100,
    //   textAlign: 'left',
    //   Cell: row => (
    //     <div
    //       style={{
    //         textAlign: 'left'
    //       }}
    //     >
    //       <span>
    //         {row.value ? (
    //           <div>
    //             <i className="fas fa-glass-martini fa-lg"></i>
    //           </div>
    //         ) : null}
    //       </span>
    //     </div>
    //   )
    // },
    {
      Header: 'stops',
      accessor: 'waypoints_number',
      // width: 'auto',
      width: 75,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'item_count',
      accessor: 'item_count',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'created at',
      accessor: 'created_at',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    },
    {
      Header: 'Actions',
      style: {
        paddingTop: `0px`,
        paddingBottom: `0px`
      },
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => {
        if (!apolloading) {
          if (props.original.status === 'route_temp_claimed_dispatch' && props.original.updated_by == SUB) {
            return (
              <div style={{ textAlign: 'left' }}>
                <ButtonGroup style={{ textAlign: 'left' }}>
                  <Button
                    className="btn btn-default"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      CancelTempClaimRoutes(props.original.route_id);
                      fetchMergingRoutes();
                    }}
                  >
                    Go Ready
                  </Button>
                  <Button
                    className="btn btn-info"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      handleAssign(props.original);
                    }}
                  >
                    Assign
                  </Button>
                  <Button
                    className="btn btn-default"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      setRoute(props.original);
                      setRouteWaypoints(props.original.path);
                      setShowReorderWaypoints(true);
                    }}
                  >
                    Reorder
                  </Button>
                </ButtonGroup>
              </div>
            );
          }
          return <div></div>;
        } else {
          return <Loading />;
        }
      }
    }
  ];

  const readyColumns = [
    //   {
    //     Header: '',
    //     width: 20,
    //     textAlign: 'left',
    //     Cell: row => (
    //       <input
    //         key={row.index}
    //         type="checkbox"
    //         checked={readyRouteCheckboxChecked}
    //         disabled={row.original.status === 'route_ready' ? false : true}
    //         onChange={() => {
    //           setReadyRouteCheckboxChecked(true);
    //           console.log('clcik ready checkbox');
    //           setTimeout(() => {
    //             tempClaimRoutes(row.original.route_id);
    //           }, 300);
    //         }}
    //       />
    //     )
    //   },
    {
      Header: 'Route-Ship_ID',
      accessor: 'route_friendly_id',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.value}--
          {props.original.Shipments.map((ship, index) => {
            return index !== 0 ? `/${ship.shipment_friendly_id}` : ship.shipment_friendly_id;
          })}
        </div>
      )
    },
    {
      Header: 'Pay($)',
      // accessor: 'status',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{RouteTotalDriverPays(props.original.Shipments)}</div>
    },
    {
      Header: 'Customer',
      // accessor: 'status',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{getCustomerNameFromRoute(props.original.Shipments)}</div>
    },
    //! comment out because of route
    // {
    //     Header: 'Type',
    //     accessor: 'vehicle_type',
    //     width: 'auto',
    //     textAlign: 'left',
    //     Cell: row => (
    //         <div>
    //             <span style={{ textAlign: 'left' }}>
    //                 {row.value === 'car' ? <Car /> : row.value === 'suv' ? <SUV /> : row.value === 'truck' ? <Truck /> : row.value === 'trailer' ? <Trailer size={1.5} /> : row.value === 'box_truck' ? <BoxTruck /> : 'None Found'}
    //             </span>
    //         </div>
    //     ),
    // },

    // {
    //   Header: 'Status',
    //   accessor: 'status',
    //   width: 80,
    //   Cell: row => (
    //     <div>
    //       <span>
    //         {
    //           row.value === 'shipment_ready_for_driver' ?
    //           <div>
    //             <span id="UncontrolledTooltipExample">
    //               <RedStatusCircle />
    //             </span>
    //             <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
    //               Shipment ready for driver!
    //             </UncontrolledTooltip>
    //           </div>
    //            :
    //           row.value === 'shipment_assigned' ?
    //             <div>
    //               <span id="UncontrolledTooltipExample">
    //                 <OrangeStatusCircle />
    //               </span>
    //               <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
    //                 Shipment assigned!
    //               </UncontrolledTooltip>
    //             </div>
    //             :
    //           row.value === 'shipment_is_in_route' ?
    //           <div>
    //             <span id="UncontrolledTooltipExample">
    //               <YellowStatusCircle />
    //             </span>
    //             <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
    //               Shipment en route!
    //             </UncontrolledTooltip>
    //           </div>
    //            :
    //           row.value === 'shipment_completed' ?
    //           <div>
    //             <span id="UncontrolledTooltipExample">
    //               <GreenStatusCircle />
    //             </span>
    //             <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
    //               Shipment completed!
    //             </UncontrolledTooltip>
    //           </div>
    //            : "None Found"
    //         }
    //       </span>
    //     </div>
    //   )
    // },
    //! Comment out Alcohol
    // {
    //   Header: 'Lit',
    //   accessor: 'contains_alcohol',
    //   // width: 'auto',
    //   width: 100,
    //   textAlign: 'left',
    //   Cell: row => (
    //     <div
    //       style={{
    //         textAlign: 'left'
    //       }}
    //     >
    //       <span>
    //         {/* {
    //           row.value === 'shipment_ready_for_driver' ?  <FaHourglassHalf size="30" color="#be1e2d" /> :
    //           row.value === 'shipment_assigned' ?  <FaPeopleCarry size="30" color="#ff6500" /> :
    //           row.value === 'shipment_enroute' ? <FaHome size="30" color="#fcb23a" /> :
    //           row.value === 'shipment_completed' ? <FaRegCheckCircle size="30" color="#2bbbae"/> :
    //           row.value === 'shipment_scheduled' ? now.isAfter(row.original.scheduled_for) ?
    //           makeReady(row.original.shipment_friendly_id) : tomorrow.isAfter(row.original.scheduled_for) ?
    //           makeAlmostReady(row.original.shipment_friendly_id)
    //           : <FaClock size="30" color="#2bbbae" /> :
    //           row.value === 'order_saved' ? <FaBookmark size="30" color="be1e2d" /> : "None Found"
    //         } */}
    //         {row.value ? (
    //           <div>
    //             <i className="fas fa-glass-martini fa-lg"></i>
    //           </div>
    //         ) : null}
    //       </span>
    //     </div>
    //   )
    // },
    //! comment out because of route
    // {
    //     Header: 'Customer',
    //     textAlign: 'left',
    //     width: 'auto',
    //     Cell: row => <div style={{ textAlign: 'left' }}>{row.original.shipment_type === 'direct' ? row.original.customer_blob.store_name : row.original.customer_blob.first_name + ' ' + row.original.customer_blob.last_name}</div>,
    // },
    {
      Header: 'stops',
      accessor: 'waypoints_number',
      // width: 'auto',
      width: 75,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'item_count',
      accessor: 'item_count',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'created at',
      accessor: 'created_at',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    },
    {
      Header: 'Actions',
      style: {
        paddingTop: `0px`,
        paddingBottom: `0px`
      },
      // width: 'auto',
      width: 250,
      textAlign: 'left',
      Cell: props => {
        if (!apolloading) {
          if (props.original.status === 'route_ready') {
            return (
              <div style={{ textAlign: 'left' }}>
                <ButtonGroup style={{ textAlign: 'left' }}>
                  <Button
                    className="btn btn-default"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={() => {
                      tempClaimRoutes(props.original.route_id);
                    }}
                  >
                    Go Merging
                  </Button>
                  <Button
                    className="btn btn-info"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      handleAssign(props.original);
                    }}
                  >
                    Assign
                  </Button>
                  {props.original.Shipments.length > 1 && (
                    <Button
                      className="btn btn-warning"
                      style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                      onClick={() => {
                        setRoute(props.original);
                        setShowAlertBreakRoute(true);
                      }}
                    >
                      Break
                    </Button>
                  )}
                </ButtonGroup>
              </div>
            );
          }
          return <div></div>;
        } else {
          return <Loading />;
        }
      }
    }
  ];

  const progressingColumns = [
    {
      Header: 'Route-Ship_ID',
      accessor: 'route_friendly_id',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.value}--
          {props.original.Shipments.map((ship, index) => {
            return index !== 0 ? `/${ship.shipment_friendly_id}` : ship.shipment_friendly_id;
          })}
        </div>
      )
    },
    // {
    //   Header: 'status',
    //   accessor: 'status',
    //   // width: 'auto',
    //   width: 75,
    //   textAlign: 'left',
    //   Cell: props => <div style={{ textAlign: 'left' }}>{props.value.replace('route_', '')}</div>
    // },
    {
      Header: 'Pay($)',
      // accessor: 'status',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{RouteTotalDriverPays(props.original.Shipments)}</div>
    },
    {
      Header: 'Customer',
      // accessor: 'status',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{getCustomerNameFromRoute(props.original.Shipments)}</div>
    },
    {
      Header: 'Driver',
      // accessor: 'status',
      // width: 'auto',
      width: 175,
      textAlign: 'left',
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.original.RouteDrivers.length > 0 ? props.original.RouteDrivers[0].driver_blob.first_name + ' ' + props.original.RouteDrivers[0].driver_blob.last_name : ''}
        </div>
      )
    },
    //! Comment out Alcohol
    // {
    //   Header: 'Lit',
    //   accessor: 'contains_alcohol',
    //   // width: 'auto',
    //   width: 70,
    //   textAlign: 'left',
    //   Cell: row => (
    //     <div
    //       style={{
    //         textAlign: 'left'
    //       }}
    //     >
    //       <span>
    //         {/* {
    //           row.value === 'shipment_ready_for_driver' ?  <FaHourglassHalf size="30" color="#be1e2d" /> :
    //           row.value === 'shipment_assigned' ?  <FaPeopleCarry size="30" color="#ff6500" /> :
    //           row.value === 'shipment_enroute' ? <FaHome size="30" color="#fcb23a" /> :
    //           row.value === 'shipment_completed' ? <FaRegCheckCircle size="30" color="#2bbbae"/> :
    //           row.value === 'shipment_scheduled' ? now.isAfter(row.original.scheduled_for) ?
    //           makeReady(row.original.shipment_friendly_id) : tomorrow.isAfter(row.original.scheduled_for) ?
    //           makeAlmostReady(row.original.shipment_friendly_id)
    //           : <FaClock size="30" color="#2bbbae" /> :
    //           row.value === 'order_saved' ? <FaBookmark size="30" color="be1e2d" /> : "None Found"
    //         } */}
    //         {row.value ? (
    //           <div>
    //             <i className="fas fa-glass-martini fa-lg"></i>
    //           </div>
    //         ) : null}
    //       </span>
    //     </div>
    //   )
    // },
    //! comment out because of route
    // {
    //     Header: 'Customer',
    //     textAlign: 'left',
    //     width: 'auto',
    //     Cell: row => <div style={{ textAlign: 'left' }}>{row.original.shipment_type === 'direct' ? row.original.customer_blob.store_name : row.original.customer_blob.first_name + ' ' + row.original.customer_blob.last_name}</div>,
    // },
    // {
    //   Header: 'stops',
    //   accessor: 'waypoints_number',
    //   // width: 'auto',
    //   width: 75,
    //   textAlign: 'left',
    //   Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    // },
    {
      Header: 'item_count',
      accessor: 'item_count',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    },
    {
      Header: 'created at',
      accessor: 'created_at',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    },
    {
      Header: 'Actions',
      style: {
        paddingTop: `0px`,
        paddingBottom: `0px`
      },
      // width: 'auto',
      width: 470,
      textAlign: 'left',
      Cell: props => {
        if (!apolloading) {
          if (props.original.status === 'route_assigned' || props.original.status === 'route_claimed') {
            return (
              <div style={{ textAlign: 'left' }}>
                <ButtonGroup style={{ textAlign: 'left' }}>
                  <Button
                    className="btn btn-info"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      setRoute(props.original);
                      setRouteWaypoints(props.original.path);
                      setShowReorderWaypoints(true);
                    }}
                  >
                    Reorder
                  </Button>
                  <Button
                    className="btn btn-default"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      handleReassign(props.original);
                    }}
                  >
                    Reassign
                  </Button>
                  <Button
                    className="btn btn-secondary"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      handleAssignPassengerOnly(props.original);
                    }}
                  >
                    Passenger
                  </Button>
                  <Button
                    className="btn btn-success"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      setRoute(props.original);
                      setShowAlertUnassignRoute(true);
                    }}
                  >
                    Unassign
                  </Button>
                  {props.original.Shipments.length > 1 && (
                    <Button
                      className="btn btn-warning"
                      style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                      onClick={e => {
                        e.stopPropagation();
                        setRoute(props.original);
                        setShowAlertBreakRoute(true);
                      }}
                    >
                      Break
                    </Button>
                  )}
                  <Button
                    className="btn btn-danger"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      // ! needs to be complete route
                      e.stopPropagation();
                      completeRoute(props.original, () => {
                        fetchAllDrivers();
                      });
                    }}
                  >
                    Complete
                  </Button>
                </ButtonGroup>
              </div>
            );
          }
          return <div></div>;
        } else {
          return <Loading />;
        }
      }
    }
  ];

  const completedColumns = [
    {
      Header: 'route-ship_ID',
      accessor: 'route_friendly_id',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => (
        <div style={{ textAlign: 'left' }}>
          {props.value}--
          {props.original.Shipments.map((ship, index) => {
            return index !== 0 ? `/${ship.shipment_friendly_id}` : ship.shipment_friendly_id;
          })}
        </div>
      )
    },
    {
      Header: 'Pay($)',
      // accessor: 'status',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{RouteTotalDriverPays(props.original.Shipments)}</div>
    },
    // {
    //   Header: 'status',
    //   accessor: 'status',
    //   // width: 'auto',
    //   width: 150,
    //   textAlign: 'left',
    //   Cell: props => <div style={{ textAlign: 'left' }}>{props.value}</div>
    // },
    {
      Header: 'Customer',
      // accessor: 'status',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{getCustomerNameFromRoute(props.original.Shipments)}</div>
    },
    //! comment out because of route
    // {
    //     Header: 'Type',
    //     accessor: 'vehicle_type',
    //     width: 'auto',
    //     textAlign: 'left',
    //     Cell: row => (
    //         <div>
    //             <span style={{ textAlign: 'left' }}>
    //                 {row.value === 'car' ? <Car /> : row.value === 'suv' ? <SUV /> : row.value === 'truck' ? <Truck /> : row.value === 'trailer' ? <Trailer size={1.5} /> : row.value === 'box_truck' ? <BoxTruck /> : 'None Found'}
    //             </span>
    //         </div>
    //     ),
    // },
    //! Comment out Alcohol
    // {
    //   Header: 'Lit',
    //   accessor: 'contains_alcohol',
    //   // width: 'auto',
    //   width: 100,
    //   textAlign: 'left',
    //   Cell: row => (
    //     <div
    //       style={{
    //         textAlign: 'left'
    //       }}
    //     >
    //       <span>
    //         {row.value ? (
    //           <div>
    //             <i className="fas fa-glass-martini fa-lg"></i>
    //           </div>
    //         ) : null}
    //       </span>
    //     </div>
    //   )
    // },
    //! comment out because of route
    // {
    //     Header: 'Customer',
    //     textAlign: 'left',
    //     width: 'auto',
    //     Cell: row => <div style={{ textAlign: 'left' }}>{row.original.shipment_type === 'direct' ? row.original.customer_blob.store_name : row.original.customer_blob.first_name + ' ' + row.original.customer_blob.last_name}</div>,
    // },
    {
      Header: 'created at',
      accessor: 'created_at',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    },
    {
      Header: 'completed at',
      accessor: 'completed_at',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{formatTimestamp(props.value)}</div>
    }
  ];

  //! scheduled shipments column
  const savedForLaterColumns = [
    // {
    //   expander: true,
    //   textAlign: 'center',
    //   Expander: ({isExpanded, ...rest}) =>
    //   <div style={{
    //     width: 20,
    //     textAlign: 'center'
    //   }}>
    //     { !isExpanded ?
    //     <span><FaCaretRight color={'#BFC0C5'} size="20" /></span>
    //      :
    //     <span><FaCaretDown color={'#BFC0C5'} size="20" /></span> }
    //   </div>
    // },
    {
      Header: 'Ship_ID',
      accessor: 'shipment_friendly_id',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.value}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          <span style={{ textAlign: 'left' }}>
            {row.value === 'shipment_ready_for_driver' ? (
              <FaHourglassHalf size="30" color="#be1e2d" />
            ) : row.value === 'shipment_assigned' ? (
              <FaPeopleCarry size="30" color="#ff6500" />
            ) : row.value === 'shipment_enroute' ? (
              <FaHome size="30" color="#fcb23a" />
            ) : row.value === 'shipment_completed' ? (
              <FaRegCheckCircle size="30" color="#2bbbae" />
            ) : row.value === 'shipment_scheduled' ? (
              now.isAfter(row.original.scheduled_for) ? (
                makeReady(row.original.order_friendly_id)
              ) : tomorrow.isAfter(row.original.scheduled_for) ? (
                makeAlmostReady(row.original.shipment_friendly_id)
              ) : (
                <FaClock size="30" color="#2bbbae" />
              )
            ) : row.value === 'shipment_saved' ? (
              <FaBookmark size="30" color="be1e2d" />
            ) : (
              'None Found'
            )}
          </span>
        </div>
      )
    },
    {
      Header: 'Scheduled For',
      accessor: 'scheduled_for',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props =>
        props.value ? (
          <div
            style={{
              textAlign: 'left'
            }}
          >
            {formatTimestamp(props.value)}
          </div>
        ) : (
          <div
            style={{
              textAlign: 'left'
            }}
          >
            Not Scheduled
          </div>
        )
    },
    {
      Header: 'Pay($)',
      // accessor: 'status',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: props => <div style={{ textAlign: 'left' }}>{RouteTotalDriverPays([props.original])}</div>
    },
    {
      Header: 'Customer',
      accessor: 'customer_blob',
      // width: 'auto',
      width: 150,
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {/* {props.value.first_name + ' ' + props.value.last_name} */}
          {props.value.store_name ? props.value.store_name : props.value.first_name + ' ' + props.value.last_name}
        </div>
      )
    },
    // {
    //     Header: 'Size',
    //     accessor: 'vehicle_type',
    //     width: 'auto',
    //     textAlign: 'left',
    //     Cell: row => (
    //         <div
    //             style={{
    //                 //paddingLeft: 10,
    //                 textAlign: 'left',
    //             }}
    //         >
    //             <span style={{ textAlign: 'left' }}>
    //                 {row.value === 'car' ? <Car /> : row.value === 'suv' ? <SUV /> : row.value === 'truck' ? <Truck /> : row.value === 'trailer' ? <Trailer size={1.5} /> : row.value === 'box_truck' ? <BoxTruck /> : 'None Found'}
    //             </span>
    //         </div>
    //     ),
    // },
    {
      Header: 'created at',
      accessor: 'created_at',
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {formatTimestamp(props.value)}
        </div>
      )
    },
    {
      Header: 'Actions',
      style: {
        paddingTop: `0px`,
        paddingBottom: `0px`
      },
      // width: 'auto',
      width: 200,
      textAlign: 'left',
      Cell: props => {
        if (!apolloading) {
          return (
            <div style={{ textAlign: 'left' }}>
              {props.original.shipment_type === 'direct' ||
              props.original.shipment_type === 'connect' ||
              props.original.shipment_type === 'routed_direct' ||
              props.original.shipment_type === 'multidrop_direct' ? (
                <ButtonGroup style={{ textAlign: 'left' }}>
                  <Button
                    className="btn btn-danger"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      setShipment(props.original);
                      setShowAlertCancelShipment(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-default"
                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                    onClick={e => {
                      e.stopPropagation();
                      readyScheduledShipment(props.original);
                    }}
                  >
                    Ready
                  </Button>
                </ButtonGroup>
              ) : null}
            </div>
          );
        } else {
          return <Loading />;
        }
      }
    }
  ];

  //! driver list column
  const driverListColumns = [
    // {
    //   expander: true,
    //   textAlign: 'center',
    //   Expander: ({isExpanded, ...rest}) =>
    //   <div style={{
    //     width: 20,
    //     textAlign: 'center'
    //   }}>
    //     { !isExpanded ?
    //     <span><FaCaretRight color={'#BFC0C5'} size="20" /></span>
    //      :
    //     <span><FaCaretDown color={'#BFC0C5'} size="20" /></span> }
    //   </div>
    // },
    {
      Header: 'Name',
      width: 140,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.original.User.firstname + ' ' + row.original.User.lastname}
        </div>
      )
    },
    {
      Header: 'Phone',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            textAlign: 'left'
          }}
        >
          {row.original.User.phone}
        </div>
      )
    },
    {
      Header: 'On Line Status',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {row.original.is_online && row.original.is_available ? '🟢' : row.original.is_online && !row.original.is_available ? '🟠 ' : '⚪️'}
        </div>
      )
    },
    {
      Header: 'Has Route',
      width: 100,
      // width: 'auto',
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {row.original.is_online && row.original.is_available ? 'Available' : row.original.is_online && !row.original.is_available ? 'Enroute' : 'Unavailable'}
        </div>
      )
    },

    {
      Header: 'Vehicle Type',
      // width: 'auto',
      width: 120,
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            fontWeight: 'bold',
            textAlign: 'left'
          }}
        >
          {row.original.Vehicles.length === 0
            ? 'Unknown'
            : row.original.Vehicles.length === 1
            ? row.original.Vehicles[0].type
            : row.original.Vehicles.filter(vehicle => {
                return vehicle.vehicle_id === row.original.active_vehicle;
              })[0].type}
        </div>
      )
    },
    // add other twic etc later
    {
      Header: 'Permits',
      // width: 'auto',
      width: 100,
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {row.original.permit}
        </div>
      )
    },
    {
      Header: 'Market',
      // width: 'auto',
      width: 120,
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left'
          }}
        >
          {row.original.market_name}
        </div>
      )
    },
    {
      Header: 'SMS',
      // width: 'auto',
      width: 50,
      textAlign: 'left',
      Cell: row => (
        <div
          style={{
            // width: 'auto',
            textAlign: 'left',
            position: 'relative',
            display: 'inline-block'
          }}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            onClick={e => {
              e.stopPropagation();
              setMessageRecipientInfo(row.original.User);
              setShowDriverMessageForm(true);
              retrieveMessage(row.original.User.phone);
              if (row.original.unread_message_count > 0) {
                markDriverMessageRead(row.original.driver_id, row.original.unread_message_count);
              }
            }}
            style={{
              color: 'white', // Set the color to white for dark mode
              cursor: 'pointer'
            }}
          />
          {row.original.unread_message_count > 0 && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                right: '-7px',
                backgroundColor: 'red',
                width: '8px',
                height: '8px',
                borderRadius: '50%'
              }}
            ></div>
          )}
        </div>
      )
    }
  ];

  if (apolloading || apolloadingRoute) return <Loading />;
  return (
    <div>
      <div className="content">
        <div className="float-content">
          <div style={{ width: `65%` }}>
            <DispatchMarketsSelection memoizedOptions={memoizedOptions} />
            {isSingleMarket && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button
                  color={isRouteBuilderAccessible ? 'success' : 'secondary'}
                  // color="info"
                  name="build-routes"
                  onClick={e => {
                    if (isRouteBuilderAccessible) {
                      setShowAlertMergeRoute(true);
                    }
                  }}
                  style={{ width: `18%` }}
                >
                  Build Routes
                </Button>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Row>
                    <Col style={{ width: '150%' }}>
                      <p>Updated: </p>
                      <p style={{ color: 'orange' }}>{priceUpdateTime.slice(5, 10)} </p>
                    </Col>
                    <Col>
                      <p>REGULAR:</p>
                      <p>DIESEL:</p>
                    </Col>
                    <Col style={{ marginLeft: '-20px' }}>
                      <p>
                        <span className="digital-font" style={{ color: 'red' }}>
                          {Number(gasPrice).toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span className="digital-font" style={{ color: 'green' }}>
                          {Number(dieselPrice).toFixed(2)}
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <Button
                    color="warning"
                    name="update-gas-price"
                    onClick={e => {
                      setShowGasPrice(true);
                    }}
                    style={{ width: `42%`, marginLeft: '30px' }}
                  >
                    Update Price
                  </Button>
                </div>
                <Button
                  color="success"
                  name="update-market-note"
                  onClick={e => {
                    setShowMarketNote(true);
                    fetchMarketNote();
                  }}
                  style={{ width: '20%' }}
                >
                  Market Note
                </Button>
              </div>
            )}
            {!isSingleMarket && regionId && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="warning"
                  name="update-gas-price-region"
                  onClick={e => {
                    setShowGasPriceRegion(true);
                    fetchRegionAndMarketGasPrice();
                  }}
                >
                  Update Region Gas Price
                </Button>
                <Button
                  color="success"
                  name="update-region-markets-note"
                  onClick={e => {
                    setShowMarketNoteRegion(true);
                    fetchRegionAndMarketNote();
                  }}
                >
                  Region Markets Note
                </Button>
              </div>
            )}
          </div>
          <div style={{ width: `40%`, marginLeft: 0, color: 'white' }}>
            <MapFilters marketIdArray={marketIdArray} />
            <Button
              size="sm"
              className="btn btn-info"
              style={{ padding: `5px 10px`, display: `inline-block`, marginLeft: '10px' }}
              onClick={() => setShowDispatchMap(prev => !prev)}
            >
              {showDispatchMap ? 'Hide Map' : 'Show Map'}
            </Button>
          </div>
        </div>

        <div className="float-container">
          <ResizableBox className="box" width={5000} height={15000} minConstraints={[1000, 4000]} maxConstraints={[10000, 30000]} draggableOpts={{ grid: [10, 10] }} axis="x">
            <div className="float-child-table">
              <div style={{ marginBottom: 10, width: `100%`, display: `flex`, flexDirection: `row` }}>
                {/* <Multiplayer urls={['https://a.uguu.se/V8BHQdvyLDru_Notification-admin_1.mp3']} /> */}
                <ButtonGroup style={{ className: 'center', width: '100%' }}>
                  <Button
                    className={selected === 'pending' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="pending"
                    onClick={e => {
                      setFilter(['shipment_created', 'shipment_pending', 'shipment_pending_schedule', 'shipment_pending_payment']);
                      setSelected(e.target.name);
                      setShowMergingRoutesMap(false);
                    }}
                    style={{ width: `20%` }}
                  >
                    Pending
                    {amountApproval > 0 && (
                      <div className="approve-amount-container">
                        <p className="approval-amount">{amountApproval}</p>
                      </div>
                    )}
                    {amountPending > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{amountPending}</p>
                      </div>
                    ) : null}
                  </Button>
                  <Button
                    style={{ width: '20%' }}
                    className={selected === 'saved' ? 'btn' : 'btn-simple'}
                    name="saved"
                    color="info"
                    onClick={e => {
                      setFilter(['shipment_scheduled']);
                      setSelected('saved');
                      setShowMergingRoutesMap(false);
                    }}
                  >
                    Scheduled
                    {amountScheduled > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{amountScheduled}</p>
                      </div>
                    ) : null}
                    <Badge hidden={almostReady.length === 0} name="saved" style={{ display: 'inline-block', whiteSpace: 'nowrap', backgroundColor: '#F7941D', color: '#ffffff' }}>
                      {almostReady.length}
                    </Badge>
                    <Badge hidden={ready.length === 0} name="saved" style={{ display: 'inline-block', whiteSpace: 'nowrap', backgroundColor: '#be1e2d', color: '#ffffff' }}>
                      {ready.length}
                    </Badge>
                  </Button>
                  <Button
                    className={selected === 'ready' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="ready"
                    onClick={e => {
                      setFilterRoutes(['route_ready', 'route_temp_claimed']);
                      setSelected(e.target.name);
                      setShowMergingRoutesMap(false);
                    }}
                    style={{ width: `20%` }}
                  >
                    Ready
                    {amountReady > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{amountReady}</p>
                      </div>
                    ) : null}
                  </Button>
                  <Button
                    className={selected === 'merging' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="merging"
                    onClick={e => {
                      fetchMergingRoutes();
                      setSelected(e.target.name);
                    }}
                    style={{ width: `20%` }}
                  >
                    Merging
                    {amountMerging > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{amountMerging}</p>
                      </div>
                    ) : null}
                  </Button>
                  <Button
                    className={selected === 'progressing' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="progressing"
                    onClick={e => {
                      setFilterRoutes(['route_claimed', 'route_assigned', 'route_enroute']);
                      setSelected(e.target.name);
                      setShowMergingRoutesMap(false);
                    }}
                    style={{ width: `20%` }}
                  >
                    Delivering
                    {amountDelivering > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{amountDelivering}</p>
                      </div>
                    ) : null}
                  </Button>
                  <Button
                    className={selected === 'completed' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="completed"
                    onClick={e => {
                      setFilterRoutes(['route_completed']);
                      setSelected(e.target.name);
                      setShowMergingRoutesMap(false);
                    }}
                    style={{ width: `20%` }}
                  >
                    Completed
                  </Button>

                  <Button
                    className={selected === 'driverList' ? 'btn' : 'btn-simple'}
                    color="info"
                    name="driverList"
                    onClick={e => {
                      fetchAllDriversForSMS();
                      setSelected(e.target.name);
                      setShowMergingRoutesMap(false);
                    }}
                    style={{ width: `20%` }}
                  >
                    Drivers
                    {totalUnreadDriverMessages > 0 ? (
                      <div className="amount-container">
                        <p className="total-amount">{totalUnreadDriverMessages}</p>
                      </div>
                    ) : null}
                  </Button>
                </ButtonGroup>
              </div>
              {selected === 'pending' || selected === 'saved' ? (
                <ReactTable
                  key="1"
                  data={shipmentsToDisplay}
                  columns={
                    filter.includes('shipment_scheduled')
                      ? savedForLaterColumns
                      : filter.includes('shipment_created') ||
                        filter.includes('shipment_pending') ||
                        filter.includes('shipment_pending_schedule') ||
                        filter.includes('shipment_pending_payment')
                      ? pendingColumns
                      : null
                  }
                  sortable={true}
                  resizable={true}
                  defaultPageSize={50}
                  className="-striped -highlight"
                  getTrProps={getTrProps}
                  getTheadThProps={getTheadThProps}
                  onSortedChange={e => resetExpand()}
                  onPageChange={e => resetExpand()}
                  collapseOnSortingChange={true}
                  collapseOnPageChange={true}
                  collapseOnDataChange={true}
                  expanded={expandedItems[selected]}
                  SubComponent={row => {
                    if (selected !== 'pending') {
                      // if(row.original.shipment_type === "direct" || row.original.shipment_type === "bridge"){
                      // console.log('shipment_type', row.original.shipment_type);
                      return (
                        <div
                          style={{
                            backgroundColor: '#17192D',
                            borderRadius: '6px',
                            paddingTop: '15px',
                            paddingLeft: '10px',
                            marginRight: '10px',
                            marginLeft: '10px',
                            marginBottom: '20px',
                            height: 'auto'
                          }}
                        >
                          {selected === 'saved' ? (
                            <div>
                              <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px', paddingBottom: '0px' }}>
                                <Col className="col-8" style={{ textAlign: 'left' }}>
                                  {row.original.shipment_type === 'direct' ||
                                  row.original.shipment_type === 'routed_direct' ||
                                  row.original.shipment_type === 'multidrop_direct' ? (
                                    <strong style={{ color: '#cdcaca', padding: '20px' }}>
                                      Direct Delivery - {row.original.status === 'shipment_scheduled' ? 'Scheduled for ' + formatTimestamp(row.original.scheduled_for) : 'Saved'}
                                      {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : null}
                                    </strong>
                                  ) : row.original.shipment_type === 'bridge' ? (
                                    <strong style={{ color: '#cdcaca', padding: '20px' }}>
                                      Bridge Delivery - {row.original.status === 'shipment_scheduled' ? 'Scheduled for ' + formatTimestamp(row.original.scheduled_for) : 'Saved'}
                                      {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : null}
                                    </strong>
                                  ) : row.original.shipment_type === 'connect' ? (
                                    <strong style={{ color: '#cdcaca', padding: '20px' }}>
                                      Connect Delivery - {row.original.status === 'shipment_scheduled' ? 'Scheduled for ' + formatTimestamp(row.original.scheduled_for) : 'Saved'}
                                      {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : null}
                                    </strong>
                                  ) : null}
                                </Col>
                                {row.original.shipment_type === 'direct' ||
                                row.original.shipment_type === 'connect' ||
                                row.original.shipment_type === 'routed_direct' ||
                                row.original.shipment_type === 'multidrop_direct' ? (
                                  <UpdateShippingFee shipment={row.original} />
                                ) : null}
                                <UpdateDriverPayBeforeClaim shipment={row.original} />
                              </Row>
                              {row.original.shipment_type === 'direct' || row.original.shipment_type === 'connect' ? (
                                <>
                                  <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                                    <Col style={{ textAlign: 'left' }}>
                                      <BasicInfoDirectPick shipment={row.original} />
                                    </Col>
                                    {row.original.Orders[1] ? (
                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <BasicInfoDirectDrop shipment={row.original} />
                                      </Col>
                                    ) : (
                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <strong>Dropoff Information</strong>
                                        <p>
                                          {row.original.customer_blob.first_name} {row.original.customer_blob.last_name}
                                        </p>
                                        <p>{row.original.customer_blob.phone}</p>
                                        <p>{row.original.customer_blob.email}</p>
                                        <p>{row.original.customer_blob.address_1}</p>
                                        <p>
                                          {row.original.customer_blob.city}, {row.original.customer_blob.state}, {row.original.customer_blob.zip}
                                        </p>
                                      </Col>
                                    )}
                                    <Col style={{ textAlign: 'left' }}>
                                      <strong>Delivery Status</strong>
                                      <p>{row.original.status === 'shipment_scheduled' ? 'Scheduled' : null}</p>
                                      <FileViewer shipmentId={row.original.shipment_id} showGenerateBolButton={true} />
                                      <FileUpload shipmentId={row.original.shipment_id} />
                                    </Col>
                                    <Col style={{ textAlign: 'left' }}>
                                      <strong>Load Details</strong>
                                      <LoadDetails shipment={row.original} />
                                    </Col>
                                    {/* <Col>
                                                            <strong>Order Weight</strong>
                                                            <p>{row.original.weight} lbs</p>
                                                        </Col>
                                                        <Col>
                                                            <strong>Pickup Instructions</strong>
                                                            {}
                                                            <p>{row.original.pick_up_instructions}</p>
                                                        </Col>
                                                        <Col>
                                                            <strong>Drop-off Instructions</strong>
                                                            <p>{row.original.drop_off_instructions}</p>
                                                        </Col> */}
                                  </Row>
                                  <Row style={{ padding: '20px' }}>
                                    <Col className="col-3" style={{ textAlign: 'left' }}>
                                      <DeliveryDescription shipment={row.original} />
                                    </Col>

                                    <RegularPickAndDropInstructions shipment={row.original} />

                                    {/* {row.original.Route.RouteDrivers[0] ? (
                                <Col style={{ textAlign: 'left' }}>
                                  <strong>Driver(s)</strong>
                                  <p>
                                    {row.original.Route.RouteDrivers[0].driver_blob.first_name} {row.original.Route.RouteDrivers[0].driver_blob.last_name}
                                  </p>
                                  {row.original.Route.RouteDrivers[1] ? (
                                    <p>
                                      {row.original.Route.RouteDrivers[1].driver_blob.first_name} {row.original.Route.RouteDrivers[1].driver_blob.last_name}
                                    </p>
                                  ) : null}
                                  {row.original.Route.RouteDrivers[2] ? (
                                    <p>
                                      {row.original.Route.RouteDrivers[2].driver_blob.first_name} {row.original.Route.RouteDrivers[2].driver_blob.last_name}
                                    </p>
                                  ) : null}
                                </Col>
                              ) : null} */}
                                  </Row>
                                </>
                              ) : row.original.shipment_type === 'routed_direct' ? (
                                <>
                                  <Row
                                    style={{
                                      paddingLeft: '20px',
                                      paddingRight: '20px',
                                      paddingBottom: '10px',
                                      paddingTop: '10px',
                                      textAlign: 'left'
                                    }}
                                  >
                                    <BasicInfoRoutedWaypoint orders={row.original.Orders} display={false} />
                                    <Col className="col-2" style={{ textAlign: 'left' }}>
                                      <strong>Load Details</strong>
                                      <LoadDetails shipment={row.original} />
                                    </Col>
                                  </Row>
                                </>
                              ) : row.original.shipment_type === 'multidrop_direct' ? (
                                <>
                                  <Row
                                    style={{
                                      paddingLeft: '20px',
                                      paddingRight: '20px',
                                      paddingBottom: '10px',
                                      paddingTop: '10px',
                                      textAlign: 'left'
                                    }}
                                  >
                                    <BasicInfoMultidropWaypoint orders={row.original.Orders} display={false} />
                                    <Col className="col-3" style={{ textAlign: 'left' }}>
                                      <strong>Load Details</strong>
                                      <LoadDetails shipment={row.original} />
                                    </Col>
                                  </Row>
                                </>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      );
                    } else {
                      // for the case (selected === 'pending')
                      return (
                        // <div style={{backgroundColor: '#17192D', borderRadius: '6px', paddingTop: '15px', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px', marginLeft: '10px', marginBottom: '20px', height: 'auto', paddingBottom: '15px'}}>
                        <div className="marketplace-shipment-container">
                          {row.original.shipment_type === 'marketplace' ? (
                            <strong style={{ color: '#cdcaca', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                              Marketplace Delivery {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : <p></p>}
                            </strong>
                          ) : row.original.shipment_type === 'bridge' ? (
                            <strong style={{ color: '#cdcaca', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                              Bridge Delivery {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : <p></p>}
                            </strong>
                          ) : row.original.shipment_type === 'school_lunch' ? (
                            <strong style={{ color: '#cdcaca', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                              School Lunch Delivery {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : <p></p>}
                            </strong>
                          ) : row.original.shipment_type === 'direct' || row.original.shipment_type === 'routed_direct' || row.original.shipment_type === 'multidrop_direct' ? (
                            <strong style={{ color: '#cdcaca', padding: '20px' }}>
                              Direct Delivery {row.original.status === 'shipment_pending_schedule' ? ' - Scheduled for ' + formatTimestamp(row.original.scheduled_for) : null}
                              {row.original.is_expedited ? <p style={{ color: '#f7941d', paddingLeft: '20px' }}>This is an expedited delivery</p> : null}
                            </strong>
                          ) : null}
                          {row.original.shipment_type === 'school_lunch' ? (
                            <>
                              {row.original.Orders.map((order, index) => {
                                return (
                                  <>
                                    {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                    <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                                      <Col className="col-4" style={{ textAlign: 'left' }}>
                                        <strong>Store Info</strong>
                                        <p>{order.location_blob.name}</p>

                                        <p>{order.location_blob.address_1 + ', ' + order.location_blob.city}</p>

                                        <p>{order.location_blob.phone}</p>
                                      </Col>
                                      <Col className="col-4" style={{ textAlign: 'left' }}>
                                        <strong>Customer Info</strong>
                                        <p>{row.original.customer_blob.first_name + ' ' + row.original.customer_blob.last_name}</p>
                                        <p>{row.original.customer_blob.address_1 + ', ' + row.original.customer_blob.city + ', ' + row.original.customer_blob.zip}</p>
                                        <p>{row.original.customer_blob.phone}</p>
                                      </Col>
                                      <Col className="col-4" style={{ textAlign: 'left' }}>
                                        <div className="order-status">
                                          {order.status === 'order_created' ? (
                                            <ButtonGroup>
                                              <Button
                                                style={{ width: `40%`, textAlign: 'center' }}
                                                size="sm"
                                                color="info"
                                                name="ready"
                                                className="btn btn-success"
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  readyOrderAdmin(row.original, order);
                                                }}
                                              >
                                                Ready
                                              </Button>
                                              <Button
                                                style={{ width: `40%` }}
                                                size="sm"
                                                color="info"
                                                name="decline"
                                                className="btn btn-danger"
                                                onClick={() => {
                                                  setShipment(row.original);
                                                  setOrder(order);
                                                  setShowAlertCancelOrder(true);
                                                }}
                                              >
                                                Decline
                                              </Button>
                                              <Button className="btn btn-simple status-button" size="sm" color="info" disabled>
                                                Awaiting Approval
                                              </Button>
                                            </ButtonGroup>
                                          ) : order.status === 'awaiting_driver' ? (
                                            <Button className="btn btn-simple status-button" color="success" disabled>
                                              Approved
                                            </Button>
                                          ) : order.status === 'order_declined' ? (
                                            <Button className="btn btn-simple status-button" color="danger" disabled>
                                              Declined
                                            </Button>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}
                              <SchoolLunchInfo order={row.original.Orders[0]} shipment={row.original} />
                            </>
                          ) : row.original.shipment_type === 'direct' ? (
                            <>
                              <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'center' }}>
                                <Col className="col-2" style={{ textAlign: 'left' }}>
                                  <BasicInfoDirectPick shipment={row.original} />
                                </Col>
                                {row.original.Orders[1] ? (
                                  <Col className="col-2" style={{ textAlign: 'left' }}>
                                    <BasicInfoDirectDrop shipment={row.original} />
                                  </Col>
                                ) : null}
                                <Col className="col-3" style={{ textAlign: 'left' }}>
                                  <strong>Load Details</strong>
                                  <LoadDetails shipment={row.original} />
                                </Col>
                                <Col className="col-3" style={{ textAlign: 'left' }}>
                                  <DeliveryDescription shipment={row.original} />
                                </Col>
                                {(row.original.status === 'shipment_pending' || row.original.status === 'shipment_pending_schedule') && (
                                  <Col className="col-2" style={{ textAlign: 'left' }}>
                                    <QuoteShippingFeeButton shipment={row.original} />
                                  </Col>
                                )}
                              </Row>
                              <Row style={{ padding: '20px' }}>
                                <RegularPickAndDropInstructions shipment={row.original} />
                              </Row>
                            </>
                          ) : row.original.shipment_type === 'routed_direct' ? (
                            <>
                              <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                                <BasicInfoRoutedWaypoint orders={row.original.Orders} display={false} />
                                <Col className="col-2" style={{ textAlign: 'left' }}>
                                  <strong>Load Details</strong>
                                  <LoadDetails shipment={row.original} />
                                </Col>
                                {(row.original.status === 'shipment_pending' || row.original.status === 'shipment_pending_schedule') && (
                                  <Col className="col-2" style={{ textAlign: 'left' }}>
                                    <QuoteShippingFeeButton shipment={row.original} />
                                  </Col>
                                )}

                                {/* <Col className="col-2">
                          <strong>Description of Item(s)</strong>
                          <RoutedDirectItemList orders={row.original.Orders} />
                        </Col> */}
                              </Row>
                            </>
                          ) : row.original.shipment_type === 'multidrop_direct' ? (
                            <>
                              <Row style={{ paddingTop: 10, paddingBottom: 0, marginRight: 0, marginLeft: 0, textAlign: 'left' }}>
                                <BasicInfoMultidropWaypoint orders={row.original.Orders} display={false} />
                                <Col className="col-2" style={{ textAlign: 'left' }}>
                                  <strong>Load Details</strong>
                                  <LoadDetails shipment={row.original} />
                                </Col>

                                {(row.original.status === 'shipment_pending' || row.original.status === 'shipment_pending_schedule') && (
                                  <Col className="col-2" style={{ textAlign: 'left' }}>
                                    <QuoteShippingFeeButton shipment={row.original} />
                                  </Col>
                                )}
                              </Row>
                            </>
                          ) : (
                            row.original.Orders.map((order, index) => {
                              return (
                                <>
                                  {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                  <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '10px' }}>
                                    <Col className="col-3" style={{ textAlign: 'left' }}>
                                      <strong>Store Info</strong>
                                      <p>
                                        {order.location_blob.name} - {order.location_blob.phone}
                                      </p>

                                      <p>{order.location_blob.address_1 + ', ' + order.location_blob.city}</p>

                                      <p>{order.location_blob.phone}</p>
                                      <div>
                                        <p
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setOrder(order);
                                            setShowOrder(true);
                                          }}
                                        >
                                          <i className="fas fa-caret-right fa-md"></i> Number of Items: {getTotalItems(order.Items)}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col className="col-3" style={{ textAlign: 'left' }}>
                                      <strong>Customer Info</strong>
                                      <p>{row.original.customer_blob.first_name + ' ' + row.original.customer_blob.last_name}</p>
                                      <p>{row.original.customer_blob.address_1 + ', ' + row.original.customer_blob.city + ', ' + row.original.customer_blob.zip}</p>
                                      <p>{row.original.customer_blob.phone}</p>

                                      {order.drop_off_instructions ? <p>{order.drop_off_instructions}</p> : null}
                                    </Col>
                                    <Col className="col-3" style={{ textAlign: 'left' }}>
                                      <div className="order-status">
                                        {order.status === 'order_created' ? (
                                          <Button className="btn btn-simple status-button" color="info" disabled>
                                            Awaiting Approval
                                          </Button>
                                        ) : order.status === 'awaiting_driver' ? (
                                          <Button className="btn btn-simple status-button" color="success" disabled>
                                            Approved
                                          </Button>
                                        ) : order.status === 'order_declined' ? (
                                          <Button className="btn btn-simple status-button" color="danger" disabled>
                                            Declined
                                          </Button>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })
                          )}
                        </div>
                      );
                    }
                  }}
                />
              ) : selected === 'ready' || selected === 'progressing' || selected === 'completed' ? (
                <ReactTable
                  key="2"
                  data={routesToDisplay}
                  columns={
                    filterRoutes.includes('route_completed')
                      ? completedColumns
                      : filterRoutes.includes('route_ready') || filterRoutes.includes('route_temp_claimed')
                      ? readyColumns
                      : progressingColumns
                  }
                  sortable={true}
                  resizable={true}
                  defaultPageSize={50}
                  className="-striped -highlight"
                  getTrProps={getTrProps}
                  getTheadThProps={getTheadThProps}
                  onSortedChange={e => resetExpand()}
                  onPageChange={e => resetExpand()}
                  collapseOnSortingChange={true}
                  collapseOnPageChange={true}
                  collapseOnDataChange={true}
                  expanded={expandedItems[selected]}
                  SubComponent={row => {
                    return (
                      <div
                        style={{
                          backgroundColor: '#17192D',
                          borderRadius: '6px',
                          paddingTop: '15px',
                          paddingLeft: '10px',
                          marginRight: '10px',
                          marginLeft: '10px',
                          marginBottom: '20px',
                          height: 'auto'
                        }}
                      >
                        {selected === 'ready' ? (
                          <div>
                            {row.original.Shipments.map((shipment, index) => {
                              return (
                                <div>
                                  <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px', paddingBottom: '0px' }}>
                                    <Col className="col-6" style={{ textAlign: 'left' }}>
                                      {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                      {shipment.shipment_type === 'direct' || shipment.shipment_type === 'routed_direct' || shipment.shipment_type === 'multidrop_direct' ? (
                                        <ShipmentType shipment={shipment} type="Direct" />
                                      ) : shipment.shipment_type === 'bridge' ? (
                                        <ShipmentType shipment={shipment} type="Bridge" />
                                      ) : shipment.shipment_type === 'marketplace' ? (
                                        <ShipmentType shipment={shipment} type="Marketplace" />
                                      ) : shipment.shipment_type === 'school_lunch' ? (
                                        <ShipmentType shipment={shipment} type="School Lunch" />
                                      ) : null}
                                    </Col>
                                    <Col className="col-2" style={{ textAlign: 'left' }}>
                                      {row.original.Shipments.length === 1 && (
                                        <Button
                                          className="btn btn-danger"
                                          style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                          onClick={() => {
                                            setShipment(shipment);
                                            setShowAlertCancelShipment(true);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      )}
                                      {row.original.Shipments.length > 1 && (
                                        <Button
                                          className="btn btn-danger"
                                          style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                          onClick={() => {
                                            setRoute(row.original);
                                            setShipment(shipment);
                                            setShowAlertRemoveShipment(true);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </Col>
                                    {shipment.shipment_type === 'direct' ||
                                    shipment.shipment_type === 'connect' ||
                                    shipment.shipment_type === 'routed_direct' ||
                                    shipment.shipment_type === 'multidrop_direct' ? (
                                      <UpdateShippingFee shipment={shipment} />
                                    ) : null}
                                    <UpdateDriverPayBeforeClaim shipment={shipment} />
                                  </Row>
                                  {shipment.shipment_type === 'direct' || shipment.shipment_type === 'connect' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <DeliveryStatus shipment={shipment} />
                                          <FileViewer shipmentId={shipment.shipment_id} showGenerateBolButton={true} />
                                          <FileUpload shipmentId={shipment.shipment_id} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectPick shipment={shipment} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectDrop shipment={shipment} />
                                        </Col>
                                      </Row>
                                      <Row style={{ padding: '20px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <DeliveryDescription shipment={shipment} />
                                        </Col>
                                        <RegularPickAndDropInstructions shipment={shipment} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'routed_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingTop: '20px',
                                          paddingBottom: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <BasicInfoRoutedWaypoint orders={shipment.Orders} display={false} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'multidrop_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingBottom: '10px',
                                          paddingTop: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <BasicInfoMultidropWaypoint orders={shipment.Orders} display={false} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'school_lunch' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <DeliveryStatus shipment={shipment} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Pickup Information</strong>
                                          <BasicInfo order={shipment.Orders[0]} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>{shipment.customer_blob.first_name + ' ' + shipment.customer_blob.last_name}</p>
                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>{shipment.customer_blob.address_1}</p>
                                          <p>{shipment.customer_blob.city + ', ' + shipment.customer_blob.state + ', ' + shipment.customer_blob.zip}</p>
                                        </Col>
                                      </Row>
                                      <SchoolLunchInfo order={shipment.Orders[0]} shipment={shipment} />
                                    </>
                                  ) : (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <p>
                                            {shipment.vehicle_type === 'box_truck'
                                              ? 'Box Truck'
                                              : shipment.vehicle_type === 'suv'
                                              ? 'SUV'
                                              : shipment.vehicle_type === 'car'
                                              ? 'Car'
                                              : shipment.vehicle_type === 'truck'
                                              ? 'Truck'
                                              : shipment.vehicle_type === 'trailer'
                                              ? 'Truck and Trailer'
                                              : shipment.vehicle_type
                                                  .split('_')
                                                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                                                  .join(' ')}
                                          </p>
                                          <p>{shipment.weight} lbs</p>
                                          <p>{shipment.num_of_gophrs} Gophrs Needed</p>
                                          <p>{shipment.distance.toFixed(2) + ' miles'}</p>
                                          <p>{'$' + shipment.shipping_fee.toFixed(2)}</p>
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <p>
                                            {shipment.status === 'shipment_ready_for_driver'
                                              ? 'Ready for Driver'
                                              : shipment.status === 'shipment_assigned'
                                              ? 'Assigned to Driver'
                                              : shipment.status === 'shipment_enroute'
                                              ? 'En Route'
                                              : shipment.status === 'shipment_completed'
                                              ? 'Completed'
                                              : shipment.status === 'shipment_scheduled'
                                              ? 'Scheduled'
                                              : null}
                                          </p>
                                        </Col>
                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>
                                            {shipment.customer_blob.first_name} {shipment.customer_blob.last_name}
                                          </p>

                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>
                                            {shipment.customer_blob.address_1} {shipment.customer_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                          </p>
                                          <p>
                                            {shipment.customer_blob.city}, {shipment.customer_blob.state}, {shipment.customer_blob.zip}
                                          </p>
                                        </Col>
                                        {shipment.drop_off_instructions ? (
                                          <Col className="col-3" style={{ textAlign: 'left' }}>
                                            <strong>Dropoff Instructions</strong>
                                            <p>{shipment.drop_off_instructions}</p>
                                          </Col>
                                        ) : null}
                                      </Row>
                                      <div style={{ color: '#f7941d', paddingLeft: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                                        <strong>Orders Info</strong>
                                      </div>
                                      {shipment.Orders.map((order, index) => {
                                        return (
                                          <>
                                            {index !== 0 ? <hr style={{ backgroundColor: '#f7941d', width: '90%' }}></hr> : null}
                                            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '0px', paddingBottom: '5px' }}>
                                              <Col className="col-5" style={{ textAlign: 'left' }}>
                                                <div style={{ paddingBottom: '5px' }}>
                                                  <strong>
                                                    <p>
                                                      {index + 1} . {order.location_blob.name}
                                                    </p>
                                                  </strong>
                                                </div>
                                                <p>{order.location_blob.phone}</p>
                                                <p>
                                                  {order.location_blob.address_1} {order.location_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                                </p>
                                                <p>
                                                  {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
                                                </p>
                                                <p>{order.total != null ? '$' + order.total.toFixed(2) : '$' + 0.0}</p>
                                                {order.pick_up_instructions ? (
                                                  <div>
                                                    <p>Pick Up Instruction: {order.pick_up_instructions}</p>
                                                  </div>
                                                ) : null}
                                              </Col>
                                              <Col className="col-5" style={{ textAlign: 'left' }}>
                                                <div className="order-container">
                                                  <div className="item-quantity">
                                                    <strong>Qty.</strong>
                                                  </div>
                                                  <div className="item-name">
                                                    <strong>Product</strong>
                                                  </div>
                                                  <div className="item-total">
                                                    <strong>Price</strong>
                                                  </div>
                                                  <div className="item-weight">
                                                    <strong>Weight</strong>
                                                  </div>
                                                </div>
                                                {order
                                                  ? order.Items.map(item => {
                                                      return (
                                                        <>
                                                          <div className="item-container">
                                                            <div className="item-quantity">{item.quantity}</div>
                                                            <div className="item-name">
                                                              {item.product_blob.name + ' '}
                                                              {item.ItemOptions.length > 0
                                                                ? item.ItemOptions.map((option, index) => {
                                                                    if (index === 0) {
                                                                      return '(' + option.option_name;
                                                                    } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                                      return ', ' + option.option_name;
                                                                    } else {
                                                                      return ', ' + option.option_name + ')';
                                                                    }
                                                                  })
                                                                : null}
                                                            </div>
                                                            <div className="item-total">${item.product_price.toFixed(2)}</div>
                                                            <div className="item-weight">{item.product_blob.weight} lbs.</div>
                                                          </div>
                                                        </>
                                                      );
                                                    })
                                                  : 'Something went wrong'}
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : selected === 'progressing' ? (
                          <div>
                            {row.original.Shipments.map((shipment, index) => {
                              return (
                                <div key={index}>
                                  <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px', paddingBottom: '0px' }}>
                                    <Col className="col-8" style={{ textAlign: 'left' }}>
                                      {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                      {shipment.shipment_type === 'direct' || shipment.shipment_type === 'routed_direct' || shipment.shipment_type === 'multidrop_direct' ? (
                                        <ShipmentType shipment={shipment} type="Direct" />
                                      ) : shipment.shipment_type === 'bridge' ? (
                                        <ShipmentType shipment={shipment} type="Bridge" />
                                      ) : shipment.shipment_type === 'marketplace' ? (
                                        <ShipmentType shipment={shipment} type="Marketplace" />
                                      ) : shipment.shipment_type === 'school_lunch' ? (
                                        <ShipmentType shipment={shipment} type="School Lunch" />
                                      ) : null}
                                    </Col>
                                    <Col className="col-2" style={{ textAlign: 'left' }}>
                                      {row.original.Shipments.length > 1 && (
                                        <Button
                                          className="btn btn-warning"
                                          style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                          onClick={() => {
                                            setRoute(row.original);
                                            setShipment(shipment);
                                            setShowAlertRemoveShipment(true);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </Col>
                                    {shipment.shipment_type === 'direct' ||
                                    shipment.shipment_type === 'connect' ||
                                    shipment.shipment_type === 'routed_direct' ||
                                    shipment.shipment_type === 'multidrop_direct' ? (
                                      <UpdateShippingFee shipment={shipment} />
                                    ) : null}
                                  </Row>
                                  {shipment.shipment_type === 'direct' || shipment.shipment_type === 'connect' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <p>
                                            {shipment.status === 'shipment_assigned'
                                              ? 'Assigned to Driver'
                                              : shipment.status === 'shipment_claimed'
                                              ? 'Driver Claimed'
                                              : shipment.status === 'shipment_completed'
                                              ? 'Completed✅'
                                              : null}
                                          </p>
                                          <p>
                                            Pick up: {shipment.Orders[0].status === 'order_completed' ? formatTimestamp(shipment.Orders[0].completed_at) + '✅' : 'approaching'}
                                          </p>
                                          {shipment.Orders[0].status !== 'order_completed' && <ETAUpdate handleOrderETAUpdate={handleOrderETAUpdate} order={shipment.Orders[0]} />}
                                          {(shipment.Orders[0].status === 'driver_assigned' || shipment.Orders[0].status === 'driver_claimed') && (
                                            <CompleteOrderButton shipmentId={shipment.shipment_id} orderId={shipment.Orders[0].order_id} notify={props.notify} />
                                          )}

                                          <p>
                                            Drop off:{' '}
                                            {shipment.Orders[0].status === 'order_completed' && shipment.Orders[1].status === 'order_completed'
                                              ? formatTimestamp(shipment.Orders[1].completed_at) + '✅'
                                              : shipment.Orders[0].status === 'order_completed' && shipment.Orders[1].status !== 'order_completed'
                                              ? 'delivering'
                                              : 'waiting'}
                                          </p>
                                          {shipment.Orders[1].status !== 'order_completed' && <ETAUpdate handleOrderETAUpdate={handleOrderETAUpdate} order={shipment.Orders[1]} />}
                                          <FileViewer shipmentId={shipment.shipment_id} showGenerateBolButton={true} />
                                          <FileUpload shipmentId={shipment.shipment_id} />
                                        </Col>
                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectPick shipment={shipment} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectDrop shipment={shipment} />
                                        </Col>
                                      </Row>
                                      <Row style={{ padding: '20px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <DeliveryDescription shipment={shipment} />
                                        </Col>
                                        <RegularPickAndDropInstructions shipment={shipment} />
                                        {row.original.RouteDrivers[0] ? (
                                          <Col style={{ textAlign: 'left' }}>
                                            <DriverCounts count={row.original.RouteDrivers.length} />
                                            <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                            {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                            {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'routed_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingTop: '20px',
                                          paddingBottom: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />

                                          <p style={{ paddingBottom: '15px' }}>
                                            {shipment.status === 'shipment_assigned'
                                              ? 'Assigned to Driver'
                                              : shipment.status === 'shipment_claimed'
                                              ? 'Driver Claimed'
                                              : shipment.status === 'shipment_completed'
                                              ? 'Completed✅'
                                              : null}
                                          </p>
                                          <DriverCounts count={row.original.RouteDrivers.length} />
                                          <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>
                                        <BasicInfoRoutedWaypoint orders={shipment.Orders} display={true} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'multidrop_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingBottom: '10px',
                                          paddingTop: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col className="col-4" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />

                                          <p style={{ paddingBottom: '15px' }}>
                                            {shipment.status === 'shipment_assigned'
                                              ? 'Assigned to Driver'
                                              : shipment.status === 'shipment_claimed'
                                              ? 'Driver Claimed'
                                              : shipment.status === 'shipment_completed'
                                              ? 'Completed✅'
                                              : null}
                                          </p>

                                          <DriverCounts count={row.original.RouteDrivers.length} />
                                          <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>
                                        <BasicInfoMultidropWaypoint orders={shipment.Orders} display={true} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'school_lunch' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <DeliveryStatus shipment={shipment} />
                                          <p>
                                            Pick up: {shipment.Orders[0].status === 'order_completed' ? formatTimestamp(shipment.Orders[0].completed_at) + '✅' : 'approaching'}
                                          </p>
                                          <p>
                                            Drop off:
                                            {shipment.Orders[0].status === 'order_completed' && shipment.status === 'shipment_completed'
                                              ? formatTimestamp(shipment.completed_at) + '✅'
                                              : shipment.Orders[0].status === 'order_completed' && shipment.status !== 'shipment_completed'
                                              ? 'delivering'
                                              : 'waiting'}
                                          </p>
                                        </Col>

                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          {' '}
                                          <DriverCounts count={row.original.RouteDrivers.length} />
                                          <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Pickup Information</strong>
                                          <BasicInfo order={shipment.Orders[0]} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>{shipment.customer_blob.first_name + ' ' + shipment.customer_blob.last_name}</p>
                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>{shipment.customer_blob.address_1}</p>
                                          <p>{shipment.customer_blob.city + ', ' + shipment.customer_blob.state + ', ' + shipment.customer_blob.zip}</p>
                                        </Col>
                                      </Row>
                                      <SchoolLunchInfo order={shipment.Orders[0]} shipment={shipment} />
                                    </>
                                  ) : (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <p>
                                            {shipment.vehicle_type === 'box_truck'
                                              ? 'Box Truck'
                                              : shipment.vehicle_type === 'suv'
                                              ? 'SUV'
                                              : shipment.vehicle_type === 'car'
                                              ? 'Car'
                                              : shipment.vehicle_type === 'truck'
                                              ? 'Truck'
                                              : shipment.vehicle_type === 'trailer'
                                              ? 'Truck and Trailer'
                                              : shipment.vehicle_type
                                                  .split('_')
                                                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                                                  .join(' ')}
                                          </p>
                                          <p>{shipment.weight} lbs</p>
                                          <p>{shipment.num_of_gophrs} Gophrs Needed</p>
                                          <p>{shipment.distance.toFixed(2) + ' miles'}</p>
                                          <p>{'$' + shipment.shipping_fee.toFixed(2)}</p>
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <Row>
                                            <Col style={{ textAlign: 'left' }}>
                                              <strong>Delivery Status</strong>
                                              <p>
                                                {shipment.status === 'shipment_ready_for_driver'
                                                  ? 'Ready for Driver'
                                                  : shipment.status === 'shipment_assigned'
                                                  ? 'Assigned to Driver'
                                                  : shipment.status === 'shipment_enroute'
                                                  ? 'En Route'
                                                  : shipment.status === 'shipment_completed'
                                                  ? 'Completed'
                                                  : shipment.status === 'shipment_scheduled'
                                                  ? 'Scheduled'
                                                  : null}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row style={{ textAlign: 'left' }}>
                                            {row.original.RouteDrivers[0] ? (
                                              <Col style={{ textAlign: 'left', paddingTop: '30px' }}>
                                                <DriverCounts count={row.original.RouteDrivers.length} />
                                                <p>
                                                  {row.original.RouteDrivers[0].driver_blob.first_name} {row.original.RouteDrivers[0].driver_blob.last_name}
                                                </p>
                                                {row.original.RouteDrivers[1] ? (
                                                  <p>
                                                    {row.original.RouteDrivers[1].driver_blob.first_name} {row.original.RouteDrivers[1].driver_blob.last_name}
                                                  </p>
                                                ) : null}
                                                {row.original.RouteDrivers[2] ? (
                                                  <p>
                                                    {row.original.RouteDrivers[2].driver_blob.first_name} {row.original.RouteDrivers[2].driver_blob.last_name}
                                                  </p>
                                                ) : null}
                                              </Col>
                                            ) : null}
                                          </Row>
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>
                                            {shipment.customer_blob.first_name} {shipment.customer_blob.last_name}
                                          </p>

                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>
                                            {shipment.customer_blob.address_1} {shipment.customer_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                          </p>
                                          <p>
                                            {shipment.customer_blob.city}, {shipment.customer_blob.state}, {shipment.customer_blob.zip}
                                          </p>
                                        </Col>
                                        {shipment.drop_off_instructions ? (
                                          <Col style={{ textAlign: 'left' }}>
                                            <strong>Dropoff Instructions</strong>
                                            <p>{shipment.drop_off_instructions}</p>
                                          </Col>
                                        ) : null}
                                      </Row>
                                      <div style={{ color: '#f7941d', paddingLeft: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                                        <strong>Orders Info</strong>
                                      </div>
                                      {shipment.Orders.map((order, index) => {
                                        return (
                                          <>
                                            {index !== 0 ? <hr style={{ backgroundColor: '#f7941d', width: '90%' }}></hr> : null}
                                            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '0px', paddingBottom: '5px' }}>
                                              <Col style={{ textAlign: 'left' }}>
                                                <div style={{ paddingBottom: '5px' }}>
                                                  <strong>
                                                    <p>
                                                      {index + 1} . {order.location_blob.name}
                                                    </p>
                                                  </strong>
                                                </div>
                                                <p>{order.location_blob.phone}</p>
                                                <p>
                                                  {order.location_blob.address_1} {order.location_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                                </p>
                                                <p>
                                                  {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
                                                </p>
                                                <p>{order.total != null ? '$' + order.total.toFixed(2) : '$' + 0.0}</p>
                                                {order.pick_up_instructions ? (
                                                  <div>
                                                    <p>Pick Up Instruction: {order.pick_up_instructions}</p>
                                                  </div>
                                                ) : null}
                                              </Col>
                                              <Col style={{ textAlign: 'left' }}>
                                                <div className="order-container">
                                                  <div className="item-quantity">
                                                    <strong>Qty.</strong>
                                                  </div>
                                                  <div className="item-name">
                                                    <strong>Product</strong>
                                                  </div>
                                                  <div className="item-total">
                                                    <strong>Price</strong>
                                                  </div>
                                                  <div className="item-weight">
                                                    <strong>Weight</strong>
                                                  </div>
                                                </div>
                                                {order
                                                  ? order.Items.map(item => {
                                                      return (
                                                        <div className="item-container">
                                                          <div className="item-quantity">{item.quantity}</div>
                                                          <div className="item-name">
                                                            {item.product_blob.name + ' '}
                                                            {item.ItemOptions.length > 0
                                                              ? item.ItemOptions.map((option, index) => {
                                                                  if (index === 0) {
                                                                    return '(' + option.option_name;
                                                                  } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                                    return ', ' + option.option_name;
                                                                  } else {
                                                                    return ', ' + option.option_name + ')';
                                                                  }
                                                                })
                                                              : null}
                                                          </div>
                                                          <div className="item-total">${item.product_price.toFixed(2)}</div>
                                                          <div className="item-weight">{item.product_blob.weight} lbs.</div>
                                                        </div>
                                                      );
                                                    })
                                                  : 'Something went wrong'}
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : selected === 'completed' ? (
                          <div>
                            {row.original.Shipments.map((shipment, index) => {
                              return (
                                <div>
                                  <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px', paddingBottom: '0px' }}>
                                    <Col className="col-8" style={{ textAlign: 'left' }}>
                                      {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                      {shipment.shipment_type === 'direct' || shipment.shipment_type === 'routed_direct' || shipment.shipment_type === 'multidrop_direct' ? (
                                        <ShipmentType shipment={shipment} type="Direct" />
                                      ) : shipment.shipment_type === 'bridge' ? (
                                        <ShipmentType shipment={shipment} type="Bridge" />
                                      ) : shipment.shipment_type === 'marketplace' ? (
                                        <ShipmentType shipment={shipment} type="Marketplace" />
                                      ) : shipment.shipment_type === 'school_lunch' ? (
                                        <ShipmentType shipment={shipment} type="School Lunch" />
                                      ) : null}
                                    </Col>
                                    {shipment.shipment_type === 'direct' ||
                                    shipment.shipment_type === 'connect' ||
                                    shipment.shipment_type === 'routed_direct' ||
                                    shipment.shipment_type === 'multidrop_direct' ? (
                                      <UpdateShippingFee shipment={shipment} />
                                    ) : null}
                                    <Col className="col-2" style={{ textAlign: 'left' }}>
                                      <Button
                                        className="btn btn-success"
                                        style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                        onClick={() => {
                                          setShipment(shipment);
                                          setShowChargeSetupFee(true);
                                        }}
                                      >
                                        Setup Fee
                                      </Button>
                                    </Col>
                                  </Row>
                                  {shipment.shipment_type === 'direct' || shipment.shipment_type === 'connect' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                          {shipment.shipping_fee_blob.hasOwnProperty('setup_fee') && <p>{'$' + shipment.shipping_fee_blob.setup_fee.toFixed(2)}(setup_fee)</p>}
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <DeliveryStatus shipment={shipment} />
                                          <FileViewer shipmentId={shipment.shipment_id} showGenerateBolButton={true} />
                                          <FileUpload shipmentId={shipment.shipment_id} />
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectPick shipment={shipment} />
                                        </Col>

                                        <Col style={{ textAlign: 'left' }}>
                                          <BasicInfoDirectDrop shipment={shipment} />
                                        </Col>
                                      </Row>
                                      <Row style={{ padding: '20px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <DeliveryDescription shipment={shipment} />
                                        </Col>
                                        <RegularPickAndDropInstructions shipment={shipment} />
                                        {row.original.RouteDrivers[0] ? (
                                          <Col style={{ textAlign: 'left' }}>
                                            <DriverCounts count={row.original.RouteDrivers.length} />
                                            <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />

                                            {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}

                                            {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'routed_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingTop: '20px',
                                          paddingBottom: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                          {shipment.shipping_fee_blob.hasOwnProperty('setup_fee') && <p>{'$' + shipment.shipping_fee_blob.setup_fee.toFixed(2)}(setup_fee)</p>}
                                          <DeliveryStatus shipment={shipment} />

                                          {row.original.RouteDrivers[0] ? (
                                            <>
                                              <DriverCounts count={row.original.RouteDrivers.length} />
                                              <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                            </>
                                          ) : null}
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}

                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>
                                        <BasicInfoRoutedWaypoint orders={shipment.Orders} display={true} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'multidrop_direct' ? (
                                    <>
                                      <Row
                                        style={{
                                          paddingLeft: '20px',
                                          paddingRight: '20px',
                                          paddingBottom: '10px',
                                          paddingTop: '10px',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <Col className="col-2.2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />

                                          {shipment.shipping_fee_blob.hasOwnProperty('setup_fee') && <p>{'$' + shipment.shipping_fee_blob.setup_fee.toFixed(2)}(setup_fee)</p>}
                                          <DeliveryStatus shipment={shipment} />

                                          <DriverCounts count={row.original.RouteDrivers.length} />
                                          <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>
                                        <BasicInfoMultidropWaypoint orders={shipment.Orders} display={true} />
                                      </Row>
                                    </>
                                  ) : shipment.shipment_type === 'school_lunch' ? (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <LoadDetails shipment={shipment} />
                                        </Col>
                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <strong>Delivery Status</strong>
                                          <DeliveryStatus shipment={shipment} />
                                        </Col>

                                        <Col className="col-2" style={{ textAlign: 'left' }}>
                                          <DriverCounts count={row.original.RouteDrivers.length} />
                                          <DriverPay shipment={shipment} driver={row.original.RouteDrivers[0]} driverStatus="driver" />
                                          {row.original.RouteDrivers[1] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[1]} driverStatus="passenger" /> : null}
                                          {row.original.RouteDrivers[2] ? <DriverPay shipment={shipment} driver={row.original.RouteDrivers[2]} driverStatus="passenger" /> : null}
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Pickup Information</strong>
                                          <BasicInfo order={shipment.Orders[0]} />
                                        </Col>

                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>{shipment.customer_blob.first_name + ' ' + shipment.customer_blob.last_name}</p>
                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>{shipment.customer_blob.address_1}</p>
                                          <p>{shipment.customer_blob.city + ', ' + shipment.customer_blob.state + ', ' + shipment.customer_blob.zip}</p>
                                        </Col>
                                      </Row>
                                      <SchoolLunchInfo order={shipment.Orders[0]} shipment={shipment} />
                                    </>
                                  ) : (
                                    <>
                                      <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Load Details</strong>
                                          <p>
                                            {shipment.vehicle_type === 'box_truck'
                                              ? 'Box Truck'
                                              : shipment.vehicle_type === 'suv'
                                              ? 'SUV'
                                              : shipment.vehicle_type === 'car'
                                              ? 'Car'
                                              : shipment.vehicle_type === 'truck'
                                              ? 'Truck'
                                              : shipment.vehicle_type === 'trailer'
                                              ? 'Truck and Trailer'
                                              : shipment.vehicle_type
                                                  .split('_')
                                                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                                                  .join(' ')}
                                          </p>
                                          <p>{shipment.weight} lbs</p>
                                          <p>{shipment.num_of_gophrs} Gophrs Needed</p>
                                          <p>{shipment.distance.toFixed(2) + ' miles'}</p>
                                          <p>{'$' + shipment.shipping_fee.toFixed(2)}</p>
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <Row>
                                            <Col style={{ textAlign: 'left' }}>
                                              <strong>Delivery Status</strong>
                                              <p>
                                                {shipment.status === 'shipment_ready_for_driver'
                                                  ? 'Ready for Driver'
                                                  : shipment.status === 'shipment_assigned'
                                                  ? 'Assigned to Driver'
                                                  : shipment.status === 'shipment_enroute'
                                                  ? 'En Route'
                                                  : shipment.status === 'shipment_completed'
                                                  ? 'Completed'
                                                  : shipment.status === 'shipment_scheduled'
                                                  ? 'Scheduled'
                                                  : null}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row style={{ textAlign: 'left' }}>
                                            {row.original.RouteDrivers[0] ? (
                                              <Col style={{ textAlign: 'left', paddingTop: '30px' }}>
                                                <DriverCounts count={row.original.RouteDrivers.length} />
                                                <p>
                                                  {row.original.RouteDrivers[0].driver_blob.first_name} {row.original.RouteDrivers[0].driver_blob.last_name}
                                                </p>
                                                {row.original.RouteDrivers[1] ? (
                                                  <p>
                                                    {row.original.RouteDrivers[1].driver_blob.first_name} {row.original.RouteDrivers[1].driver_blob.last_name}
                                                  </p>
                                                ) : null}
                                                {row.original.RouteDrivers[2] ? (
                                                  <p>
                                                    {row.original.RouteDrivers[2].driver_blob.first_name} {row.original.RouteDrivers[2].driver_blob.last_name}
                                                  </p>
                                                ) : null}
                                              </Col>
                                            ) : null}
                                          </Row>
                                        </Col>
                                        <Col style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Information</strong>
                                          <p>
                                            {shipment.customer_blob.first_name} {shipment.customer_blob.last_name}
                                          </p>

                                          <p>{shipment.customer_blob.phone}</p>
                                          <p>
                                            {shipment.customer_blob.address_1} {shipment.customer_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                          </p>
                                          <p>
                                            {shipment.customer_blob.city}, {shipment.customer_blob.state}, {shipment.customer_blob.zip}
                                          </p>
                                        </Col>
                                        {shipment.drop_off_instructions ? (
                                          <Col style={{ textAlign: 'left' }}>
                                            <strong>Dropoff Instructions</strong>
                                            <p>{shipment.drop_off_instructions}</p>
                                          </Col>
                                        ) : null}
                                      </Row>
                                      <div style={{ color: '#f7941d', paddingLeft: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                                        <strong>Orders Info</strong>
                                      </div>
                                      {shipment.Orders.map((order, index) => {
                                        return (
                                          <>
                                            {index !== 0 ? <hr style={{ backgroundColor: '#f7941d', width: '90%' }}></hr> : null}
                                            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '0px', paddingBottom: '5px' }}>
                                              <Col style={{ textAlign: 'left' }}>
                                                <div style={{ paddingBottom: '5px' }}>
                                                  <strong>
                                                    <p>
                                                      {index + 1} . {order.location_blob.name}
                                                    </p>
                                                  </strong>
                                                </div>
                                                <p>{order.location_blob.phone}</p>
                                                <p>
                                                  {order.location_blob.address_1} {order.location_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                                </p>
                                                <p>
                                                  {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
                                                </p>
                                                <p>{order.total != null ? '$' + order.total.toFixed(2) : '$' + 0.0}</p>
                                                {order.pick_up_instructions ? (
                                                  <div>
                                                    <p>Pick Up Instruction: {order.pick_up_instructions}</p>
                                                  </div>
                                                ) : null}
                                              </Col>
                                              <Col style={{ textAlign: 'left' }}>
                                                <div className="order-container">
                                                  <div className="item-quantity">
                                                    <strong>Qty.</strong>
                                                  </div>
                                                  <div className="item-name">
                                                    <strong>Product</strong>
                                                  </div>
                                                  <div className="item-total">
                                                    <strong>Price</strong>
                                                  </div>
                                                  <div className="item-weight">
                                                    <strong>Weight</strong>
                                                  </div>
                                                </div>
                                                {order
                                                  ? order.Items.map(item => {
                                                      return (
                                                        <div className="item-container">
                                                          <div className="item-quantity">{item.quantity}</div>
                                                          <div className="item-name">
                                                            {item.product_blob.name + ' '}
                                                            {item.ItemOptions.length > 0
                                                              ? item.ItemOptions.map((option, index) => {
                                                                  if (index === 0) {
                                                                    return '(' + option.option_name;
                                                                  } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                                    return ', ' + option.option_name;
                                                                  } else {
                                                                    return ', ' + option.option_name + ')';
                                                                  }
                                                                })
                                                              : null}
                                                          </div>
                                                          <div className="item-total">${item.product_price.toFixed(2)}</div>
                                                          <div className="item-weight">{item.product_blob.weight} lbs.</div>
                                                        </div>
                                                      );
                                                    })
                                                  : 'Something went wrong'}
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    );
                  }}
                />
              ) : selected === 'merging' ? (
                <ReactTable
                  key="3"
                  data={mergingRoutes}
                  columns={mergingRoutesColumns}
                  sortable={true}
                  resizable={true}
                  defaultPageSize={50}
                  className="-striped -highlight"
                  getTrProps={getTrProps}
                  getTheadThProps={getTheadThProps}
                  onSortedChange={e => resetExpand()}
                  onPageChange={e => resetExpand()}
                  collapseOnSortingChange={true}
                  collapseOnPageChange={true}
                  collapseOnDataChange={true}
                  expanded={expandedItems[selected]}
                  SubComponent={row => {
                    return (
                      <div
                        style={{
                          backgroundColor: '#17192D',
                          borderRadius: '6px',
                          paddingTop: '15px',
                          paddingLeft: '10px',
                          marginRight: '10px',
                          marginLeft: '10px',
                          marginBottom: '20px',
                          height: 'auto'
                        }}
                      >
                        <div>
                          {row.original.Shipments.map((shipment, index) => {
                            return (
                              <div>
                                <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px', paddingBottom: '0px' }}>
                                  <Col className="col-8" style={{ textAlign: 'left' }}>
                                    {index !== 0 ? <hr style={{ backgroundColor: '#cdcaca' }}></hr> : null}
                                    {shipment.shipment_type === 'direct' || shipment.shipment_type === 'routed_direct' || shipment.shipment_type === 'multidrop_direct' ? (
                                      <ShipmentType shipment={shipment} type="Direct" />
                                    ) : shipment.shipment_type === 'bridge' ? (
                                      <ShipmentType shipment={shipment} type="Bridge" />
                                    ) : shipment.shipment_type === 'marketplace' ? (
                                      <ShipmentType shipment={shipment} type="Marketplace" />
                                    ) : shipment.shipment_type === 'school_lunch' ? (
                                      <ShipmentType shipment={shipment} type="School Lunch" />
                                    ) : null}
                                  </Col>
                                  {shipment.shipment_type === 'direct' ||
                                  shipment.shipment_type === 'connect' ||
                                  shipment.shipment_type === 'routed_direct' ||
                                  shipment.shipment_type === 'multidrop_direct' ? (
                                    <UpdateShippingFee shipment={shipment} />
                                  ) : null}
                                  <UpdateDriverPayBeforeClaim shipment={shipment} />
                                  {/* <Col className="col-2" style={{ textAlign: 'left' }}>
                                  <Button
                                    className="btn btn-danger"
                                    style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                    onClick={() => {
                                      setShipment(shipment);
                                      setShowAlertCancelShipment(true);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                {shipment.shipment_type === 'direct' || shipment.shipment_type === 'connect' ? (
                                  <Col className="col-2" style={{ textAlign: 'left' }}>
                                    <Button
                                      className="btn btn-success"
                                      style={{ padding: `5px 10px`, margin: 0, display: `flex`, flexDirection: `column` }}
                                      onClick={() => {
                                        setShipment(shipment);
                                        setShowUpdateShippingFee(true);
                                      }}
                                    >
                                      Shipping Fee
                                    </Button>
                                  </Col>
                                ) : null} */}
                                </Row>
                                {shipment.shipment_type === 'direct' || shipment.shipment_type === 'connect' ? (
                                  <>
                                    <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Load Details</strong>
                                        <LoadDetails shipment={shipment} />
                                      </Col>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Delivery Status</strong>
                                        <DeliveryStatus shipment={shipment} />
                                        <FileViewer shipmentId={shipment.shipment_id} showGenerateBolButton={true} />
                                        <FileUpload shipmentId={shipment.shipment_id} />
                                      </Col>
                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <BasicInfoDirectPick shipment={shipment} />
                                      </Col>

                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <BasicInfoDirectDrop shipment={shipment} />
                                      </Col>
                                    </Row>
                                    <Row style={{ padding: '20px' }}>
                                      <Col style={{ textAlign: 'left' }}>
                                        <DeliveryDescription shipment={shipment} />
                                      </Col>
                                      <RegularPickAndDropInstructions shipment={shipment} />
                                    </Row>
                                  </>
                                ) : shipment.shipment_type === 'routed_direct' ? (
                                  <>
                                    <Row
                                      style={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingTop: '20px',
                                        paddingBottom: '10px',
                                        textAlign: 'left'
                                      }}
                                    >
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Load Details</strong>
                                        <LoadDetails shipment={shipment} />
                                      </Col>
                                      <BasicInfoRoutedWaypoint orders={shipment.Orders} display={false} />
                                    </Row>
                                  </>
                                ) : shipment.shipment_type === 'multidrop_direct' ? (
                                  <>
                                    <Row
                                      style={{
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '10px',
                                        paddingTop: '10px',
                                        textAlign: 'left'
                                      }}
                                    >
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Load Details</strong>
                                        <LoadDetails shipment={shipment} />
                                      </Col>
                                      <BasicInfoMultidropWaypoint orders={shipment.Orders} display={false} />
                                    </Row>
                                  </>
                                ) : shipment.shipment_type === 'school_lunch' ? (
                                  <>
                                    <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Load Details</strong>
                                        <LoadDetails shipment={shipment} />
                                      </Col>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Delivery Status</strong>
                                        <DeliveryStatus shipment={shipment} />
                                      </Col>

                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <strong>Pickup Information</strong>
                                        <BasicInfo order={shipment.Orders[0]} />
                                      </Col>

                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <strong>Dropoff Information</strong>
                                        <p>{shipment.customer_blob.first_name + ' ' + shipment.customer_blob.last_name}</p>
                                        <p>{shipment.customer_blob.phone}</p>
                                        <p>{shipment.customer_blob.address_1}</p>
                                        <p>{shipment.customer_blob.city + ', ' + shipment.customer_blob.state + ', ' + shipment.customer_blob.zip}</p>
                                      </Col>
                                    </Row>
                                    <SchoolLunchInfo order={shipment.Orders[0]} shipment={shipment} />
                                  </>
                                ) : (
                                  <>
                                    <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px' }}>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Load Details</strong>
                                        <p>
                                          {shipment.vehicle_type === 'box_truck'
                                            ? 'Box Truck'
                                            : shipment.vehicle_type === 'suv'
                                            ? 'SUV'
                                            : shipment.vehicle_type === 'car'
                                            ? 'Car'
                                            : shipment.vehicle_type === 'truck'
                                            ? 'Truck'
                                            : shipment.vehicle_type === 'trailer'
                                            ? 'Truck and Trailer'
                                            : shipment.vehicle_type
                                                .split('_')
                                                .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                                                .join(' ')}
                                        </p>
                                        <p>{shipment.weight} lbs</p>
                                        <p>{shipment.num_of_gophrs} Gophrs Needed</p>
                                        <p>{shipment.distance.toFixed(2) + ' miles'}</p>
                                        <p>{'$' + shipment.shipping_fee.toFixed(2)}</p>
                                      </Col>
                                      <Col className="col-2" style={{ textAlign: 'left' }}>
                                        <strong>Delivery Status</strong>
                                        <p>
                                          {shipment.status === 'shipment_ready_for_driver'
                                            ? 'Ready for Driver'
                                            : shipment.status === 'shipment_assigned'
                                            ? 'Assigned to Driver'
                                            : shipment.status === 'shipment_enroute'
                                            ? 'En Route'
                                            : shipment.status === 'shipment_completed'
                                            ? 'Completed'
                                            : shipment.status === 'shipment_scheduled'
                                            ? 'Scheduled'
                                            : null}
                                        </p>
                                      </Col>
                                      <Col className="col-3" style={{ textAlign: 'left' }}>
                                        <strong>Dropoff Information</strong>
                                        <p>
                                          {shipment.customer_blob.first_name} {shipment.customer_blob.last_name}
                                        </p>

                                        <p>{shipment.customer_blob.phone}</p>
                                        <p>
                                          {shipment.customer_blob.address_1} {shipment.customer_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                        </p>
                                        <p>
                                          {shipment.customer_blob.city}, {shipment.customer_blob.state}, {shipment.customer_blob.zip}
                                        </p>
                                      </Col>
                                      {shipment.drop_off_instructions ? (
                                        <Col className="col-3" style={{ textAlign: 'left' }}>
                                          <strong>Dropoff Instructions</strong>
                                          <p>{shipment.drop_off_instructions}</p>
                                        </Col>
                                      ) : null}
                                    </Row>
                                    <div style={{ color: '#f7941d', paddingLeft: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                                      <strong>Orders Info</strong>
                                    </div>
                                    {shipment.Orders.map((order, index) => {
                                      return (
                                        <>
                                          {index !== 0 ? <hr style={{ backgroundColor: '#f7941d', width: '90%' }}></hr> : null}
                                          <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '0px', paddingBottom: '5px' }}>
                                            <Col className="col-5" style={{ textAlign: 'left' }}>
                                              <div style={{ paddingBottom: '5px' }}>
                                                <strong>
                                                  <p>
                                                    {index + 1} . {order.location_blob.name}
                                                  </p>
                                                </strong>
                                              </div>
                                              <p>{order.location_blob.phone}</p>
                                              <p>
                                                {order.location_blob.address_1} {order.location_blob.address_2 !== null ? shipment.customer_blob.address_2 : ''}
                                              </p>
                                              <p>
                                                {order.location_blob.city}, {order.location_blob.state}, {order.location_blob.zip}
                                              </p>
                                              <p>{order.total != null ? '$' + order.total.toFixed(2) : '$' + 0.0}</p>
                                              {order.pick_up_instructions ? (
                                                <div>
                                                  <p>Pick Up Instruction: {order.pick_up_instructions}</p>
                                                </div>
                                              ) : null}
                                            </Col>
                                            <Col className="col-5" style={{ textAlign: 'left' }}>
                                              <div className="order-container">
                                                <div className="item-quantity">
                                                  <strong>Qty.</strong>
                                                </div>
                                                <div className="item-name">
                                                  <strong>Product</strong>
                                                </div>
                                                <div className="item-total">
                                                  <strong>Price</strong>
                                                </div>
                                                <div className="item-weight">
                                                  <strong>Weight</strong>
                                                </div>
                                              </div>
                                              {order
                                                ? order.Items.map(item => {
                                                    return (
                                                      <>
                                                        <div className="item-container">
                                                          <div className="item-quantity">{item.quantity}</div>
                                                          <div className="item-name">
                                                            {item.product_blob.name + ' '}
                                                            {item.ItemOptions.length > 0
                                                              ? item.ItemOptions.map((option, index) => {
                                                                  if (index === 0) {
                                                                    return '(' + option.option_name;
                                                                  } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                                                                    return ', ' + option.option_name;
                                                                  } else {
                                                                    return ', ' + option.option_name + ')';
                                                                  }
                                                                })
                                                              : null}
                                                          </div>
                                                          <div className="item-total">${item.product_price.toFixed(2)}</div>
                                                          <div className="item-weight">{item.product_blob.weight} lbs.</div>
                                                        </div>
                                                      </>
                                                    );
                                                  })
                                                : 'Something went wrong'}
                                            </Col>
                                          </Row>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                />
              ) : (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={driverNameSMS}
                      onChange={filterDriverSMS}
                      style={{
                        fontSize: '12px',
                        padding: '8px',
                        borderRadius: '8px',
                        alignSelf: 'flex-end'
                      }}
                    />
                  </div>
                  <ReactTable
                    key="4"
                    // data={driversToDisplay}
                    data={driversToSMSFiltered}
                    columns={driverListColumns}
                    sortable={true}
                    resizable={true}
                    defaultPageSize={50}
                    className="-striped -highlight"
                    getTrProps={getTrProps}
                    getTheadThProps={getTheadThProps}
                    onSortedChange={e => resetExpand()}
                    onPageChange={e => resetExpand()}
                    collapseOnSortingChange={true}
                    collapseOnPageChange={true}
                    collapseOnDataChange={true}
                    expanded={expandedItems[selected]}
                    SubComponent={row => {
                      return (
                        <div
                          style={{
                            backgroundColor: '#17192D',
                            borderRadius: '6px',
                            paddingTop: '15px',
                            paddingLeft: '10px',
                            marginRight: '10px',
                            marginLeft: '10px',
                            marginBottom: '20px',
                            height: 'auto'
                          }}
                        ></div>
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </ResizableBox>
          {showDispatchMap && (
            <ResizableBox className="box" width={3000} height={1200} minConstraints={[1000, 1000]} maxConstraints={[10000, 2000]} draggableOpts={{ grid: [10, 10] }} axis="x">
              <div className="float-child-map">
                <DispatchMap drivers={driversToDisplay} coordinates={coordinates} trafficLayer={trafficLayer} />
              </div>
            </ResizableBox>
          )}
        </div>
      </div>
      <div hidden={!showOrder}>
        <ReactBSAlert
          style={{ display: 'block', width: '700px' }}
          title={order ? order.location_blob.name : 'Something Went Wrong'}
          onCancel={() => {
            setShowOrder(false);
            setOrder(null);
          }}
          cancelBtnBsStyle="default"
          cancelBtnText="Close"
          showConfirm={false}
          showCancel
          btnSize=""
        >
          <div className="order-container">
            <div className="item-quantity">
              <strong>Qty.</strong>
            </div>
            <div className="item-name">
              <strong>Product</strong>
            </div>
            <div className="item-total">
              <strong>Price</strong>
            </div>
            <div className="item-weight">
              <strong>Weight</strong>
            </div>
          </div>
          {order
            ? order.Items.map(item => {
                return (
                  <div className="item-container">
                    <div className="item-quantity">{item.quantity}</div>
                    <div className="item-name">
                      {item.product_blob.name + ' '}
                      {item.ItemOptions.length > 0
                        ? item.ItemOptions.map((option, index) => {
                            if (index === 0) {
                              return '(' + option.option_name;
                            } else if (index > 0 && index !== item.ItemOptions.length - 1) {
                              return ', ' + option.option_name;
                            } else {
                              return ', ' + option.option_name + ')';
                            }
                          })
                        : null}
                    </div>
                    <div className="item-total">${item.product_price.toFixed(2)}</div>
                    <div className="item-weight">{item.weight} lbs.</div>
                  </div>
                );
              })
            : 'Something went wrong'}
        </ReactBSAlert>
      </div>
      <div id="warning-alert" hidden={!showAlert}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            reassignDriverToRoute(route);
            setShowAlert(false);
            resetExpand();
          }}
          onCancel={() => setShowAlert(false)}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to reassign a new driver.</div>
          <div>Please, ensure you've checked route status and only 1 driver can be reassigned.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <div id="warning-alert-cancel-order" hidden={!showAlertCancelOrder}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            cancelOrderAdmin(shipment, order);
            setShowAlertCancelOrder(false);
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertCancelOrder(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to cancel the order on behalf of the merchant. Please, ensure you've contacted the merchant before doing so.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <div id="warning-alert-cancel-shipment" hidden={!showAlertCancelShipment}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            cancelShipment(shipment);
            setShowAlertCancelShipment(false);
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertCancelShipment(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to cancel a shipment.</div>
          <div>Please, ensure you've contacted the merchant and if any Gophrs assigned to it.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <div id="warning-alert-remove-shipment" hidden={!showAlertRemoveShipment}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            removeShipmentFromRoute(route.route_id, shipment.shipment_id);
            setShowAlertRemoveShipment(false);
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertRemoveShipment(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to remove shipment from route.</div>
          <div>Please, ensure you've contacted the driver if driver is currently on the route. Removed shipment will be ready immediately.</div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>

      <div id="warning-alert-unassign-route" hidden={!showAlertUnassignRoute}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            unassignRoute(route);
            setShowAlertUnassignRoute(false);
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertUnassignRoute(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to unassign a route.</div>
          <div>
            Please, ensure you've confirmed with the driver/passenger(s) and <span style={{ color: 'red' }}>make sure no waypoint has been completed yet.</span> The route will be
            ready as a whole.
          </div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <div id="warning-alert-break-route" hidden={!showAlertBreakRoute}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            breakRoute(route);
            setShowAlertBreakRoute(false);
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertBreakRoute(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to break a route.</div>
          <div>
            Please, ensure you've confirmed with the driver/passenger(s) and <span style={{ color: 'red' }}>make sure no waypoint has been completed yet.</span> The selected route
            will be broke into individual ones and ready immediately.
          </div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <div id="warning-alert-merge-route" hidden={!showAlertMergeRoute}>
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-200px' }}
          title="Are you sure?"
          onConfirm={() => {
            buildRoutes(mergeRoutesList);
            setShowAlertMergeRoute(false);
            fetchMergingRoutes();
            resetExpand();
          }}
          onCancel={() => {
            setShowAlertMergeRoute(false);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          <div>You are about to merge selected routes.</div>
          <div>
            Please, ensure you've selected the desired routes for merging. <span style={{ color: 'red' }}>A new route will be generated.</span>
          </div>
          <div>This action cannot be undone.</div>
        </ReactBSAlert>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h4 style={{ color: '#000' }}>Route Description</h4>
          {/* <p>{route.distance.toFixed(2) + ' miles'}</p> */}
          <h4 style={{ color: '#000' }}>Select drivers and passengers</h4>
          <UncontrolledDropdown>
            <DropdownToggle caret id="driver-dropdown">
              {primaryDriver ? primaryDriver.User.firstname + ' ' + primaryDriver.User.lastname + ' - ' + primaryDriver.Vehicles[0].type.replace(/_/, ' ') : 'Select a Driver'}
            </DropdownToggle>
            <DropdownMenu>
              <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />

              {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                filterDriversForAssign.map((driver, key) => {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handleDriverSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                })
              ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                driversForAssign.map((driver, key) => {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handleDriverSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                  // }
                })
              ) : (
                <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <div hidden={!driverSelected}>
            <UncontrolledDropdown>
              <DropdownToggle caret id="passenger1-dropdown">
                {passenger1 ? passenger1.User.firstname + ' ' + passenger1.User.lastname + ' - ' + passenger1.Vehicles[0].type.replace(/_/, ' ') : 'Select a Passenger'}
              </DropdownToggle>
              <DropdownMenu>
                <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />
                {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                  filterDriversForAssign.map((driver, key) => {
                    if (primaryDriver !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger1Select(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                  driversForAssign.map((driver, key) => {
                    if (primaryDriver !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger1Select(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : (
                  <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div hidden={!passenger1Selected}>
            <UncontrolledDropdown>
              <DropdownToggle caret id="passenger2-dropdown">
                {passenger2 ? passenger2.User.firstname + ' ' + passenger2.User.lastname + ' - ' + passenger2.Vehicles[0].type.replace(/_/, ' ') : 'Select a Passenger'}
              </DropdownToggle>
              <DropdownMenu>
                <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />
                {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                  filterDriversForAssign.map((driver, key) => {
                    // if((primaryDriver !== driver && passenger1 !== driver) && (driver.is_available === "true" || driver.is_available === true) && driver.Vehicles.length > 0) {
                    if (primaryDriver !== driver && passenger1 !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger2Select(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                  driversForAssign.map((driver, key) => {
                    // if((primaryDriver !== driver && passenger1 !== driver) && (driver.is_available === "true" || driver.is_available === true) && driver.Vehicles.length > 0) {
                    if (primaryDriver !== driver && passenger1 !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger2Select(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : (
                  <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggle();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => handleConfirm(primaryDriver, passenger1, passenger2)}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalReassign} toggle={toggleReassign}>
        <ModalBody style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h4 style={{ color: '#000' }}>Reassign Driver</h4>
          <p>You can only reassign driver once</p>
          <h4 style={{ color: '#000' }}>Select new driver</h4>
          <UncontrolledDropdown>
            <DropdownToggle caret id="driver-dropdown">
              {driverReassign ? driverReassign.User.firstname + ' ' + driverReassign.User.lastname + ' - ' + driverReassign.Vehicles[0].type.replace(/_/, ' ') : 'Select a Driver'}
            </DropdownToggle>
            <DropdownMenu>
              <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />
              {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                filterDriversForAssign.map((driver, key) => {
                  // if(driver.is_available === true || driver.is_available === "true") {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handleReassignDriverSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                  // }
                })
              ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                driversForAssign.map((driver, key) => {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handleReassignDriverSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                  // }
                })
              ) : (
                <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleReassign();
              resetReassign();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => reassignDriverToRoute(driverReassign)}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalAssignPassengerOnly} toggle={toggleAssignPassengerOnly}>
        <ModalBody style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h4 style={{ color: '#000', textAlign: 'left', marginLeft: '5px' }}>Route: {route.route_friendly_id}</h4>
          {/* <p>{route.distance.toFixed(2) + ' miles'}</p> */}
          <p style={{ fontSize: 14, marginTop: '-5px', textAlign: 'left', marginLeft: '25px' }}>
            Requires <span style={{ color: 'red' }}>{route.num_of_gophrs}</span> Gophrs, and now <span style={{ color: 'blue' }}>{route.RouteDrivers.length}</span> on it
          </p>
          <hr style={{ backgroundColor: '#cdcaca' }}></hr>
          <h4 style={{ color: '#000' }}>Select passenger(s)</h4>

          <UncontrolledDropdown>
            <DropdownToggle caret id="passenger1-dropdown">
              {passenger1OnlyAssigned
                ? passenger1OnlyAssigned.User.firstname + ' ' + passenger1OnlyAssigned.User.lastname + ' - ' + passenger1OnlyAssigned.Vehicles[0].type.replace(/_/, ' ')
                : 'Select a Passenger'}
            </DropdownToggle>
            <DropdownMenu>
              <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />
              {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                filterDriversForAssign.map((driver, key) => {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handlePassenger1OnlyAssignedSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                })
              ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                driversForAssign.map((driver, key) => {
                  if (driver.Vehicles.length > 0) {
                    return (
                      <DropdownItem key={key} onClick={() => handlePassenger1OnlyAssignedSelect(driver)}>
                        <DriverList driver={driver} />
                      </DropdownItem>
                    );
                  }
                })
              ) : (
                <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>

          <div hidden={!passenger1OnlyAssignedSelected}>
            <UncontrolledDropdown>
              <DropdownToggle caret id="passenger2-dropdown">
                {passenger2OnlyAssigned
                  ? passenger2OnlyAssigned.User.firstname + ' ' + passenger2OnlyAssigned.User.lastname + ' - ' + passenger2OnlyAssigned.Vehicles[0].type.replace(/_/, ' ')
                  : 'Select a Passenger'}
              </DropdownToggle>
              <DropdownMenu>
                <Input type="text" value={driverName} onChange={filterDriver} style={{ color: 'black', alignSelf: 'left' }} placeholder="Driver Name" id="driver-filter" />
                {filterDriversForAssign.length > 0 && isFilterDrivers ? (
                  filterDriversForAssign.map((driver, key) => {
                    // if((primaryDriver !== driver && passenger1 !== driver) && (driver.is_available === "true" || driver.is_available === true) && driver.Vehicles.length > 0) {
                    if (passenger1OnlyAssigned !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger2OnlyAssignedSelect(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : filterDriversForAssign.length === 0 && !isFilterDrivers ? (
                  driversForAssign.map((driver, key) => {
                    // if((primaryDriver !== driver && passenger1 !== driver) && (driver.is_available === "true" || driver.is_available === true) && driver.Vehicles.length > 0) {
                    if (passenger1OnlyAssigned !== driver && driver.Vehicles.length > 0) {
                      return (
                        <DropdownItem key={key} onClick={() => handlePassenger2OnlyAssignedSelect(driver)}>
                          <DriverList driver={driver} />
                        </DropdownItem>
                      );
                    }
                  })
                ) : (
                  <p style={{ color: 'red', margin: 10, marginLeft: 25 }}>No drivers found</p>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {(passenger1OnlyAssignedSelected && passenger2OnlyAssignedSelected ? 2 : passenger1OnlyAssignedSelected ? 1 : 0) + route.RouteDrivers.length > route.num_of_gophrs ? (
            <p style={{ color: 'red', fontSize: 15, marginTop: '10px', textAlign: 'left', marginLeft: '25px' }}>
              You try to assign{' '}
              {(passenger1OnlyAssignedSelected && passenger2OnlyAssignedSelected ? 2 : passenger1OnlyAssignedSelected ? 1 : 0) + route.RouteDrivers.length - route.num_of_gophrs}{' '}
              more Gophrs than required, are you sure? If so confirm, else cancel
            </p>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              toggleAssignPassengerOnly();
              resetAssignPassengerOnly();
            }}
          >
            Cancel
          </Button>
          <Button color="success" onClick={() => assignPassengerOnlyToRoute(passenger1OnlyAssigned, passenger2OnlyAssigned)}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showDriverMessageForm} toggle={toggleDriverMessageForm}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <div className="message-logs-title">
            <h4 style={{ color: 'orange', paddingTop: '10px', marginRight: '10px' }}>SMS histories with {messageRecipientInfo.firstname + ' ' + messageRecipientInfo.lastname}</h4>
            <DriverSMSRefreshButton retrieveMessage={retrieveMessage} messageRecipient={messageRecipientInfo.phone} />
          </div>

          <MessageLogsContainer driverMessageRetrieved={driverMessageRetrieved} />
          <h4 style={{ color: 'orange' }}>Send new SMS to {messageRecipientInfo.firstname + ' ' + messageRecipientInfo.lastname}</h4>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Content:{' '}
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={10}>
              <textarea
                id="driver-message"
                value={driverMessage}
                onChange={e => {
                  setDriverMessage(e.target.value);
                }}
                style={{ color: 'black', alignSelf: 'left', width: '100%', height: '120px', paddingTop: '10px', whiteSpace: 'pre-wrap' }}
                placeholder="Would you be interested..."
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleDriverMessageForm();
              setDriverMessage('');
              setMessageRecipientInfo({});
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => sendMessage(driverMessage, messageRecipientInfo.phone)}>
            Send
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showUpdateShippingFee} toggle={toggleShippingFee}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Update Shipping Fee</h4>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Reason:{' '}
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={10}>
              <Input
                id="shipping-fee-reason"
                value={shippingFeeReason}
                onChange={e => {
                  setShippingFeeReason(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="extra weights && MS(initial)"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px` }}>
              Ship_fee($):
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={10}>
              <Input
                id="shipping-fee"
                value={shippingFee}
                onChange={e => {
                  setShippingFee(e.target.value);
                }}
                type="number"
                style={{ color: 'black' }}
                placeholder="18.25"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleShippingFee();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => updateShippingFee(shipment)}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showChargeSetupFee} toggle={toggleSetupFee}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Charge Setup Fee</h4>
          <FormGroup row>
            <Label sm={4} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'right' }}>
              Setup Time (hr):
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={7}>
              <Input
                id="setup-fee"
                value={setupDuration}
                onChange={e => {
                  setSetupDuration(e.target.value);
                }}
                type="number"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="1.0"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleSetupFee();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => chargeSetupFee(shipment)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showDriverPay} toggle={toggleDriverPay}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Driver Pay Info</h4>
          <div style={{ textAlign: 'center', marginBottom: 10, marginLeft: 30 }}>
            <Row>
              <Col style={{ textAlign: 'left', marginLeft: 50 }}>
                <p>name:</p>
                <p>status: </p>
                <p>distance earning: </p>
                <p>expedited earning: </p>
                <p>overweight earning: </p>
                <p>setup earning: </p>
                <p>tools earning: </p>
                <p>gratuity: </p>
                <p>bonus: </p>
                <p>min pay: </p>
                <p>total earning: </p>
              </Col>
              <Col style={{ textAlign: 'right', marginRight: 80 }}>
                <p>
                  {driverPay.first_name} {driverPay.last_name}
                </p>
                <p> {driverPay.driver_status}</p>
                <p> ${driverPay.distance_earning.toFixed(2)} </p>
                <p> ${driverPay.expedited_earning.toFixed(2)} </p>
                <p> ${driverPay.overweight_earning.toFixed(2)} </p>
                <p> ${driverPay.setup_earning.toFixed(2)} </p>
                <p> ${driverPay.tools_earning.toFixed(2)} </p>
                <p> ${driverPay.gratuity != 0 ? driverPay.gratuity.toFixed(2) : '0.00'} </p>
                <p> ${driverPay.bonus.toFixed(2)} </p>
                <p> ${driverPay.min_pay.toFixed(2)} </p>
                <p> ${driverPay.total_earning.toFixed(2)} </p>
              </Col>
            </Row>
          </div>

          <h4 style={{ color: 'orange' }}>Update bonus</h4>

          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Reason:
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={10}>
              <Input
                id="bonus-reason"
                value={bonusReason}
                onChange={e => {
                  setBonusReason(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="extra miles && MS(initial)"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Bonus($):{' '}
            </Label>
            {/* <InputGroup className="price-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
            <Col sm={10}>
              <Input
                id="bonus"
                value={driverBonus}
                onChange={e => {
                  setDriverBonus(e.target.value);
                }}
                type="number"
                style={{ color: 'black', alignSelf: 'center' }}
                placeholder="10.00"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleDriverPay();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => updateBonus(driverPay)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showDriverInfoAndPO} toggle={toggleDriverInfoAndPO}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Update Driver Info and/or PO#</h4>
          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              First Name:
            </Label>
            <Col sm={9}>
              <Input
                id="route-driver-first-name"
                value={routeDriverFirstName}
                onChange={e => {
                  setRouteDriverFirstName(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="John"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Last Name:
            </Label>
            <Col sm={9}>
              <Input
                id="route-driver-last-name"
                value={routeDriverLastName}
                onChange={e => {
                  setRouteDriverLastName(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="Doe"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Phone:
            </Label>
            <Col sm={9}>
              <Input
                id="route-driver-phone"
                value={routeDriverPhone}
                onChange={e => {
                  setRouteDriverPhone(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="3371234567"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              PO#
            </Label>

            <Col sm={9}>
              <Input
                id="route-driver-po#"
                value={routeDriverPONumber}
                onChange={e => {
                  setRouteDriverPONumber(e.target.value);
                }}
                type="number"
                style={{ color: 'black', alignSelf: 'center' }}
                placeholder="1234567"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleDriverInfoAndPO();
            }}
          >
            Cancel
          </Button>
          {(routeDriverFirstName !== '' || routeDriverLastName !== '' || routeDriverPhone !== '' || routeDriverPONumber !== '') && (
            <Button color="info" onClick={() => updateRouteDriverInfoAndPO(routeDriverRouteId)}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={showUpdateOrderInfo} toggle={toggleShowUpdateOrderInfo}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Update Order Info</h4>
          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              First Name:
            </Label>
            <Col sm={9}>
              <Input
                id="update-order-first_name"
                value={updateOrderInfo.first_name}
                onChange={e => {
                  setUpdateOrderInfo({ ...updateOrderInfo, first_name: e.target.value });
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="John"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Last Name:
            </Label>
            <Col sm={9}>
              <Input
                id="update-order-last_name"
                value={updateOrderInfo.last_name}
                onChange={e => {
                  setUpdateOrderInfo({ ...updateOrderInfo, last_name: e.target.value });
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="Doe"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Phone:
            </Label>
            <Col sm={9}>
              <Input
                id="update-order-phone"
                value={updateOrderInfo.phone}
                onChange={e => {
                  setUpdateOrderInfo({ ...updateOrderInfo, phone: e.target.value });
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="3371234567"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Business Name:
            </Label>
            <Col sm={9}>
              <Input
                id="update-order-business_name"
                value={updateOrderInfo.business_name}
                onChange={e => {
                  setUpdateOrderInfo({ ...updateOrderInfo, business_name: e.target.value });
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="Home Depot"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              {orderType} Instruction:
            </Label>

            <Col sm={9}>
              <Input
                id="update-order-instruction"
                value={updateOrderInfo.instruction}
                onChange={e => {
                  setUpdateOrderInfo({ ...updateOrderInfo, instruction: e.target.value });
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'center' }}
                placeholder="call 10 minutes before arrival"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleShowUpdateOrderInfo();
            }}
          >
            Cancel
          </Button>
          {Object.values(updateOrderInfo).some(value => value.trim() !== '') && (
            <Button color="info" onClick={() => updateOrderInfoForAdmin(orderId)}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={showReorderWaypoints} toggle={toggleReorderWaypoints}>
        <ModalBody style={{ alignSelf: 'center', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Reorder Route</h4>
          <div style={{ alignSelf: 'center' }}>
            <ReorderWaypoints routeWaypoints={routeWaypoints} setRouteWaypoints={setRouteWaypoints} />
            {isReorderError && <h5 style={{ color: 'red' }}>{reorderError}</h5>}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleReorderWaypoints();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => handleReorder(route.route_id, routeWaypoints)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showGasPrice} toggle={toggleGasPrice}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'black' }}>
            Update Fuel Price(s) for Market: <span style={{ color: 'red' }}>{marketName} </span>
          </h4>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Gas($):
            </Label>
            <Col sm={10}>
              <Input
                id="gas-price"
                value={gasPriceUpdate}
                onChange={e => {
                  setGasPriceUpdate(e.target.value);
                  if (e.target.value != '') {
                    if (e.target.value <= 0 || e.target.value >= 10) {
                      setGasPriceError(true);
                    } else {
                      setGasPriceError(false);
                    }
                  } else {
                    setGasPriceError(false);
                  }
                }}
                type="number"
                style={{ color: 'black' }}
                placeholder="3.99"
              />
            </Col>
          </FormGroup>
          {gasPriceError && (
            <p style={{ color: 'red', fontSize: 13, marginTop: '10px', textAlign: 'left', marginLeft: '80px', marginBottom: '10px' }}>Make Sure to input the right value</p>
          )}
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Diesel($):
            </Label>
            <Col sm={10}>
              <Input
                id="diesel-price"
                value={dieselPriceUpdate}
                onChange={e => {
                  setDieselPriceUpdate(e.target.value);
                  if (e.target.value != '') {
                    if (e.target.value <= 0 || e.target.value >= 10) {
                      setDieselPriceError(true);
                    } else {
                      setDieselPriceError(false);
                    }
                  } else {
                    setDieselPriceError(false);
                  }
                }}
                type="number"
                style={{ color: 'black' }}
                placeholder="4.99"
              />
            </Col>
          </FormGroup>
          {dieselPriceError && (
            <p style={{ color: 'red', fontSize: 13, marginTop: '10px', textAlign: 'left', marginLeft: '80px', marginBottom: '10px' }}>Make Sure to input the right value</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleGasPrice();
            }}
          >
            Cancel
          </Button>
          {!gasPriceError && !dieselPriceError && ((gasPriceUpdate != null && gasPriceUpdate != '') || (dieselPriceUpdate != null && dieselPriceUpdate != '')) && (
            <Button color="info" onClick={() => handleGasPriceUpdate(gasPriceUpdate, dieselPriceUpdate)}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={showGasPriceRegion} toggle={toggleGasPriceRegion}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          {region && (
            <div>
              <h4 style={{ color: 'black' }}>
                Update Fuel Price(s) for Region: <span style={{ color: 'red' }}>{region.region_name}</span>
              </h4>

              <h5 style={{ color: 'black', fontSize: '1.1em' }}>Current Fuel Price For Each Market </h5>
              <div style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                  <div style={{ flex: 1 }}>Market</div>
                  <div style={{ flex: 1 }}>Gas Price($)</div>
                  <div style={{ flex: 1 }}>Diesel Price($)</div>
                </div>
                {region.Markets.map((market, index) => (
                  <div key={index} style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>{market.market_name}</div>
                    <div style={{ flex: 1 }}>{market.fuel_prices.gas_price.toFixed(2)}</div>
                    <div style={{ flex: 1 }}>{market.fuel_prices.diesel_price.toFixed(2)}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Gas($):
            </Label>
            <Col sm={10}>
              <Input
                id="gas-price"
                value={gasPriceUpdate}
                onChange={e => {
                  setGasPriceUpdate(e.target.value);
                  if (e.target.value != '') {
                    if (e.target.value <= 0 || e.target.value >= 10) {
                      setGasPriceError(true);
                    } else {
                      setGasPriceError(false);
                    }
                  } else {
                    setGasPriceError(false);
                  }
                }}
                type="number"
                style={{ color: 'black' }}
                placeholder="3.99"
              />
            </Col>
          </FormGroup>
          {gasPriceError && (
            <p style={{ color: 'red', fontSize: 13, marginTop: '10px', textAlign: 'left', marginLeft: '80px', marginBottom: '10px' }}>Make Sure to input the right value</p>
          )}
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Diesel($):
            </Label>
            <Col sm={10}>
              <Input
                id="diesel-price"
                value={dieselPriceUpdate}
                onChange={e => {
                  setDieselPriceUpdate(e.target.value);
                  if (e.target.value != '') {
                    if (e.target.value <= 0 || e.target.value >= 10) {
                      setDieselPriceError(true);
                    } else {
                      setDieselPriceError(false);
                    }
                  } else {
                    setDieselPriceError(false);
                  }
                }}
                type="number"
                style={{ color: 'black' }}
                placeholder="4.99"
              />
            </Col>
          </FormGroup>
          {dieselPriceError && (
            <p style={{ color: 'red', fontSize: 13, marginTop: '10px', textAlign: 'left', marginLeft: '80px', marginBottom: '10px' }}>Make Sure to input the right value</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleGasPriceRegion();
            }}
          >
            Cancel
          </Button>
          {!gasPriceError && !dieselPriceError && ((gasPriceUpdate != null && gasPriceUpdate != '') || (dieselPriceUpdate != null && dieselPriceUpdate != '')) && (
            <Button color="info" onClick={() => handleGasPriceUpdateThroughRegion(gasPriceUpdate, dieselPriceUpdate)}>
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={showMarketNote} toggle={toggleMarketNote}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'black' }}>
            Update Note for Market: <span style={{ color: 'red' }}>{marketName} </span>
          </h4>
          <p style={{ textAlign: 'left', marginBottom: '20px' }}>
            <span style={{ color: 'blue' }}>Current Note:</span> {marketNote.note}
          </p>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Note:
            </Label>
            <Col sm={10}>
              <Input
                id="market-note"
                value={marketNoteUpdate}
                onChange={e => {
                  setMarketNoteUpdate(e.target.value);
                }}
                type="text"
                style={{ color: 'black' }}
                placeholder="Keep empty and save to clear note"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleMarketNote();
            }}
          >
            Cancel
          </Button>

          <Button color="info" onClick={() => handleMarketNoteUpdate(marketNoteUpdate)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showMarketNoteRegion} toggle={toggleMarketNoteRegion}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          {regionNote && (
            <div>
              <h4 style={{ color: 'black' }}>
                Update Market Note for Region: <span style={{ color: 'red' }}>{regionNote.region_name}</span>
              </h4>

              <h5 style={{ color: 'black', fontSize: '1.1em' }}>Current Note For Each Market </h5>
              <div style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                  <div style={{ flex: 1 }}>Market</div>
                  <div style={{ flex: 3 }}>Note</div>
                </div>
                {regionNote.Markets.map((market, index) => (
                  <div key={index} style={{ display: 'flex' }}>
                    <div style={{ flex: 1, color: 'blue' }}>{market.market_name}</div>
                    <div style={{ flex: 3, textAlign: 'left' }}>{market.note}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Note:
            </Label>
            <Col sm={10}>
              <Input
                id="region-market-note"
                value={marketNoteUpdate}
                onChange={e => {
                  setMarketNoteUpdate(e.target.value);
                }}
                type="text"
                style={{ color: 'black' }}
                placeholder="Keep empty and save to clear note"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleMarketNoteRegion();
            }}
          >
            Cancel
          </Button>

          <Button color="info" onClick={() => handleMarketNoteUpdateThroughRegion(marketNoteUpdate)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showUpdateDriverPayBeforeClaim} toggle={toggleUpdateDriverPayBeforeClaim}>
        <ModalBody style={{ alignSelf: 'left', textAlign: 'center', display: 'block' }}>
          <h4 style={{ color: 'orange' }}>Driver Pay Info</h4>
          <div style={{ textAlign: 'center', marginBottom: 10, marginLeft: 30 }}>
            <Row>
              <Col style={{ textAlign: 'left', marginLeft: 50 }}>
                <p>distance earning: </p>
                <p>expedited earning: </p>
                <p>overweight earning: </p>
                <p>setup earning: </p>
                <p>tools earning: </p>
                <p>gratuity: </p>
                <p>bonus: </p>
                <p>min pay: </p>
                <p>total earning: </p>
              </Col>
              {shipment && (
                <Col style={{ textAlign: 'right', marginRight: 80 }}>
                  <p> ${shipment.shipping_fee_blob.driverEarning.distanceEarning_1099.toFixed(2)} </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.expeditedEarning_1099.toFixed(2)} </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.weightEarning.toFixed(2)} </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.setup_earning.toFixed(2)} </p>
                  <p> ${driverPay.tools_earning.toFixed(2)} </p>
                  <p> $0.00 </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.bonus ? shipment.shipping_fee_blob.driverEarning.bonus.toFixed(2) : '0.00'} </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.minDriverPay_1099.toFixed(2)} </p>
                  <p> ${shipment.shipping_fee_blob.driverEarning.driver_1099_earning.toFixed(2)} </p>
                </Col>
              )}
            </Row>
          </div>

          <h4 style={{ color: 'orange' }}>Update Total Earning</h4>

          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Reason:
            </Label>
            <Col sm={10}>
              <Input
                id="total-earning-reason"
                value={updateDriverPayBeforeClaimReason}
                onChange={e => {
                  setUpdateDriverPayBeforeClaimReason(e.target.value);
                }}
                type="text"
                style={{ color: 'black', alignSelf: 'left' }}
                placeholder="extra miles && MS(initial)"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ color: `#494747`, fontSize: `12px`, textAlign: 'left' }}>
              Total($):{' '}
            </Label>
            <Col sm={10}>
              <Input
                id="bonus"
                value={driverPayBeforeClaimTotalAmount}
                onChange={e => {
                  setDriverPayBeforeClaimTotalAmount(e.target.value);
                }}
                type="number"
                style={{ color: 'black', alignSelf: 'center' }}
                placeholder="10.00"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            onClick={() => {
              toggleUpdateDriverPayBeforeClaim();
            }}
          >
            Cancel
          </Button>
          <Button color="info" onClick={() => updateTotalDriverPayBeforeClaim(shipment)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DispatchOrderTable;
