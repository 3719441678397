import React from 'react';
import { Styles } from './Styles/MerchantListHeaderStyles';
import { AddIcon } from '../../assets/svgs/WhiteIcons';

const MerchantListHeader = ({ role }) => {
  return (
    <Styles>
      <h3>{role < 200 ? 'Customers' : 'My Store'}</h3>
      {/* <button className="create-merchant-btn"><AddIcon />Create Merchant</button> */}
    </Styles>
  );
};

export default MerchantListHeader;
