/*!
 * Black Dashboard PRO React - v1.0.0
 * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Users from '../src/views/Users.jsx';
import UserSubMerchants from '../src/views/UserSubMerchants.jsx';
import Dashboard from '../src/components/Global/Dashboard.jsx';
import MerchantList from '../src/components/Merchant/MerchantList';
import CreateDeliveryForMerchant from '../src/components/Dispatch/CreateOrderForMerchant/MainPage';
import DocumentationViewer from './components/W9-COL/DocumentationViewer.js';

const merchantRoutes = [
  {
    collapse: false,
    name: 'User Management',
    path: '/users',
    rtlName: 'المكونات',
    icon: 'tim-icons icon-badge',
    component: UserSubMerchants,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Order Dashboard',
    path: '/dashboard',
    rtlName: 'إستمارات',
    icon: 'tim-icons icon-delivery-fast',
    component: Dashboard,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Inventory Manager',
    path: '/inventory',
    icon: 'tim-icons icon-settings',
    component: MerchantList,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Documentation',
    path: '/documentation',
    icon: 'tim-icons icon-paper',
    component: DocumentationViewer,
    layout: '/admin'
  }
];

const inventoryManagerRoutes = [
  {
    collapse: false,
    name: 'Order Dashboard',
    path: '/dashboard',
    rtlName: 'إستمارات',
    icon: 'tim-icons icon-delivery-fast',
    component: Dashboard,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Inventory Manager',
    path: '/inventory',
    icon: 'tim-icons icon-settings',
    component: MerchantList,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Documentation',
    path: '/documentation',
    icon: 'tim-icons icon-paper',
    component: DocumentationViewer,
    layout: '/admin'
  }
];

const pickerRoutes = [
  {
    collapse: false,
    name: 'Order Dashboard',
    path: '/dashboard',
    rtlName: 'إستمارات',
    icon: 'tim-icons icon-delivery-fast',
    component: Dashboard,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Documentation',
    path: '/documentation',
    icon: 'tim-icons icon-paper',
    component: DocumentationViewer,
    layout: '/admin'
  }
];

const adminRoutes = [
  {
    collapse: false,
    name: 'User Management',
    path: '/users',
    rtlName: 'المكونات',
    icon: 'tim-icons icon-badge',
    component: Users,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Shipment Dashboard',
    path: '/dashboard',
    rtlName: 'إستمارات',
    icon: 'tim-icons icon-delivery-fast',
    component: Dashboard,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Inventory Manager',
    path: '/inventory',
    icon: 'tim-icons icon-bank',
    component: MerchantList,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Delivery Creation',
    path: '/delivery_creation',
    icon: 'tim-icons icon-cart',
    component: CreateDeliveryForMerchant,
    layout: '/admin'
  },
  {
    collapse: false,
    name: 'Documentation',
    path: '/documentation',
    icon: 'tim-icons icon-paper',
    component: DocumentationViewer,
    layout: '/admin'
  }
];

export { merchantRoutes, adminRoutes, pickerRoutes, inventoryManagerRoutes };
